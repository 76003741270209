import React from "react";
import { Form, Row, Col, Button } from "antd";
import i18n from "../../../misc/i18n";
import { withAuth, AuthContextProps } from "oidc-react";
import FsFrame from "../reportbuilder/fsframe";
import report from "./_DSGVOFarmerReport";
import FarmerIdSelect from "./farmerIdSelect/farmerIdSelect";
interface IProps extends AuthContextProps {}

type IState = {
    reportUri: string;
    form: any;
    formVisible: boolean;
    formFields: any;
    language: string;
};
class DSGVOFarmerReport extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            reportUri: "",
            form: React.createRef(),
            formVisible: true,
            formFields: report.formItems,
            language: ""
        };
    }

    ///ReportServer/Pages/ReportViewer.aspx?/Grenzwerte GrassSilage&CompanyId=80&EndDate=24.02.2021&Perimeter=10&StartDate=24.02.2019&Type=Gras_silage&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer
    setUri() {
        let query = "/ReportServer/Pages/ReportViewer.aspx?/DSGVO_Ansicht&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer";

        let farmerField = this.findFormfield("farmerId");
        if (farmerField != null && typeof farmerField.value !== "undefined") {
            query += "&FarmerId=" + farmerField.value.farmerId;
        } else {
        }

        let language = "";
        if (this.state.language === "de") {
            language = "German";
        } else if (this.state.language === "cz") {
            language = "Czech Republic";
        } else {
            language = "English";
        }
        query += "&Language=" + language;

        this.setState({
            reportUri: query
        });


    }

    findFormfield(fieldname: string) {
        return this.state.formFields.find((obj: any) => {
            if (Array.isArray(obj.name)) {
                return obj.name.some((item: string) => {
                    return item === fieldname;
                });
            } else {
                return obj.name === fieldname;
            }
        });
    }

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2)
                },
                () => {
                    this.setUri();
                }
            );
        }
    }

    componentDidMount() {
        this.setState({}, () => this.setUri());
    }

    onChange = (newFields: any) => {
        this.setState(
            {
                formFields: newFields
            },
            () => {
                this.setUri();
            }
        );
    };

    render() {
        return (
            <>
                <div className="list-detail-wrap">
                    <Row>
                        <Col span={24}>
                            <div className={this.state.formVisible ? "report-form" : "report-form hidden"}>
                                <Form
                                    ref={this.state.form}
                                    size="small"
                                    onFieldsChange={(_, allFields) => {
                                        this.onChange(allFields);
                                    }}
                                    initialValues={{}}
                                >
                                    <Row gutter={24}>
                                        <Col xs={24} md={8} xl={8} key={1}>
                                            <Row>
                                                <Col md={4} xl={4}>
                                                    <div>{i18n.t("form.label.report.label.FarmerId") + " :"}</div>
                                                </Col>
                                                <Col md={20} xl={20}>
                                                    <Form.Item name="farmerId" style={{ marginBottom: "12px" }}>
                                                        <FarmerIdSelect />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="form-collapse-wrap">
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            formVisible: !this.state.formVisible
                                        });
                                    }}
                                >
                                    {this.state.formVisible ? <i className="fal fa-chevron-square-up"></i> : <i className="fal fa-chevron-square-down"></i>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FsFrame uri={this.state.reportUri} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withAuth(DSGVOFarmerReport);
