/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    CvasDokumentInhalt,
    CvasDokumentInhaltFromJSON,
    CvasDokumentInhaltToJSON,
    PageProbenbestellung,
    PageProbenbestellungFromJSON,
    PageProbenbestellungToJSON,
    Probenbestellung,
    ProbenbestellungFromJSON,
    ProbenbestellungToJSON,
} from '../models';

export interface AnonymizeRequest {
    body: string;
}

export interface DeleteCVASRequest {
    probenNummer: string;
}

export interface DeleteProbeRequest {
    probenId: number;
}

export interface FindCvasDokumentInhaltRequest {
    probenNummer: string;
    dokumentArt: string;
}

export interface FindDistinctAnalyseTypeRequest {
    land?: Array<string>;
    bundesland?: Array<string>;
    region?: Array<string>;
    plz?: Array<string>;
    probenart?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface FindDistinctAnalyseTypeForXRFRequest {
    startDate?: Date;
    endDate?: Date;
}

export interface FindDistinctSampleTypeRequest {
    land?: Array<string>;
    bundesland?: Array<string>;
    region?: Array<string>;
    plz?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface FindDistinctSampleTypeForXRFRequest {
    startDate?: Date;
    endDate?: Date;
}

export interface FindFilteredRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindProbenByQueryRequest {
    q: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface FindProbenByQueryForLaborbuchRequest {
    q: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface FindProbenbestellungByIdRequest {
    probenId: number;
}

export interface FindSamplesForXrfRequest {
    type: string;
    betriebsId: string;
    search?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface UpdateProbenbestellungRequest {
    id: number;
    body: string;
}

/**
 * 
 */
export class ProbenbestellungControllerApi extends runtime.BaseAPI {

    /**
     * This method must only been used between the REST Webservice and the Importer Webservice. Not for calls except REST Webservice.
     */
    async anonymizeRaw(requestParameters: AnonymizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling anonymize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/anonymize/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     * This method must only been used between the REST Webservice and the Importer Webservice. Not for calls except REST Webservice.
     */
    async anonymize(requestParameters: AnonymizeRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.anonymizeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteCVASRaw(requestParameters: DeleteCVASRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.probenNummer === null || requestParameters.probenNummer === undefined) {
            throw new runtime.RequiredError('probenNummer','Required parameter requestParameters.probenNummer was null or undefined when calling deleteCVAS.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/deleteCVAS/{probenNummer}`.replace(`{${"probenNummer"}}`, encodeURIComponent(String(requestParameters.probenNummer))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteCVAS(requestParameters: DeleteCVASRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteCVASRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteProbeRaw(requestParameters: DeleteProbeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.probenId === null || requestParameters.probenId === undefined) {
            throw new runtime.RequiredError('probenId','Required parameter requestParameters.probenId was null or undefined when calling deleteProbe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/deleteProbenbestellung/{probenId}`.replace(`{${"probenId"}}`, encodeURIComponent(String(requestParameters.probenId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteProbe(requestParameters: DeleteProbeRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteProbeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findCvasDokumentInhaltRaw(requestParameters: FindCvasDokumentInhaltRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<CvasDokumentInhalt>> {
        if (requestParameters.probenNummer === null || requestParameters.probenNummer === undefined) {
            throw new runtime.RequiredError('probenNummer','Required parameter requestParameters.probenNummer was null or undefined when calling findCvasDokumentInhalt.');
        }

        if (requestParameters.dokumentArt === null || requestParameters.dokumentArt === undefined) {
            throw new runtime.RequiredError('dokumentArt','Required parameter requestParameters.dokumentArt was null or undefined when calling findCvasDokumentInhalt.');
        }

        const queryParameters: any = {};

        if (requestParameters.probenNummer !== undefined) {
            queryParameters['probenNummer'] = requestParameters.probenNummer;
        }

        if (requestParameters.dokumentArt !== undefined) {
            queryParameters['dokumentArt'] = requestParameters.dokumentArt;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/cvasDokumentInhalt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => CvasDokumentInhaltFromJSON(jsonValue));
    }

    /**
     */
    async findCvasDokumentInhalt(requestParameters: FindCvasDokumentInhaltRequest, initOverrides?: RequestInit): Promise<CvasDokumentInhalt> {
        const response = await this.findCvasDokumentInhaltRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDistinctAnalyseTypeRaw(requestParameters: FindDistinctAnalyseTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.land) {
            queryParameters['land'] = requestParameters.land;
        }

        if (requestParameters.bundesland) {
            queryParameters['bundesland'] = requestParameters.bundesland;
        }

        if (requestParameters.region) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.plz) {
            queryParameters['plz'] = requestParameters.plz;
        }

        if (requestParameters.probenart) {
            queryParameters['probenart'] = requestParameters.probenart;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/findDistinctAnalyseTypeForLaborstatistik`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDistinctAnalyseType(requestParameters: FindDistinctAnalyseTypeRequest = {}, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.findDistinctAnalyseTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDistinctAnalyseTypeForXRFRaw(requestParameters: FindDistinctAnalyseTypeForXRFRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/distinctAnalyseTypeForXRF`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDistinctAnalyseTypeForXRF(requestParameters: FindDistinctAnalyseTypeForXRFRequest = {}, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.findDistinctAnalyseTypeForXRFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDistinctSampleTypeRaw(requestParameters: FindDistinctSampleTypeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.land) {
            queryParameters['land'] = requestParameters.land;
        }

        if (requestParameters.bundesland) {
            queryParameters['bundesland'] = requestParameters.bundesland;
        }

        if (requestParameters.region) {
            queryParameters['region'] = requestParameters.region;
        }

        if (requestParameters.plz) {
            queryParameters['plz'] = requestParameters.plz;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/findDistinctSampleTypeForLaborstatistik`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDistinctSampleType(requestParameters: FindDistinctSampleTypeRequest = {}, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.findDistinctSampleTypeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDistinctSampleTypeForXRFRaw(requestParameters: FindDistinctSampleTypeForXRFRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/distinctSampleTypeForXRF`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDistinctSampleTypeForXRF(requestParameters: FindDistinctSampleTypeForXRFRequest = {}, initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.findDistinctSampleTypeForXRFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDistinctSampleTypesRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/findDistinctSampleTypes`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDistinctSampleTypes(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.findDistinctSampleTypesRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findFilteredRaw(requestParameters: FindFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageProbenbestellung>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProbenbestellungFromJSON(jsonValue));
    }

    /**
     */
    async findFiltered(requestParameters: FindFilteredRequest = {}, initOverrides?: RequestInit): Promise<PageProbenbestellung> {
        const response = await this.findFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findProbenByQueryRaw(requestParameters: FindProbenByQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageProbenbestellung>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling findProbenByQuery.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProbenbestellungFromJSON(jsonValue));
    }

    /**
     */
    async findProbenByQuery(requestParameters: FindProbenByQueryRequest, initOverrides?: RequestInit): Promise<PageProbenbestellung> {
        const response = await this.findProbenByQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findProbenByQueryForLaborbuchRaw(requestParameters: FindProbenByQueryForLaborbuchRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageProbenbestellung>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling findProbenByQueryForLaborbuch.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/findProbenByQueryForLaborbuch`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageProbenbestellungFromJSON(jsonValue));
    }

    /**
     */
    async findProbenByQueryForLaborbuch(requestParameters: FindProbenByQueryForLaborbuchRequest, initOverrides?: RequestInit): Promise<PageProbenbestellung> {
        const response = await this.findProbenByQueryForLaborbuchRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findProbenbestellungByIdRaw(requestParameters: FindProbenbestellungByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Probenbestellung>> {
        if (requestParameters.probenId === null || requestParameters.probenId === undefined) {
            throw new runtime.RequiredError('probenId','Required parameter requestParameters.probenId was null or undefined when calling findProbenbestellungById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/{probenId}`.replace(`{${"probenId"}}`, encodeURIComponent(String(requestParameters.probenId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ProbenbestellungFromJSON(jsonValue));
    }

    /**
     */
    async findProbenbestellungById(requestParameters: FindProbenbestellungByIdRequest, initOverrides?: RequestInit): Promise<Probenbestellung> {
        const response = await this.findProbenbestellungByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findSamplesForXrfRaw(requestParameters: FindSamplesForXrfRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Probenbestellung>>> {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type','Required parameter requestParameters.type was null or undefined when calling findSamplesForXrf.');
        }

        if (requestParameters.betriebsId === null || requestParameters.betriebsId === undefined) {
            throw new runtime.RequiredError('betriebsId','Required parameter requestParameters.betriebsId was null or undefined when calling findSamplesForXrf.');
        }

        const queryParameters: any = {};

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.betriebsId !== undefined) {
            queryParameters['betriebsId'] = requestParameters.betriebsId;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/samplesForXRF`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ProbenbestellungFromJSON));
    }

    /**
     */
    async findSamplesForXrf(requestParameters: FindSamplesForXrfRequest, initOverrides?: RequestInit): Promise<Array<Probenbestellung>> {
        const response = await this.findSamplesForXrfRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateProbenbestellungRaw(requestParameters: UpdateProbenbestellungRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateProbenbestellung.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateProbenbestellung.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/probenB/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateProbenbestellung(requestParameters: UpdateProbenbestellungRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateProbenbestellungRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
