import React from "react";
import importstatus from "./_importstatus";
import { List, Button } from "antd";
import * as Api from "../../../../api/importstatus";
import helper from "../listdetail/listtable/_helper";
import ListTable from "../listdetail/listtable";
import { getImportApiConfig } from "../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from "../../../misc/notification";
import LoadMask from "../../../misc/loadmask";
import i18n from "../../../misc/i18n";

interface IProps extends AuthContextProps {}

interface IState {
    loading: boolean;
    listdata: any;
    detaildata: any;
    pageSize: number;
    detailVisible: boolean;
    conf: any;
    ownloadLoading: boolean;
    pagination?: any;
    downloadLoading?: boolean;
}

class ImportStatus extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: 20,
            detailVisible: false,
            conf: importstatus,
            ownloadLoading: false
        };
    }

    _getApi() {
        return new Api.ImportControllerApi(getImportApiConfig("importstate", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp: Api.PageSdnImportJob) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            detailVisible: false,
            detaildata: {},
            loading: false,
            pagination: {
                total: resp.totalElements,
                pageSize: this.state.pageSize
            }
        });
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        try {
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort
            };
            let resp = await this._getApi().findFiltered1(requestBody);
            this._setListState(resp);
        } catch (e) {
            userNotification("error", "notification.import.get.error");
            this.setState({
                loading: false
            });
        }
    }
    saveByteArray(reportName: string, byte: Uint8Array) {
        let blob = new Blob([byte], { type: "application/txt" });
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.click();
    }
    base64ToArrayBuffer(base64: string) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    async downloadErrorFile(record: any) {
        this.setState({
            downloadLoading: true
        });
        try {
            let requestBody = {
                id: record.importJobId
            }
            let cvasResp = await this._getApi().createErrorFile(requestBody);
            if (cvasResp != null) {
                this.setState({
                    downloadLoading: false
                });
                if (cvasResp.dokumentInhalt != null) {
                    let bytes = this.base64ToArrayBuffer(cvasResp.dokumentInhalt);
                    this.saveByteArray(record.euNummer + "_" + record.systemName + ".txt", bytes);
                }
            }
        } catch (e) {
            userNotification("error", "notification.order.content.get.error");
            this.setState({
                downloadLoading: false
            });
        }
    }

    getExpandable() {
        return {
            expandedRowRender: (record: any) => {
                let errors = [];
                for (let i = 0; i < record.sdnImportJobErrors.length; i++) {
                    errors.push(record.sdnImportJobErrors[i].errorText);
                }
                if (errors.length <= 1) {
                    return (
                        <List
                            size="small"
                            bordered={false}
                            footer={
                                <div className="importstatus-detail-footer">
                                    <Button
                                        size="middle"
                                        onClick={() => {
                                            navigator.clipboard.writeText(record.externerLink);
                                        }}
                                    >
                                        <i className="fas fa-copy"></i>
                                    </Button>{" "}
                                    {record.externerLink}
                                </div>
                            }
                            dataSource={errors}
                            renderItem={(item) => <List.Item>{item}</List.Item>}
                        />
                    );
                } else {
                    let toShow = [errors[0]];
                    return (
                        <>
                            <List
                                size="small"
                                bordered={false}
                                footer={
                                    <div className="importstatus-detail-footer">
                                        <Button
                                            size="middle"
                                            onClick={() => {
                                                navigator.clipboard.writeText(record.externerLink);
                                            }}
                                        >
                                            <i className="fas fa-copy"></i>
                                        </Button>{" "}
                                        {record.externerLink}
                                    </div>
                                }
                                dataSource={toShow}
                                renderItem={(item) => <List.Item>{item}</List.Item>}
                            />
                            <Button
                                size="middle"
                                onClick={() => {
                                    this.downloadErrorFile(record);
                                }}
                            >
                                {i18n.t("table.col.title.download")}
                            </Button>
                        </>
                    );
                }
            },
            rowExpandable: (record: any) => {
                return typeof record.sdnImportJobErrors !== "undefined" ? true : false;
            },
            expandRowByClick: true
        };
    }

    render() {
        return (
            // <div className="sano-importstatus-wrap">
            <div className="list-detail-wrap">
                {this.state.loading ? <LoadMask /> : null}
                <ListTable
                    cols={this.state.conf.columns}
                    renderTitle={this.state.conf.renderTitle}
                    renderRecordSub={this.state.conf.renderRecordSub}
                    data={this.state.listdata.length ? this.state.listdata : []}
                    pagination={this.state.pagination}
                    onTableChange={(p: any) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                    expandable={this.getExpandable()}
                />
            </div>
        );
    }
}
export default withAuth(ImportStatus);
