import React from 'react';
import i18n from "../../../../../misc/i18n"
import { Typography } from 'antd';
const {Paragraph} = Typography
interface IProps{
    value: string
}

export default class DisplayProbenartPlaintext extends  React.Component<IProps>{

    render(){
        return (
            <Paragraph className="deparagraph"> {this.props.value != null ? i18n.t("order.probenart."+this.props.value)+"" : this.props.value } </Paragraph>
        )
            
    }

}
