import React from 'react';
import { Select, Spin } from 'antd';
import * as Api from '../../../../../api/importstatus';
import { getImportApiConfig } from '../../../../../_conf';
import { AuthContextProps, withAuth } from "oidc-react";
import i18n from '../../../../misc/i18n';
import areArraysEqual from '../../../../misc/arraysEqual';

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: string[];
    language: string;
    alli18n: string;
    land: string[];
    state: string[];
    region: string[];
    plz: string[];
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    disabled: boolean | undefined;
    defaultValue: string[] | undefined;
    land: string[];
    state: string[];
    region: string[];
    plz: string[];
    startDate: any;
    endDate:any;
}

class ProbenartSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            land: [],
            state: [],
            region: [],
            plz: [],
            value: this.props.defaultValue ? this.props.defaultValue : [],
            language: '',
            alli18n: 'order.probenart.all'
        };
    }
    componentDidMount() {
        this._getSelectData();
    }

    componentDidUpdate(prevProps:IProps) {
        if (i18n.language !== this.state.language) {
            let values = this.state.value;
            for (let i = 0; i < values.length; i++) {
                //Alte Übersetzung mit neuer austauschen
                if (values[i] === i18n.t(this.state.alli18n, { lng: this.state.language })) {
                    values[i] = i18n.t(this.state.alli18n);
                }
            }
            this.setState(
                {
                    language: i18n.language,
                    value: values
                },
                () => {
                    if (this.props.onChange != null) {
                        this.props.onChange(values, []);
                    }
                }
            );
        }
        if (
            this.props.land.length !== this.state.land.length ||
            this.props.state.length !== this.state.state.length ||
            this.props.region.length !== this.state.region.length ||
            this.props.plz.length !== this.state.plz.length
        ) {
            this.setState(
                {
                    land: this.props.land,
                    state: this.props.state,
                    region: this.props.region,
                    plz: this.props.plz
                },
                () => {
                    let newVal = this.props.defaultValue ? this.props.defaultValue : [];
                    if (this.props.land.length === 0 || this.props.plz.length === 0 || this.props.state.length === 0 || this.props.region.length === 0) {
                        newVal = [];
                    }
                    if (this.props.onChange !== undefined) {
                        this.props.onChange(newVal, {});
                    }
                    this.setState({
                        value: newVal
                    });
                    if (this.props.land.length > 0 && this.props.state.length > 0 && this.props.region.length > 0 && this.props.plz.length > 0) {
                        this._getSelectData();
                    }
                    
                }
            );
        } else {
            if (this.props.land.length > 0 && this.props.state.length > 0 && this.props.region.length > 0 && this.props.plz.length > 0) {
                if (
                    !areArraysEqual(this.props.land, this.state.land) ||
                    !areArraysEqual(this.props.state, this.state.state) ||
                    !areArraysEqual(this.props.region, this.state.region) ||
                    !areArraysEqual(this.props.plz, this.state.plz)
                ) {
                    this.setState(
                        {
                            land: this.props.land,
                            state: this.props.state,
                            region: this.props.region,
                            plz: this.props.plz
                        },
                        () => {
                            if (this.props.onChange !== undefined) {
                                this.props.onChange(this.props.defaultValue ? this.props.defaultValue : [], {});
                            }
                            this.setState({
                                value: this.props.defaultValue ? this.props.defaultValue : []
                            });
                            this._getSelectData();
                        }
                    );
                }
            }
        }
        //Wenn das Datum geändert wurde neue Daten laden
        if(this.props.startDate.toISOString() !== prevProps.startDate.toISOString() || this.props.endDate.toISOString() !== prevProps.endDate.toISOString()){
            this._getSelectData();
        }
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        try {
            let api = new Api.ProbenbestellungControllerApi(getImportApiConfig('probeorder', this.props.userData));
            let land = this.props.land.includes(i18n.t(this.state.alli18n)) ? undefined : this.props.land;
            let state = this.props.state.includes(i18n.t(this.state.alli18n)) ? undefined : this.props.state;
            let region = this.props.region.includes(i18n.t(this.state.alli18n)) ? undefined : this.props.region;
            let plz = this.props.plz.includes(i18n.t(this.state.alli18n)) ? undefined : this.props.plz;
            let requestBody = {
                land: land,
                bundesland: state,
                region: region,
                plz: plz,
                startDate: this.props.startDate,
                endDate: this.props.endDate
            }
            let resp = await api.findDistinctSampleType(requestBody);

            if (resp !== undefined) {
                this.setState({
                    selectdata: resp,
                    loading: false
                });
            } else {
                this.setState({
                    selectdata: resp,
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    onChange = (value: string[], option: any) => {
        if (value.includes(i18n.t(this.state.alli18n)) && !this.state.value.includes(i18n.t(this.state.alli18n))) {
            value = [i18n.t(this.state.alli18n)];
            // Prüft ob alle vorausgewählt ist und nun eine spezifiesche Auswahl getroffen wurde und entfernt dann den Eintrag "Alle"
        } else if (
            this.state.value.length === 1 &&
            this.state.value.includes(i18n.t(this.state.alli18n)) &&
            value.includes(i18n.t(this.state.alli18n)) &&
            value.length > 1
        ) {
            value = value.filter((e) => e !== i18n.t(this.state.alli18n));
        }
        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };
    addAll(items: any[]) {
        if (items.length > 0) {
            let all = i18n.t(this.state.alli18n);
            return [all, ...items];
        }
        return items;
    }

    render() {
        const { loading } = this.state;
        return (
            <Select
                mode="multiple"
                value={this.props.value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                style={{ width: '100%' }}
                disabled={this.props.disabled}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => { return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            >
                {this.addAll(this.state.selectdata).map((itm, y) => {
                    return (
                        <Select.Option key={y} value={itm}>
                            {i18n.t('order.probenart.' + itm).indexOf('order.probenart.') !== -1 ? itm : i18n.t('order.probenart.' + itm)}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}

export default withAuth(ProbenartSelectForm);
