import i18n from "./i18n";

interface rAttributes{
    defaultSelectedKeys: string[],
    defaultOpenKeys: string[]
}

class routes_class {
    
    getItemById(id:string, items:Array<any>){
        for(let i=0; i<items.length; i++){
            if(items[i].id === id){
                return items[i];
            }
        }
        return false;
    }

    getSelection(){
        let psplit = document.location.pathname.split('/');
        let tstr = ""
        let r : rAttributes = {
            defaultSelectedKeys: [],
            defaultOpenKeys: [],
        }
        for(let i=1; i<psplit.length-1; i++){ 
            tstr += "/"+psplit[i]; 
            r.defaultOpenKeys.push(tstr);
        }
        r.defaultSelectedKeys.push(document.location.pathname)
        return(r)
    }

    setDocTitile(r : object[]){
        let item = routes.getItemById(document.location.pathname, r);
        if(!i18n.t("navitem."+item.title).includes("undefined")){
            document.title = '' + i18n.t("navitem."+item.title) + ' - SANO - Portal';
        }else{
            document.title =  'SANO - Portal';
        }   
        
    }

}

const routes = new routes_class();
export { routes_class }
export default routes;