import React from "react";
import { Table, Switch } from "antd";
import { getRestApiConfig } from "../../../../../_conf";
import * as Api from "../../../../../api/rest";
import { AuthContextProps, withAuth } from "oidc-react";
import helper from "../../listdetail/listtable/_helper";
import i18n from "../../../../misc/i18n";
interface IProps extends AuthContextProps {
    currentFarmer: number;
    disableAll: boolean;
}

interface IState {
    rights: any;
    columns: Array<any>;
    listdata: Array<any>;
    data: Array<any>;
    loading: boolean;
    pageSize: number;
}

class Rights extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            loading: false,
            rights: {},
            data: [],
            pageSize: 200,
            listdata: [],
            columns: [
                {
                    title: i18n.t("table.col.title.betriebsId"),
                    dataIndex: "company",
                    key: "company"
                },
                {
                    title: i18n.t("table.col.title.ort"),
                    dataIndex: "ort",
                    key: "ort"
                },
                {
                    title: i18n.t("table.col.title.plz"),
                    dataIndex: "plz",
                    key: "plz"
                },
                {
                    title: i18n.t("table.col.title.strasse"),
                    dataIndex: "strasseNr",
                    key: "strasseNr"
                },
                {
                    title: i18n.t("table.col.title.report_dairydata"),
                    dataIndex: "report_dairydata",
                    key: "report_dairydata",
                    render: (text: string, rec: any) => {
                        let checked = text ? true : false;
                        return (
                            <Switch
                                defaultChecked={checked}
                                disabled={this.props.disableAll}
                                onChange={(v) => {
                                    let value = [];
                                    if (v) {
                                        value.push("report_dairydata");
                                    }
                                    let api = new Api.PermissionControllerApi(getRestApiConfig("security", this.props.userData));
                                    let requestBody = {
                                        parentgroup: "Betrieb",
                                        group: rec.company,
                                        requestBody: value
                                    };
                                    api.assignRolesToGroup(requestBody);
                                }}
                            />
                        );
                    }
                }
            ]
        };
    }

    _getApi() {
        return new Api.BetriebControllerApi(getRestApiConfig("company", this.props.userData));
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        params.search = "sdnLandwirt.landwirtId==" + this.props.currentFarmer;
        try {
            let requestBody = {
                size: 200,
                search: params.search,
                page: params.page
            };
            let resp = await this._getApi().findBetriebeWithRolesForLandwirt(requestBody);
            let tdata = helper.prepareListData(resp.content);
            let data = [];
            for (let i = 0; i < tdata.length; i++) {
                let rec = tdata[i];
                data.push({
                    key: i,
                    company: rec.betrieb.betriebsId,
                    ort: rec.betrieb.ort,
                    plz: rec.betrieb.plz,
                    strasseNr: rec.betrieb.strasseNr,
                    report_dairydata: rec.roles.indexOf("report_dairydata") >= 0 ? true : false
                });
            }
            this.setState({
                listdata: tdata,
                data: data,
                loading: false
            });
        } catch (e) {
            console.error(e);
        }
    }

    componentDidMount() {
        this._fetchListData({});
    }

    render() {
        return (
            <Table showHeader={true} columns={this.state.columns} dataSource={this.state.data} pagination={false} size="small" loading={this.state.loading} />
        );
    }
}

export default withAuth(Rights);
