import React from "react";
import { Typography } from 'antd';
const { Title } = Typography;

export default class Imprint extends React.Component{

    render(){
        return(
            <div className="imprint-wrap">
                <Title level={3}>Impressum</Title>
                <Title level={4}>Herausgeber:</Title>
                <p>Sano - Moderne Tierernährung GmbH<br/>
                Firmensitz: Grafenwald 1, D-84180 Loiching<br/>
                Telefon:   +49 (0)8744 96 01 - 0<br/>
                Telefax:  +49 (0)8744 96 01 - 88<br/>
                E-Mail:  info@sano.de </p>
                <Title level={4}>Geschäftsführer:</Title>
                <p>Bernhard Waldinger, Edeltraud Waldinger, Richard Waldinger</p>
                <p>UST-IdNr.: DE 128 961 142<br/>
                Steuer-Nr.: 108 / 116 / 40064<br/>
                Handelsregister: Landshut HRB 343
                </p>
                <p>Inhaltlich Verantwortlicher gemäß § 55 Abs. 2 RStV:<br/>
                Richard Waldinger (Anschrift wie oben)</p>
                <Title level={4}>Sano - Moderne Tierernährung GmbH</Title>
                Alle Inhalte und sonstige Medien dürfen ohne schriftliche Genehmigung der Sano - Moderne Tierernährung GmbH nicht, auch nicht teilweise, verwendet werden. Alle Markenzeichen, die auf dieser Homepage genannt werden, sind Eigentum der jeweiligen Firmen. Änderung & Irrtümer vorbehalten.
                <Title level={4}>Haftungshinweis</Title>
                Trotz sorgfältiger inhaltlicher Kontrolle übernehmen wir keine Haftung für die Inhalte externer Links. Für den Inhalt der verlinkten Seiten sind ausschließlich deren Betreiber verantwortlich.
                <Title level={4}>Streitschlichtung</Title>
                Wir sind nicht bereit oder verpflichtet, an Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle teilzunehmen.
                <Title level={4}>Haftung für Inhalte</Title>
                <p>
                Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter jedoch nicht verpflichtet, übermittelte oder gespeicherte fremde Informationen zu überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.<br/>
                Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend entfernen.
                </p>
            </div>
        )
    }

}