import React from "react";
import order from "./_order";
import ListDetail from "../listdetail";
import helper from "../listdetail/listtable/_helper";
import ResultDownload from "../resultdownload";
import LoadMask from "../../../misc/loadmask";
import { getImportApiConfig } from "../../../../_conf";
import { withAuth } from "oidc-react";
import * as Api from "../../../../api/importstatus";
import userNotification from "../../../misc/notification";
import { Tabs } from "antd";
import i18n from "../../../misc/i18n";
import { isFarmer } from "../../../misc/getUserRoles";
import DeleteOrder from "./deleteorder/deleteOrder";
import { isAdmin } from "../../../misc/getUserRoles";
import cloneDeep from "lodash.clonedeep";
import { dec } from "../../../misc/crypt";
const queryToStr = require("query-string");

class Order extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCompany: undefined,
            data: [],
            loading: false,
            listdata: {},
            detaildata: {},
            pagination: {},
            listPaginationObject: {},
            pageSize: 20,
            conf: order,
            downloadLoading: false,
            detailVisible: false,
            cvasList: []
        };
    }

    _getApi() {
        return new Api.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));
    }

    async componentDidMount() {
        if(typeof(this.props.id) !== "undefined"){
            this._fetchDetailData(this.props.id)
        }else{
            await this._fetchListData({});
            const result = queryToStr.parse(document.location.search);
            if(typeof(result.id) !== "undefined" && result.id !== ""){
                let id = dec(result.id);
                this._fetchDetailData(id)
            }
        }  
    }

    async _fetchListData(p) {
        if (p.sorter == null || Object.keys(p.sorter).length === 0) {
            p.sorter = {};
            p.sorter.order = "descend";
            p.sorter.field = "tsProbennahme";
        }
        this.setState({
            loading: true
        });
        let excludeDeaktiviert = "status=out=(DEAKTIVIERT)";
        let params = helper.prepareListParams(p, this.state.pageSize);

        if (params.search != null && !params.search.includes("status")) {
            params.search = excludeDeaktiviert + ";" + params.search;
        } else {
            if (params.search == null) {
                params.search = excludeDeaktiviert;
            }
        }
        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        };
        let resp = await this._getApi()
            .findFiltered(requestBody)
            .catch(() => {
                userNotification("error", "notification.order.get.error");
                this.setState({
                    loading: false
                });
            });
        if (resp != null) {
            this.setState({
                listdata: helper.prepareListData(resp.content),
                loading: false,
                pageSize: params.size,
                pagination: {
                    total: resp.totalElements,
                    pageSize: params.size
                }
            });
        }
    }

    async _fetchDetailData(id) {
        try {
            let requestBody = {
                probenId: id
            };
            let resp = await this._getApi().findProbenbestellungById(requestBody);
            let newOrder = cloneDeep(order);
            newOrder.formitems = this.filterFormitems(resp);
            this.setState(
                {
                    detaildata: resp,
                    detailVisible: true,
                    conf: newOrder
                },
                () => {
                    this.getProtectedCVASList();
                }
            );
        } catch (e) {
            userNotification("error", "notification.order.content.get.error");
        }
    }
    filterFormitems(detaildata) {
        let items = cloneDeep(order.formitems);

        let newItems = [];
        if (
            detaildata.probenart == null ||
            (detaildata.probenart.toLowerCase().indexOf("maize") === -1 && detaildata.probenart.toLowerCase().indexOf("gras") === -1)
        ) {
            for (let element of items) {
                if (element.label != null) {
                    if (element.label.toLowerCase().indexOf("hybridsorte") === -1) {
                        if (element.label.toLowerCase().indexOf("schnitthoehe") === -1) {
                            if (element.label.toLowerCase().indexOf("ertrag") === -1) {
                                newItems.push(element);
                            }
                        }
                    }
                }
            }
            items = newItems;
        }
        return items;
    }

    shouldShow(itm) {
        if (isFarmer(this.props.userData) && itm.dokumentArt.toLowerCase() === "xml") {
            return false;
        }
        return true;
    }
    getProtectedCVASList() {
        let cvasList = [];
        if (this.state.detaildata?.cvasDocumentList === undefined) {
            return [];
        }
        this.state.detaildata.cvasDocumentList.forEach((e) => {
            if (this.shouldShow(e)) {
                cvasList.push(e);
            }
        });
        this.setState({
            cvasList: cvasList
        });
    }
    updateTable = () => {
        this.setState({
            detailVisible: false
        });
        this._fetchListData(this.state.listPaginationObject);
    };

    updateCvasDownload = () => {
        //this.getProtectedCVASList()
        this.setState({
            cvasList: [],
            detailVisible: false
        });
        this._fetchListData(this.state.listPaginationObject);
    };

    render() {
        let extraTabs = [];
        if (this.state.detaildata.status === "ERGEBNIS") {
            extraTabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.dateien")} key="2">
                    <ResultDownload dataSource={this.state.cvasList} />
                </Tabs.TabPane>
            );
        }
        if (isAdmin(this.props.userData)) {
            extraTabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.delete")} key="3">
                    <DeleteOrder
                        dataSource={this.state.cvasList}
                        order={this.state.detaildata}
                        onUpdateAll={this.updateTable}
                        onUpdateCvas={this.updateCvasDownload}
                    />
                </Tabs.TabPane>
            );
        }

        return (
            <div>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail
                    columns={this.state.conf.columns}
                    renderTitle={this.state.conf.renderTitle}
                    listdata={this.state.listdata}
                    pagination={this.state.pagination}
                    showSaveButton={false}
                    formitems={this.state.conf.formitems}
                    detailProp={this.state.conf.detailProp}
                    type={this.state.conf.type}
                    detaildata={this.state.detaildata}
                    fetchListData={(p) => this._fetchListData(p)}
                    detailVisible={this.state.detailVisible}
                    loading={this.state.loading}
                    onDetailClose={() => {
                        this.setState({
                            detailVisible: false
                        },() => {
                            if(this.props.onDetailClose){
                                this.props.onDetailClose()
                            }
                        });
                    }}
                    onRowSelection={(id) => {
                        this._fetchDetailData(id);
                    }}
                    onTableChange={(p) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize,
                                listPaginationObject: p
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                    extraTabs={extraTabs}
                />
            </div>
        );
    }
}

export default withAuth(Order);
