/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    GroupRepresentation,
    GroupRepresentationFromJSON,
    GroupRepresentationToJSON,
    UserRepresentation,
    UserRepresentationFromJSON,
    UserRepresentationToJSON,
} from '../models';

export interface AssignRolesToGroupRequest {
    parentgroup: string;
    group: string;
    requestBody: Array<string>;
}

export interface AssignUsersToGroupRequest {
    parentgroup: string;
    group: string;
    requestBody: Array<string>;
}

export interface CreateDefaultGroupRequest {
    parentgroup: string;
    group: string;
}

export interface DeleteDefaultGroupRequest {
    parentgroup: string;
    group: string;
}

export interface FindAllGroupsForUserRequest {
    user: string;
}

export interface FindAllPermittedUsersForBetriebRequest {
    betrieb: string;
    q?: string;
}

export interface FindAllUsersRequest {
    q?: string;
}

export interface FindAllUsersForGroupRequest {
    parentgroup: string;
    group: string;
    q?: string;
}

export interface FindGroupByNameRequest {
    parentgroup: string;
    group: string;
}

export interface FindUserByNameRequest {
    user: string;
}

export interface FindUsersForCountryRequest {
    countries: Array<string>;
    q?: string;
}

export interface FindUsersForRolesRequest {
    roles: Array<FindUsersForRolesRolesEnum>;
    q?: string;
}

/**
 * 
 */
export class PermissionControllerApi extends runtime.BaseAPI {

    /**
     */
    async assignRolesToGroupRaw(requestParameters: AssignRolesToGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.parentgroup === null || requestParameters.parentgroup === undefined) {
            throw new runtime.RequiredError('parentgroup','Required parameter requestParameters.parentgroup was null or undefined when calling assignRolesToGroup.');
        }

        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling assignRolesToGroup.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling assignRolesToGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/roles/{parentgroup}/{group}`.replace(`{${"parentgroup"}}`, encodeURIComponent(String(requestParameters.parentgroup))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignRolesToGroup(requestParameters: AssignRolesToGroupRequest, initOverrides?: RequestInit): Promise<void> {
        await this.assignRolesToGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async assignUsersToGroupRaw(requestParameters: AssignUsersToGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.parentgroup === null || requestParameters.parentgroup === undefined) {
            throw new runtime.RequiredError('parentgroup','Required parameter requestParameters.parentgroup was null or undefined when calling assignUsersToGroup.');
        }

        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling assignUsersToGroup.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling assignUsersToGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/{parentgroup}/{group}`.replace(`{${"parentgroup"}}`, encodeURIComponent(String(requestParameters.parentgroup))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async assignUsersToGroup(requestParameters: AssignUsersToGroupRequest, initOverrides?: RequestInit): Promise<void> {
        await this.assignUsersToGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createDefaultGroupRaw(requestParameters: CreateDefaultGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.parentgroup === null || requestParameters.parentgroup === undefined) {
            throw new runtime.RequiredError('parentgroup','Required parameter requestParameters.parentgroup was null or undefined when calling createDefaultGroup.');
        }

        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling createDefaultGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/{parentgroup}/{group}/default`.replace(`{${"parentgroup"}}`, encodeURIComponent(String(requestParameters.parentgroup))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async createDefaultGroup(requestParameters: CreateDefaultGroupRequest, initOverrides?: RequestInit): Promise<void> {
        await this.createDefaultGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async deleteDefaultGroupRaw(requestParameters: DeleteDefaultGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.parentgroup === null || requestParameters.parentgroup === undefined) {
            throw new runtime.RequiredError('parentgroup','Required parameter requestParameters.parentgroup was null or undefined when calling deleteDefaultGroup.');
        }

        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling deleteDefaultGroup.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/{parentgroup}/{group}/default`.replace(`{${"parentgroup"}}`, encodeURIComponent(String(requestParameters.parentgroup))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async deleteDefaultGroup(requestParameters: DeleteDefaultGroupRequest, initOverrides?: RequestInit): Promise<void> {
        await this.deleteDefaultGroupRaw(requestParameters, initOverrides);
    }

    /**
     */
    async findAllGroupsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<GroupRepresentation>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupRepresentationFromJSON));
    }

    /**
     */
    async findAllGroups(initOverrides?: RequestInit): Promise<Array<GroupRepresentation>> {
        const response = await this.findAllGroupsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllGroupsForUserRaw(requestParameters: FindAllGroupsForUserRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<GroupRepresentation>>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling findAllGroupsForUser.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/users/{user}/groups`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(GroupRepresentationFromJSON));
    }

    /**
     */
    async findAllGroupsForUser(requestParameters: FindAllGroupsForUserRequest, initOverrides?: RequestInit): Promise<Array<GroupRepresentation>> {
        const response = await this.findAllGroupsForUserRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllPermittedUsersForBetriebRaw(requestParameters: FindAllPermittedUsersForBetriebRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserRepresentation>>> {
        if (requestParameters.betrieb === null || requestParameters.betrieb === undefined) {
            throw new runtime.RequiredError('betrieb','Required parameter requestParameters.betrieb was null or undefined when calling findAllPermittedUsersForBetrieb.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/Betrieb/{betrieb}/permittedUsers`.replace(`{${"betrieb"}}`, encodeURIComponent(String(requestParameters.betrieb))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRepresentationFromJSON));
    }

    /**
     */
    async findAllPermittedUsersForBetrieb(requestParameters: FindAllPermittedUsersForBetriebRequest, initOverrides?: RequestInit): Promise<Array<UserRepresentation>> {
        const response = await this.findAllPermittedUsersForBetriebRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllUsersRaw(requestParameters: FindAllUsersRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserRepresentation>>> {
        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/users/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRepresentationFromJSON));
    }

    /**
     */
    async findAllUsers(requestParameters: FindAllUsersRequest = {}, initOverrides?: RequestInit): Promise<Array<UserRepresentation>> {
        const response = await this.findAllUsersRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllUsersForGroupRaw(requestParameters: FindAllUsersForGroupRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserRepresentation>>> {
        if (requestParameters.parentgroup === null || requestParameters.parentgroup === undefined) {
            throw new runtime.RequiredError('parentgroup','Required parameter requestParameters.parentgroup was null or undefined when calling findAllUsersForGroup.');
        }

        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling findAllUsersForGroup.');
        }

        const queryParameters: any = {};

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/{parentgroup}/{group}/users`.replace(`{${"parentgroup"}}`, encodeURIComponent(String(requestParameters.parentgroup))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRepresentationFromJSON));
    }

    /**
     */
    async findAllUsersForGroup(requestParameters: FindAllUsersForGroupRequest, initOverrides?: RequestInit): Promise<Array<UserRepresentation>> {
        const response = await this.findAllUsersForGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findGroupByNameRaw(requestParameters: FindGroupByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<GroupRepresentation>> {
        if (requestParameters.parentgroup === null || requestParameters.parentgroup === undefined) {
            throw new runtime.RequiredError('parentgroup','Required parameter requestParameters.parentgroup was null or undefined when calling findGroupByName.');
        }

        if (requestParameters.group === null || requestParameters.group === undefined) {
            throw new runtime.RequiredError('group','Required parameter requestParameters.group was null or undefined when calling findGroupByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/groups/{parentgroup}/{group}`.replace(`{${"parentgroup"}}`, encodeURIComponent(String(requestParameters.parentgroup))).replace(`{${"group"}}`, encodeURIComponent(String(requestParameters.group))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => GroupRepresentationFromJSON(jsonValue));
    }

    /**
     */
    async findGroupByName(requestParameters: FindGroupByNameRequest, initOverrides?: RequestInit): Promise<GroupRepresentation> {
        const response = await this.findGroupByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findUserByNameRaw(requestParameters: FindUserByNameRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<UserRepresentation>> {
        if (requestParameters.user === null || requestParameters.user === undefined) {
            throw new runtime.RequiredError('user','Required parameter requestParameters.user was null or undefined when calling findUserByName.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/users/{user}`.replace(`{${"user"}}`, encodeURIComponent(String(requestParameters.user))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => UserRepresentationFromJSON(jsonValue));
    }

    /**
     */
    async findUserByName(requestParameters: FindUserByNameRequest, initOverrides?: RequestInit): Promise<UserRepresentation> {
        const response = await this.findUserByNameRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findUsersForCountryRaw(requestParameters: FindUsersForCountryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserRepresentation>>> {
        if (requestParameters.countries === null || requestParameters.countries === undefined) {
            throw new runtime.RequiredError('countries','Required parameter requestParameters.countries was null or undefined when calling findUsersForCountry.');
        }

        const queryParameters: any = {};

        if (requestParameters.countries) {
            queryParameters['countries'] = requestParameters.countries;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/usersForCountries/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRepresentationFromJSON));
    }

    /**
     */
    async findUsersForCountry(requestParameters: FindUsersForCountryRequest, initOverrides?: RequestInit): Promise<Array<UserRepresentation>> {
        const response = await this.findUsersForCountryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findUsersForRolesRaw(requestParameters: FindUsersForRolesRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<UserRepresentation>>> {
        if (requestParameters.roles === null || requestParameters.roles === undefined) {
            throw new runtime.RequiredError('roles','Required parameter requestParameters.roles was null or undefined when calling findUsersForRoles.');
        }

        const queryParameters: any = {};

        if (requestParameters.roles) {
            queryParameters['roles'] = requestParameters.roles;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/permission/usersForRole/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(UserRepresentationFromJSON));
    }

    /**
     */
    async findUsersForRoles(requestParameters: FindUsersForRolesRequest, initOverrides?: RequestInit): Promise<Array<UserRepresentation>> {
        const response = await this.findUsersForRolesRaw(requestParameters, initOverrides);
        return await response.value();
    }

}

/**
    * @export
    * @enum {string}
    */
export enum FindUsersForRolesRolesEnum {
    Administrator = 'ROLE_Administrator',
    BusinessAdministrator = 'ROLE_BusinessAdministrator',
    Consultant = 'ROLE_Consultant',
    Farmer = 'ROLE_Farmer',
    Labor = 'ROLE_Labor',
    Extern = 'ROLE_Extern'
}
