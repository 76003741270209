import React from "react";

export default class PDM extends React.Component{

    render(){
        return(
            <h1>PDM</h1>
        )
    }

}