/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Region
 */
export interface Region {
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    parameter?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionTyp?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    regionName?: string;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof Region
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof Region
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof Region
     */
    land?: string;
    /**
     * 
     * @type {number}
     * @memberof Region
     */
    regionId?: number;
}

export function RegionFromJSON(json: any): Region {
    return RegionFromJSONTyped(json, false);
}

export function RegionFromJSONTyped(json: any, ignoreDiscriminator: boolean): Region {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'regionTyp': !exists(json, 'regionTyp') ? undefined : json['regionTyp'],
        'regionName': !exists(json, 'regionName') ? undefined : json['regionName'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'land': !exists(json, 'land') ? undefined : json['land'],
        'regionId': !exists(json, 'regionId') ? undefined : json['regionId'],
    };
}

export function RegionToJSON(value?: Region | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'description': value.description,
        'parameter': value.parameter,
        'regionTyp': value.regionTyp,
        'regionName': value.regionName,
        'uiInsert': value.uiInsert,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'land': value.land,
        'regionId': value.regionId,
    };
}

