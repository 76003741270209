import React from "react";
import * as Api from "../../../../../api/rest";
import { Spin, Button } from "antd";
import Toolbar from "../../listdetail/toolbar";
import { getRestApiConfig } from "./../../../../../_conf";
import { withAuth, AuthContextProps } from "oidc-react";
import userNotification from "./../../../../misc/notification";
import Tagselect from "../../tagselect";
import { FindUsersForRolesRolesEnum } from "../../../../../api/rest/";
interface IState {
    selectdata: Array<Api.UserRepresentation>;
    value: Array<string>;
    loading: boolean;
    parentgroup: string;
    currentCompany: string;
}

interface IProps extends AuthContextProps {
    parentgroup: string;
    currentCompany: string;
    disableAll: boolean;
}

let Security = class Security extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            value: [],
            loading: false,
            parentgroup: props.parentgroup,
            currentCompany: props.currentCompany
        };
        //@ts-ignore
        this.loadNewData = this.debounce(this.loadNewData, 800);
    }

    _getApi() {
        return new Api.PermissionControllerApi(getRestApiConfig("security", this.props.userData));
    }

    debounce(func: Function, wait: number, immediate?: boolean) {
        var timeout: NodeJS.Timeout | null;
        return function () {
            // @ts-ignore this braucht eine ()=>{} Funktion, aber dann funktioniert arguments nicht mehr.
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            if (timeout != null) {
                clearTimeout(timeout);
            }
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }

    async componentDidMount() {
        this._getSelectData();
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        let groupUsers = [];
        try {
            if (this.state.currentCompany == null) {
                throw Error("currentCompany ID not set");
            }
            let requestBody = {
                group: this.state.currentCompany,
                parentgroup: this.state.parentgroup
            };
            groupUsers = await this._getApi().findAllUsersForGroup(requestBody);
            try {
                let users = [];
                if (this.props.parentgroup === "Region") {
                    let requestBody = {
                        roles: [
                            FindUsersForRolesRolesEnum.Administrator,
                            FindUsersForRolesRolesEnum.BusinessAdministrator,
                            FindUsersForRolesRolesEnum.Consultant,
                            FindUsersForRolesRolesEnum.Labor,
                            FindUsersForRolesRolesEnum.Extern
                        ]
                    };
                    users = await this._getApi().findUsersForRoles(requestBody);
                } else {
                    let requestBody = {
                        roles: [
                            FindUsersForRolesRolesEnum.Administrator,
                            FindUsersForRolesRolesEnum.BusinessAdministrator,
                            FindUsersForRolesRolesEnum.Consultant,
                            FindUsersForRolesRolesEnum.Farmer,
                            FindUsersForRolesRolesEnum.Labor,
                            FindUsersForRolesRolesEnum.Extern
                        ]
                    };
                    users = await this._getApi().findUsersForRoles(requestBody);
                }
                let selectedKeys: Array<string> = [];
                if (groupUsers != null) {
                    groupUsers.forEach((itm) => {
                        if (itm.username != null) {
                            selectedKeys.push(itm.username);
                        }
                    });
                }

                this.setState({
                    value: groupUsers != null ? selectedKeys : [],
                    selectdata: users != null ? users : [],
                    loading: false
                });
            } catch (e) {
                userNotification("error", "notification.user.get.error");
            }
        } catch (e) {
            userNotification("error", "notification.user.group.get.error");
        }
    }

    async handleSave() {
        try {
            let requestBody = {
                parentgroup: this.state.parentgroup,
                group: this.state.currentCompany,
                requestBody: this.state.value
            };
            await this._getApi().assignUsersToGroup(requestBody);
            userNotification("success", "notification.security.save.success");
        } catch (e) {
            userNotification("error", "notification.security.save.error");
        }
    }

    loadNewData = async (userInput: string) => {
        this.setState({
            loading: true
        });
        let searchString = userInput != null && userInput.length > 0 ? userInput : undefined;
        try {
            let body = [];
            if (this.props.parentgroup === "Region") {
                let requestBody = {
                    roles: [
                        FindUsersForRolesRolesEnum.Administrator,
                        FindUsersForRolesRolesEnum.BusinessAdministrator,
                        FindUsersForRolesRolesEnum.Consultant,
                        FindUsersForRolesRolesEnum.Labor,
                        FindUsersForRolesRolesEnum.Extern
                    ],
                    q: searchString
                };
                body = await this._getApi().findUsersForRoles(requestBody);
            } else {
                let requestBody = {
                    roles: [
                        FindUsersForRolesRolesEnum.Administrator,
                        FindUsersForRolesRolesEnum.BusinessAdministrator,
                        FindUsersForRolesRolesEnum.Consultant,
                        FindUsersForRolesRolesEnum.Labor,
                        FindUsersForRolesRolesEnum.Farmer,
                        FindUsersForRolesRolesEnum.Extern
                    ],
                    q: searchString
                };
                body = await this._getApi().findUsersForRoles(requestBody);
            }

            this.setState({
                selectdata: body != null ? body : [],
                loading: false
            });
        } catch (e) {
            userNotification("error", "notification.user.get.error");
        }
    };

    onChange = (value: Array<string>, option: unknown) => {
        this.setState({
            value: value,
            loading: false
        });
    };

    textRenderer(item: Api.UserRepresentation) {
        let str = "";
        str += item.username;
        str += item.attributes != null && item.attributes.country != null && item.attributes.country.length > 0 ? " | " + item.attributes.country[0] : "";
        str += item.firstName != null && item.firstName !== "" ? " | " + item.firstName : "";
        str += item.lastName != null && item.lastName !== "" ? " | " + item.lastName : "";
        return str;
    }

    render() {
        const { loading, value } = this.state;
        return (
            <div className="detail-wrap">
                <Toolbar>
                    <Button
                        type="primary"
                        onClick={() => {
                            this.handleSave();
                        }}
                        disabled={this.props.disableAll}
                    >
                        <i className="fal fa-save"></i>
                    </Button>
                </Toolbar>
                <Tagselect
                    keyAttributeName="id"
                    valueAttributeName="username"
                    onChange={this.onChange}
                    notFoundContent={loading ? <Spin size="small" /> : null}
                    onSearch={this.loadNewData}
                    selectdata={this.state.selectdata}
                    textrender={this.textRenderer}
                    style={{ width: "100%" }}
                    value={value}
                    disabled={this.props.disableAll}
                />
            </div>
        );
    }
};

export default withAuth(Security);
