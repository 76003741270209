import React from 'react';
import { Form, Input, Button } from 'antd';
import i18n from '../../../../misc/i18n';
import { PlusOutlined } from '@ant-design/icons';
import { Callbacks } from 'rc-field-form/lib/interface';
interface IProps {
    oldMails: Array<string>;
    updateMails: Function;
    disableAll: boolean;
}

const SubmitMails: React.FC<IProps> = (props) => {
    const onSave: Callbacks['onFinish'] = (values) => {
        props.updateMails(values.emailList);
    };
    

    return (
        <Form name="dynamic_form_item" onFinish={onSave} initialValues={{ emailList: props.oldMails }}>
            <Form.Item>
                <Button type="primary" htmlType="submit" disabled={props.disableAll}>
                    <i className="fal fa-save"></i>
                </Button>
            </Form.Item>
            <Form.List name="emailList">
                {(fields, { add, remove }) => (
                    <>
                        {fields.map((field, index) => (
                            <Form.Item label={index + 1 + '. ' + i18n.t('form.label.email')} key={field.key}>
                                <Form.Item
                                    {...field}
                                    validateTrigger={['onSubmit', 'onChange']}
                                    noStyle
                                    rules={[
                                        { required: true, message: i18n.t('formvalidation.submitMails.notEmpty') },
                                        { type: 'email', message: i18n.t('formvalidation.submitMails.notEmail') }
                                    ]}
                                >
                                    <Input type="email" style={{ width: '95%' }} disabled={(props.disableAll) ? true : false} />
                                </Form.Item>
                                {(props.disableAll) 
                                    ? <i className="fal fa-trash-alt" style={{ paddingLeft: '2%' }}></i>
                                    : <i className="fal fa-trash-alt" onClick={() => remove(field.name)} style={{ paddingLeft: '2%' }}></i>
                                }
                            </Form.Item>
                        ))}
                        <Form.Item>
                            <Button type="dashed" onClick={() => add()} icon={<PlusOutlined />} disabled={props.disableAll}></Button>
                        </Form.Item>
                    </>
                )}
            </Form.List>
        </Form>
    );
};

export default SubmitMails;
