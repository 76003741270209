/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Landwirt
 */
export interface Landwirt {
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    parameter?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    ort?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    plz?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Landwirt
     */
    externeSysteme?: { [key: string]: string; };
    /**
     * 
     * @type {boolean}
     * @memberof Landwirt
     */
    emailbenachrichtigungJn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    land?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    beschreibung?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    ortsteil?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    bundesland?: string;
    /**
     * 
     * @type {number}
     * @memberof Landwirt
     */
    landwirtId?: number;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    nachname?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    strasse?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    telefonNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    vorname?: string;
}

export function LandwirtFromJSON(json: any): Landwirt {
    return LandwirtFromJSONTyped(json, false);
}

export function LandwirtFromJSONTyped(json: any, ignoreDiscriminator: boolean): Landwirt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'plz': !exists(json, 'plz') ? undefined : json['plz'],
        'externeSysteme': !exists(json, 'externeSysteme') ? undefined : json['externeSysteme'],
        'emailbenachrichtigungJn': !exists(json, 'emailbenachrichtigungJn') ? undefined : json['emailbenachrichtigungJn'],
        'land': !exists(json, 'land') ? undefined : json['land'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'ortsteil': !exists(json, 'ortsteil') ? undefined : json['ortsteil'],
        'bundesland': !exists(json, 'bundesland') ? undefined : json['bundesland'],
        'landwirtId': !exists(json, 'landwirtId') ? undefined : json['landwirtId'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'nachname': !exists(json, 'nachname') ? undefined : json['nachname'],
        'strasse': !exists(json, 'strasse') ? undefined : json['strasse'],
        'telefonNummer': !exists(json, 'telefonNummer') ? undefined : json['telefonNummer'],
        'vorname': !exists(json, 'vorname') ? undefined : json['vorname'],
    };
}

export function LandwirtToJSON(value?: Landwirt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameter': value.parameter,
        'ort': value.ort,
        'plz': value.plz,
        'externeSysteme': value.externeSysteme,
        'emailbenachrichtigungJn': value.emailbenachrichtigungJn,
        'land': value.land,
        'beschreibung': value.beschreibung,
        'ortsteil': value.ortsteil,
        'bundesland': value.bundesland,
        'landwirtId': value.landwirtId,
        'email': value.email,
        'nachname': value.nachname,
        'strasse': value.strasse,
        'telefonNummer': value.telefonNummer,
        'vorname': value.vorname,
    };
}

