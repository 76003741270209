import React, { useState, useEffect, useCallback } from "react";
import SelectLand from "./components/selectLand";
import { Row, Col, Input, List } from "antd";
import i18n from "../../../misc/i18n";
import ListTable from "../listdetail/listtable";
import assignment from "./_assignment";
import userNotification from "../../../misc/notification";
import * as Api from "../../../../api/rest";
import { AuthContextProps, withAuth } from "oidc-react";
import { getRestApiConfig } from "../../../../_conf";
import helper from "../listdetail/listtable/_helper";
import cloneDeep from "lodash.clonedeep";

interface IProps extends AuthContextProps {}

const expandableConfig = {
    expandedRowRender: (record: any) => {
        return <List size="small" bordered={false} dataSource={record.einsendeMails} renderItem={(item: string) => <List.Item>{item}</List.Item>} />;
    },
    rowExpandable: (record: any) => {
        return record.einsendeMails != null && record.einsendeMails.length > 0 ? true : false;
    },
    expandRowByClick: true,
    defaultExpandAllRows: false
};

const _getApi = (userdata:AuthContextProps["userData"]) => {
    return new Api.BetriebControllerApi(getRestApiConfig("company", userdata));
};

const AssignmentSubmitmail: React.FC<IProps> = (props) => {
    let confCopy = cloneDeep(assignment);
    let confLandKey = confCopy.columns.findIndex((obj) => obj.title === "land");
    if (confLandKey > -1) {
        confCopy.columns.splice(confLandKey, 1);
    }
    const [land, setLand] = useState([]);
    const [submitMail, setSubmitMail] = useState("**");
    const [listdata, setListdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [pagination, setPagination] = useState({ pageSize: 15 });
    const [listParams, setListParams] = useState<{pagination:{pageSize:number}, filter: {land?:any[]}}>({ pagination: pagination, filter: { land: land } });

    const {userData} = props;

    const onChangeLand = (val: any) => {
        if (val.length === 0) {
            setLand([]);
        } else {
            setLand(val);
        }
    };



    const _fetchListData = async () => {
        setLoading(true);
        if (land != null && land.length !== 0) {
            listParams.filter.land = land;
        } else {
            delete listParams.filter.land;
        }

        let params = helper.prepareListParams(listParams, pagination.pageSize);
        if (submitMail != null && submitMail !== "**") {
            let submitMailSearch = "sdnBetriebEinsendeEmails.email==" + submitMail;
            if (params.search != null) {
                params.search += ";" + submitMailSearch;
            } else {
                params.search = submitMailSearch;
            }
        }

        try {
            let requestBody = {
                page: params.page,
                search: params.search,
                size: params.size,
                sort: params.sort
            };
            let resp = await _getApi(userData).findFiltered3(requestBody);
            if (resp != null) {
                setListdata(helper.prepareListData(resp.content));
                setLoading(false);
                setPagination((oldPagination) => ({ ...oldPagination, total: resp.totalElements }));
            }
        } catch (e) {
            userNotification("error", "notification.company.get.error");
            setLoading(false);
        }
    };

    const fetchListDataCallback = useCallback(_fetchListData, [land, listParams, pagination.pageSize, submitMail, userData]);

    useEffect(() => {
        fetchListDataCallback();
    }, [fetchListDataCallback]);

    return (
        <div className="regionWrapper">
            <div className="headerWrapper">
                <Row>
                    <Col span={12}>
                        <Row>
                            <Col span={2}>
                                <div className="centeredAssignment">{i18n.t("table.col.title.land") + ":"}</div>
                            </Col>
                            <Col span={19}>
                                <SelectLand onChange={onChangeLand} />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12}>
                        <Row>
                            <Col span={4}>
                                <div className="centeredAssignment">{i18n.t("form.label.submitMails") + ":"}</div>
                            </Col>
                            <Col span={18}>
                                <Input
                                    onChange={(newSubmitMail: React.ChangeEvent<HTMLInputElement>) => setSubmitMail("*" + newSubmitMail.target.value + "*")}
                                ></Input>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            <div className="list-detail-wrap">
                <ListTable
                    cols={confCopy.columns}
                    pagination={pagination}
                    fetchListData={(p: any) => setPagination(p)}
                    loading={loading}
                    onTableChange={(p: any) => {
                        setPagination(p.pagination);
                        setListParams(p);
                    }}
                    data={listdata.length ? listdata : []}
                    expandable={expandableConfig}
                    renderRecordSub={{}}
                />
            </div>
        </div>
    );
};

export default withAuth(AssignmentSubmitmail);
