import React from "react";
import devmocks from "./../devmocks/devmocks";

export default class NavTop extends React.Component {
    render(){
        return(
            <div className="demes-navtop-wrap">
                <div className="demes-navtop-toolbar">
                    {devmocks.hmenu}
                </div>
                <div className="demes-navtop-body">
                    {devmocks.content}
                </div>
            </div>
        )
    }
}