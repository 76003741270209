import React from 'react';
import { Layout, Tooltip } from 'antd';
import MainMenu from './mainmenu';
import TopMenu from './topmenu';
import ajax from './../../misc/ajax';
import DeLayout from './layout';
import i18n from './../../misc/i18n';
import Error404 from './../../apps/error404';
import { conf } from './../../../_conf';
import { withAuth } from 'oidc-react';
import menueFilter from './_menueFilter';
import MediaQuery from 'react-responsive';
import Tablet from './responsive/tablet';

import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';

const { Header, Content, Sider } = Layout;
class DeLayoutContainer extends React.Component {
    constructor(props) {
        super(props);
        let collapsed = JSON.parse(localStorage.getItem('demes-portal-menu-collapsed'));

        this.userData = this.props.userData;
        this.state = {
            rawNavData: {
                meta: {},
                tabs: []
            },
            value: '',
            collapsed: collapsed,
            items: [],
            routes: [],
            errors: null,
            navWidth: 300,
            navTabs: [],
            background: 'rgba(248, 175, 0, 1)',
            version: ''
        };
    }
    shouldComponentUpdate(nextProps, nextState) {
        return nextProps !== this.props || nextState !== this.state ? true : false;
    }

    componentDidMount() {
        this.setState({version: conf.version})
        this.fetchData();
    }

    componentDidUpdate(prevProps) {
        this.updateNav();
    }
    updateNav() {
        if (this.state.rawNavData !== null) {
            let toShow = menueFilter(this.state.rawNavData, this.props.userData);
            let r = this.state.routes;
            let rn = this.doRoutes(toShow.tabs);
            let allRoutes = r.concat(rn);
            if (allRoutes.length === 2) {
                allRoutes.push({
                    app: 'component',
                    component: 'Dashboard',
                    disabled: false,
                    exact: this.props.userData != null ? true : false,
                    icon: 'fal fa-home',
                    id: '/',
                    title: 'dashboard',
                    uri: 'about:blank'
                });
                allRoutes.push({
                    app: 'component',
                    component: 'GlobalSearchTabs',
                    disabled: false,
                    exact: this.props.userData != null ? true : false,
                    icon: 'fal fa-search',
                    id: '/search',
                    title: 'search',
                    uri: 'about:blank'
                });
        
                this.setState({
                    routes: allRoutes,
                    navWidth: toShow.meta.navWidth != null ? toShow.meta.navWidth : this.state.navWidth,
                    background: toShow.meta.background != null ? toShow.meta.background : this.state.background
                });
            } else {
                allRoutes.forEach((itm) => {
                    if (itm.id === '/' && itm.exact === !this.props.userData) {
                        itm.exact = this.props.userData != null ? true : false;
                    }
                });
            }
            if (JSON.stringify(toShow.tabs) === JSON.stringify(this.state.navTabs)) {
                return;
            }
            this.setState({
                navWidth: toShow.meta.navWidth != null ? toShow.meta.navWidth : this.state.navWidth,
                background: toShow.meta.background != null ? toShow.meta.background : this.state.background,
                navTabs: toShow.tabs,
                items: toShow.items,
                routes: allRoutes
            });
        }
    }

    async fetchData() {
        await ajax
            .request(conf.uri.nav, 'GET', {}, {})
            .then((response) => {
                this.setState({
                    rawNavData: response,
                    background: response.meta.background,
                    navWidth: response.meta.navWidth
                });
            })
            .catch((error) => {
                // Your error is here!
            });
    }

    addRoute(n, r) {
        if (n.children && n.children.length) {
            n.children.map((item, i) => this.addRoute(item, r));
        } else {
            let ir = Object.assign({ exact: true }, n);
            r.push(ir);
        }
        return r;
    }

    doRoutes(n) {
        let r = [];
        for (let i = 0; i < n.length; i++) {
            for (let y = 0; y < n[i].items.length; y++) {
                r = this.addRoute(n[i].items[y], r);
            }
        }
        r = this.addRoute(
            {
                id: '/imprint',
                title: 'imprint',
                app: 'component',
                component: 'Imprint'
            },
            r
        );
        r = this.addRoute(
            {
                id: '/privacy',
                title: 'privacy',
                app: 'component',
                component: 'Privacy'
            },
            r
        );
        return r;
    }

    routeRender(item) {
        return (
            <Layout style={{ minHeight: '100vh' }}>
                <Sider
                    className="demes-left-sider"
                    width={this.state.navWidth}
                    style={{ background: this.state.background }}
                    collapsible
                    collapsed={this.state.collapsed}
                    onCollapse={() =>
                        this.setState({ collapsed: !this.state.collapsed }, () => {
                            if (typeof Event === 'function') {
                                // modern browsers
                                window.dispatchEvent(new Event('resize'));
                            } else {
                                //This will be executed on old browsers and especially IE
                                var resizeEvent = window.document.createEvent('UIEvents');
                                resizeEvent.initUIEvent('resize', true, false, window, 0);
                                window.dispatchEvent(resizeEvent);
                            }
                        })
                    }
                >
                    <div className={this.state.collapsed ? 'brand-logo-collapsed' : 'brand-logo'}>
                        <Tooltip title={i18n.t('tooltip.logo')}>
                            <Link to="/">
                                <img src="/img/sdn_logo.png" alt="/img/sdn_logo.png" />
                            </Link>
                        </Tooltip>
                    </div>
                    <MainMenu
                        items={this.state.items}
                        routes={this.state.routes}
                        collapsed={this.state.collapsed}
                        tabs={this.state.navTabs}
                        background={this.state.background}
                        version={this.state.version}
                    />
                </Sider>
                <Layout>
                    <Header className="demes-top-header">
                        <TopMenu
                            collapsed={this.state.collapsed}
                            onCollapse={() => {
                                this.setState(
                                    {
                                        collapsed: !this.state.collapsed
                                    },
                                    () => {
                                        localStorage.setItem('demes-portal-menu-collapsed', JSON.stringify(this.state.collapsed));
                                    }
                                );
                            }}
                        />
                    </Header>
                    <Content>
                        <DeLayout routes={this.state.routes} {...item} collapsed={this.state.collapsed} />
                    </Content>
                </Layout>
            </Layout>
        );
    }

    _renderDesktop() {
        let ir = this.state.routes.map((item, i) => (
            <Route
                key={item.id}
                path={'' + item.id}
                exact={typeof item.exact !== 'undefined' ? item.exact : false}
                render={(props) => {
                    return this.routeRender(item);
                }}
            />
        ));
        return (
            <Router hashType="noslash">
                <Switch>
                    {ir}
                    <Route
                        key="dashboard"
                        path="/"
                        exact={this.props.userData != null ? true : false}
                        render={(props) => {
                            this.routeRender({
                                app: 'component',
                                component: 'Dashboard',
                                disabled: false,
                                exact: this.props.userData != null ? true : false,
                                icon: 'fal fa-home',
                                id: '/',
                                title: 'dashboard',
                                uri: 'about:blank'
                            });
                        }}
                    />
                    {this.props.userData != null && <Route key="erro404" path="*" exact={true} render={(props) => <Error404 />} />}
                </Switch>
            </Router>
        );
    }

    _renderMobile() {
        let ir = this.state.routes.map((item, i) => (
            <Route
                key={item.id}
                path={'' + item.id}
                exact={typeof item.exact !== 'undefined' ? item.exact : false}
                render={(props) => {
                    return (
                        <Tablet
                            routes={this.state.routes}
                            {...item}
                            collapsed={this.state.collapsed}
                            menuTabs={this.state.navTabs}
                            menuItems={this.state.items}
                            version={this.state.version}
                        />
                    );
                }}
            />
        ));
        return (
            <Router hashType="noslash">
                <Switch>
                    <div>
                        <div>{ir}</div>
                    </div>
                </Switch>
            </Router>
        );
    }

    _renderTablet() {
        let ir = this.state.routes.map((item, i) => (
            <Route
                key={item.id}
                path={'' + item.id}
                exact={typeof item.exact !== 'undefined' ? item.exact : false}
                render={(props) => {
                    return (
                        <Tablet
                            routes={this.state.routes}
                            {...item}
                            collapsed={this.state.collapsed}
                            menuTabs={this.state.navTabs}
                            menuItems={this.state.items}
                            version={this.state.version}
                        />
                    );
                }}
            />
        ));
        return (
            <Router hashType="noslash">
                <Switch>{ir}</Switch>
            </Router>
        );
    }

    render() {
        return (
            <>
                <MediaQuery minWidth={1224}>{this._renderDesktop()}</MediaQuery>
                <MediaQuery maxWidth={1224}>{this._renderTablet()}</MediaQuery>
            </>
        );
    }
}

export default withAuth(DeLayoutContainer);
