import React from 'react';
import Dashboard from "./../../portal/examples/dashboard";
import NavTop from "./../../portal/examples/navtop";
import NavRight from "./../../portal/examples/navright";
import NavTopRight from "./../../portal/examples/navtopright";
import SplitVertical from "./../../portal/examples/splitvertical";
import SplitHorizontal from "./../../portal/examples/splithorizontal";
import SplitVerticalNavTop from "./../../portal/examples/splitverticalnavtop";
import SplitHorizontalNavTop from "./../../portal/examples/splithorizontalnavtop";
import SplitNested from "./../../portal/examples/splitnested";
import PDM from "./../../portal/examples/pdm";
import ReportBuilder from "./../../portal/sano/reportbuilder";
import Account from "./../../portal/sano/account";
import ImportStatus from "./../../portal/sano/importstatus";
import Companies from "./../../portal/sano/companies";
import Farmer from "./../../portal/sano/farmer";
import Regions from "./../../portal/sano/regions";
import Translation from "./../../portal/sano/translation";
import Order from '../../portal/sano/order';
import Laborbuch from "../../portal/sano/laborbuch";
import Imprint from '../../portal/sano/imprint';
import Privacy from '../../portal/sano/privacy';
import {assignmentUser, assignmentRegion,AssignmentUserToRegion, assignmentSubmitmail }from "../../portal/sano/assignment"
import Laborstatistik from '../../portal/sano/laborstatistik/index';
import XRFReport from "../../portal/sano/xfrreport/index"
import {XRFReportGras,XRFReportMais} from "../../portal/sano/xrfreportregional/index"
import Mailverwaltung from "../../portal/sano/mailverwaltung"
import LKVAuswertung from "../../portal/sano/lkvauswertung"
import Maillog from "../../portal/sano/maillog"
import GeoData from "../../portal/sano/geodata"
import ExportCompany from "../../portal/sano/exportcompany"
import LKVAuswertung2 from '../../portal/sano/lkvauswertung2';
import { GlobalSearchTabs } from "../../portal/sano/globalsearch";
import DSGVOFarmerReport from '../../portal/sano/DSGVOFarmerReport'
export default class Component extends React.Component {

    components = {
        Dashboard: Dashboard,
        NavTop: NavTop,
        NavRight: NavRight,
        NavTopRight: NavTopRight,
        SplitVertical: SplitVertical,
        SplitHorizontal: SplitHorizontal,
        SplitVerticalNavTop: SplitVerticalNavTop,
        SplitHorizontalNavTop: SplitHorizontalNavTop,
        SplitNested: SplitNested,
        PDM: PDM,
        ReportBuilder: ReportBuilder,
        Account: Account,
        ImportStatus: ImportStatus,
        Translation: Translation,
        Companies: Companies,
        Farmer: Farmer,
        Regions: Regions,
        Order: Order,
        Laborbuch: Laborbuch,
        Imprint: Imprint,
        Privacy: Privacy,
        assignmentUser: assignmentUser,
        assignmentRegion : assignmentRegion,
        AssignmentUserToRegion: AssignmentUserToRegion,
        Laborstatistik: Laborstatistik,
        XRFReport:XRFReport,
        XRFReportMais:XRFReportMais,
        XRFReportGras:XRFReportGras,
        Mailverwaltung:Mailverwaltung,
        LKVAuswertung:LKVAuswertung,
        Maillog:Maillog,
        LKVAuswertung2: LKVAuswertung2,
        assignmentSubmitmail: assignmentSubmitmail,
        GeoData: GeoData,
        ExportCompany: ExportCompany,
        GlobalSearchTabs: GlobalSearchTabs,
        DSGVOFarmerReport: DSGVOFarmerReport
    }

    render(){
        const TagName = this.components[this.props.component];
        return (
            <React.Fragment>
                <TagName props={this.props.props} collapsed={this.props.collapsed} />
            </React.Fragment>
        )
    }
}
