import React from 'react';
import moment from 'moment';
import { Input, Button, Space, DatePicker, Checkbox, Col, Row, Form, InputNumber } from 'antd';
import i18n from './../../../misc/i18n';
import BundeslandRenderer from './bundeslandRenderer';
import ProbenartRenderer from './ProbenartRenderer';
import CountryRenderer from './countryRenderer';

const colfilters = {
    render() {
        return {
            handlePreSelect(val, setSelectedKeys) {
                let values = [];
                if (val !== null) {
                    for (let i = 0; i < val.length; i++) {
                        values.push(val[i]);
                    }
                }
                setSelectedKeys(values ? values : []);
            },

            doType(setSelectedKeys, selectedKeys, confirm) {
                switch (this.filter) {
                    case 'boolean':
                        return (
                            <Checkbox.Group
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    this.handlePreSelect(e ? [e] : null, setSelectedKeys);
                                }}
                                ref={(node) => {
                                    this.filterInput = node;
                                }}
                            >
                                <Row>
                                    {this.filter_list?.map((item, i) => (
                                        <Col span={24} key={i}>
                                            <Checkbox value={typeof item === 'object' && item != null && item.value != null ? item.value : item}>
                                                {typeof item === 'object' && item != null && item.label != null
                                                    ? i18n.t('colfilter.label.' + item.label)
                                                    : null
                                                }
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        );                    
                    case 'list':
                        return (
                            <Checkbox.Group
                                style={{ width: '100%' }}
                                onChange={(e) => {
                                    this.handlePreSelect(e ? [e] : null, setSelectedKeys);
                                }}
                                ref={(node) => {
                                    this.filterInput = node;
                                }}
                            >
                                <Row>
                                    {this.filter_list?.map((item, i) => (
                                        <Col span={24} key={i}>
                                            <Checkbox value={typeof item === 'object' && item != null && item.value != null ? item.value : item}>
                                                {typeof item === 'object' && item != null && item.label != null
                                                    ? i18n.t('order.status.' + item.label)
                                                    : this.renderer === 'bestellstatus'
                                                    ? i18n.t('order.status.' + item)
                                                    : item}
                                            </Checkbox>
                                        </Col>
                                    ))}
                                </Row>
                            </Checkbox.Group>
                        );
                    case 'daterange':
                        return (
                            <DatePicker.RangePicker
                                format={i18n.t('format.date')}
                                allowClear={false}
                                onChange={(e) => {
                                    e[0] = moment(e[0].startOf('day'));
                                    e[1] = moment(e[1].endOf('day'));
                                    let formated = [e[0].format(), e[1].format()];
                                    this.handlePreSelect(formated ? [formated] : null, setSelectedKeys);
                                }}
                            />
                        );
                    case 'number':
                        return (
                            <InputNumber
                                placeholder={`${i18n.t('global.search')} ${this.label}`}
                                onChange={(e) => {
                                    this.handlePreSelect(e ? [e] : null, setSelectedKeys);
                                }}
                                onPressEnter={() => {
                                    this.handlePreSelect(selectedKeys, confirm, this.dataIndex);
                                }}
                            />
                        );
                    case 'country':
                        return <CountryRenderer handlePreSelect={this.handlePreSelect} setSelectedKeys={setSelectedKeys} />;    
                    case 'bundesland':
                        return <BundeslandRenderer handlePreSelect={this.handlePreSelect} setSelectedKeys={setSelectedKeys} />;
                    case 'probenart':
                        return <ProbenartRenderer handlePreSelect={this.handlePreSelect} setSelectedKeys={setSelectedKeys} />;
                    default:
                        return (
                            <Input
                                placeholder={`${i18n.t('global.search')} ${this.label}`}
                                onChange={(e) => {
                                    this.handlePreSelect(e.target.value ? [e.target.value] : null, setSelectedKeys);
                                }}
                                onPressEnter={() => {
                                    this.handlePreSelect(selectedKeys, confirm, this.dataIndex);
                                }}
                            />
                        );
                }
            },

            filterDropdown: function ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) {
                return (
                    <div style={{ padding: 8 }}>
                        <div style={{ padding: '0 0 0 0' }}>
                            <Form
                                ref={(node) => {
                                    this.filterForm = node;
                                }}
                            >
                                <Form.Item name={this.dataIndex}>{this.doType(setSelectedKeys, selectedKeys, confirm)}</Form.Item>
                            </Form>
                        </div>
                        <div style={{ width: '100%', textAlign: 'right' }}>
                            <Space>
                                <Button
                                    size="small"
                                    style={{ width: 100 }}
                                    onClick={() => {
                                        this.filterForm.resetFields([this.dataIndex]);
                                        clearFilters();
                                    }}
                                >
                                    {i18n.t('btn.reset')}
                                </Button>
                                <Button
                                    size="small"
                                    style={{ width: 100 }}
                                    type="primary"
                                    onClick={() => {
                                        confirm();
                                    }}
                                >
                                    {i18n.t('btn.ok')}
                                </Button>
                            </Space>
                        </div>
                    </div>
                );
            }

            // onFilter: (value, record) => {},
        };
    }
};

export default colfilters;
