import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";

let GeoLand = class GeoLand extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            selectdata: [],
            value: "",
            valueObj: null,
            landIso: "",
            loading: false
        }
        this.updateByLandwirt = this.updateByLandwirt.bind(this)
    }

    updateByLandwirt(e) {
        this.setState(
            {
                value: e.detail.land
            }, () => {
                this.props.onChange(e.detail.land)
                window.dispatchEvent(new CustomEvent('landSelected',{"detail":e.detail.land}));
            }
        )
    }


    componentDidMount() {
        window.addEventListener("landwirtSelected",this.updateByLandwirt,true)
        this._getSelectData();
    }

    componentWillUnmount(){
        window.removeEventListener("landwirtSelected",this.updateByLandwirt,true)
    }

    async _getSelectData(str) {
        this.setState({
            loading: true
        })
        try{
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));

            let sstr = typeof str === "undefined" ? "name==*" : "name==" + str + "*,iso==" + str + "*";

            let requestBody = {
                search: sstr,
                size: 300
            };

            let resp = await api.findAllLand(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: (resp.content !== undefined) ? resp.content : [],
                    loading: false,
                });
            }
        }catch(e){
            this.setState({
                loading: false
            })
        }
        
    }
    async componentDidUpdate(){
        if(this.props.data !== {} && this.props.data.land != null && this.state.value === ""){
            this.setState({
                value: this.props.data.land
            },()=>{
                this.props.onChange(this.props.data.land)
            })
            try{
                let api = new Api.RegionControllerApi(getRestApiConfig("region",this.props.userData))
                let requestBody = {
                    search: "regionName=='"+this.props.data.land+"'"
                }
                let region = await api.findFiltered(requestBody)
                if(Object.keys(this.props.data).length !== 0 && this.props.data.constructor === Object && this.props.data.regionen !== undefined &&  !this.props.data.regionen.includes(region.regionId)){
                    window.dispatchEvent(new CustomEvent('landSelected',{"detail":this.props.data.land}));
                }else{
                    console.info("Region is already set")
                }
            }catch(e){
                console.error("geoLand: Region couldn't be loaded")
            }
            
        }
    }

    onChange = (value,option) => {
        window.dispatchEvent(new CustomEvent('landSelected',{"detail":option.value}));
        this.setState({
            value,
            loading: false,
        },()=>{
            this.props.onChange(value)
        });
    };

    render() {
        const { loading, value } = this.state;
        return (
            <Select
                value={value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                filterOption={false}
                disabled={this.props.disabled}
            >
                {this.state.selectdata.map((itm, y) => {
                    return (
                        <Select.Option
                            key={y}
                            value={itm.iso}
                        >
                            {itm.iso}
                        </Select.Option>
                    )
                })}
            </Select>


        )
    }

}

export default withAuth(GeoLand);