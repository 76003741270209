import { Filter } from '../../../definitions/FilterEnum';
import { Renderer } from '../../../definitions/RendererEnum';

const laborbuch = {
    detailProp: 'bestellId',
    formitems: [
        {
            label: 'Probennummer',
            name: 'probenNummer',
            type: 'plaintext',
            disabled: false
        },
        {
            type: 'collapse',
            label: 'labor',
            defaultActiveKey: ['labor_0'],
            groups: [
                {
                    label: 'contact',
                    items: [
                        {
                            label: 'nachname',
                            name: 'nachname',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'vorname',
                            name: 'vorname',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'firma',
                            name: 'firma',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'strasseUndNr',
                            name: 'strasseNr',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'plz',
                            name: 'postleitzahl',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'wohnort',
                            name: 'ort',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'land',
                            name: 'land',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'telefon',
                            name: 'telefon',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'email',
                            name: 'email',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            // label: 'euBetriebsnummer',
                            label: 'betriebsId',
                            name: 'euNummer',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'crmNummer',
                            name: 'crmNumer',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'semiramis',
                            name: 'erpNumer',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'kontaktaufnahme',
                            type: 'divider'
                        },
                        {
                            label: 'email1',
                            name: 'email1',
                            type: 'plaintext',
                            labelTop: true,
                            disabled: false
                        },
                        {
                            label: 'email2',
                            name: 'email2',
                            type: 'plaintext',
                            labelTop: true,
                            disabled: false
                        },
                        {
                            label: 'berater',
                            name: 'berater',
                            type: 'plaintext',
                            labelTop: true,
                            disabled: false
                        }
                    ]
                },
                {
                    label: 'probe',
                    items: [
                        {
                            label: 'probenart',
                            name: 'probenart',
                            type: 'displayprobenartplain',
                            disabled: false
                        },
                        {
                            label: 'schnitt',
                            name: 'schnitt',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'siliermittelbezeichnung',
                            name: 'siliermittelbezeichnung',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'silonummer',
                            name: 'silonummer',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'hybridSorte',
                            name: 'hybridSorte',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'schnitthoehe',
                            name: 'schnitthoehe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'ertrag',
                            name: 'ertrag',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'jahr',
                            name: 'jahr',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'analyseTyp',
                            name: 'analyseTyp',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'bemerkung',
                            name: 'bemerkung',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'tsProbennahme',
                            name: 'tsProbennahme',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'dsgvo',
                            name: 'dsgvo',
                            type: 'boolean',
                            disabled: false
                        },
                        {
                            label: 'emailLaborUser',
                            name: 'emailLaborUser',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'kundenNummer',
                            name: 'kundenNummer',
                            type: 'plaintext',
                            disabled: false
                        }
                    ]
                },
                {
                    label: 're-empfaenger',
                    items: [
                        {
                            label: 'vorname',
                            name: 'vornameRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'nachname',
                            name: 'nachnameRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'firma',
                            name: 'firmaRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'strasseUndNr',
                            name: 'strasseNrRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'PLZ',
                            name: 'postleitzahRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'ort',
                            name: 'ortRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'ortsteil',
                            name: 'ortsteilRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'land',
                            name: 'landRe',
                            type: 'plaintext',
                            disabled: false
                        }
                    ]
                },
                {
                    label: 'furtherInfos',
                    items: [
                        {
                            label: 'tsInsertProbe',
                            name: 'tsInsert',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'uiInsert',
                            name: 'uiInsert',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'tsChangeProbe',
                            name: 'tsChange',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'uiChange',
                            name: 'uiChange',
                            type: 'plaintext',
                            disabled: false
                        }
                    ]
                }
            ]
        }
    ],
    formitemsSecondTab: [
        /* {
            label: 'Probennummer',
            name: 'probenNummer',
            type: 'plaintext',
            disabled: false
        },*/
        {
            label: 'tsSanoEingang',
            name: 'tsSanoEingang',
            type: 'labtimedisplaysano',
            disabled: false
        },
        {
            label: 'tsLaborEingang',
            name: 'tsLaborEingang',
            type: 'labtimedisplaylabor',
            disabled: false
        },
        {
            label: 'laborant',
            name: 'laborant',
            type: 'text',
            disabled: false
        },
        {
            label: 'bemerkung',
            name: 'bemerkung',
            type: 'textarea',
            disabled: false
        },
        {
            label: 'status',
            name: 'status',
            type: 'labstatusdropdown',
            data: [
                { key: 'PROBENAHME', value: 'PROBENAHME' },
                { key: 'EINGANG_SANO', value: 'EINGANG_SANO' },
                { key: 'EINGANG_LABOR', value: 'EINGANG_LABOR' },
                { key: 'ERGEBNIS', value: 'ERGEBNIS' },
                { key: 'DEAKTIVIERT', value: 'DEAKTIVIERT' }
            ]
        },
        {
            label: 'gratisProbe',
            name: 'gratisProbe',
            type: 'boolean',
            disabled: false
        }
    ],
    renderTitle: (rec) => {
        return rec.probenNummer + ' (' + rec.probenart + ' | ' + rec.status + ')';
    },
    columns: [
        {
            title: 'probenNummer',
            dataIndex: 'probenNummer',
            filter: Filter.TEXT,
            sort: true
            //"sortOrder":"descend",
            // "responsive": ["lg"]
        },
        {
            title: 'labId',
            dataIndex: 'labId',
            filter: 'text',
            sort: true
            //"sortOrder":"descend",
            // "responsive": ["lg"]
        },
        {
            title: 'analyseTyp',
            dataIndex: 'analyseTyp',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'probenart',
            dataIndex: 'probenart',
            filter: Filter.PROBENART,
            sort: true,
            renderer: Renderer.PROBENART
            // "responsive": ["lg"]
        },
        {
            title: 'status',
            dataIndex: 'status',
            filter: Filter.LIST,
            filter_list: ['PROBENAHME', 'EINGANG_SANO', 'EINGANG_LABOR', 'ERGEBNIS', 'DEAKTIVIERT'],
            sort: true,
            responsive: ['lg'],
            renderer: Renderer.BESTELLSTATUS
        },
        {
            title: 'firma',
            dataIndex: 'firma',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'vorname',
            dataIndex: 'vorname',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'nachname',
            dataIndex: 'nachname',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'plz',
            dataIndex: 'postleitzahl',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'land',
            dataIndex: 'land',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'tsProbennahme',
            dataIndex: 'tsProbennahme',
            renderer: Renderer.RENDERDATE,
            filter: Filter.DATERANGE,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'gratisProbe',
            dataIndex: 'gratisProbe',
            filter: Filter.LIST,
            filter_list: [
                { label: 'Ja', value: 'true' },
                { label: 'Nein', value: 'false' }
            ],
            renderer: Renderer.CHECKBOX,
            sort: true,
            responsive: ['lg']
        }
    ]
};

export default laborbuch;