import { User } from 'oidc-client';
import { isExtern } from '../../../../../misc/getUserRoles';

const filter_companys = (companies:any,oidcUser: User) => {
    let newItems: any = []
    companies.columns.forEach((element:any,index:number) => {
        if(element.title.includes("landwirt")){
            if(!isExtern(oidcUser)){
                newItems.push(element)
            }
        }else if(element.title.includes("euNummer")){
            if(!isExtern(oidcUser)){
                newItems.push(element)
            }
        }else if(element.title.includes("ort")){
            if(!isExtern(oidcUser)){
                newItems.push(element)
            }
        }else if(element.title.includes("betriebsId")){
            if(!isExtern(oidcUser)){
                newItems.push(element)
            }
        }else{
            newItems.push(element)
        }
    });
    companies.columns = newItems
    return companies
}

export default filter_companys