/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    LkvDokumentInhalt,
    LkvDokumentInhaltFromJSON,
    LkvDokumentInhaltToJSON,
    PageLkvDokument,
    PageLkvDokumentFromJSON,
    PageLkvDokumentToJSON,
} from '../models';

export interface FindFilteredLkvDocumentsRequest {
    betriebsId: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindLkvDokumentInhaltRequest {
    importJobId: string;
}

/**
 * 
 */
export class LkvControllerApi extends runtime.BaseAPI {

    /**
     */
    async findFilteredLkvDocumentsRaw(requestParameters: FindFilteredLkvDocumentsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageLkvDokument>> {
        if (requestParameters.betriebsId === null || requestParameters.betriebsId === undefined) {
            throw new runtime.RequiredError('betriebsId','Required parameter requestParameters.betriebsId was null or undefined when calling findFilteredLkvDocuments.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.betriebsId !== undefined) {
            queryParameters['betriebsId'] = requestParameters.betriebsId;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/lkv/lkvDokument`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLkvDokumentFromJSON(jsonValue));
    }

    /**
     */
    async findFilteredLkvDocuments(requestParameters: FindFilteredLkvDocumentsRequest, initOverrides?: RequestInit): Promise<PageLkvDokument> {
        const response = await this.findFilteredLkvDocumentsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findLkvDokumentInhaltRaw(requestParameters: FindLkvDokumentInhaltRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<LkvDokumentInhalt>> {
        if (requestParameters.importJobId === null || requestParameters.importJobId === undefined) {
            throw new runtime.RequiredError('importJobId','Required parameter requestParameters.importJobId was null or undefined when calling findLkvDokumentInhalt.');
        }

        const queryParameters: any = {};

        if (requestParameters.importJobId !== undefined) {
            queryParameters['importJobId'] = requestParameters.importJobId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/lkv/lkvDokumentInhalt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LkvDokumentInhaltFromJSON(jsonValue));
    }

    /**
     */
    async findLkvDokumentInhalt(requestParameters: FindLkvDokumentInhaltRequest, initOverrides?: RequestInit): Promise<LkvDokumentInhalt> {
        const response = await this.findLkvDokumentInhaltRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
