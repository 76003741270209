import React from 'react';
import ListDetail from '../../listdetail';
import helper from './../../listdetail/listtable/_helper';
import location from './_location';
import LoadMask from '../../../../misc/loadmask';
import * as Api from '../../../../../api/rest';
import { getRestApiConfig } from '../../../../../_conf';
import { checkChanged } from '../../../../misc/checkChanged';
import userNotification from '../../../../misc/notification';
import { validateData, RequestType } from './../../../../misc/validateData';
import { withAuth } from 'oidc-react';

class Location extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: location.defaultPageSize,
            detailVisible: false
        };
    }

    _getApi() {
        return new Api.GeoControllerApi(getRestApiConfig('geo', this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp, params) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: false,
            // detaildata: {},
            loading: false,
            pageSize: params.size,
            pagination: {
                current: params.page + 1,
                total: resp.totalElements,
                pageSize: params.size,
                pageSizeOptions: location.pageSizeOptions
            }
        });
    }

    _purgeData(data) {
        delete data['email'];
        delete data['tsChange'];
        delete data['tsInsert'];
        delete data['uiChange'];
        delete data['uiInsert'];
        data.longitude = parseFloat(data.longitude);
        data.latitude = parseFloat(data.latitude);
        data.elevation = parseInt(data.elevation);
        return data;
    }

    async _fetchListData(p) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        };
        let resp = await this._getApi()
            .findAllOrt(requestBody)
            .catch(() => {
                userNotification('error', 'notification.location.get.error');
            });
        if (resp != null) {
            this._setListState(resp, params);
        }
    }

    async _fetchDetailData(id) {
        let requestBody = {
            id: id
        };
        let resp = await this._getApi()
            .findOrtById(requestBody)
            .catch(() => {
                userNotification('error', 'notification.location.get.error');
            });
        this.setState({
            detaildata: resp,
            detailVisible: true
        });
    }

    async _insertData(data) {
        data = this._purgeData(data);

        try {
            let requestBody = {
                ort: data
            };
            let result = await this._getApi().createOrt(requestBody);
            if (result != null) {
                userNotification('success', 'notification.location.insert.success');
                this._fetchDetailData(result.id);
                this._fetchListData({});
            }
        } catch (errorInsert) {
            try {
                errorInsert.json().then((errorResponse) => userNotification('error', 'notification.location.insert.error'));
            } catch (errorToJson) {
                userNotification('error', 'notification.location.insert.error');
            }
        }
    }

    async _updateData(id, data) {
        data = this._purgeData(data);
        try {
            let requestBody = {
                ortId: id,
                body: data
            };
            let result = await this._getApi().updateOrt(requestBody);
            if (result != null) {
                userNotification('success', 'notification.location.update.success');
                this._fetchListData({});
            }
        } catch (errorUpdate) {
            try {
                errorUpdate
                    .json()
                    .then((errorResponse) =>
                        errorResponse.message != null && errorResponse.message === '2404'
                            ? userNotification('error', 'notification.location.update.errorcast')
                            : userNotification('error', 'notification.location.update.error')
                    );
            } catch (errorToJson) {
                userNotification('error', 'notification.location.update.error');
            }
        }
    }

    async _deleteData(id) {
        try {
            let requestBody = {
                id: id
            };
            await this._getApi().deleteOrt(requestBody);
            userNotification('success', 'notification.location.delete.success');
            this._fetchListData({});
        } catch (e) {
            if (e.status + '' === '406') {
                userNotification('error', 'notification.location.delete.assigned');
            } else {
                userNotification('error', 'notification.location.delete.error');
            }
        }
    }

    _getUserPermissions() {
        let perm = this.props.perm;

        perm.token.realm_access.roles.forEach((role) => {
            if (role === 'Administrator') {
                perm.permission = {
                    delete: true,
                    hasToolbar: true,
                    showSaveButton: true,
                    onDelete: (id) => this._deleteData(id),
                    onNew: () => {
                        this.setState({
                            detaildata: {
                                new: true
                            },
                            detailVisible: true
                        });
                    },
                    onSave: (data) => {
                        if (this.state.detaildata.new != null) {
                            if (validateData(data, this.state.detaildata, location, RequestType.post)) {
                                this._insertData(data);
                            }
                        } else {
                            if (validateData(data, this.state.detaildata, location, RequestType.patch)) {
                                // data = this.state.detaildata
                                checkChanged(
                                    data,
                                    this.state.detaildata,
                                    location,
                                    () => {
                                        data.elevation = parseInt(data.elevation);
                                        data.latitude = parseFloat(data.latitude);
                                        data.longitude = parseFloat(data.longitude);

                                        this._updateData(this.state.detaildata[location.detailProp], data);
                                    },
                                    () => {}
                                );
                            }
                        }
                    }
                };
            }
        });
        return perm.permission;
    }

    render() {
        let permission = this._getUserPermissions();
        let props = {
            detailProp: location.detailProp,
            columns: location.columns,
            formitems: location.formitems,

            detaildata: this.state.detaildata,
            loading: this.state.loading,
            listdata: this.state.listdata,
            detailVisible: this.state.detailVisible,

            hasToolbar: permission.hasToolbar,
            delete: permission.delete,

            pagination: this.state.pagination,
            renderTitle: location.renderTitle,
            fetchListData: (p) => {
                this._fetchListData(p);
            },
            onSave: (data) => {
                permission.onSave(data);
            },
            onTableChange: (p) => {
                this.setState(
                    {
                        pageSize: p.pagination.pageSize
                    },
                    () => {
                        this._fetchListData(p);
                    }
                );
            },
            onRowSelection: (id) => {
                this._fetchDetailData(id);
            },
            onDelete: (id) => {
                permission.onDelete(id);
            },
            onDetailClose: () => {
                this.setState({
                    detailVisible: false
                });
            },
            onNew: () => {
                permission.onNew();
            }
        };

        return (
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail {...props} />
            </>
        );
    }
}

export default withAuth(Location);
