import React from "react";
import { Form, Button } from "antd";
import SortField from "./sortfield";
import i18n from './../../../../misc/i18n';

export default class SortPanel extends React.Component {

    constructor(props){
        super(props)
        // this.sorter = {}

        this.state = {
            btnSize: "large",
            sorter: this.props.sorter
        }
    }

    _handleReset(){
        if(this.props.onReset){
            this.setState({
                sorter: {}
            },() => {
                this.props.onReset(this.state.sorter)
            })            
        }
    }

    _handleAbort(){
        if(this.props.onReset){
            this.props.onAbort()
        }
    }

    _handleSort(){
        if(this.props.onReset){
            this.props.onSort(this.state.sorter)
        }
    }

    render(){
        return(
            <>        
                <Form>
                    <Form.Item name="field">
                        <SortField columns={this.props.columns} type="single" sorter={this.props.sorter} onChange={(sorter) => {
                            this.setState({
                                sorter: sorter
                            })
                        }} />
                    </Form.Item>
                </Form>
                <div className="resp-table-menu-button-wrap">
                    <Button size={this.state.btnSize} onClick={() => { this._handleReset() }}>{i18n.t('btn.reset')}</Button>
                    <Button size={this.state.btnSize} onClick={() => { this._handleAbort() }}>{i18n.t('btn.cancel')}</Button> 
                    <Button size={this.state.btnSize} type="primary" onClick={() => { this._handleSort() }}>{i18n.t('btn.ok')}</Button>
                </div>
            </>
        )
    }
}