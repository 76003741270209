import React from "react";
import { Collapse, Button, BackTop, Pagination, Table } from "antd";
import { CSSTransition } from 'react-transition-group';
import SearchPanel from "./searchpanel";
import SortPanel from "./sortpanel";
import colrenderer from "./../../../portal/sano/datatable/colrenderer";
import OrderDownload from "./orderdownload"; 

export default class RespTable extends React.Component{

    constructor(props){
        super(props)
        this.table = {
            pagination: (props.pagination) ? {current: 1, pageSize: 20} : {}, 
            filters: {}, 
            sorter: {}, 
        };
        this.state = {
            showDetail: false,
            activeItem: {},
            transitionTimeout: 100,
            searchexpand: false,
            sortexpand: false,
            btnSize: "large",
        }
    }

    _propagateChange(){
        if(this.props.onChange){
            this.props.onChange(this.table.pagination, this.table.filters, this.table.sorter)
        }
    }

    _getTopToolbar(){
        return(
            <>
                <Button size={this.state.btnSize} type="ghost" className={(Object.keys(this.table.sorter).length !== 0) ? " active" : null} onClick={() => {
                    this.setState({
                        searchexpand: false,
                        sortexpand: !this.state.sortexpand
                    })
                }}><i className="fas fa-sort"></i></Button>

                <Button size={this.state.btnSize} type="ghost" className={(Object.keys(this.table.filters).length !== 0) ? " active" : null} onClick={() => {
                    this.setState({
                        sortexpand: false,
                        searchexpand: !this.state.searchexpand
                    })
                }}><i className="fal fa-search"></i></Button>
            </>
        )
    }

    _renderDefaultRecord(item){
        let dataSource = [];
        let columns = [{
            title: 'Name',
            dataIndex: 'name',
            key: 'name',
            className: 'label-cell'
          },
          {
            title: 'Value',
            dataIndex: 'value',
            key: 'value',
          }];
        for(let i=0; i<this.props.columns.length; i++){
            let v = item[this.props.columns[i].dataIndex];
            if(typeof(this.props.columns[i].renderer) !== "undefined"){
                try{
                    let renderer = this.props.columns[i].renderer
                    if(renderer === "bundesland"){
                        renderer = "renderBundeslandName"
                    }
                    if(renderer === "probenart"){
                        renderer = "renderProbenartName"
                    }
                    if(renderer === "bestellstatus"){
                        renderer = "renderBestellStatus"
                    }
                    v = colrenderer[renderer]({},this.props.columns[i].dataIndex).render(v)
                }catch(e){}
            }
            if(typeof(v) !== "undefined"){
                dataSource.push({
                    name: this.props.columns[i].title,
                    value: v,
                    key: i
                })
            }
        }
        return(<Table dataSource={dataSource} columns={columns} size="small" showHeader={false} pagination={false} />)
    }

    _renderDefaultTitle(item){
        if(item.betriebsName != null){
            return item.betriebsName
        }else{
            return("This is panel header 1")
        }
       
    }

    _getContent(){
        return(
            <>
                <Collapse bordered={false} accordion onChange={(e) => {
                    this.setState({
                        activeItem: this.props.dataSource[e]
                    },() =>{
                        if(typeof(this.props.rowSelection) !== "undefined" && this.props.rowSelection.onChange){
                            this.props.rowSelection.onChange([parseInt(e)], [this.state.activeItem])
                        }
                    })
                }}>
                    {this.props.dataSource.map((item, i) => {
                        return(
                            <Collapse.Panel key={i} header={(this.props.renderTitle) ? this.props.renderTitle(item) : this._renderDefaultTitle(item)}>
                                {(this.props.renderRecord) ? this.props.renderRecord(item) : this._renderDefaultRecord(item)}
                                
                                {/* Hack wg Downloads */}
                                {
                                    (typeof(this.props.type) !== "undefined" && this.props.type === "order")
                                        ? <OrderDownload item={this.state.activeItem} />
                                        : null
                                }
                            </Collapse.Panel>
                        )
                    })}
                </Collapse>
                <BackTop />
            </>
        )
    }

    _getPaging(){
        return(
            <Pagination 
                defaultCurrent={1} 
                total={this.props.pagination.total} 
                pageSize={20}
                responsive={true} 
                showSizeChanger={false}
                onChange={(page, pageSize) => {
                    this.table.pagination.current = page;
                    this.table.pagination.pageSize = pageSize;
                    this._propagateChange()
                }}
            />
        )
    }

    _getSearchPanel(){
        return(
            <CSSTransition in={this.state.searchexpand} timeout={this.state.transitionTimeout} classNames="example" unmountOnExit>
                <div className="menu">
                    <SearchPanel 
                        {...this.props} 
                        filters={this.table.filters}
                        onSearch={(filters) => {
                            this.table.filters = filters
                            this.setState({
                                searchexpand: !this.state.searchexpand
                            }, () => {
                                this._propagateChange()
                            })
                        }}
                        onAbort={() => {
                            this.setState({
                                searchexpand: !this.state.searchexpand
                            })
                        }} 
                        onReset={(filters) => {
                            this.table.filters = filters
                            this.setState({
                                searchexpand: !this.state.searchexpand
                            }, () => {
                                this._propagateChange()
                            })
                        }} 
                    />
                </div>
            </CSSTransition>
        )
    }

    _getSortPanel(){
        return(
            <CSSTransition in={this.state.sortexpand} timeout={this.state.transitionTimeout} classNames="example" unmountOnExit>
                <div className="menu">
                    <SortPanel {...this.props} 
                        sorter={this.table.sorter}
                        onSort={(sorter) => {
                            this.table.sorter = sorter
                            this.setState({
                                sortexpand: !this.state.sortexpand
                            },() => {
                                this._propagateChange()
                            })
                        }}
                        onAbort={() => {
                            this.setState({
                                sortexpand: !this.state.sortexpand
                            })
                        }}
                        onReset={(sorter) => {
                            this.table.sorter = sorter                                
                            this.setState({
                                sortexpand: !this.state.sortexpand
                            },() => {
                                this._propagateChange()
                            })
                        }}
                    />
                </div>
            </CSSTransition>
        )
    }

    render(){
        return(
            <>
                <div className="toptoolbar">
                    {this._getTopToolbar()}
                </div>
                <div className="content">
                    {this._getContent()}
                </div>
                {this._getSortPanel()}
                {this._getSearchPanel()}
                {(this.props.pagination && !this.state.searchexpand && !this.state.sortexpand) ? <div className="paging">{this._getPaging()}</div> : null}
            </>
        )
    }
}
