import { Filter } from "../../../../definitions/FilterEnum";
import { Renderer } from "../../../../definitions/RendererEnum";

const farmer = {
    columns: [
        {
            title: "landwirtIdNumber",
            dataIndex: "landwirtId",
            filter: Filter.NUMBER,
            sort: true,
            align: "right"
            // "responsive": ["lg"]
        },
        {
            title: "vorname",
            dataIndex: "vorname",
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: "nachname",
            dataIndex: "nachname",
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: "plz",
            dataIndex: "plz",
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: "ort",
            dataIndex: "ort",
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: "bundesland",
            dataIndex: "bundesland",
            renderer: Renderer.BUNDESLAND,
            filter: Filter.BUNDESLAND
            //"sort": true,
            // "responsive": ["lg"]
        },
        {
            title: "land",
            dataIndex: "land",
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        }
    ]
};

export default farmer;
