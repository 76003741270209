import React from "react";
import { Spin } from "antd";
import * as Api from "../../../../../api/importstatus";
import { getImportApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import TagSelect from "../../tagselect/tagselect";

import userNotification from "../../../../misc/notification";
interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: string[];
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    companyId: any;
    probenart: string;
    disabled: boolean | undefined;
    defaultValue: string[];
}

class ProbennummerSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            value: this.props.defaultValue ? this.props.defaultValue : []
        };
    }
    componentDidMount() {
        this._getSelectData();
    }

    componentDidUpdate(prevProps: IProps) {
        if (
            (this.props.companyId !== prevProps.companyId && this.props.companyId.companyId !== prevProps.companyId.companyId) ||
            this.props.probenart !== prevProps.probenart
        ) {
            let newVal = this.props.defaultValue ? this.props.defaultValue : [];
            if (this.props.companyId == null) {
                newVal = [];
            }
            if (this.props.onChange != null) {
                this.props.onChange(newVal, {});
            }
            this.setState({
                value: newVal
            });
            if (this.props.companyId != null && this.props.companyId.companyId != null && this.props.probenart != null) {
                this._getSelectData();
            }
        } else {
            if (this.props.companyId != null && this.props.companyId.companyId != null && this.props.probenart != null) {
                if (this.props.companyId.companyId !== prevProps.companyId.companyId || this.props.probenart !== prevProps.probenart) {
                    if (this.props.onChange !== undefined) {
                        this.props.onChange(this.props.defaultValue ? this.props.defaultValue : [], {});
                    }
                    this.setState({
                        value: this.props.defaultValue ? this.props.defaultValue : []
                    });
                    this._getSelectData();
                }
            }
        }
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        if (this.props.companyId === undefined || this.props.companyId.companyId === undefined) {
            return;
        }
        let api = new Api.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));
        try {
            let requestBody = {
                type: this.props.probenart,
                betriebsId: this.props.companyId.companyId
            };
            let resp = await api.findSamplesForXrf(requestBody);
            if (resp !== undefined) {
                this.setState({
                    selectdata: resp !== undefined ? resp : [],
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.probenumber.error");
            this.setState({
                loading: false
            });
        }
    }
    onSearch = async (value: string) => {
        this.setState({
            loading: true
        });
        if (value === "") {
            await this._getSelectData();
            return;
        }

        let api = new Api.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));
        try {
            let requestBody = {
                type: this.props.probenart,
                betriebsId: this.props.companyId.companyId + "",
                search: "probenNummer=='" + value + "*'"
            };
            let resp = await api.findSamplesForXrf(requestBody);
            if (resp !== undefined) {
                this.setState({
                    selectdata: resp !== undefined ? resp : [],
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.probenumber.error");
            this.setState({
                loading: false
            });
        }
    };

    onChange = (value: string[], option: any) => {
        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };
    textRenderer = (item: Api.Probenbestellung) => {
        return item.probenNummer ? item.probenNummer : "";
    };

    render() {
        const { loading } = this.state;
        return (
            <TagSelect
                disabled={this.props.disabled}
                keyAttributeName="probenNummer"
                valueAttributeName="probenNummer"
                onChange={this.onChange}
                notFoundContent={loading ? <Spin size="small" /> : null}
                selectdata={this.state.selectdata}
                textrender={this.textRenderer}
                style={{ width: "100%" }}
                selectAll={true}
                selectAlli18n="order.probenart.all"
                value={this.state.value}
                onSearch={this.onSearch}
                showSearch={true}
            />
        );
    }
}

export default withAuth(ProbennummerSelectForm);
