import {  Input } from 'antd';
import React from "react";

export default class StreetAutoinsert extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: null
        }
    }

    componentDidUpdate() {
        if (this.props.data !== {} && this.props.data.strasseNr != null ) {

            this.setState({
                value: this.props.data.strasseNr
            })
        }
    }
    onChange = (value) => {
        this.setState({
            value,
        }, () => {
            this.props.onChange(value)
        });
    };

    render(){
        return (
            <Input
            value={this.state.value}
            onChange={this.onChange}
        />
        );
        
    }
}