import React from "react";
import { Spin } from "antd";
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import i18n from "../../../../misc/i18n";
import TagSelect from "../../tagselect/tagselect";
import areArraysEqual from "../../../../misc/arraysEqual";
import userNotification from "../../../../misc/notification";

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: any[];
    language: string;
    alli18n: string;
    land: string[];
    state: string[];
    region: string[];
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    disabled: boolean | undefined;
    land: string[];
    state: string[];
    region: string[];
    defaultValue: string[];
    startDate: any;
    endDate: any;
}

class PlzSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            value: this.props.defaultValue ? this.props.defaultValue : [],
            language: "",
            land: [],
            state: [],
            region: [],
            alli18n: "order.probenart.all"
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (i18n.language !== this.state.language) {
            let values = this.state.value;
            for (let i = 0; i < values.length; i++) {
                //Alte Übersetzung mit neuer austauschen
                if (values[i] === i18n.t(this.state.alli18n, { lng: this.state.language })) {
                    values[i] = i18n.t(this.state.alli18n);
                }
            }
            this.setState(
                {
                    language: i18n.language,
                    value: values
                },
                () => {
                    if (this.props.onChange != null) {
                        this.props.onChange(values, []);
                    }
                }
            );
        }

        //Zuständig für allgemeine Änderungen da sich die  Länge dann immer mit +1 oder -1 ändert
        if (
            this.props.land.length !== this.state.land.length ||
            this.props.state.length !== this.state.state.length ||
            this.props.region.length !== this.state.region.length
        ) {
            this.setState(
                {
                    land: this.props.land,
                    state: this.props.state,
                    region: this.props.region
                },
                () => {
                    let newVal = this.props.defaultValue ? this.props.defaultValue : [];
                    if (this.props.land.length === 0 || this.props.state.length === 0 || this.props.region.length === 0) {
                        newVal = [];
                    }
                    if (this.props.onChange !== undefined) {
                        this.props.onChange(newVal, {});
                    }
                    this.setState({
                        value: newVal
                    });
                    if (this.props.land.length > 0 && this.props.state.length > 0 && this.props.region.length > 0) {
                        this._getSelectData();
                    }
                }
            );
        } else {
            if (this.props.land.length > 0 && this.props.state.length > 0 && this.props.region.length > 0) {
                if (
                    !areArraysEqual(this.props.land, this.state.land) ||
                    !areArraysEqual(this.props.state, this.state.state) ||
                    !areArraysEqual(this.props.region, this.state.region)
                ) {
                    this.setState(
                        {
                            land: this.props.land,
                            state: this.props.state,
                            region: this.props.region
                        },
                        () => {
                            if (this.props.onChange !== undefined) {
                                this.props.onChange(this.props.defaultValue ? this.props.defaultValue : [], {});
                            }
                            this.setState({
                                value: this.props.defaultValue ? this.props.defaultValue : []
                            });
                            this._getSelectData();
                        }
                    );
                }
            }
        }
        //Wenn das Datum geändert wurde neue Daten laden
        if (this.props.startDate.toISOString() !== prevProps.startDate.toISOString() || this.props.endDate.toISOString() !== prevProps.endDate.toISOString()) {
            this._getSelectData();
        }
    }
    onSearch = async (value: string) => {
        this.setState({
            loading: true
        });
        if (value === "") {
            await this._getSelectData();
            return;
        }
        let query = "postleitzahl=='" + value + "*'";
        if (this.props.land.length > 0 && this.props.state.length > 0 && this.state.region.length > 0) {
            let statequery = "";
            if (!this.props.state.includes(i18n.t(this.state.alli18n))) {
                statequery = ";isoBundesland=in=(" + this.props.state + ")";
            }
            query += statequery;
        } else {
            return;
        }
        try {
            let laender = this.props.land.includes(i18n.t(this.state.alli18n)) || this.props.land.length === 0 ? undefined : this.props.land;
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let region = this.props.region.includes(i18n.t(this.state.alli18n)) ? undefined : this.props.region;
            let requestBody = {
                size: 45,
                search: query,
                regionNamen: region,
                laender: laender,
                startDate: this.props.startDate,
                endDate: this.props.endDate
            };
            let resp = await api.findFilteredPlzLaborstatistik(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.geodb.plz.error");
            this.setState({
                loading: false
            });
        }
    };

    async _getSelectData() {
        this.setState({
            loading: true
        });
        let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
        let query = undefined;
        if (this.props.land.length > 0 && this.props.state.length > 0 && this.state.region.length > 0) {
            let statequery = "";
            if (!this.props.state.includes(i18n.t(this.state.alli18n))) {
                statequery = "isoBundesland=in=(" + this.props.state + ")";
            }
            query = statequery;
            if (statequery === "") {
                query = undefined;
            }
        } else {
            return;
        }
        try {
            let region = this.props.region.includes(i18n.t(this.state.alli18n)) ? undefined : this.props.region;
            let laender = this.props.land.includes(i18n.t(this.state.alli18n)) || this.props.land.length === 0 ? undefined : this.props.land;
            let requestBody = {
                size: 25,
                search: query,
                regionNamen: region,
                laender: laender,
                startDate: this.props.startDate,
                endDate: this.props.endDate
            };
            let resp = await api.findFilteredPlzLaborstatistik(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.geodb.plz.error");
            this.setState({
                loading: false
            });
        }
    }

    onChange = (value: string[], option: any) => {
        if (this.props.onChange != null) {
            let modified: string[] = [];
            value.forEach((element) => {
                modified.push(element.split("_")[0]);
            });
            this.props.onChange(modified, option);
        }

        this.setState({
            value: value,
            loading: false
        });
    };
    textRenderer = (itm: any) => {
        if (itm.selectvalue === i18n.t(this.state.alli18n)) {
            return i18n.t(this.state.alli18n);
        }
        return itm.postleitzahl + " | " + itm.ort;
    };
    modifiedSelectData() {
        let modified: any[] = [];
        this.state.selectdata.forEach((itm, i) => {
            let newItem = {};
            Object.assign(newItem, itm);
            Object.assign(newItem, {
                selectvalue: itm.postleitzahl + "_" + itm.ort + "_" + i
            });
            modified.push(newItem);
        });
        return modified;
    }

    render() {
        const { loading } = this.state;
        return (
            <TagSelect
                disabled={this.props.disabled}
                keyAttributeName="selectvalue"
                valueAttributeName="selectvalue"
                onChange={this.onChange}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onSearch={this.onSearch}
                selectdata={this.modifiedSelectData()}
                textrender={this.textRenderer}
                style={{ width: "100%" }}
                selectAll={true}
                value={this.state.value}
                selectAlli18n={this.state.alli18n}
            />
        );
    }
}

export default withAuth(PlzSelectForm);
