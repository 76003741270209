import i18n from './i18n';
import {notification} from "antd";
import IllegalArgumentException from "./customErrors"

let userNotification = (type:string,i18nPath:string, messagePlacement = "bottomRight")=>{
    type = type.toLowerCase()
    if(!["success","info","warning","error"].includes(type) ){
        throw new IllegalArgumentException("Type has to be success || info || warning || error");
    }
    //@ts-ignore Das öffnen einer Notification ist so nicht in Typescript möglich
    // da in antd success,info ect. als function hinterlegt sind.
    // Da die NotificationAPI aber ein Objekt ist kann in Javascript über strings welche als Key verwendet werden einfach
    // auf die Methoden zugegriffen werden
    notification[type]({
        message: i18n.t(i18nPath+".title"),
        description:
        i18n.t(i18nPath+".body"),
        placement: messagePlacement
      });
}

export default userNotification;