import moment from 'moment';
import i18n from '../../../misc/i18n';
const report = {
    formItems: [
        {
            name: 'companyId',
            value: "",
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'perimeter',
            value: 10,
            default:10
        },
        {
            name: 'type',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'datum',
            value: [moment().subtract(2, 'years'), moment()]
        },
        {
            name: 'calculation',
            value: "% / ppm",
            default:"% / ppm"
        }
        
    ]
};

export default report;
