import React from 'react';
import { Select, Spin } from 'antd';
import * as Api from '../../../../../api/rest';
import { getRestApiConfig } from '../../../../../_conf';
import { AuthContextProps, withAuth } from "oidc-react";
import i18n,{ i18nApp } from '../../../../misc/i18n';
import areArraysEqual from "../../../../misc/arraysEqual";

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    land: string[];
    value: string[];
    language: string;
    alli18n: string;
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    disabled: boolean | undefined;
    land: string[];
    defaultValue: string[];
    startDate: any;
    endDate:any;
}

class BundeslandSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            land: [],
            value: this.props.defaultValue ? this.props.defaultValue : [],
            language: '',
            alli18n: 'order.probenart.all'
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if(i18n.language !== this.state.language){
            let values = this.state.value
            for(let i = 0; i<values.length;i++){
                //Alte Übersetzung mit neuer austauschen
                if (values[i] === i18n.t(this.state.alli18n, { lng: this.state.language })) {
                    values[i] = i18n.t(this.state.alli18n);
                }
            }
            this.setState(
                {
                    language: i18n.language,
                    value: values
                },
                () => {
                    if (this.props.onChange != null) {
                        this.props.onChange(values, []);
                    }
                }
            );
        }
        if (this.props.land.length !== this.state.land.length) {
            this.setState(
                {
                    land: this.props.land
                },
                () => {
                    let newVal = this.props.defaultValue ? this.props.defaultValue : [];
                    if (this.props.land.length === 0) {
                        newVal = [];
                    }
                    if (this.props.onChange !== undefined) {
                        this.props.onChange(newVal, {});
                    }
                    this.setState(
                        {
                            value: newVal
                        },
                        () => {
                            this._getSelectData();
                        }
                    );
                }
            );
        } else {
            if (this.props.land.length > 0) {
                if (!areArraysEqual(this.props.land, this.state.land)) {
                    this.setState(
                        {
                            land: this.props.land
                        },
                        () => {
                            if (this.props.onChange !== undefined) {
                                this.props.onChange(this.props.defaultValue ? this.props.defaultValue : [], {});
                            }
                            this.setState(
                                {
                                    value: this.props.defaultValue ? this.props.defaultValue : []
                                },
                                () => {
                                    this._getSelectData();
                                }
                            );
                        }
                    );
                }
            }
        }
        //Wenn das Datum geändert wurde neue Daten laden
        if(this.props.startDate.toISOString() !== prevProps.startDate.toISOString() || this.props.endDate.toISOString() !== prevProps.endDate.toISOString()){
            this._getSelectData();
        }
    }

    componentWillUnmount() {}

    async _getSelectData() {
        this.setState({
            loading: true
        });
        try {
            let api = new Api.GeoControllerApi(getRestApiConfig('geo', this.props.userData));
            let query: string[] = [];
            if (this.state.land.length > 0) {
                if (!this.state.land.includes(i18n.t(this.state.alli18n))) {
                    query = this.state.land;
                }
            } else {
                return;
            }
            let requestBody = {
                laender: query,
                startDate: this.props.startDate,
                endDate: this.props.endDate
                
            }
            let resp = await api.findAllBundeslandForLaborstatistik(requestBody);
            if (resp !== undefined) {
                this.setState({
                    selectdata: resp !== undefined ? resp : [],
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    onChange = (value: string[], option: any) => {
        if (value.includes(i18n.t(this.state.alli18n)) && !this.state.value.includes(i18n.t(this.state.alli18n))) {
            value = [i18n.t(this.state.alli18n)];
            // Prüft ob alle vorausgewählt ist und nun eine spezifiesche Auswahl getroffen wurde und entfernt dann den Eintrag "Alle"
        } else if (
            this.state.value.length === 1 &&
            this.state.value.includes(i18n.t(this.state.alli18n)) &&
            value.includes(i18n.t(this.state.alli18n)) &&
            value.length > 1
        ) {
            value = value.filter((e) => e !== i18n.t(this.state.alli18n));
        }
        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };

    textRenderer(itm: Api.Bundesland) {
        if (itm.isoBundesland != null) {
            let text =
                i18nApp.getBundeslandString(itm.isoBundesland).indexOf('iso.bundesland') !== -1 ? itm.name : i18nApp.getBundeslandString(itm.isoBundesland);

            return text ? text : '';
        }
        return ""
    }
    addAll(items: any[]) {
        if (items.length > 0) {
            let all = {
                name: i18n.t(this.state.alli18n),
                isoBundesland: i18n.t(this.state.alli18n)
            };
            return [all, ...items];
        }
        return items;
    }

    render() {
        const { loading } = this.state;
        return (
            <Select
                mode="multiple"
                value={this.props.value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                style={{ width: '100%' }}
                disabled={this.props.disabled}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                    return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
            >
                {this.addAll(this.state.selectdata).map((itm, y) => {
                    return (
                        <Select.Option key={y} value={itm.isoBundesland}>
                            {i18nApp.getBundeslandString(itm.isoBundesland).indexOf('iso.bundesland') !== -1
                                ? itm.name
                                : i18nApp.getBundeslandString(itm.isoBundesland)}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}

export default withAuth(BundeslandSelectForm);
