import React from "react";
import { GlobalSearchField } from "./../../../../sano/globalsearch";
import { withAuth } from 'oidc-react';
import {
    isExtern
} from "./../../../../../misc/getUserRoles";


class MainSearch extends React.Component{

    render(){
        if(typeof(this.props.userData) !== "undefined" && this.props.userData !== null){
            if(isExtern(this.props.userData)){
                return null
            }else{
                return(
                    <div className="mainsearch-field-wrap">
                        <GlobalSearchField />
                    </div>
                )
            }
        }
        return null
    }

}
export default withAuth(MainSearch)