import React from 'react';
// import { Menu, Dropdown, Drawer, Button, Tooltip } from "antd";

import { User } from 'oidc-client';
import { Menu, Dropdown, Drawer, Button } from 'antd';
import i18n from './../../../../misc/i18n';
import About from './../../../../about';
import Help from './../../../../help';
import { GlobalSearchField } from '../../../sano/globalsearch';
import { AuthContextProps, withAuth } from "oidc-react";
import {
    isExtern
} from "./../../../../misc/getUserRoles";

const { SubMenu, Divider } = Menu;

interface IProps extends AuthContextProps {
    collapsed: boolean;
    onCollapse: Function;
    onLangChange: Function;
}

interface IState {
    about: boolean;
    help: boolean;
    lang: string;
    languages: Array<string>;
    loading: boolean;
}

class TopMenuLoggedIn extends React.Component<IProps, IState> {
    userData: User | null | undefined;
    constructor(props: IProps) {
        super(props);
        this.userData = props.userData;
        this.state = {
            about: false,
            help: false,
            languages: ['de', 'en','cz'],
            lang: i18n.language,
            loading: false
        };
    }

    changeLang(e: string) {
        this.setState(
            {
                lang: e
            },
            () => {
                i18n.changeLanguage(e);
                if (this.props.onLangChange) {
                    this.props.onLangChange();
                }
            }
        );
    }
    delete_cookie(name: string, path: string) {
        function get_cookie(name: string):boolean {
            return document.cookie.split(';').some((c) => {
                return c.trim().startsWith(name + '=');
            });
        }
        if (get_cookie(name)) {
            document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=' + path;
        }
    }

    render() {
        let username = null;
        if (this.userData != null) {
            username = this.userData.profile.preferred_username;
        }

        let userMenu = (

                        <Menu key="user">
                            <SubMenu
                                key="lang"
                                title={
                                    <span className="submenu-title-wrapper">
                                        <div className="demes-topmenu-icon">
                                            <i className="fal fa-language"></i>
                                        </div>
                                        {i18n.t('topmenu.language')}
                                    </span>
                                }
                            >
                                {this.state.languages.map((item, i) =>
                                    this.state.lang === item ? (
                                        <Menu.Item key={'lang' + item} disabled={true}>
                                            <div className="demes-topmenu-icon">
                                                <i className="fal fa-check-square"></i>
                                            </div>
                                            {i18n.t('topmenu.lang.' + item)}{' '}                                            
                                        </Menu.Item>
                                    ) : (
                                        <Menu.Item key={'lang' + item} onClick={() => this.changeLang(item)}>
                                            <div className="demes-topmenu-icon">
                                                <i className="fal fa-square"></i>
                                            </div>
                                            {i18n.t('topmenu.lang.' + item)}
                                        </Menu.Item>
                                    )
                                )}
                            </SubMenu>
                            <Divider />

                            <Menu.Item
                                key="logout"
                                onClick={() => {
                                    localStorage.setItem('plz', '');
                                    localStorage.setItem('betrieb', '');
                                    fetch(
                                        '/ReportServer/oidclogon.aspx?method=logout',
                                        {
                                            method: 'GET'
                                        }
                                    );
                                    this.props.signOutRedirect()
                                }}
                            >
                                <div className="demes-topmenu-icon">
                                    <i className="fal fa-sign-out-alt"></i>
                                </div>
                                {i18n.t('topmenu.logout')}
                            </Menu.Item>
                        </Menu>

        );
        return (
            <div className="topmanuloggedin">
                        {(typeof(this.props.userData) !== "undefined" && this.props.userData !== null)
                            ?
                                (isExtern(this.props.userData))
                                    ? null
                                    : <GlobalSearchField />
                            : null
                        }
                <div className="username">{this.userData?.profile.name != null ? this.userData.profile.name : username}</div>
                {/* <Dropdown overlay={infoMenu} placement="bottomRight">
                    <Button><i className="fas fa-info-circle"></i></Button>
                </Dropdown> */}

                <Dropdown overlay={userMenu} placement="bottomRight">
                    <Button>
                        <i className="fas fa-user"></i>
                    </Button>
                </Dropdown>

                <About
                    visible={this.state.about}
                    onOk={() => {
                        this.setState({
                            about: !this.state.about
                        });
                    }}
                />

                <Drawer
                    className="demes-help-drawer-wrap"
                    title={
                        <span>
                            <i className="fal fa-question-circle"></i>
                            {i18n.t('help.title')}
                        </span>
                    }
                    placement="right"
                    closable={true}
                    width={800}
                    visible={this.state.help}
                    onClose={() => {
                        this.setState({
                            help: !this.state.help
                        });
                    }}
                >
                    <Help {...this.props} />
                </Drawer>
            </div>
        );
    }
}
export default withAuth(TopMenuLoggedIn);
