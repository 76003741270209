import {Filter} from '../../../../definitions/FilterEnum';
import {Renderer} from '../../../../definitions/RendererEnum'

const location = {
    detailProp: 'id',
    defaultPageSize: 10,
    // pageSizeOptions: [15,30,45,60,75,90,100],
    columns:[{
        title: 'isoBundesland',
        dataIndex: 'isoBundesland',
        filter: Filter.BUNDESLAND,
        sort: true
    },{
        title: 'nameBezirk',
        dataIndex: 'nameBezirk',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'nameGemeinde',
        dataIndex: 'nameGemeinde',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'nameKreis',
        dataIndex: 'nameKreis',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'ortsteil',
        dataIndex: 'ortsteil',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'postleitzahl',
        dataIndex: 'postleitzahl',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'tsChange',
        dataIndex: 'tsChange',
        filter: Filter.DATERANGE,
        sort: true,
        renderer: Renderer.RENDERDATETIME,
    },{
        title: 'tsInsert',
        dataIndex: 'tsInsert',
        filter: Filter.DATERANGE,
        sort: true,
        renderer: Renderer.RENDERDATETIME,
/*
    },{
        title: 'elevation',
        dataIndex: 'elevation',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'latitude',
        dataIndex: 'latitude',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'longitude',
        dataIndex: 'longitude',
        filter: Filter.TEXT,
        sort: true
*/
    },],
    formitems: [{
		label: "bundeslandId",
		name: "bundeslandId",
		type: "isofederalstateid",
		disabled: false,
        required: true
    // },{
	// 	label: "bundeslandId",
	// 	name: "isoBundesland",
	// 	type: "isofederalstate",
	// 	disabled: false
    },{
        label: "elevation",
        name: 'elevation',
        type: 'number',
        disabled: false,
        required: true
    },{
        label: 'latitude',
        name: 'latitude',
        type: 'number',
        disabled: false,
        required: true
    },{
        label: 'longitude',
        name: 'longitude',
        type: 'number',
        disabled: false,
        required: true
    },{
        label: 'nameBezirk',
        name: 'nameBezirk',
        type: 'text',
        disabled: false,
        required: true
    },{
        label: 'nameGemeinde',
        name: 'nameGemeinde',
        type: 'text',
        disabled: false,
        required: true
    },{
        label: 'nameKreis',
        name: 'nameKreis',
        type: 'text',
        disabled: false,
        required: true
    },{
        label: 'ortsteil',
        name: 'ortsteil',
        type: 'text',
        disabled: false,
        required: false
    },{
        label: 'plz',
        name: 'postleitzahl',
        type: 'text',
        disabled: false,
        required: true
    }],

    renderTitle: (rec) => {
        let title = rec.nameBezirk !== null ? rec.nameGemeinde + ' / ' + rec.nameBezirk + ' (' + rec.isoBundesland + ')' : '';
        return title;
    },

// -------------------------

    listresp: {},
    detaildata: {}

}

export default location;