import React from "react";
import { Tree, Input } from 'antd';
import ajax from "./../misc/ajax";

const { Search } = Input;

class HelpTree extends React.Component {
    state = {
        expandedKeys: [],
        searchValue: '',
        autoExpandParent: true,
        listData: {}
    };

    componentDidMount(){
        this.fetchData()
    }

    onExpand = expandedKeys => {
        this.setState({
            expandedKeys,
            autoExpandParent: false,
        });
    };

    onChange = e => {
        const { value } = e.target;
        if(value !== ""){
            let d = []
            for(let i=0; i<this.state.listData.length; i++){
                if(this.state.listData[i].title.toLowerCase().includes(value.toLowerCase()) || (typeof this.state.listData[i].keywords !== "undefined" && this.state.listData[i].keywords.toLowerCase().includes(value.toLowerCase()))){
                    d.push(Object.assign(this.state.listData[i], {title: this.state.listData[i].title}))
                }
            }
            this.setState({
                treedata: d
            })
        }else{
            this.setState({
                treedata: this.state.data
            })
        }
    };

    doDataList(d, r){
        r.push(Object.assign(d, {icon: <i className="far fa-question-circle"></i>}))
        if ((d.children && d.children.length)) {
            d.children.map((item, i) => (
                this.doDataList(item, r)
            ))
        }
        return r;
    }

    async fetchData(keys){
        await ajax.request('/localapi/helptree.json')
            .then((response) => {
                this.setState({
                    data: response,
                    treedata: response
                }, () =>{
                    let d = [];
                    for(let i=0; i<this.state.data.length; i++){
                        d = this.doDataList(this.state.data[i], d)
                    }
                    this.setState({
                        listData: d
                    })
                });
            })
            .catch((error) => {
                // Your error is here!
            });
    }

    render() {
        // const { searchValue, expandedKeys, autoExpandParent } = this.state;
        return (
            <div>
                <Search 
                    defaultValue={this.props.helpkey} 
                    style={{ marginBottom: 8 }} 
                    placeholder="Search" 
                    onChange={this.onChange} 
                />
                <Tree
                    defaultExpandAll={true}
                    onExpand={this.onExpand}
                    //expandedKeys={expandedKeys}
                    //autoExpandParent={autoExpandParent}
                    defaultSelectedKeys={[this.props.helpkey]}
                    treeData={this.state.treedata}
                    onSelect={(selectedKeys) => {
                        if(this.props.onSelect){
                            this.props.onSelect(selectedKeys)
                        }
                    }}
                    // defaultExpandAll={true}
                />
                {/* <hr/>
                <pre>{JSON.stringify(this.state, null, 2)}</pre> */}
            </div>
        );
    }
}

export default HelpTree;