/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Bundesland,
    BundeslandFromJSON,
    BundeslandFromJSONTyped,
    BundeslandToJSON,
} from './Bundesland';

/**
 * 
 * @export
 * @interface Postleitzahl
 */
export interface Postleitzahl {
    /**
     * 
     * @type {string}
     * @memberof Postleitzahl
     */
    id?: string;
    /**
     * 
     * @type {Bundesland}
     * @memberof Postleitzahl
     */
    bundesland?: Bundesland;
    /**
     * 
     * @type {string}
     * @memberof Postleitzahl
     */
    postleitzahl?: string;
    /**
     * 
     * @type {string}
     * @memberof Postleitzahl
     */
    nameKreis?: string;
    /**
     * 
     * @type {string}
     * @memberof Postleitzahl
     */
    isoBundesland?: string;
    /**
     * 
     * @type {string}
     * @memberof Postleitzahl
     */
    nameBezirk?: string;
    /**
     * 
     * @type {string}
     * @memberof Postleitzahl
     */
    nameGemeinde?: string;
}

export function PostleitzahlFromJSON(json: any): Postleitzahl {
    return PostleitzahlFromJSONTyped(json, false);
}

export function PostleitzahlFromJSONTyped(json: any, ignoreDiscriminator: boolean): Postleitzahl {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bundesland': !exists(json, 'bundesland') ? undefined : BundeslandFromJSON(json['bundesland']),
        'postleitzahl': !exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'nameKreis': !exists(json, 'nameKreis') ? undefined : json['nameKreis'],
        'isoBundesland': !exists(json, 'isoBundesland') ? undefined : json['isoBundesland'],
        'nameBezirk': !exists(json, 'nameBezirk') ? undefined : json['nameBezirk'],
        'nameGemeinde': !exists(json, 'nameGemeinde') ? undefined : json['nameGemeinde'],
    };
}

export function PostleitzahlToJSON(value?: Postleitzahl | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bundesland': BundeslandToJSON(value.bundesland),
        'postleitzahl': value.postleitzahl,
        'nameKreis': value.nameKreis,
        'isoBundesland': value.isoBundesland,
        'nameBezirk': value.nameBezirk,
        'nameGemeinde': value.nameGemeinde,
    };
}

