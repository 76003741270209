import React from 'react';
import { Button } from 'antd';
import i18n from '../../../../misc/i18n';
import { AuthContextProps, withAuth } from 'oidc-react';


interface IProps extends AuthContextProps {
    collapsed: boolean;
    onCollapse: Function;
}

interface IState {
    loginVisible: boolean;
    silentLogin: boolean;
}

class TopMenuNotLoggedIn extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loginVisible: false,
            silentLogin: false
        };
    }
    async componentDidMount() {
        if (this.getSSOToken() !== '' && this.state.silentLogin === false) {
            await this.props.userManager?.signinSilent()
            this.setState({ silentLogin: true });
        }
    }

    getSSOToken() {
        var name = 'token=';
        var keycloakName = 'KEYCLOAK_SESSION';
        var decodedCookie = decodeURIComponent(document.cookie);
        var ca = decodedCookie.split(';');
        for (var i = 0; i < ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0) === ' ') {
                c = c.substring(1);
            }
            if (c.indexOf(name) === 0 || c.indexOf(keycloakName) === 0) {
                return c.substring(name.length, c.length);
            }
        }

        return '';
    }
    render() {
        return (
            <React.Fragment>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Button
                        type="primary"
                        onClick={() => {
                            // if(getCookie("accepts-cookies") != null){
                            this.props.signInPopup();
                            // }
                        }}
                    >
                        Login
                    </Button>
                    <div style={{ fontSize: '10px', paddingTop: '9px', fontWeight: 'bolder', color: 'rgba(248, 175, 0, 1)' }}>
                        {i18n.t('topmenu.newCustomer')} <a href="https://sano24.de/sdn">{i18n.t('topmenu.registLink')}</a>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
export default withAuth(TopMenuNotLoggedIn);
