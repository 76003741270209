import {Filter} from '../../../../definitions/FilterEnum'
const companies = {
    "detailProp": "betriebsId",
	"columns": [
        {
            "title": "betriebsId",
            "dataIndex": "betriebsId",
            "filter": Filter.NUMBER,
            "sort": true,
            "align": "right",
            "show_responsive": true
        },{
            "title": "ort",
            "dataIndex": "ort",
            "filter": Filter.TEXT,
            "sort": true,
            "show_responsive": true
        },{
            "title": "plz",
            "dataIndex": "plz",
            "filter": Filter.TEXT,
            "sort": true,
            "show_responsive": true
        },{
            "title":"strasse",
            "dataIndex":"strasseNr",
            "filter":Filter.TEXT,
            "sort":true,
            "show_responsive": true
        } 
    ],
	"renderTitle":(rec:any) => {
		return rec.dokumentName
	}
}

export default companies;