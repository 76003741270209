import React from 'react';
import { Typography } from 'antd';
import * as Api from '../../../../../../api/rest';
const { Paragraph } = Typography;
interface IProps {
    value: Api.Landwirt;
}

export default class PlaintextBetriebLandwirt extends React.Component<IProps> {

    createString(item: Api.Landwirt) {
        let str = '';
        str += item.vorname != null  ?item.vorname : '';
        str += item.nachname != null && item.nachname !== '' ? ' ' + item.nachname : '';
        return str;
    }

    render() {
        return <Paragraph className="deparagraph"> {this.props.value != null ? this.createString(this.props.value) : <>&nbsp; </>} </Paragraph>;
    }
}
