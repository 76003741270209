import React from 'react';
import { Form as AntdForm, Input, Select, DatePicker, Slider, Row, Col, Checkbox, Divider, Collapse } from 'antd';
import LookUp from './editors/lookup';
import { GeoLand, GeoLandRE, GeoBundesland, GeoPLZ, GeoPLZRE, GeoOrt, GeoOrtRE, GeoRegion, IsoCountry, IsoFederalstate, IsoFederalstateID } from './editors/geolocation';
import { FarmerSelect, StreetAutoinsert, DistrictAutoinsert, CustomInput } from './editors/farmerselect';
import CompanySelect from './editors/companyselect';
import SampleNumber from './editors/samplenumber';
import DEParagraph from "./editors/DEparagraph";
import PlaintextBundesland from "./editors/plaintextBundesland";
import PlaintextBetriebLandwirt from "./editors/plaintextBetriebLandwirt";
import PlaintextRegions from "./editors/plaintextRegions";
import i18n from './../../../misc/i18n';
import PlainTimeDisplay from './editors/PlainTimeDisplay';
import LabTimepickerLabor from "./editors/labbook/LapTimepickerLabor";
import LabTimepickerSano from "./editors/labbook/LabTimepickerSano";
import LabStatusDropdown from "./editors/labbook/LabStatusDropdown";
import LabStatusPlaintext from "./editors/labbook/LabStatusPlaintext";
import DisplayProbenart from "./editors/displayProbenart"
import Copytextplain from "./editors/copytextplain"
import DisplayProbenartPlain from './editors/displayProbenartPlain';
export default class Form extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            layout: this.props.layout
                ? this.props.layout
                : {
                      labelCol: { span: 5 },
                      wrapperCol: { span: 18 }
                  }
        };
        this.formRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        if (this.props.values !== null && this.props.values !== nextProps.values) {
            this.formRef.current.setFieldsValue(nextProps.values);
        }
        return this.props !== nextProps || this.state !== nextState ? true : false;
    }

    getFormItemType(item, i) {
        switch (item.type) {
            case 'companyselect':
                return <CompanySelect style={item.style} probetype={item.probetype} disabled={item.disabled} />;
            case 'custominput':
                return <CustomInput name={item.name} data={this.props.values} disabled={item.disabled ? true : false} />;
            case 'daterange':
                return (
                    <DatePicker.RangePicker
                        disabled={item.disabled ? [true, true] : [false, false]}
                        allowClear={false}
                        className="de-rangepicker-wrap"
                        format={item.format}
                        style={item.style}
                    />
                );
            case "simpledate":
                return(
                    <DatePicker
                        showTime
                        className="de-rangepicker-wrap"
                        onChange={this.onChange}
                        style={item.style}
                        disabled={item.disabled}
                    />
                    )
            case 'slider':
                return (
                    <Slider
                        disabled={item.disabled ? true : false}
                        min={item.min}
                        max={item.max}
                        step={item.step}
                        tooltipVisible={item.tooltipVisible}
                        tooltipPlacement="right"
                        style={item.style}
            
                    />
                );
            case 'samplenumber':
                return <SampleNumber mode={item.multiple ? 'multiple' : null} disabled={item.disabled ? true : false} data={item.data} style={item.style} />;
            case 'select':
                return (
                    <Select mode={item.multiple ? 'multiple' : null} disabled={item.disabled ? true : false} style={item.style}>
                        {item.data.map((itm, y) => {
                            return (
                                <Select.Option key={y} value={itm.key}>
                                    {itm.value}
                                </Select.Option>
                            );
                        })}
                    </Select>
                );
            case 'lookup':
                return <LookUp disabled={item.disabled} />;
            case "plaintext":
                return <DEParagraph disabled={item.disabled} />
            case "plaintextWithDefault":
                return <DEParagraph item={item} disabled={item.disabled} />
            case "plaintextBundesland":
                return <PlaintextBundesland disabled={item.disabled} />
            case "plaintextBetriebLandwirt":
                return <PlaintextBetriebLandwirt disabled={item.disabled} />
            case "plaintextRegions":
                return <PlaintextRegions disabled={item.disabled} />
            case "plaintimedisplay":
                return <PlainTimeDisplay disabled={item.disabled} />
            case "labtimedisplaysano":
                return <LabTimepickerSano data={this.props.values} disabled={item.disabled} />
            case "labtimedisplaylabor":
                return <LabTimepickerLabor data={this.props.values} disabled={item.disabled} />
            case "labstatusdropdown":
                return <LabStatusDropdown data={this.props.values} item={item} disabled={item.disabled} />
            case "labstatusplaintext":
                return <LabStatusPlaintext data={this.props.values} disabled={item.disabled} />
            case 'divider':
                return <Divider />;
            case 'boolean':
                return <Checkbox disabled={item.disabled}/>;
            case 'textarea':
                return <Input.TextArea disabled={item.disabled} />;
            case 'geoland':
                return <GeoLand data={this.props.values} disabled={item.disabled} />;
            case 'geolandre':
                return <GeoLandRE data={this.props.values} disabled={item.disabled} />;

            case 'geobundesland':
                return <GeoBundesland data={this.props.values} disabled={item.disabled} />;
            case 'geoplz':
                return <GeoPLZ data={this.props.values} disabled={item.disabled} />;
            case 'geoplzre':
                return <GeoPLZRE data={this.props.values} disabled={item.disabled} />;
            case 'geoort':
                return <GeoOrt data={this.props.values} disabled={item.disabled} />;
            case 'geoortre':
                return <GeoOrtRE data={this.props.values} disabled={item.disabled} />;
            case 'georegion':
                return <GeoRegion data={this.props.values} disabled={item.disabled} />;
            case 'isofederalstate':
                return <IsoFederalstate data={this.props.values} disabled={item.disabled} />;
            case 'isofederalstateid':
                return <IsoFederalstateID data={this.props.values} disabled={item.disabled} />;
            case 'isocountry':
                return <IsoCountry data={this.props.values} disabled={item.disabled} />;
            case 'farmerselect':
                return <FarmerSelect data={this.props.values} disabled={item.disabled} />;
            case 'streetautoinsert':
                return <StreetAutoinsert data={this.props.values} disabled={item.disabled} />;
            case 'districtautoinsert':
                return <DistrictAutoinsert data={this.props.values} disabled={item.disabled} />;
            case 'displayprobenart':
                return <DisplayProbenart data={this.props.values} disabled={item.disabled} />;
            case 'displayprobenartplain':
                return <DisplayProbenartPlain data={this.props.values} disabled={item.disabled} />;
            case "email":
                return <Input type="email" disabled={item.disabled} />
            case "betriebsId":
                return <Input disabled={true}/>;
            case "copytextplain":
                return <Copytextplain/>
            default:
                return <Input disabled={item.disabled}/>;
        }
    }

    getFormItem(item, i) {
        // if(typeof item.required !== "undefined" && item.required){
        //     item.rules = {{
        //         required: true
        //     }}
        // }

        let style = {};
        if(typeof item.width !== "undefined"){

            style = {
                width: item.width
            }
        } 
        
        item.disabled=this.props.disableAll;

        if (this.props.horizontal) {
            return (
                <Col span={8} key={i}>
                    <AntdForm.Item
                        
                        key={i}
                        // label={i18n.t('form.label.'+item.label)}
                        label={
                            typeof item.required !== 'undefined' && item.required
                                ? i18n.t('form.label.' + item.label) + '*'
                                : i18n.t('form.label.' + item.label)
                        }
                        name={item.name}
                        rules={item.rules}
                        valuePropName={item.type === 'boolean' ? 'checked' : 'value'}
                        style={style}
                    >
                        {this.getFormItemType(item, i)}
                    </AntdForm.Item>
                </Col>
            );
        } else {
            return (
                <AntdForm.Item
                    // disabled={true}
                    key={i}
                    labelCol={item.labelTop != null && item.labelTop ? {span:24} : undefined}
                    // label={i18n.t('form.label.'+item.label)}
                    label={
                        typeof item.required !== 'undefined' && item.required ? i18n.t('form.label.' + item.label) + '*' : i18n.t('form.label.' + item.label)
                    } // label={i18n.t(item.label)}
                    name={item.name}
                    rules={item.rules}
                    valuePropName={item.type === 'boolean' ? 'checked' : 'value'}
                    style={style}
                >
                    {this.getFormItemType(item, i)}
                </AntdForm.Item>
            );
        }
    }
    generateKey (pre) {
        return `${ pre }_${ new Date().getTime() }`;
    }
    
    _doReportFormItems(){
        if(this.props.isReportForm){
            let firstRowItems = [];
            for(let i=0; i<this.props.itemsInFirstRow; i++){
                firstRowItems.push(<div key={i} className="report-formitem-horizontal">{this.getFormItem(this.props.formItems[i], i)}</div>)
            }
            let items = [
                <Row key={0} className="report-form-row">
                    <Col span={24}>
                        {firstRowItems}
                    </Col>
                </Row>
            ];
            let iitems = [];
            for(let i=this.props.itemsInFirstRow; i<this.props.formItems.length; i++){
                let item = this.props.formItems[i];
                iitems.push(<div key={i} className="report-formitem-horizontal">{this.getFormItem(item, i)}</div>)
            }
            items.push(<Row className="report-form-row" key={1}><Col span={24}>{iitems}</Col></Row>)
            return items;
        }else{
            return null
        }
    }

    getForm() {

        if(this.props.isReportForm){
            return (
                <AntdForm
                    
                    ref={this.formRef}
                    {...this.state.layout}
                    name={new Date().getTime()}
                    size={(this.props.size) ? this.props.size : "middle"}
                    initialValues={this.props.values}
                    validateMessages={this.props.validateMessages}
                    onFieldsChange={(changedFields, allFields) => {
                        if (this.props.onFieldsChange) {
                            this.props.onFieldsChange(changedFields, allFields)
                        }
                    }}
                >
                    {this._doReportFormItems()}
                </AntdForm>
            )
        }else{

            return (
                <AntdForm
                    
                    ref={this.formRef}
                    {...this.state.layout}
                    name={new Date().getTime()}
                    size={(this.props.size) ? this.props.size : "middle"}
                    initialValues={this.props.values}
                    validateMessages={this.props.validateMessages}
                    onFieldsChange={(changedFields, allFields) => {
                        if (this.props.onFieldsChange) {
                            this.props.onFieldsChange(changedFields, allFields);
                        }
                    }}
                >
                    {this.props.horizontal ? (
                        <Row gutter={24}>
                            {this.props.formItems.map((item, i) => {
                                if (item.type === 'collapse') {
                                    return (
                                        <Collapse ghost key={i}>
                                            {item.groups.map((group, x) => {
                                                return (
                                                    <Collapse.Panel header={i18n.t('form.label.' + group.label)} key={i + '_' + x}>
                                                        {group.items.map((groupItem, y) => {
                                                            return this.getFormItem(groupItem, i * x + y);
                                                        })}
                                                    </Collapse.Panel>
                                                );
                                            })}
                                        </Collapse>
                                    );
                                } else {
                                    if (item.type === 'divider') {
                                        return (
                                            <Divider key={i } orientation="left">
                                                {i18n.t('form.label.' + item.label)}
                                            </Divider>
                                        );
                                    } else {
                                        return this.getFormItem(item, i );
                                    }
                                }
                            })}
                        </Row>
                    ) : (
                        this.props.formItems.map((item, i) => {
                            if (item.type === 'collapse') {
                                return (
                                    <Collapse ghost defaultActiveKey={item.defaultActiveKey} key={i}>
                                        {item.groups.map((group, x) => {
                                            return (
                                                <Collapse.Panel header={i18n.t('form.label.' + group.label)} key={item.label+"_"+x}>
                                                    {group.items.map((groupItem, y) => {
                                                        if (groupItem.type === 'divider') {
                                                            return (
                                                                <Divider key={i * x + y} orientation="left">
                                                                    {i18n.t('form.label.' + groupItem.label)}
                                                                </Divider>
                                                            );
                                                        } else {
                                                            return this.getFormItem(groupItem, i * x + y);
                                                        }
                                                    })}
                                                </Collapse.Panel>
                                            );
                                        })}
                                    </Collapse>
                                );
                            } else {
                                if (item.type === 'divider') {
                                    return (
                                        <Divider key={i } orientation="left">
                                            {i18n.t('form.label.' + item.label)}
                                        </Divider>
                                    );
                                } else {
                                    return this.getFormItem(item, i );
                                }
                            }
                        })
                    )}
                </AntdForm>
            );
        }

    }

    render() {
        return this.getForm();
    }
}
