/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Bundesland
 */
export interface Bundesland {
    /**
     * 
     * @type {string}
     * @memberof Bundesland
     */
    name?: string;
    /**
     * 
     * @type {string}
     * @memberof Bundesland
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof Bundesland
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof Bundesland
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof Bundesland
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof Bundesland
     */
    iso?: string;
    /**
     * 
     * @type {number}
     * @memberof Bundesland
     */
    bundeslandId?: number;
    /**
     * 
     * @type {string}
     * @memberof Bundesland
     */
    isoBundesland?: string;
}

export function BundeslandFromJSON(json: any): Bundesland {
    return BundeslandFromJSONTyped(json, false);
}

export function BundeslandFromJSONTyped(json: any, ignoreDiscriminator: boolean): Bundesland {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'iso': !exists(json, 'iso') ? undefined : json['iso'],
        'bundeslandId': !exists(json, 'bundeslandId') ? undefined : json['bundeslandId'],
        'isoBundesland': !exists(json, 'isoBundesland') ? undefined : json['isoBundesland'],
    };
}

export function BundeslandToJSON(value?: Bundesland | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'uiInsert': value.uiInsert,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'iso': value.iso,
        'bundeslandId': value.bundeslandId,
        'isoBundesland': value.isoBundesland,
    };
}

