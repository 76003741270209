import React from 'react';
import { Select} from 'antd';
import i18n from '../../../misc/i18n';
type IProps = {
    onChange: ((value: any, option: any) => void);
    notFoundContent?: React.ReactNode;
    onSearch?: ((value: string) => void);
    selectdata: any[];
    textrender: (selectdataItem: any) => string;
    keyAttributeName: string,
    valueAttributeName: string,
    style?: React.CSSProperties,
    value?: any[],
    disabled?: boolean,
    //Displays a Entry to select all values
    selectAll?: boolean,
    selectAlli18n?: string,
    showSearch?: boolean,
    allowClear?: boolean
};

type IState = {
    saved: Array<any>;
    value: Array<any>;
    language: string;
};

export default class TagSelect extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            saved: [],
            value: props.value != null ? props.value : [],
            language: ""
        };

    }

    componentDidUpdate(){
        //Wird die Sprache geändert wird der entsprechende Wert in dem Value ebenfalls neu übersetzt und an die Form übergeben.
        if(this.props.selectAll && this.props.selectAlli18n != null){
            if(this.props.value?.includes(i18n.t(this.props.selectAlli18n)) && this.state.saved.length > 0){
                this.setState({
                    saved:[]
                })
            }
            if(i18n.language !== this.state.language){
                let values = this.state.value
                for(let i = 0; i<values.length;i++){
                    //Alte Übersetzung mit neuer austauschen
                    if(values[i] === i18n.t(this.props.selectAlli18n,{lng:this.state.language})){
                        values[i]= i18n.t(this.props.selectAlli18n)
                    }
                }
                this.setState({
                    language: i18n.language,
                    value :values
                },()=>{
                       this.props.onChange(values,[])   
                })
            }
        }
        

    }

    onSelectChange = (value: any[], option: any) => {
        if(this.props.selectAll && this.props.selectAlli18n != null){
            if(value.includes(i18n.t(this.props.selectAlli18n)) && !this.state.value.includes(i18n.t(this.props.selectAlli18n))){
                value = [i18n.t(this.props.selectAlli18n)]
                option = []
            }else if(this.state.value.length === 1 && this.state.value.includes(i18n.t(this.props.selectAlli18n)) && value.includes(i18n.t(this.props.selectAlli18n)) && value.length > 1){
                    let str = i18n.t(this.props.selectAlli18n)
                    value = value.filter(e => e !== str);
            }
        }
        

        this.setState({
            value: value
        });
        let ret = []
        for (let x = 0; x < option.length; x++) {
            if (option[x].obj != null && option[x].obj[this.props.keyAttributeName] != null) {
                ret.push(option[x].obj);
                let arr = this.state.saved;
                let exists = arr.find((item) => item[this.props.keyAttributeName] === option[x].obj[this.props.keyAttributeName]);
                if (exists === undefined) {
                    this.setState({
                        saved: [...this.state.saved, option[x].obj]
                    });
                }
            } else {
                let found = this.state.saved.find((item) => item[this.props.valueAttributeName] === value[x]);
                if (found != null) {
                    ret.push(found);
                }
            }
        }

        this.props.onChange(value,option)
    };

    render() {
        let toShow: any[]=[]
        if(this.props.selectAll &&  this.props.selectdata.length > 0){
            let all : any = {}
            all[this.props.keyAttributeName] = this.props.selectAlli18n ? i18n.t(this.props.selectAlli18n) : "selectAlli18n not set"
            all[this.props.valueAttributeName] = this.props.selectAlli18n ? i18n.t(this.props.selectAlli18n) : "selectAlli18n not set"
            toShow =  [all,...this.props.selectdata]
        }else{
            toShow =  [...this.props.selectdata]
        }
        this.state.saved.forEach((el)=>{
            let found = toShow.find((item)=> item[this.props.valueAttributeName] === el[this.props.valueAttributeName])
            if(found === undefined){
                toShow.push(el)
            }
        })
        return (
            <Select
                disabled={this.props.disabled}
                mode="multiple"
                value={this.props.value}
                notFoundContent={this.props.notFoundContent}
                showSearch={this.props.showSearch}
                onChange={this.onSelectChange}
                filterOption={false}
                onSearch={this.props.onSearch}
                style={this.props.style}
                allowClear={this.props.allowClear}
            >
                {toShow.map((itm) => {
                    return (
                        <Select.Option key={itm[this.props.keyAttributeName]} value={itm[this.props.valueAttributeName]} obj={itm}>
                            {this.props.textrender(itm)}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}
