import React from "react";
import { Select } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";

class IsoFederalstateID extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: "",
            loading: false,
            selectdata: []
        };
    }

    componentDidMount() {
        this._getSelectData();
    }

    async _getSelectData(str) {
        this.setState({
            loading: true
        });
        let sstr = typeof str === "undefined" ? "name==*" : "name==" + str + "*,isoBundesland==" + str + "*";

        try {
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let requestBody = {
                size: 300,
                search: sstr
            };
            let resp = await api.findAllBundesland(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }

    async _handleSearch(str) {
        this._getSelectData(str);
    }

    render() {
        return (
            <Select
                showSearch
                onSearch={(v) => {
                    this._handleSearch(v);
                }}
                onChange={(v) => {
                    this.setState(
                        {
                            value: v
                        },
                        () => {
                            if (this.props.onChange) {
                                this.props.onChange(parseInt(this.state.value));
                            }
                        }
                    );
                }}
                filterOption={false}
                value={this.props.value}
                defaultValue={this.props.value}
            >
                {this.state.selectdata.map((item, i) => {
                    return (
                        <Select.Option key={item.bundeslandId} value={item.bundeslandId}>
                            {item.isoBundesland} ({item.name})
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}

export default withAuth(IsoFederalstateID);
