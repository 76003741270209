import React from 'react';
import { Typography } from 'antd';
const {Paragraph} = Typography
interface IProps{
    value: string
    item: any,
    onChange: Function 
}

export default class DEParagraph extends  React.Component<IProps>{

    componentDidUpdate(){
        if(this.props.item != null && this.props.item.default != null && this.props.item.default !== this.props.value){
            this.props.onChange(this.props.item.default)
        }
        
    }

    render(){
        return (
            <Paragraph className="deparagraph"> {this.props.value != null ? this.props.value : this.props.item != null && this.props.item.default != null? this.props.item.default : <>&nbsp; </> } </Paragraph>
        )
            
    }

}
