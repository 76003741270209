export enum Filter {
    LIST = 'list',
    TEXT = 'text',
    DATERANGE = 'daterange',
    NUMBER = 'number',
    BUNDESLAND = 'bundesland',
    PROBENART = 'probenart',
    BOOLEAN = 'boolean',
    COUNTRY = 'country'
}
