import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

// import "@fortawesome/fontawesome-free/css/all.css"
import "@3rdparty/fontawesome-pro/css/all.css";
import 'antd/dist/antd.css';

import 'antd/dist/antd.less'; // Import Ant Design styles by less entry
// import 'your-theme-file.less'; // variables to override above

ReactDOM.render(<Suspense fallback="loading"><App /></Suspense>, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
