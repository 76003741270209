import React from 'react';
import {  Select } from 'antd';

export default class SampleNumber extends  React.Component{

    onChange(v){
        let val = v;
        let allIdx = val.indexOf('all') 
        if(allIdx === val.length-1){
             val = ["all"]
        }else if(allIdx === 0){
            val.splice(allIdx, 1);
        }
        if(val.length === 0){
            val = ["all"]
        }

        this.setState({ val }, () => {
            if(this.props.onChange){
                this.props.onChange(val)
            }
        });
    }

    render(){

        return (
            <>
            <Select 
                className="samplenumberselect"
                mode={this.props.mode} 
                disabled={(this.props.disabled) ? true : false}
                value={this.props.value}
                showSearch={false}
                autoClearSearchValue={true}
                filterOption={false}
                style={this.props.style}
                onChange={(v) => {
                    this.onChange(v)
                }}
            >
                    {this.props.data.map((itm, y) => {
                        return(
                            <Select.Option 
                                key={y} 
                                value={itm.key}
                            >
                                {itm.value}
                            </Select.Option>
                        )
                    })}
            </Select>
            </>
        )
    }

}
