import React from 'react';
import { withAuth, AuthContextProps } from 'oidc-react';
import ListeIT from '../listen/ListeIT/ListeIT';


interface IProps extends AuthContextProps {}

class ImportFailed extends React.Component<IProps> {
    render() {
        return <ListeIT />;
    }
}

export default withAuth(ImportFailed);
