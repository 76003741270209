const anonymise = {
    formitems: [
        {
            label: 'uiChange',
            name: 'uiChange',
            type: 'plaintext',
            disabled: false
        },
        {
            label: 'tsChange',
            name: 'tsChange',
            type: 'plaintimedisplay',
            disabled: false
        },
    ],
    renderTitle: (rec) => {
        let title = rec.vorname !== null ? rec.vorname + ' ' : '';
        title += rec.nachname;
        return title;
    }
};

export default anonymise;
