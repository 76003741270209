import React from "react";

import { getImportApiConfig } from "../../../../../_conf";
import * as Api from "../../../../../api/importstatus";
import userNotification from "../../../../misc/notification";
import { Row, Col, Button, Popconfirm } from "antd";
import i18n from "../../../../misc/i18n";
import { AuthContextProps, withAuth } from "oidc-react";

interface IProps extends AuthContextProps {
    onDownload: Function;
    dataSource: Array<any>;
    order: any;
    onUpdateAll: () => void;
    onUpdateCvas: () => void;
}

interface IState {
    downloadLoading: boolean;
}

class DeleteOrder extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            downloadLoading: false
        };
    }
    _getApi() {
        return new Api.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));
    }

    async onDeleteAll() {
        try {
            let requestBody = {
                probenId: this.props.order.bestellId
            };
            await this._getApi().deleteProbe(requestBody);
            userNotification("success", "notification.order.delete.all.success");
            this.props.onUpdateAll();
        } catch (e) {
            userNotification("error", "notification.order.delete.all.error");
        }
    }
    async onDeleteData() {
        try {
            let requestBody = {
                probenNummer: this.props.order.probenNummer
            };
            await this._getApi().deleteCVAS(requestBody);
            this.props.onUpdateCvas();
            userNotification("success", "notification.order.delete.cvas.success");
        } catch (e) {
            userNotification("error", "notification.order.delete.cvas.error");
        }
    }

    onConfirmAll(e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) {
        this.onDeleteAll();
    }
    onConfirmData(e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) {
        this.onDeleteData();
    }

    render() {
        return (
            <>
                <Row style={{ width: "100%", paddingBottom: "8px" }}>
                    <Col span={3}>
                        <Popconfirm
                            title={i18n.t("order.delete.popup.order")}
                            onConfirm={(e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => this.onConfirmAll(e)}
                            onCancel={() => {}}
                            okText={i18n.t("btn.yes")}
                            cancelText={i18n.t("btn.no")}
                        >
                            <Button type="default" shape="round">
                                <i className="far fa-trash-alt"></i>
                            </Button>
                        </Popconfirm>
                    </Col>
                    <Col span={13} style={{ float: "left", transform: "translate(0%, 12.5%)" }}>
                        {this.props.order.status === "ERGEBNIS" && <div>{i18n.t("order.delete.orderAndDocuments")}</div>}
                        {this.props.order.status !== "ERGEBNIS" && <div>{i18n.t("order.delete.order")}</div>}
                    </Col>
                </Row>
                {this.props.order.status === "ERGEBNIS" && (
                    <Row>
                        <Col span={3}>
                            <Popconfirm
                                title={i18n.t("order.delete.popup.documents")}
                                onConfirm={(e?: React.MouseEvent<HTMLElement, MouseEvent> | undefined) => this.onConfirmData(e)}
                                onCancel={() => {}}
                                okText={i18n.t("btn.yes")}
                                cancelText={i18n.t("btn.no")}
                            >
                                <Button type="default" shape="round">
                                    <i className="far fa-trash-alt"></i>
                                </Button>
                            </Popconfirm>
                        </Col>
                        <Col span={13} style={{ float: "left", transform: "translate(0%, 12.5%)" }}>
                            {i18n.t("order.delete.documents")}
                        </Col>
                    </Row>
                )}
            </>
        );
    }
}

export default withAuth(DeleteOrder);
