import i18n from "../../../misc/i18n"
const report = {
    formItems: [
        {
            name: 'land',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'Einheit1',
            data: [{key:"Durchschnittsalter",value:"Durchschnittsalter"},{key:"Eiweiss-%",value:"Eiweiss-%"},{key:"Eiweiss-KG",value:"Eiweiss-KG"},{key:"Fett-%",value:"Fett-%"},{key:"Fett+Eiweiss-KG",value:"Fett+Eiweiss-KG"},{key:"Fett-KG",value:"Fett-KG"},{key:"Kalbungen (A+B)-Kühe",value:"Kalbungen (A+B)-Kühe"},{key:"Kuhzahl",value:"Kuhzahl"},{key:"Milch-KG",value:"Milch-KG"},{key:"Zwischenkalbezeit",value:"Zwischenkalbezeit"}],            
            default: "Milch-KG",
            multiple: false
        },
        {
            name: 'Einheit2',
            data: [{key:"Durchschnittsalter",value:"Durchschnittsalter"},{key:"Eiweiss-%",value:"Eiweiss-%"},{key:"Eiweiss-KG",value:"Eiweiss-KG"},{key:"Fett-%",value:"Fett-%"},{key:"Fett+Eiweiss-KG",value:"Fett+Eiweiss-KG"},{key:"Fett-KG",value:"Fett-KG"},{key:"Kalbungen (A+B)-Kühe",value:"Kalbungen (A+B)-Kühe"},{key:"Kuhzahl",value:"Kuhzahl"},{key:"Milch-KG",value:"Milch-KG"},{key:"Zwischenkalbezeit",value:"Zwischenkalbezeit"}],            
            default: "Fett+Eiweiss-KG",
            multiple: false
        }
    ]
};

export default report;
