import React from "react";

export default class LookUp extends React.Component{

    render(){
        return(
            <div>LookUp</div>
        )
    }
     
}