import React from "react";
import MarkdownIt from "markdown-it";
import ajax from "./../misc/ajax";

class HelpContent extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            selectedKeys: [],
            mddata: "",
            media: [],
            currentMedia: ""
        };
        this.lRef = React.createRef();
    }

    componentDidMount(){
        this.fetchData();
    }

    shouldComponentUpdate(nextProps, nextState){
        return (nextProps !== this.props || nextState !== this.state) ? true : false;
    }

    fetchData(keys){
        ajax.request('/help/readme.md?ids='+this.props.selected.toString())
            .then((response) => {
                this.setState({
                    mddata: response,
                });
            })
            .catch((error) => {
                // Your error is here!
            });

        ajax.request('/help/media.json?ids='+this.props.selected.toString())
            .then((response) => {
                this.setState({
                    media: response.media,
                    currentMedia: (response.media.length > 0) ? response.media[0] : ""
                });
            })
            .catch((error) => {
                // Your error is here!
            });
    }

    getPlayer(){
        if(this.state.currentMedia !== ""){
            switch(this.state.currentMedia.type){
                case "mp4":
                    return(
                        <video key={this.state.currentMedia} controls style={{ width: '100%', height: '400px'}}>
                            <source src={this.state.currentMedia.uri} type="video/mp4"/>
                            Your browser does not support the video tag.
                        </video>
                    )
                case "mp3":
                    return(
                        <audio key={this.state.currentMedia} controls>
                            <source src={this.state.currentMedia.uri} type="audio/mp3"/>
                            Your browser does not support the video tag.
                        </audio>
                    )
                case "img":
                    return(
                        <div key={this.state.currentMedia} controls style={{ width: '100%', height: '400px'}}>
                            <img src={this.state.currentMedia.uri} alt={this.state.currentMedia.name}/>
                        </div>
                    )
                default:
                    return(
                        <div key={this.state.currentMedia} controls style={{ width: '100%', height: '400px'}}>
                        </div>
                    )
            }
        }else{
            return null
        }
    }

    getTypeIcon(type){
        switch(type){
            case "img":
                return <i className="fas fa-image"></i>
            case "mp3":
                return <i className="fas fa-play-circle"></i>
            case "mp4":
                return <i className="fas fa-video"></i>
            default:
                return null
        }
    }

    render(){
        if(this.props.selected.length > 0 && this.state.data !== ""){
            let md = new MarkdownIt();            
            return (
                <React.Fragment>
                    <div className="demes-help-player-wrap">
                        {this.getPlayer()}
                    </div>
                    <hr/>
                    <ul className="demes-help-media-list">
                        {this.state.media.map((item, i) =>(
                            <li key={i}  >
                                {this.getTypeIcon(item.type)} <span onClick={() => (
                                    this.setState({
                                        currentMedia: item
                                    })
                                )} className={(this.state.currentMedia === item) ? "demes-help-video-link demes-help-current-video" : "demes-help-video-link"} >{item.name} {item.duration}</span>
                            </li>
                            ))
                        }
                    </ul>
                    <div style={{textAlign: 'left'}} className="demes-help-md-wrap" dangerouslySetInnerHTML={{__html: md.render(this.state.mddata)}} />
                </React.Fragment>
            )
        }else{
            return null
        }
    }

}
export default HelpContent;