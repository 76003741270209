import {  Input } from 'antd';
import React from "react";

export default class customInput extends React.Component{
    constructor(props){
        super(props)
        if(props.name == null || props.name === ""){
            throw Error("name muss definiert sein und darf nicht leer sein")
        }
        this.state = {
            name : props.name,
            value: props.data[props.name] != null ? props.data[props.name] : "",
            prevValue: null
        }
        this.updateByLandwirt = this.updateByLandwirt.bind(this)
    }

    updateByLandwirt(e) {
        this.setState(
            {
                value: e.detail[this.props.name]
            }, () => {
                this.props.onChange(e.detail[this.props.name])
            }
        )
    }
    componentDidMount() {
        window.addEventListener("landwirtSelected",this.updateByLandwirt,true)
    }

    componentWillUnmount(){
        window.removeEventListener("landwirtSelected",this.updateByLandwirt,true)
    }


    componentDidUpdate() {
        if (this.props.data !== {} && this.props.data[this.state.name] != null && this.state.value !== this.state.prevValue) {
            this.setState({
                value: this.props.data[this.state.name],
                prevValue: this.state.value
            },() => {
                this.props.onChange(this.props.data[this.state.name])
            })
        }
    }
    onChange = (e) => {
        let val = e.target.value;
        this.setState({
            value : val,
        }, () => {
            this.props.onChange(val)
        });
    };

    render(){
        return (
            <Input
            value={this.state.value || ''}
            onChange={this.onChange}
            disabled = {this.props.disabled}
        />
        );
        
    }
}