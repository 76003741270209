/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    SdnImportJobError,
    SdnImportJobErrorFromJSON,
    SdnImportJobErrorFromJSONTyped,
    SdnImportJobErrorToJSON,
} from './SdnImportJobError';

/**
 * 
 * @export
 * @interface SdnImportJob
 */
export interface SdnImportJob {
    /**
     * 
     * @type {number}
     * @memberof SdnImportJob
     */
    importJobId?: number;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    systemName?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    euNummer?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnImportJob
     */
    tsGestartet?: Date;
    /**
     * 
     * @type {Date}
     * @memberof SdnImportJob
     */
    tsBeendet?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    status?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    statusText?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    externerLink?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    parameter?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnImportJob
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnImportJob
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJob
     */
    uiChange?: string;
    /**
     * 
     * @type {Array<SdnImportJobError>}
     * @memberof SdnImportJob
     */
    sdnImportJobErrors?: Array<SdnImportJobError>;
}

export function SdnImportJobFromJSON(json: any): SdnImportJob {
    return SdnImportJobFromJSONTyped(json, false);
}

export function SdnImportJobFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdnImportJob {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importJobId': !exists(json, 'importJobId') ? undefined : json['importJobId'],
        'systemName': !exists(json, 'systemName') ? undefined : json['systemName'],
        'euNummer': !exists(json, 'euNummer') ? undefined : json['euNummer'],
        'tsGestartet': !exists(json, 'tsGestartet') ? undefined : (new Date(json['tsGestartet'])),
        'tsBeendet': !exists(json, 'tsBeendet') ? undefined : (new Date(json['tsBeendet'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
        'statusText': !exists(json, 'statusText') ? undefined : json['statusText'],
        'externerLink': !exists(json, 'externerLink') ? undefined : json['externerLink'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'sdnImportJobErrors': !exists(json, 'sdnImportJobErrors') ? undefined : ((json['sdnImportJobErrors'] as Array<any>).map(SdnImportJobErrorFromJSON)),
    };
}

export function SdnImportJobToJSON(value?: SdnImportJob | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importJobId': value.importJobId,
        'systemName': value.systemName,
        'euNummer': value.euNummer,
        'tsGestartet': value.tsGestartet === undefined ? undefined : (value.tsGestartet.toISOString()),
        'tsBeendet': value.tsBeendet === undefined ? undefined : (value.tsBeendet.toISOString()),
        'status': value.status,
        'statusText': value.statusText,
        'externerLink': value.externerLink,
        'description': value.description,
        'parameter': value.parameter,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiInsert': value.uiInsert,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'uiChange': value.uiChange,
        'sdnImportJobErrors': value.sdnImportJobErrors === undefined ? undefined : ((value.sdnImportJobErrors as Array<any>).map(SdnImportJobErrorToJSON)),
    };
}

