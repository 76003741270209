import React from "react";
import { NavLink } from 'react-router-dom';
import { Button, Tabs, Space } from "antd";
import i18n from "./../../../../../misc/i18n";

import { withAuth } from "oidc-react";

const { TabPane } = Tabs;

/*
    TODO: Rework depth
*/

class MainMenu extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            activeTab: 0,
            activeParent: null,
            activeRoot: "root",
            activeMenu: []
        }
    }

    componentDidMount(){
        this.setState({
            activeMenu: (this.props.tabs.length > 0) ? this.props.tabs[this.state.activeTab].items : {}
        })
    }

    _setMenu(){
        if(this.state.activeRoot !== "root"){
            for(let i=0; i<this.props.tabs[this.state.activeTab].items.length; i++){
                if(this.props.tabs[this.state.activeTab].items[i].id === this.state.activeRoot){
                    this.setState({
                        activeMenu: this.props.tabs[this.state.activeTab].items[i].children
                    })
                }
            }
        }else{
            this.setState({
                activeMenu: this.props.tabs[this.state.activeTab].items
            })  
        }
    }


    _getLink(item){
        if(item.id === document.location.pathname){
            return(
                <NavLink to={item.id} key={item.id} className="tablet-nav-item" onClick={() => {
                    if(this.props.onClose){
                        this.props.onClose()
                    }
                }}>
                    <div className="tablet-nav-icon-cell">
                        {(typeof item.icon !== "undefined") ? <i className={item.icon}></i> : null}
                    </div>
                    <div className="tablet-nav-title-cell">
                        {i18n.t("navitem."+item.title)}
                    </div>
                    <div className="tablet-nav-expand-cell">
                    </div>
                </NavLink>            
            )
        }else{
            return(
                <NavLink to={item.id} key={item.id} className="tablet-nav-item">
                    <div className="tablet-nav-icon-cell">
                        {(typeof item.icon !== "undefined") ? <i className={item.icon}></i> : null}
                    </div>
                    <div className="tablet-nav-title-cell">
                        {i18n.t("navitem."+item.title)}
                    </div>
                    <div className="tablet-nav-expand-cell">
                    </div>
                </NavLink>            
            )
        }
    }

    _renderMenu(){
        return(
            // <div className="tablet-header-nav-wrap">
            <div>
                {
                    (this.state.activeRoot !== "root")
                    ? <div className="tablet-nav-back-wrap" onClick={() => {
                        this.setState({
                            // activeRoot: "root"
                            activeRoot: (this.state.activeParent !== null) ? "root" : this.state.activeParent
                        },() => {
                            this._setMenu()
                        })
                        }}>
                            <i className="far fa-angle-left"></i>
                        </div>
                    : null
                }
                <div className="tablet-menu">
                    {this.state.activeMenu.map((item , ii) => {
                        return (
                            (typeof item.children !== "undefined") 
                                ? 
                                    <div key={item.id} className="tablet-nav-item"  onClick={() => {
                                        this.setState({
                                            activeParent: item.id,
                                            activeRoot: item.id
                                        },() => {
                                            this._setMenu()
                                        })
                                    }}>
                                        <div className="tablet-nav-icon-cell">
                                            {(typeof item.icon !== "undefined") ? <i className={item.icon}></i> : null}
                                        </div>
                                        <div className="tablet-nav-title-cell">
                                            {i18n.t("navitem."+item.title)}
                                        </div>
                                        <div className="tablet-nav-expand-cell">
                                            <i className="far fa-angle-right"></i>
                                        </div>
                                    </div>
                                : 
                                    this._getLink(item)
                            )
                        })}
                    </div>
            </div>
        )
    }

    _renderTabs(){
        return(
            <div className="tablet-header-nav-wrap">
                <div className="close-wrap" onClick={() => {
                    if(this.props.onClose){
                        this.props.onClose()
                    }
                }}><i className="fal fa-times-circle"></i></div>
                <Tabs 
                    type="card" 
                    tabPosition="right"
                    onChange={(idx) => {
                        this.setState({
                            activeTab: idx,
                            activeParent: "root",
                            activeRoot: "root"
                        },() => {
                            this._setMenu()
                        })
                    }}
                >
                    {this.props.tabs.map((item, i) => {
                        return(
                            <TabPane 
                                tab={<span><i className={item.meta.icon}></i></span>} 
                                key={i}
                            >
                                {this._renderMenu()}
                            </TabPane>
                        )
                    })}
                </Tabs>
                <div className="tablet-header-logout-wrap">
                    <Button size="large" onClick={() => {
                            localStorage.setItem("plz", "");
                            localStorage.setItem("betrieb", "");
                            fetch("/ReportServer/oidclogon.aspx?method=logout",{
                                method: "GET",
                            })
                            this.props.signOutRedirect()
                        }}>
                            <Space><i className="fal fa-sign-out-alt"></i>{i18n.t("topmenu.logout")}</Space>
                    </Button>
                </div>
            </div>
        )
    }

    render(){
        return(
            <>  
                {this._renderTabs()}
                {/* {this._renderMenu()} */}
                <div className="tablet-nav-dim"></div>
            </>
        )
    }

}

export default withAuth(MainMenu)