import React from "react";
import SplitterLayout from 'react-splitter-layout';
// import devmocks from "./../devmocks/devmocks";
import 'react-splitter-layout/lib/index.css';

export default class SplitNested extends React.Component{

    render(){
        return(
            <div className="demes-split-wrap-nested">
                <SplitterLayout>
                <SplitterLayout  vertical={false}>
                    <div>1st</div>
                    <div>2st</div>
                </SplitterLayout>
                <SplitterLayout vertical={true}>
                    <div>2nd</div>
                    <div>4nd</div>
                </SplitterLayout>
                </SplitterLayout>
            </div>
        )
    }

/*

    render(){
        return(
            <div className="demes-split-wrap-nested">
                <SplitterLayout primaryIndex={1} secondaryInitialSize={250}>
                <div>1st</div>
                <SplitterLayout secondaryInitialSize={250}>
                    <SplitterLayout vertical secondaryInitialSize={250}>
                    <div>2nd</div>
                    <SplitterLayout secondaryInitialSize={250}>
                        <div>3rd</div>
                        <div>4th</div>
                    </SplitterLayout>
                    </SplitterLayout>
                    <div>5th</div>
                </SplitterLayout>
                </SplitterLayout>
            </div>
        )
    }

    */


/*
    render(){
        return(
            <div className="demes-split-wrap">
<SplitterLayout primaryIndex={1} secondaryInitialSize={250}>
  <div>1st</div>
  <SplitterLayout secondaryInitialSize={250}>
    <SplitterLayout vertical secondaryInitialSize={250}>
      <div>2nd</div>
      <SplitterLayout secondaryInitialSize={250}>
        <div>3rd</div>
        <div>4th</div>
      </SplitterLayout>
    </SplitterLayout>
    <div>5th</div>
  </SplitterLayout>
</SplitterLayout>
            </div>
        )
    }
*/
}