import {Filter} from '../../../../definitions/FilterEnum';
import {Renderer} from '../../../../definitions/RendererEnum'

const federalstate = {
    detailProp: 'bundeslandId',
    defaultPageSize: 10,
    // pageSizeOptions: [15,30,45,60,75,90,100],
    columns:[{
        title: 'isoLand',
        dataIndex: 'iso',
        // filter: Filter.TEXT,
        filter: Filter.COUNTRY,
        sort: true        
    },{
        title: 'isoBundesland',
        dataIndex: 'isoBundesland',
        // filter: Filter.TEXT,
        filter: Filter.BUNDESLAND,
        sort: true        
    },{
        title: 'name',
        dataIndex: 'name',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'tsChange',
        dataIndex: 'tsChange',
        filter: Filter.DATERANGE,
        sort: true,
        renderer: Renderer.RENDERDATETIME,
    },{
        title: 'tsInsert',
        dataIndex: 'tsInsert',
        filter: Filter.DATERANGE,
        sort: true,
        renderer: Renderer.RENDERDATETIME,
    }],
    formitems: [{
		label: "isoLand",
		name: "iso",
		type: "isocountry",
		disabled: false,
		data: [],
		required: true
    },{
        label: 'isoBundesland',
        name: 'isoBundesland',
        type: 'text',
        disabled: false,
        required: true
    },{
        label: 'name',
        name: 'name',
        type: 'text',
        disabled: false,
        required: true
    }],

    renderTitle: (rec) => {
        let title = rec.name !== null ? rec.name + ' (' + rec.isoBundesland + ')' : '';
        return title;
    },

    // -------------------------

    listresp: {},
    detaildata: {}


}

export default federalstate;
