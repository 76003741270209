import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";
import userNotification from "../../../../../misc/notification";

let GeoPLZ = class GeoPLZ extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectdata: [],
            value: "",
            valueObj: null,
            landIso: "*",
            loading: false,
            disabled: true
        };
        this.loadNewData = this.debounce(this.loadNewData, 1000);
        this.updateStateEvent = this.updateStateEvent.bind(this);
        this.updateByLandwirt = this.updateByLandwirt.bind(this);
    }
    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
    updateStateEvent(e) {
        this.setState(
            {
                landIso: e.detail
            },
            () => {
                this._getSelectData();
            }
        );
    }
    updateByLandwirt(e) {
        this.setState(
            {
                value: e.detail.plz
            },
            () => {
                this.props.onChange(e.detail.plz);
            }
        );
    }

    componentDidMount() {
        window.addEventListener("landSelected", this.updateStateEvent, true);
        window.addEventListener("landwirtSelected", this.updateByLandwirt, true);
    }
    componentWillUnmount() {
        window.removeEventListener("landSelected", this.updateStateEvent, true);
        window.removeEventListener("landwirtSelected", this.updateByLandwirt, true);
    }
    componentDidUpdate() {
        if (this.props.data !== {} && this.props.data.plz != null && this.state.value === "") {
            this.setState(
                {
                    value: this.props.data.plz,
                    disabled: false
                },
                () => {
                    this.props.onChange(this.props.data.plz);
                }
            );
        }
    }

    async _getSelectData() {
        if (this.state.landIso === "*") {
            return;
        }
        this.setState({
            loading: true,
            selectdata: []
        });
        try {
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let requestBody = {
                search: "iso==" + this.state.landIso,
                size: 300
            };
            let resp = await api.findFilteredPlz(requestBody);
            this.setState({
                selectdata: resp != null && resp.content != null ? resp.content : [],
                loading: false,
                disabled: this.state.landIso !== "*" ? false : true
            });
        } catch (e) {
            userNotification("error", "notification.geodb.plz.error");
            this.setState({
                loading: false
            });
        }
    }

    loadNewData = async (userInput) => {
        this.setState({
            loading: true,
            selectdata: []
        });
        let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
        let searchString = "iso==" + this.state.landIso;
        searchString = userInput != null && userInput !== "" ? searchString + ";postleitzahl=='" + userInput + "*'" : searchString;
        try {
            let requestBody = {
                search: searchString,
                size: 300
            };
            let body = await api.findFilteredPlz(requestBody);
            this.setState({
                selectdata: body != null && body.content != null ? body.content : [],
                loading: false
            });
        } catch (e) {
            userNotification("error", "notification.geodb.plz.error");
            this.setState({
                loading: false
            });
        }
    };
    onChange = (value, option) => {
        window.dispatchEvent(new CustomEvent("plzSelected", { detail: option.obj }));
        this.setState(
            {
                value: value,
                loading: false
            },
            () => {
                this.props.onChange(option.obj.postleitzahl);
            }
        );
    };

    render() {
        const { loading, value } = this.state;
        return (
            <Select
                // disabled={this.state.disabled}
                value={value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                showSearch
                optionLabelProp="title"
                onChange={this.onChange}
                filterOption={false}
                onSearch={this.loadNewData}
                disabled={this.props.disabled}
            >
                {this.state.selectdata.map((itm) => {
                    return (
                        <Select.Option key={itm.postleitzahl + " - " + itm.ort} value={itm.postleitzahl + " - " + itm.ort} obj={itm} title={itm.postleitzahl}>
                            {itm.postleitzahl + " - " + itm.ort}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
};

export default withAuth(GeoPLZ);
