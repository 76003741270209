import { Filter } from '../../../definitions/FilterEnum';
import { Renderer } from '../../../definitions/RendererEnum';
import i18n from '../../../misc/i18n';

const order = {
    detailProp: 'bestellId',
    formitems: [
        {
            type: 'collapse',
            label: 'order',
            defaultActiveKey: ['order_0'],
            groups: [
                {
                    label: 'probe',
                    items: [
                        {
                            label: 'betriebsId',
                            name: 'betriebsId',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'Probennummer',
                            name: 'probenNummer',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'probenart',
                            name: 'probenart',
                            type: 'displayprobenartplain',
                            disabled: false
                        },
                        {
                            label: 'schnitt',
                            name: 'schnitt',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'siliermittelbezeichnung',
                            name: 'siliermittelbezeichnung',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'silonummer',
                            name: 'silonummer',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'hybridSorte',
                            name: 'hybridSorte',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'schnitthoehe',
                            name: 'schnitthoehe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'ertrag',
                            name: 'ertrag',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'jahr',
                            name: 'jahr',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'analyseTyp',
                            name: 'analyseTyp',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'bemerkung',
                            name: 'bemerkung',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'tsProbennahme',
                            name: 'tsProbennahme',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'dsgvo',
                            name: 'dsgvo',
                            type: 'boolean',
                            disabled: false
                        },
                        {
                            label: 'emailLaborUser',
                            name: 'emailLaborUser',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'kundenNummer',
                            name: 'kundenNummer',
                            type: 'plaintext',
                            disabled: false
                        }
                    ]
                },
                {
                    label: 're-empfaenger',
                    items: [
                        {
                            label: 'vorname',
                            name: 'vornameRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'nachname',
                            name: 'nachnameRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'firma',
                            name: 'firmaRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'strasseUndNr',
                            name: 'strasseNrRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'PLZ',
                            name: 'postleitzahRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'ort',
                            name: 'ortRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'ortsteil',
                            name: 'ortsteilRe',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'land',
                            name: 'landRe',
                            type: 'plaintext',
                            disabled: false
                        }
                    ]
                },
                {
                    label: 'kontaktaufnahme',
                    items: [
                        {
                            label: 'email1',
                            name: 'email1',
                            type: 'plaintext',
                            labelTop: true,
                            disabled: false
                        },
                        {
                            label: 'email2',
                            name: 'email2',
                            type: 'plaintext',
                            labelTop: true,
                            disabled: false
                        }
                    ]
                }
            ]
        }
    ],

    renderTitle: (rec) => {
        return rec.probenNummer + ' (' + i18n.t('order.probenart.' + rec.probenart) + ' | ' + i18n.t('order.status.' + rec.status) + ')';
    },
    type: 'order',
    columns: [
        {
            title: 'probenNummer',
            dataIndex: 'probenNummer',
            filter: Filter.TEXT,
            sort: true
        },
        {
            title: 'betriebsId',
            dataIndex: 'betriebsId',
            filter: Filter.NUMBER,
            sort: true,
            align: 'right',
            // width: '140px'
        },
        {
            title: 'probenart',
            dataIndex: 'probenart',
            filter: Filter.PROBENART,
            sort: true,
            renderer: 'probenart'
            // "responsive": ["lg"]
        },
        {
            title: 'analyseTyp',
            dataIndex: 'analyseTyp',
            filter: 'text',
            sort: true
        },
        {
            title: 'status',
            dataIndex: 'status',
            filter: Filter.LIST,
            filter_list: ['PROBENAHME', 'EINGANG_SANO', 'EINGANG_LABOR', 'ERGEBNIS', 'DEAKTIVIERT'],
            sort: true,
            renderer: 'bestellstatus'
            // "responsive": ["lg"]
        },
        {
            title: 'firma',
            dataIndex: 'firma',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'vorname',
            dataIndex: 'vorname',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'nachname',
            dataIndex: 'nachname',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'plz',
            dataIndex: 'postleitzahl',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'land',
            dataIndex: 'land',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'tsProbennahme',
            dataIndex: 'tsProbennahme',
            renderer: Renderer.RENDERDATETIME,
            filter: Filter.DATERANGE,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'ergebnis',
            dataIndex: 'tsCompleted',
            renderer: Renderer.RENDERDATETIME,
            filter: Filter.DATERANGE,
            sort: true,
            responsive: ['lg']
        }
    ]
};

export default order;
