const translation = {
	"baseUri": (document.location.hostname === "localhost") ? "/localapi/importstatus.json" : "/localapi/importstatus.json",
	"expandedUri": (document.location.hostname === "localhost") ? "/localapi/importstatus_expanded.json" : "/localapi/importstatus_expanded.json",
	"expandable": true,
    "columns": [
		{
        	"title": "key",
			"dataIndex": "key",
			"align": "left"
        },
		{
        	"title": "DE",
			"dataIndex": "de",
			"align": "left"
        },
		{
        	"title": "EN",
			"dataIndex": "en",
			"align": "left"
        },
	]
}

export default translation;