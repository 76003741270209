import React from "react";
import { Spin } from "antd";
import MediaQuery from 'react-responsive';

export interface ILoadMaskProps{
    collapsed?: boolean
}
export default class LoadMask extends React.Component<ILoadMaskProps>{

    render(){
        return(
            <>
                <MediaQuery minWidth={1224}>
                    <div className={(this.props.collapsed) ? "demes-load-mask" : "demes-load-mask collapsed"}>
                        <div className="demes-load-mask-spinner-wrap">
                            <Spin spinning={true} size="large"></Spin>
                        </div>
                    </div>            
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                    <div className="demes-load-mask-responsiv">
                        <div className="demes-load-mask-spinner-wrap-responsiv">
                            <Spin spinning={true} size="large"></Spin>
                        </div>
                    </div>            
                </MediaQuery>
            </>
        )
    }
}
