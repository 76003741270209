import {Filter} from '../../../definitions/FilterEnum'

const regions = {
	"listUri": (document.location.hostname === "localhost") ? "http://dev0010-204/rest/landwirt/" : "/rest/landwirt/",
	"detailUri": (document.location.hostname === "localhost") ? "http://dev0010-204/rest/landwirt/" : "/rest/landwirt/",
	"detailProp": "regionId",
	"formitems": [
		{
			"label": "regionName",
			"name": "regionName",
			"type": "text",
			"disabled": false
		},
		{
			"label": "regionTyp",
			"name": "regionTyp",
			"type": "select",
			"rawdata": [
				{"key":"Country", "value":"Country"},
				{"key":"Region", "value":"Region"}],
			"data": [
				{"key":"Country", "value":"Country"},
				{"key":"Region", "value":"Region"},
			],
			"disabled": false
		},{
			"label": "land",
			"name": "land",
			"type": "geoland",
			"disabled": false,
			"data": []
		}
	],
	"renderTitle": (rec) => {
		return(
			rec.regionName + " (" + rec.regionTyp + ")"
		)
	},
	
	"columns": [{
		"title": "regionName",
		"dataIndex": "regionName",
		"filter": Filter.TEXT,
		"sort": true,
		// "responsive": ["lg"]
	}, {
		"title": "regionTyp",
		"dataIndex": "regionTyp",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
	}, {
		"title": "land",
		"dataIndex": "land",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
	}]

}

export default regions;