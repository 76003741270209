import React from "react";
import { Spin } from "antd";

export default class LoadMaskFrame extends React.Component{

    render(){
        return(
            <div className="load-mask-frame">
                <Spin spinning={true} size="large"></Spin>
            </div>
        )
    }

}