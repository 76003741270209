import React from 'react';

interface IProps {}

const Default = (props: IProps) => {
    return (
        <div className="demes-app-default-wrap">
            <pre>{JSON.stringify(props, null, 2)}</pre>
        </div>
    );
};

export default Default;
