import React from "react";
import devmocks from "./../devmocks/devmocks";

export default class NavRight extends React.Component {

    render(){
        return(
            <div className="demes-navright-wrap">
                <div className="demes-navright-body">
                    <div className="demes-navright-content">
                        {devmocks.content}
                    </div>
                    <div className="demes-navright-rightnav">
                        {devmocks.accordion({topnav: false})}
                    </div>
                </div>
            </div>
        )
    }
}