import React from "react";
import SelectLand from "./components/selectLand";
import SelectRegion from "./components/selectRegion";
import { Row, Col } from "antd";
import i18n from "./../../../misc/i18n";
import ListDetail from "../listdetail";
import assignment from "./_assignment";
import userNotification from "../../../misc/notification";
import * as Api from "../../../../api/rest";
import { AuthContextProps, withAuth } from "oidc-react";
import { getRestApiConfig } from "../../../../_conf";
import helper from "../listdetail/listtable/_helper";

interface IState {
    land: Array<string>;
    region: Array<string>;
    conf: any;
    listdata: any;
    pageSize: number;
    detaildata: any;
    detailVisible: boolean;
    loading: boolean;
    pagination: {};
}
interface IProps extends AuthContextProps {}

let klasse = class assignmentRegion extends React.Component<IProps, IState> {
    constructor(props: any) {
        super(props);
        this.state = {
            land: [],
            region: [],
            conf: assignment,
            listdata: [],
            pagination: 0,
            pageSize: 15,
            detaildata: {},
            detailVisible: false,
            loading: false
        };
    }

    onChangeLand = (val: Array<string>) => {
        if (val.length === 0) {
            this.setState({
                listdata: []
            });
        }
        this.setState({
            land: val
        });
    };
    onChangeRegion = (val: Array<string>) => {
        if (val.length === 0) {
            this.setState({
                listdata: []
            });
        }
        this.setState(
            {
                region: val
            },
            () => {
                this._fetchListData({});
            }
        );
    };
    async componentDidMount() {
        await this._fetchListData({});
    }

    _getApi() {
        return new Api.BetriebControllerApi(getRestApiConfig("company", this.props.userData));
    }

    async _fetchDetailData(id: number) {
        try {
            let requestBody = {
                betriebsId: id
            };
            let resp = await this._getApi().findById2(requestBody);

            this.setState({
                detaildata: resp,
                detailVisible: true
            });
        } catch (e) {
            userNotification("error", "notification.company.get.error");
        }
    }
    async _fetchListData(p: any) {
        if (this.state.region.length === 0) {
            return;
        }
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        try {
            let requestBody = {
                regions: this.state.region,
                page: params.page,
                size: params.size,
                sort: params.sort,
                search: params.search
            };
            let resp = await this._getApi().findBetriebeForRegions(requestBody);
            if (resp != null) {
                this.setState({
                    listdata: helper.prepareListData(resp.content),
                    loading: false,
                    pageSize: params.size,
                    pagination: {
                        total: resp.totalElements,
                        pageSize: params.size
                    }
                });
            }
        } catch (e) {
            userNotification("error", "notification.company.get.error");
            this.setState({
                loading: false
            });
        }
    }

    render() {
        return (
            <div className="regionWrapper">
                <div className="headerWrapper">
                    <Row>
                        <Col span={12}>
                            <Row>
                                <Col span={2}>
                                    <div className="centeredAssignment">{i18n.t("table.col.title.land") + ":"}</div>
                                </Col>
                                <Col span={19}>
                                    <SelectLand onChange={this.onChangeLand} />
                                </Col>
                            </Row>
                        </Col>
                        <Col span={12}>
                            <Row>
                                <Col span={3}>
                                    <div className="centeredAssignment">{i18n.t("form.label.regionen") + ":"}</div>
                                </Col>
                                <Col span={18}>
                                    <SelectRegion onChange={this.onChangeRegion} selectedCountrys={this.state.land} />
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
                <ListDetail
                    columns={this.state.conf.columns}
                    listdata={this.state.listdata}
                    pagination={this.state.pagination}
                    showSaveButton={false}
                    formitems={this.state.conf.formitems}
                    detailProp={this.state.conf.detailProp}
                    detaildata={this.state.detaildata}
                    fetchListData={(p: any) => this._fetchListData(p)}
                    detailVisible={this.state.detailVisible}
                    loading={this.state.loading}
                    onDetailClose={() => {
                        this.setState({
                            detailVisible: false
                        });
                    }}
                    onRowSelection={(id: number) => {
                        this._fetchDetailData(id);
                    }}
                    onTableChange={(p: any) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                />
            </div>
        );
    }
};

export default withAuth(klasse);
