import React from "react";
import { Card, Tooltip } from 'antd';
import ajax from "./../../misc/ajax";
import DeIframe from "./../../apps/iframe";
import i18n from "./../../misc/i18n";
import {
    BrowserRouter as Router,
    Route,
    Switch,
    Link
} from 'react-router-dom'

const { Meta } = Card;

export default class Start extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            links: [],
            showDetail: false,
            loginVisible: false,
            doLogin: false
        }
    }

    componentDidMount(){
        this.fetchData()
    }    

    async fetchData(keys){
        await ajax.request('/localapi/publicnav.json')
            .then((response) => {
                let links = response
                this.setState({
                    links: links,
                }, () =>{
                });
            })
            .catch((error) => {
                // Your error is here!
            });
    }

    getLink(pathname){
        let links = this.state.links;
        for(let i=0; i<links.length; i++){
            if(links[i].path === pathname){
                return(links[i])
            }
        }
    }

    getCards(){
        let links = this.state.links;
        let ret = [];
        for(let i=0; i<links.length; i++){
            let item = links[i];
            if(typeof item.target !== "undefined"){
                ret.push(
                    <Tooltip key={i} title={<span>{item.meta_link}<br/>{i18n.t("tooltip.open_new_window")}</span>}>
                        <a href={item.meta_link} target={item.target}>
                            <Card
                                key={i}
                                hoverable
                                style={{ width: 240 }}
                                cover={<img alt={item.img} src={item.img} />}
                            >
                                <Meta title={item.meta_title} description={item.meta_short_link} />
                            </Card>
                        </a>
                    </Tooltip>
                )
            }else{
                ret.push(
                    <Tooltip key={i} title={<span>{item.meta_link}<br/>{i18n.t("tooltip.open_inline")}</span>}>
                        <Link to={item.path}>
                            <Card
                                key={i}
                                hoverable
                                style={{ width: 240 }}
                                cover={<img alt={item.img} src={item.img} />}
                            >
                                <Meta title={item.meta_title} description={item.meta_short_link} />
                            </Card>
                        </Link>
                    </Tooltip>
                )
            }
        }
        return(ret)
    }

    render(){
        return(
            <React.Fragment>
                <Router hashType="noslash">
                    <Switch>
                        <Route
                            key="home"
                            path="/"
                            exact={true}
                            render={(props) => (
                                <div className="demes-start-cards-wrap">
                                    {this.getCards()}
                                </div>
                            )}
                        />
                        {this.state.links.map((item, i) => (
                            <Route
                                key={i}
                                path={item.path}
                                exact={(typeof item.exact !== "undefined") ? item.exact : false}
                                render={(props) => {
                                    let item = this.getLink(props.location.pathname);
                                    return(
                                        <DeIframe src={item.meta_link} className="public-iframe" />
                                    )
/*                                    
                                    return(
                                        <iframe 
                                            key={item.meta_link} 
                                            src={item.meta_link} 
                                            title={item.meta_title}
                                            frameBorder={0} 
                                            className="public-iframe"
                                        />
                                    )
*/
                                }}
                            />
                        ))}
                    </Switch>
                </Router>
            </React.Fragment>
        )
    }
}