import React from "react";
import { Spin } from "antd";
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from "../../../../misc/notification";
import TagSelect from "../../tagselect/index";
interface IState {
    selectdata: Array<any>;
    value: Array<number>;
    loading: boolean;
    countries: Array<any>;
}

interface IProps extends AuthContextProps {
    onChange: Function;
    selectedCountrys: Array<string>;
}

let selectLand = class selectLand extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            value: [],
            loading: false,
            countries: []
        };
    }
    componentDidUpdate() {
        if (this.props.selectedCountrys.length !== this.state.countries.length) {
            if (this.props.selectedCountrys.length < this.state.countries.length) {
                this.setState({
                    selectdata: [],
                    value: []
                });
            }

            this.setState({
                countries: this.props.selectedCountrys
            });
            this.loadNewData();
        }
    }

    async _getSelectData() {
        if (this.props.selectedCountrys.length === 0) {
            return;
        }
        this.setState({
            loading: true
        });
        try {
            let api = new Api.PermissionControllerApi(getRestApiConfig("security", this.props.userData));
            let requestBody = {
                countries: this.props.selectedCountrys
            };
            let resp = await api.findUsersForCountry(requestBody);
            if (resp !== undefined) {
                this.setState({
                    selectdata: resp,
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.user.get.error");
        }
    }

    onChange = (value: Array<number>) => {
        this.setState(
            {
                value,
                loading: false
            },
            () => {
                this.props.onChange(value);
            }
        );
    };
    loadNewData = async (userInput?: string) => {
        if (this.props.selectedCountrys.length === 0) {
            return;
        }
        this.setState({
            loading: true
        });
        let searchString = userInput != null && userInput.length > 0 ? userInput : undefined;
        try {
            let api = new Api.PermissionControllerApi(getRestApiConfig("security", this.props.userData));
            let requestBody = {
                countries: this.props.selectedCountrys,
                q: searchString
            };
            let resp = await api.findUsersForCountry(requestBody);
            this.setState({
                selectdata: resp != null ? resp : [],
                loading: false
            });
        } catch (e) {
            userNotification("error", "notification.user.get.error");
            this.setState({
                loading: false
            });
        }
    };
    textRenderer(item: Api.UserRepresentation) {
        let str = "";
        str += item.username;
        str += item.firstName != null && item.firstName !== "" ? " | " + item.firstName : "";
        str += item.lastName != null && item.lastName !== "" ? " | " + item.lastName : "";
        return str;
    }

    render() {
        const { loading } = this.state;
        return (
            /*<Select
                value={value}
                mode="multiple"
                notFoundContent={loading ? <Spin size="small" /> : null}
                showSearch
                onChange={this.onChange}
                filterOption={false}
                style={{ width: '100%' }}
                onSearch={this.loadNewData}
            >
                {this.state.selectdata.map((itm, y) => {
                    return (
                        <Select.Option key={y} value={itm.id}>
                            {this.textRenderer(itm)}
                        </Select.Option>
                    );
                })}
            </Select>*/
            <TagSelect
                keyAttributeName="username"
                valueAttributeName="id"
                onChange={this.onChange}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onSearch={this.loadNewData}
                selectdata={this.state.selectdata}
                textrender={this.textRenderer}
                style={{ width: "100%" }}
            />
        );
    }
};

export default withAuth(selectLand);
