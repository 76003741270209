import {Filter} from '../../../definitions/FilterEnum'
import {Renderer} from '../../../definitions/RendererEnum'

const companies : any = {
	"detailProp": "betriebsId",
	"formitems": [
		{
            type: 'collapse',
            label: 'company',
            defaultActiveKey: ['company_0'],
            groups: [
                {
                    label: 'company',
                    items: [
						{
							"label": "contact",
							"type": "divider"
						}, {
							// geaendert in betriebsId wg Anonymisierung 
							"label": "betriebsNummer",
							"name": "betriebsId",
							"type": "betriebsId",
							"disabled": true
						}, {
							"label": "betriebsName",
							"name": "betriebsName",
							"type": "text",
							"required": true,
							"disabled": false
						}, {
							"label": "landwirtId",
							"name": "landwirtId",
							"type": "farmerselect",
							"disabled": false,
							"required": true,
							"data": []
						}, {
							"label": "land",
							"name": "land",
							"type": "geoland",
							"disabled": false,
							"data": [],
							"required":true
						}, {
							"label": "plz",
							"name": "plz",
							"type": "geoplz",
							"disabled": false,
							"required": true,
							"data": []
						},   {
							"label": "ort",
							"name": "ort",
							"type": "geoort",
							"disabled": false,
							"data": []
						},{
							"label": "bundesland",
							"name": "bundesland",
							"type": "geobundesland",
							"disabled": false,
							"data": []
						}, {
							"label": "strasseNr",
							"name": "strasseNr",
							"type": "custominput",
							"disabled": false
						},  {
							"label": "ortsteil",
							"name": "ortsteil",
							"type": "custominput",
							"disabled": false
						}, {
							"label": "regionen",
							"name": "regionen",
							"type": "georegion",
							"disabled": false,
							"multiple": true,
							"data": [],
							"required": true
						}, 
						{
							"label": "standardBetrieb",
							"name": "standardBetrieb",
							"type": "boolean",
							"disabled": false
						},
						{
							"label": "beschreibung",
							"name": "beschreibung",
							"type": "textarea",
							"disabled": false
						},
						{
							"label": "re-empfaenger",
							"type": "divider"
						},{
							"label": "vorname",
							"name": "vornameRe",
							"type": "text"
						},{
							"label": "nachname",
							"name": "nachnameRe",
							"type": "text"
						},{	
							"label": "firma",
							"name": "firmaRe",
							"type": "text"
						},{	
							"label": "land",
							"name": "landRe",
							"type": "geolandre"
						},{	
							"label": "plz",
							"name": "postleitzahlRe",
							"type": "geoplzre"
						},{	
							"label": "ort",
							"name": "ortRe",
							"type": "geoortre"
						},{	
							"label": "strasseNr",
							"name": "strasseNrRe",
							"type": "text"
						},{	
							"label": "ortsteil",
							"name": "ortsteilRe",
							"type": "text"
						},{
							"label": "externeSysteme",
							"type": "divider"
						}, {
							"label": "externeSystemeLKV",
							"name": "externeSystemeLKV",
							"type": "text",
							"disabled": false
						}, {
							"label": "externeSystemeERP",
							"name": "lw_ERP",
							"type": "plaintext",
							"disabled": false
						}
					]
				},
                {
                    label: 'furtherInfos',
                    items: [
                        {
                            label: 'tsInsertCompany',
							name: 'tsInsert',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'uiInsert',
                            name: 'uiInsert',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'tsChangeCompany',
                            name: 'tsChange',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'uiChange',
                            name: 'uiChange',
                            type: 'plaintext',
                            disabled: false
                        }
                    ]
                }
			]
		}
	],
	"columns": [{
		"title": "landwirtIdNumber",
		"dataIndex": "lwId",
		"filter": Filter.NUMBER,
		"sort": true,
		"show_responsive": true,
		"responsive_title": true
	},{
		"title": "landwirt",
		"dataIndex": "lwnachname",
		"filter": Filter.TEXT,
		"sort": true,
		"show_responsive": true,
		"responsive_title": true
	}, {
		"title": "betriebsId",
		"dataIndex": "betriebsId",
		"filter": Filter.NUMBER,
		"sort": true,
		"align": "right",
		"width": "150px",
	}, {
		"title": "plz",
		"dataIndex": "plz",
		"filter": Filter.TEXT,
		"sort": true,
		"show_responsive": true
	}, {
		"title": "ort",
		"dataIndex": "ort",
		"filter": Filter.TEXT,
		"sort": true,
		"show_responsive": true
    }, {
		"title": "ortsteil",
		"dataIndex": "ortsteil",
		"filter": Filter.TEXT,
		"sort": true,
		"show_responsive": true
    },{
		"title": "bundesland",
		"renderer": Renderer.BUNDESLAND,
		"dataIndex": "bundesland",
		"filter": Filter.BUNDESLAND,
		"show_responsive": true
    }, {
		"title": "land",
		"dataIndex": "land",
		"filter": Filter.TEXT,
		"sort": true,
		"show_responsive": true
    }]
}

export default companies;