import React from "react";
import { Button } from "antd";
import Form from "./../../form";
import Toolbar from "./../toolbar";
import { withAuth } from "oidc-react";
let detail = class Detail extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            dirty: false,
            changedFields: []
        };
        this.formRef = React.createRef();
    }

    shouldComponentUpdate(nextProps, nextState) {
        return this.props !== nextProps || this.state !== nextState ? true : false;
    }

    _handleSave() {
        if (this.props.onSave) {
            let data = {};
            for (let i = 0; i < this.state.allFields.length; i++) {
                if (this.state.allFields[i].touched) {
                    data[this.state.allFields[i].name[0]] = this.state.allFields[i].value;
                }
            }
            this.props.onSave(data);
        }
    }

    render() {
        let saveBtnProps = {
            type: "primary",
            onClick: ""
        };
        if (!this.state.dirty) {
            saveBtnProps["disabled"] = true;
            saveBtnProps["type"] = "default";
        }

        let showButton = this.props.showSaveButton != null ? this.props.showSaveButton : true;
        return (
            <div className={this.props.className}>
                <div className="detail-wrap">
                    {showButton && (
                        <Toolbar>
                            <Button
                                {...saveBtnProps}
                                onClick={() => {
                                    this._handleSave();
                                }}
                            >
                                <i className="fal fa-save"></i>
                            </Button>
                        </Toolbar>
                    )}
                    <Form
                        className={this.props.className}
                        ref={this.props.refForForm != null ? this.props.refForForm : this.formRef}
                        horizontal={false}
                        formItems={this.props.formitems}
                        values={this.props.data}
                        disableAll={this.props.disableAll}
                        onFieldsChange={(changedFields, allFields) => {
                                this.setState(
                                    {
                                        dirty: true,
                                        changedFields: changedFields,
                                        allFields: allFields
                                    },
                                    () => {
                                        if(this.props.onChange){
                                            this.props.onChange(this.state.allFields);
                                        }
                                    }
                                );
                            
                        }}
                    />
                    {/* <pre>{JSON.stringify(this.props, null, 2)}</pre> */}
                </div>
            </div>
        );
    }
};

export default withAuth(detail);
