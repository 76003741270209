import {Filter} from '../../../../definitions/FilterEnum'
import {Renderer} from '../../../../definitions/RendererEnum'
const companies = {
	"detailProp": "betriebsId",
	"columns": [{
		"title": "dokumentName",
		"dataIndex": "dokumentName",
		"filter": Filter.TEXT,
		"width":"46%"
	}, {
		"title": "dateierstellungsdatum900003",
		"dataIndex": "dateierstellungsdatum900003",
		"filter":Filter.DATERANGE,
		"renderer": Renderer.RENDERDATE,
		"width":"25%"
	}, {
		"title": "importDate",
		"dataIndex": "tsInsert",
		"filter":Filter.DATERANGE,
		"renderer": Renderer.RENDERDATE,
		"width":"29%"
	}],
	"renderTitle":(rec) => {
		return rec.dokumentName
	}
}

export default companies;