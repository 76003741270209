/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Landwirt,
    LandwirtFromJSON,
    LandwirtFromJSONTyped,
    LandwirtToJSON,
} from './Landwirt';
import {
    PageableObject,
    PageableObjectFromJSON,
    PageableObjectFromJSONTyped,
    PageableObjectToJSON,
} from './PageableObject';
import {
    Sort,
    SortFromJSON,
    SortFromJSONTyped,
    SortToJSON,
} from './Sort';

/**
 * 
 * @export
 * @interface PageLandwirt
 */
export interface PageLandwirt {
    /**
     * 
     * @type {number}
     * @memberof PageLandwirt
     */
    totalPages?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLandwirt
     */
    totalElements?: number;
    /**
     * 
     * @type {number}
     * @memberof PageLandwirt
     */
    size?: number;
    /**
     * 
     * @type {Array<Landwirt>}
     * @memberof PageLandwirt
     */
    content?: Array<Landwirt>;
    /**
     * 
     * @type {number}
     * @memberof PageLandwirt
     */
    number?: number;
    /**
     * 
     * @type {Sort}
     * @memberof PageLandwirt
     */
    sort?: Sort;
    /**
     * 
     * @type {number}
     * @memberof PageLandwirt
     */
    numberOfElements?: number;
    /**
     * 
     * @type {boolean}
     * @memberof PageLandwirt
     */
    first?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof PageLandwirt
     */
    last?: boolean;
    /**
     * 
     * @type {PageableObject}
     * @memberof PageLandwirt
     */
    pageable?: PageableObject;
    /**
     * 
     * @type {boolean}
     * @memberof PageLandwirt
     */
    empty?: boolean;
}

export function PageLandwirtFromJSON(json: any): PageLandwirt {
    return PageLandwirtFromJSONTyped(json, false);
}

export function PageLandwirtFromJSONTyped(json: any, ignoreDiscriminator: boolean): PageLandwirt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'totalPages': !exists(json, 'totalPages') ? undefined : json['totalPages'],
        'totalElements': !exists(json, 'totalElements') ? undefined : json['totalElements'],
        'size': !exists(json, 'size') ? undefined : json['size'],
        'content': !exists(json, 'content') ? undefined : ((json['content'] as Array<any>).map(LandwirtFromJSON)),
        'number': !exists(json, 'number') ? undefined : json['number'],
        'sort': !exists(json, 'sort') ? undefined : SortFromJSON(json['sort']),
        'numberOfElements': !exists(json, 'numberOfElements') ? undefined : json['numberOfElements'],
        'first': !exists(json, 'first') ? undefined : json['first'],
        'last': !exists(json, 'last') ? undefined : json['last'],
        'pageable': !exists(json, 'pageable') ? undefined : PageableObjectFromJSON(json['pageable']),
        'empty': !exists(json, 'empty') ? undefined : json['empty'],
    };
}

export function PageLandwirtToJSON(value?: PageLandwirt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'totalPages': value.totalPages,
        'totalElements': value.totalElements,
        'size': value.size,
        'content': value.content === undefined ? undefined : ((value.content as Array<any>).map(LandwirtToJSON)),
        'number': value.number,
        'sort': SortToJSON(value.sort),
        'numberOfElements': value.numberOfElements,
        'first': value.first,
        'last': value.last,
        'pageable': PageableObjectToJSON(value.pageable),
        'empty': value.empty,
    };
}

