import React from "react";
import { Button } from "antd";
import MainMenu from "./../mainmenu";
import MainSearch from "./../mainsearch";
import i18n from "./../../../../../misc/i18n";
import { withAuth } from "oidc-react";
import {
    isExtern
} from "./../../../../../misc/getUserRoles";

class Header extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            navActive: false,
            searchActive: false
        }
    }

    _setDocTitle(title){
        document.title = title;
    }

    _getRightSide(){

        return(
            
            (this.props.userData !== null)
            ?
                <div className="tablet-header-right">
                    <div onClick={() => {
                        this.setState({
                            searchActive: false, 
                            navActive: !this.state.navActive
                        })
                    }}>
                        {(this.state.navActive) ? <i className="fal fa-times"></i> : <i className="fal fa-bars"></i>}
                    </div>
                    <div onClick={() => {
                        this.setState({
                            searchActive: !this.state.searchActive,
                            navActive: false
                        })
                    }}>
                        {
                            (this.state.navActive) 
                                ? null 
                                : (isExtern(this.props.userData))
                                    ? null
                                    : <i className="fal fa-search"></i>
                        }
                    </div>
                </div>
            :   <div className="tablet-header-right-nolog">
                    <div style={{display:"flex",flexDirection:"column",alignItems:"center"}}>
                        <Button type="primary"
                                onClick={() => {
                                    // if(getCookie("accepts-cookies") != null){
                                        this.props.signInPopup()
                                    // }
                                }}
                            >Login</Button>
                        <div style={{fontSize:"10px",paddingTop:"4px",fontWeight:"bolder"}}>Neuer Kunde? <a href="https://sano24.de/sdn">Starten Sie hier.</a></div>
                    </div>
                </div>
        )
    }

    render(){
        return(
            <>
                <div className="tablet-header-wrap">
                    <a href="/">
                        <div className="tablet-header-left">
                            <img src="/img/sdn_logo.png" alt="/img/sdn_logo.png" />
                        </div>
                    </a>
                    <div className="tablet-header-center">
                        <i className={this.props.icon}></i> {i18n.t('navitem.'+this.props.title)} 
                        {this._setDocTitle(i18n.t('navitem.'+this.props.title))}
                    </div>
                    {this._getRightSide()}
                </div>
                {(this.state.navActive)
                    ? 
                        <MainMenu
                            items={this.props.menuItems}
                            routes={this.props.routes}
                            collapsed={this.props.collapsed}
                            tabs={this.props.menuTabs}
                            onClose={() => {
                                this.setState({
                                    navActive: !this.state.navActive
                                })
                            }}
                        />
                    
                    : null
                }
                {(this.state.searchActive)
                    ? 
                        <MainSearch
                            items={this.props.menuItems}
                            routes={this.props.routes}
                            collapsed={this.props.collapsed}
                            tabs={this.props.menuTabs}
                            onClose={() => {
                                this.setState({
                                    searchActive: !this.state.searchActive
                                })
                            }}
                        />
                    
                    : null
                } 
            </>
        )
    }
}

export default withAuth(Header);
