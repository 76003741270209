/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CvasDokumentOhneInhalt
 */
export interface CvasDokumentOhneInhalt {
    /**
     * 
     * @type {number}
     * @memberof CvasDokumentOhneInhalt
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof CvasDokumentOhneInhalt
     */
    mimeType?: string;
    /**
     * 
     * @type {string}
     * @memberof CvasDokumentOhneInhalt
     */
    probenNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof CvasDokumentOhneInhalt
     */
    dokumentName?: string;
    /**
     * 
     * @type {string}
     * @memberof CvasDokumentOhneInhalt
     */
    dokumentArt?: string;
}

export function CvasDokumentOhneInhaltFromJSON(json: any): CvasDokumentOhneInhalt {
    return CvasDokumentOhneInhaltFromJSONTyped(json, false);
}

export function CvasDokumentOhneInhaltFromJSONTyped(json: any, ignoreDiscriminator: boolean): CvasDokumentOhneInhalt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'mimeType': !exists(json, 'mimeType') ? undefined : json['mimeType'],
        'probenNummer': !exists(json, 'probenNummer') ? undefined : json['probenNummer'],
        'dokumentName': !exists(json, 'dokumentName') ? undefined : json['dokumentName'],
        'dokumentArt': !exists(json, 'dokumentArt') ? undefined : json['dokumentArt'],
    };
}

export function CvasDokumentOhneInhaltToJSON(value?: CvasDokumentOhneInhalt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'mimeType': value.mimeType,
        'probenNummer': value.probenNummer,
        'dokumentName': value.dokumentName,
        'dokumentArt': value.dokumentArt,
    };
}

