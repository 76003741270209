/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Ort
 */
export interface Ort {
    /**
     * 
     * @type {number}
     * @memberof Ort
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof Ort
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof Ort
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    ortsteil?: string;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    postleitzahl?: string;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    nameKreis?: string;
    /**
     * 
     * @type {number}
     * @memberof Ort
     */
    longitude?: number;
    /**
     * 
     * @type {number}
     * @memberof Ort
     */
    bundeslandId?: number;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    nameGemeinde?: string;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    nameBezirk?: string;
    /**
     * 
     * @type {number}
     * @memberof Ort
     */
    latitude?: number;
    /**
     * 
     * @type {string}
     * @memberof Ort
     */
    isoBundesland?: string;
    /**
     * 
     * @type {number}
     * @memberof Ort
     */
    elevation?: number;
}

export function OrtFromJSON(json: any): Ort {
    return OrtFromJSONTyped(json, false);
}

export function OrtFromJSONTyped(json: any, ignoreDiscriminator: boolean): Ort {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'ortsteil': !exists(json, 'ortsteil') ? undefined : json['ortsteil'],
        'postleitzahl': !exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'nameKreis': !exists(json, 'nameKreis') ? undefined : json['nameKreis'],
        'longitude': !exists(json, 'longitude') ? undefined : json['longitude'],
        'bundeslandId': !exists(json, 'bundeslandId') ? undefined : json['bundeslandId'],
        'nameGemeinde': !exists(json, 'nameGemeinde') ? undefined : json['nameGemeinde'],
        'nameBezirk': !exists(json, 'nameBezirk') ? undefined : json['nameBezirk'],
        'latitude': !exists(json, 'latitude') ? undefined : json['latitude'],
        'isoBundesland': !exists(json, 'isoBundesland') ? undefined : json['isoBundesland'],
        'elevation': !exists(json, 'elevation') ? undefined : json['elevation'],
    };
}

export function OrtToJSON(value?: Ort | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uiInsert': value.uiInsert,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'ortsteil': value.ortsteil,
        'postleitzahl': value.postleitzahl,
        'nameKreis': value.nameKreis,
        'longitude': value.longitude,
        'bundeslandId': value.bundeslandId,
        'nameGemeinde': value.nameGemeinde,
        'nameBezirk': value.nameBezirk,
        'latitude': value.latitude,
        'isoBundesland': value.isoBundesland,
        'elevation': value.elevation,
    };
}

