/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SdnImportJobError
 */
export interface SdnImportJobError {
    /**
     * 
     * @type {number}
     * @memberof SdnImportJobError
     */
    importErrorId?: number;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJobError
     */
    errorText?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJobError
     */
    description?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJobError
     */
    parameter?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnImportJobError
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJobError
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnImportJobError
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnImportJobError
     */
    uiChange?: string;
}

export function SdnImportJobErrorFromJSON(json: any): SdnImportJobError {
    return SdnImportJobErrorFromJSONTyped(json, false);
}

export function SdnImportJobErrorFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdnImportJobError {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'importErrorId': !exists(json, 'importErrorId') ? undefined : json['importErrorId'],
        'errorText': !exists(json, 'errorText') ? undefined : json['errorText'],
        'description': !exists(json, 'description') ? undefined : json['description'],
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
    };
}

export function SdnImportJobErrorToJSON(value?: SdnImportJobError | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'importErrorId': value.importErrorId,
        'errorText': value.errorText,
        'description': value.description,
        'parameter': value.parameter,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiInsert': value.uiInsert,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'uiChange': value.uiChange,
    };
}

