/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Bundesland,
    BundeslandFromJSON,
    BundeslandToJSON,
    BundeslandFilter,
    BundeslandFilterFromJSON,
    BundeslandFilterToJSON,
    Land,
    LandFromJSON,
    LandToJSON,
    Ort,
    OrtFromJSON,
    OrtToJSON,
    PageBundesland,
    PageBundeslandFromJSON,
    PageBundeslandToJSON,
    PageLand,
    PageLandFromJSON,
    PageLandToJSON,
    PageOrt,
    PageOrtFromJSON,
    PageOrtToJSON,
    PagePostleitzahl,
    PagePostleitzahlFromJSON,
    PagePostleitzahlToJSON,
    PagePostleitzahlOrt,
    PagePostleitzahlOrtFromJSON,
    PagePostleitzahlOrtToJSON,
} from '../models';

export interface CreateBundesLandRequest {
    bundesland: Bundesland;
}

export interface CreateLandRequest {
    land: Land;
}

export interface CreateOrtRequest {
    ort: Ort;
}

export interface DeleteBundesLandRequest {
    bundeslandId: number;
}

export interface DeleteLandRequest {
    iso: string;
}

export interface DeleteOrtRequest {
    id: number;
}

export interface FindAllBundeslandRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindAllBundeslandForLaborstatistikRequest {
    laender?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface FindAllLandRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindAllLandFromBetriebeTableRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    startDate?: Date;
    endDate?: Date;
}

export interface FindAllOrtRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindBundeslandByIdRequest {
    bundeslandId: number;
}

export interface FindFiltered2Request {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindFilteredPlzRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindFilteredPlzLaborstatistikRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    regionNamen?: Array<string>;
    laender?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface FindFilteredPlzXRFRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    regionNamen?: Array<string>;
    laender?: Array<string>;
    type?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface FindLandByIdRequest {
    iso: string;
}

export interface FindLandForXRFRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    type?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface FindOrtByIdRequest {
    id: number;
}

export interface UpdateBundeslandRequest {
    bundeslandId: number;
    body: string;
}

export interface UpdateLandRequest {
    iso: string;
    body: string;
}

export interface UpdateOrtRequest {
    ortId: number;
    body: string;
}

/**
 * 
 */
export class GeoControllerApi extends runtime.BaseAPI {

    /**
     */
    async createBundesLandRaw(requestParameters: CreateBundesLandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Bundesland>> {
        if (requestParameters.bundesland === null || requestParameters.bundesland === undefined) {
            throw new runtime.RequiredError('bundesland','Required parameter requestParameters.bundesland was null or undefined when calling createBundesLand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundesland/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BundeslandToJSON(requestParameters.bundesland),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BundeslandFromJSON(jsonValue));
    }

    /**
     */
    async createBundesLand(requestParameters: CreateBundesLandRequest, initOverrides?: RequestInit): Promise<Bundesland> {
        const response = await this.createBundesLandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createLandRaw(requestParameters: CreateLandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Land>> {
        if (requestParameters.land === null || requestParameters.land === undefined) {
            throw new runtime.RequiredError('land','Required parameter requestParameters.land was null or undefined when calling createLand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/land/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LandToJSON(requestParameters.land),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandFromJSON(jsonValue));
    }

    /**
     */
    async createLand(requestParameters: CreateLandRequest, initOverrides?: RequestInit): Promise<Land> {
        const response = await this.createLandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async createOrtRaw(requestParameters: CreateOrtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Ort>> {
        if (requestParameters.ort === null || requestParameters.ort === undefined) {
            throw new runtime.RequiredError('ort','Required parameter requestParameters.ort was null or undefined when calling createOrt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/ort/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: OrtToJSON(requestParameters.ort),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrtFromJSON(jsonValue));
    }

    /**
     */
    async createOrt(requestParameters: CreateOrtRequest, initOverrides?: RequestInit): Promise<Ort> {
        const response = await this.createOrtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBundesLandRaw(requestParameters: DeleteBundesLandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.bundeslandId === null || requestParameters.bundeslandId === undefined) {
            throw new runtime.RequiredError('bundeslandId','Required parameter requestParameters.bundeslandId was null or undefined when calling deleteBundesLand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundesland/del/{bundeslandId}`.replace(`{${"bundeslandId"}}`, encodeURIComponent(String(requestParameters.bundeslandId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteBundesLand(requestParameters: DeleteBundesLandRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteBundesLandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLandRaw(requestParameters: DeleteLandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.iso === null || requestParameters.iso === undefined) {
            throw new runtime.RequiredError('iso','Required parameter requestParameters.iso was null or undefined when calling deleteLand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/land/del/{iso}`.replace(`{${"iso"}}`, encodeURIComponent(String(requestParameters.iso))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteLand(requestParameters: DeleteLandRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteLandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteOrtRaw(requestParameters: DeleteOrtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteOrt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/ort/del/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteOrt(requestParameters: DeleteOrtRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteOrtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllBundeslandRaw(requestParameters: FindAllBundeslandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBundesland>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundesland/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBundeslandFromJSON(jsonValue));
    }

    /**
     */
    async findAllBundesland(requestParameters: FindAllBundeslandRequest = {}, initOverrides?: RequestInit): Promise<PageBundesland> {
        const response = await this.findAllBundeslandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllBundeslandForLaborstatistikRaw(requestParameters: FindAllBundeslandForLaborstatistikRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Bundesland>>> {
        const queryParameters: any = {};

        if (requestParameters.laender) {
            queryParameters['laender'] = requestParameters.laender;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundeslandForLaborstatistik/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BundeslandFromJSON));
    }

    /**
     */
    async findAllBundeslandForLaborstatistik(requestParameters: FindAllBundeslandForLaborstatistikRequest = {}, initOverrides?: RequestInit): Promise<Array<Bundesland>> {
        const response = await this.findAllBundeslandForLaborstatistikRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllBundeslandForUserRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<BundeslandFilter>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundeslandForUser/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BundeslandFilterFromJSON));
    }

    /**
     */
    async findAllBundeslandForUser(initOverrides?: RequestInit): Promise<Array<BundeslandFilter>> {
        const response = await this.findAllBundeslandForUserRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllLandRaw(requestParameters: FindAllLandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageLand>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/land/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLandFromJSON(jsonValue));
    }

    /**
     */
    async findAllLand(requestParameters: FindAllLandRequest = {}, initOverrides?: RequestInit): Promise<PageLand> {
        const response = await this.findAllLandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllLandFromBetriebeTableRaw(requestParameters: FindAllLandFromBetriebeTableRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageLand>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/landFromBetriebeTable/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLandFromJSON(jsonValue));
    }

    /**
     */
    async findAllLandFromBetriebeTable(requestParameters: FindAllLandFromBetriebeTableRequest = {}, initOverrides?: RequestInit): Promise<PageLand> {
        const response = await this.findAllLandFromBetriebeTableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findAllOrtRaw(requestParameters: FindAllOrtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageOrt>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/ort/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageOrtFromJSON(jsonValue));
    }

    /**
     */
    async findAllOrt(requestParameters: FindAllOrtRequest = {}, initOverrides?: RequestInit): Promise<PageOrt> {
        const response = await this.findAllOrtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBundeslandByIdRaw(requestParameters: FindBundeslandByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Bundesland>> {
        if (requestParameters.bundeslandId === null || requestParameters.bundeslandId === undefined) {
            throw new runtime.RequiredError('bundeslandId','Required parameter requestParameters.bundeslandId was null or undefined when calling findBundeslandById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundesland/{bundeslandId}`.replace(`{${"bundeslandId"}}`, encodeURIComponent(String(requestParameters.bundeslandId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BundeslandFromJSON(jsonValue));
    }

    /**
     */
    async findBundeslandById(requestParameters: FindBundeslandByIdRequest, initOverrides?: RequestInit): Promise<Bundesland> {
        const response = await this.findBundeslandByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFiltered2Raw(requestParameters: FindFiltered2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PagePostleitzahl>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/plz/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePostleitzahlFromJSON(jsonValue));
    }

    /**
     */
    async findFiltered2(requestParameters: FindFiltered2Request = {}, initOverrides?: RequestInit): Promise<PagePostleitzahl> {
        const response = await this.findFiltered2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFilteredPlzRaw(requestParameters: FindFilteredPlzRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PagePostleitzahlOrt>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/plzOrt/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePostleitzahlOrtFromJSON(jsonValue));
    }

    /**
     */
    async findFilteredPlz(requestParameters: FindFilteredPlzRequest = {}, initOverrides?: RequestInit): Promise<PagePostleitzahlOrt> {
        const response = await this.findFilteredPlzRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFilteredPlzLaborstatistikRaw(requestParameters: FindFilteredPlzLaborstatistikRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PagePostleitzahlOrt>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.regionNamen) {
            queryParameters['regionNamen'] = requestParameters.regionNamen;
        }

        if (requestParameters.laender) {
            queryParameters['laender'] = requestParameters.laender;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/plzOrtLaborstatistik/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePostleitzahlOrtFromJSON(jsonValue));
    }

    /**
     */
    async findFilteredPlzLaborstatistik(requestParameters: FindFilteredPlzLaborstatistikRequest = {}, initOverrides?: RequestInit): Promise<PagePostleitzahlOrt> {
        const response = await this.findFilteredPlzLaborstatistikRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFilteredPlzXRFRaw(requestParameters: FindFilteredPlzXRFRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PagePostleitzahlOrt>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.regionNamen) {
            queryParameters['regionNamen'] = requestParameters.regionNamen;
        }

        if (requestParameters.laender) {
            queryParameters['laender'] = requestParameters.laender;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/plzOrtXRF/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PagePostleitzahlOrtFromJSON(jsonValue));
    }

    /**
     */
    async findFilteredPlzXRF(requestParameters: FindFilteredPlzXRFRequest = {}, initOverrides?: RequestInit): Promise<PagePostleitzahlOrt> {
        const response = await this.findFilteredPlzXRFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findLandByIdRaw(requestParameters: FindLandByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Land>> {
        if (requestParameters.iso === null || requestParameters.iso === undefined) {
            throw new runtime.RequiredError('iso','Required parameter requestParameters.iso was null or undefined when calling findLandById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/land/{iso}`.replace(`{${"iso"}}`, encodeURIComponent(String(requestParameters.iso))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandFromJSON(jsonValue));
    }

    /**
     */
    async findLandById(requestParameters: FindLandByIdRequest, initOverrides?: RequestInit): Promise<Land> {
        const response = await this.findLandByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findLandForXRFRaw(requestParameters: FindLandForXRFRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageLand>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.type) {
            queryParameters['type'] = requestParameters.type;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/landForXRF/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLandFromJSON(jsonValue));
    }

    /**
     */
    async findLandForXRF(requestParameters: FindLandForXRFRequest = {}, initOverrides?: RequestInit): Promise<PageLand> {
        const response = await this.findLandForXRFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findOrtByIdRaw(requestParameters: FindOrtByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Ort>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findOrtById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/ort/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => OrtFromJSON(jsonValue));
    }

    /**
     */
    async findOrtById(requestParameters: FindOrtByIdRequest, initOverrides?: RequestInit): Promise<Ort> {
        const response = await this.findOrtByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateBundeslandRaw(requestParameters: UpdateBundeslandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.bundeslandId === null || requestParameters.bundeslandId === undefined) {
            throw new runtime.RequiredError('bundeslandId','Required parameter requestParameters.bundeslandId was null or undefined when calling updateBundesland.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateBundesland.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/bundesland/{bundeslandId}`.replace(`{${"bundeslandId"}}`, encodeURIComponent(String(requestParameters.bundeslandId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateBundesland(requestParameters: UpdateBundeslandRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateBundeslandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLandRaw(requestParameters: UpdateLandRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.iso === null || requestParameters.iso === undefined) {
            throw new runtime.RequiredError('iso','Required parameter requestParameters.iso was null or undefined when calling updateLand.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateLand.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/land/{iso}`.replace(`{${"iso"}}`, encodeURIComponent(String(requestParameters.iso))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateLand(requestParameters: UpdateLandRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateLandRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateOrtRaw(requestParameters: UpdateOrtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.ortId === null || requestParameters.ortId === undefined) {
            throw new runtime.RequiredError('ortId','Required parameter requestParameters.ortId was null or undefined when calling updateOrt.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateOrt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/geo/ort/{ortId}`.replace(`{${"ortId"}}`, encodeURIComponent(String(requestParameters.ortId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateOrt(requestParameters: UpdateOrtRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateOrtRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
