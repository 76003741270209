import React from 'react';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import i18n from './i18n';
let { warning } = Modal;

export interface DataObject extends Object {
    regionen?: Array<any>;
    emailbenachrichtigungJn?: any;
    email?: any;
}
export interface FormItem extends Object {
    name?: keyof DataObject;
    type: string;
    label: string;
    disabled?: boolean;
    required?: boolean;
    data?: Array<any>;
    multible?: boolean;
    changeNotification?: boolean;
    rawdata: Array<any>;
    groups: Array<{
        label:string,
        items: Array<FormItem>
    }>;
}
export interface FormItems extends Object {
    detailProp: string;
    formitems: Array<FormItem>;
    columns: Array<any>;
}

enum ErrorType {
    required,
    falseValue,
    changed,
    notEmail,
    notNumber
}

export interface ErrorListObject extends Object {
    propName: string;
    type: ErrorType;
}

enum RequestType {
    patch,
    post
}
function buildModalMessage(errors: Array<ErrorListObject>) {
    let result = (
        <>
            {errors.map((itm, index) => {
                let type = itm.type === ErrorType.required ? 'notEmpty' : 'exists';
                switch (itm.type) {
                    case ErrorType.required:
                        type = 'notEmpty';
                        break;
                    case ErrorType.falseValue:
                        type = 'exists';
                        break;
                    case ErrorType.changed:
                        type = 'changed';
                        break;
                    case ErrorType.notEmail:
                        type = 'notEmail';
                        break;
                    case ErrorType.notNumber:
                        type = 'notNumber';
                        break;
                    default:
                        type = 'notKnown';
                        break;
                }
                return <p key={index}>{i18n.t('form.label.' + itm.propName) + ' ' + i18n.t('formvalidation.' + type)}</p>;
            })}
        </>
    );
    return result;
}

function testEmail(email: any) {
    // eslint-disable-next-line no-control-regex
    let regex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;
    return regex.test(email);
}

function validateData(value: DataObject, oldValue: DataObject, items: FormItems, requestType: RequestType) {
    let errors: Array<ErrorListObject> = [];
    let emailRequired = false;
    if (Object.prototype.hasOwnProperty.call(value, 'emailbenachrichtigungJn')) {
        if (value['emailbenachrichtigungJn'] === true) {
            emailRequired = true;
        }
    } else if (Object.prototype.hasOwnProperty.call(oldValue, 'emailbenachrichtigungJn') && oldValue['emailbenachrichtigungJn'] === true) {
        emailRequired = true;
    }
    if (!Object.prototype.hasOwnProperty.call(value, 'email') && requestType === RequestType.post) {
        value['email'] = '';
    }
    if (
        Object.prototype.hasOwnProperty.call(oldValue, 'emailbenachrichtigungJn') &&
        oldValue['emailbenachrichtigungJn'] === false &&
        emailRequired === true &&
        Object.prototype.hasOwnProperty.call(oldValue, 'email') &&
        oldValue['email'] !== '' &&
        !Object.prototype.hasOwnProperty.call(value, 'email')
    ) {
        value['email'] = oldValue['email'];
    }


    const loop = (loopitems: FormItem[]) => {
        for (let item of loopitems) {
            let errorFound = false;
            // Muss zwischen RequestTypes unterschieden werden, da bei Post die required Properties immer mitgegeben werden müssen und beim patch nicht, da nur geänderte werte
            // übertragen werden
            if (
                !errorFound &&
                requestType === RequestType.patch &&
                item.required === true &&
                item.name != null &&
                Object.prototype.hasOwnProperty.call(value, item.name) &&
                (value[item.name] === undefined || value[item.name].length === 0)
            ) {
                errors.push({
                    propName: item.label,
                    type: ErrorType.required
                });
                errorFound = true;
            }
            if (
                !errorFound &&
                requestType === RequestType.post &&
                item.required === true &&
                item.name != null &&
                (!Object.prototype.hasOwnProperty.call(value, item.name) || value[item.name] === undefined || value[item.name].length === 0)
            ) {
                errors.push({
                    propName: item.label,
                    type: ErrorType.required
                });
                errorFound = true;
            }
            if (
                !errorFound &&
                item.type === 'email' &&
                item.name != null &&
                (Object.prototype.hasOwnProperty.call(value, item.name) || Object.prototype.hasOwnProperty.call(oldValue, item.name)) &&
                emailRequired
            ) {
                if (!testEmail(value[item.name])) {
                    errors.push({
                        propName: item.label,
                        type: ErrorType.notEmail
                    });
                    errorFound = true;
                }
            }
            if (!errorFound && item.type === 'number' && item.name != null && Object.prototype.hasOwnProperty.call(value, item.name) && isNaN(value[item.name])) {
                errors.push({
                    propName: item.label,
                    type: ErrorType.notNumber
                });
                errorFound = true;
            }

            if(!errorFound && item.type === 'collapse' && item.groups != null){
                for(let groupItem of item.groups){
                    
                    loop(groupItem.items);
                    errorFound = errors.length > 0;

                }
            }
        }
    }


    loop(items.formitems);

    if (errors.length > 0) {
        warning({
            title: i18n.t('formvalidation.warning'),
            icon: <ExclamationCircleOutlined />,
            content: buildModalMessage(errors)
        });
        return false;
    }
    return true;
}

export { validateData, RequestType, buildModalMessage, ErrorType };
