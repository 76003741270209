import React, { useState, useEffect, useCallback } from "react";
import { Pagination, Empty, Drawer } from "antd";
import Farmer from "./../farmer/farmer";
import Companies from "./../companies/companies";
import Order from "./../order/order";
import Laborbuch from "./../laborbuch/laborbuch";
import Highlighter from "react-highlight-words";
import { getRestApiConfig, getImportApiConfig } from "../../../../_conf";
import * as ApiRest from "../../../../api/rest";
import * as ApiImport from "../../../../api/importstatus";
import { AuthContextProps, useAuth } from "oidc-react";
import userNotification from "../../../misc/notification";
import LoadMask from "../../../misc/loadmask";
import { getLinkPart } from "./../../../misc/linkhelper";
import helper from "../listdetail/listtable/_helper";
import { enc } from "../../../../components/misc/crypt";
import i18n from "../../../misc/i18n";

const queryToStr = require("query-string");

interface IGlobalSearchProps {
    title: any;
    onLoad: (count: number) => void;
    type: string;
    searchString: string;
}

// interface ISearchItem {
//     id: string;
//     title: string;
//     text: string;
// }

// interface ISearchResponse {
//     content: ISearchItem[];
//     totalElements: number;
// }

const pageSizeDefault = 10;

const _getApi = (type:string, auth:AuthContextProps): any => {
    if (type === "companies") {
        return new ApiRest.BetriebControllerApi(getRestApiConfig("company", auth.userData));
    } else if (type === "farmer") {
        return new ApiRest.LandwirtControllerApi(getRestApiConfig("farmer", auth.userData));
    } else if (type === "order") {
        // probeorder
        return new ApiImport.ProbenbestellungControllerApi(getImportApiConfig("probeorder", auth.userData));
    } else {
        // probeorder
        return new ApiImport.ProbenbestellungControllerApi(getImportApiConfig("probeorder", auth.userData));
    }
};

const GlobalSearchPage: React.FC<IGlobalSearchProps> = (props) => {
    const [listdata, setListdata] = useState([]);
    const [loading, setLoading] = useState(false);
    const [totalCount, setTotalCount] = useState(0);
    const [showDetail, setShowDetail] = useState(false);
    const [detailId, setDetailId] = useState(undefined);
    const [detailType, setDetailType] = useState(undefined);
    const [pagination, setPagination] = useState({ pageSize: pageSizeDefault, current: 0, total: 0 });
    const [listParams, setListParams] = useState({ pagination: pagination });
    const { onLoad, searchString, type } = props;
    const auth = useAuth();

    

    const _fetchListData = async () => {
        const result = queryToStr.parse(document.location.search);
        let lsearchString = result.query;
        setLoading(true);
        let params = helper.prepareListParams(listParams, pagination.pageSize);
        let resp = {
            content: [],
            totalElements: 0
        };
        try {
            let requestBody = {
                size: params.size,
                search: params.search,
                page: pagination.current,
                q: lsearchString
            };
            if (type === "companies") {
                resp = await _getApi(type, auth).findBetriebByQuery(requestBody);
            } else if (type === "farmer") {
                resp = await _getApi(type, auth).findLandwirtByQuery(requestBody);
            } else if (type === "order") {
                resp = await _getApi(type, auth).findProbenByQuery(requestBody);
            } else {
                resp = await _getApi(type, auth).findProbenByQueryForLaborbuch(requestBody);
            }
            if (resp != null) {
                setListdata(helper.prepareListData(resp.content));
                setLoading(false);
                
                setTotalCount((oldCount) => {
                    if (resp.totalElements !== undefined || oldCount !== resp.totalElements){
                        return resp.totalElements;
                    }
                    return oldCount;
                });
            }
        } catch (e) {
            userNotification("error", "notification.search.get.error");
            setTotalCount(0);
            setLoading(false);
        }
    };

    const _fetchListDataCallback = useCallback(_fetchListData, [auth, listParams, pagination, type]);

    useEffect(() => {
        _fetchListDataCallback();
    }, [searchString, listParams, _fetchListDataCallback]);

    useEffect(() => {
        if (onLoad) {
            onLoad(totalCount);
        }
    }, [totalCount, onLoad]);

    const _getPagination = () => {
        return (
            <div className="globalsearch-page-pagination-wrap">
                <Pagination
                    defaultCurrent={pagination.current}
                    current={pagination.current + 1}
                    total={totalCount}
                    pageSize={pagination.pageSize}
                    responsive={true}
                    onChange={(page: number, pageSize?: number) => {
                        setPagination({
                            current: page - 1,
                            pageSize: pageSize || pageSizeDefault,
                            total: totalCount
                        });
                        setListParams({
                            pagination: pagination
                        });
                    }}
                    onShowSizeChange={(current: number, size: number) => {
                        setPagination({
                            current: current - 1,
                            pageSize: size,
                            total: totalCount
                        });
                        setListParams({
                            pagination: pagination
                        });
                    }}
                />
            </div>
        );
    };

    const _navigate = (id: any, type: any) => {
        setDetailId(id);
        setShowDetail(true);
        setDetailType(type);
    };

    const _getItem = (item: any) => {
        let iitem = {
            icon: {},
            title: {},
            content: {},
            id: 0,
            link: "",
            type: ""
        };

        if (type === "companies") {
            let scontent = (
                <>
                    {i18n.t("form.label.betriebsId")}: {_highLightString(item.betriebsId+"")}
                    <br />
                    {i18n.t("form.label.strasse")}: {_highLightString(item.strasseNr)}
                    <br />
                    {i18n.t("form.label.plz")}: {_highLightString(item.plz)}
                    <br />
                    {i18n.t("form.label.ort")}: {_highLightString(item.ort)}
                    <br />
                </>
            );
            let title = [item.betriebsName];
            iitem = {
                icon: <i className="fal fa-industry-alt"></i>,
                title: _highLightString(title.join(" ")),
                content: scontent,
                id: item.betriebsId,
                type: props.type,
                link: getLinkPart(auth.userData, props.type) + "?id=" + enc(item.betriebsId)
            };
        } else if (type === "farmer") {
            let scontent = (
                <>
                    {i18n.t("form.label.strasse")}: {_highLightString(item.strasse)}
                    <br />
                    {i18n.t("form.label.plz")}: {_highLightString(item.plz)}
                    <br />
                    {i18n.t("form.label.ort")}: {_highLightString(item.ort)}
                    <br />
                    {i18n.t("form.label.betriebsId")}: {_highLightString(item.betriebsIds.join(", "))}
                    <br />
                </>
            );
            let title = [item.vorname, item.nachname, item.betriebsId];
            iitem = {
                icon: <i className="fal fa-tractor"></i>,
                title: _highLightString(title.join(" ")),
                content: scontent,
                id: item.landwirtId,
                type: props.type,
                link: getLinkPart(auth.userData, props.type) + "?id=" + enc(item.landwirtId)
            };
        } else if (type === "order") {
            let scontent = (
                <>
                    {i18n.t("form.label.firma")}: {_highLightString(item.firma)}
                    <br />
                    {i18n.t("form.label.strasse")}: {_highLightString(item.strasseNr)}
                    <br />
                    {i18n.t("form.label.plz")}: {_highLightString(item.postleitzahl)}
                    <br />
                    {i18n.t("form.label.ort")}: {_highLightString(item.ort)}
                    <br />
                    {i18n.t("form.label.Probennummer")}: {_highLightString(item.probenNummer)}
                    <br />
                    {i18n.t("form.label.betriebsId")}: {_highLightString(item.betriebsId.toString())}
                    <br />
                </>
            );
            let title = [item.vorname, item.nachname];
            iitem = {
                icon: <i className="fal fa-cogs"></i>,
                title: _highLightString(title.join(" ")),
                content: scontent,
                id: item.bestellId,
                type: props.type,
                link: getLinkPart(auth.userData, props.type) + "?id=" + enc(item.bestellId)
            };
        } else {
            let scontent = (
                <>
                    {i18n.t("form.label.firma")}: {_highLightString(item.firma)}
                    <br />
                    {i18n.t("form.label.strasse")}: {_highLightString(item.strasseNr)}
                    <br />
                    {i18n.t("form.label.plz")}: {_highLightString(item.postleitzahl)}
                    <br />
                    {i18n.t("form.label.ort")}: {_highLightString(item.ort)}
                    <br />
                    {i18n.t("form.label.Probennummer")}: {_highLightString(item.probenNummer)}
                    <br />
                    {i18n.t("form.label.betriebsId")}: {_highLightString(item.betriebsId.toString())}
                    <br />
                </>
            );
            let title = [item.vorname, item.nachname];
            iitem = {
                icon: <i className="fal fa-book"></i>,
                title: _highLightString(title.join(" ")),
                content: scontent,
                id: item.bestellId,
                type: props.type,
                link: getLinkPart(auth.userData, props.type) + "?id=" + enc(item.bestellId)
            };
        }

        return {
            sitem: iitem,
            item: item
        };
    };

    const _highLightString = (str: any) => {
        return <Highlighter highlightClassName="globalsearch-highlighter" searchWords={[searchString]} autoEscape={true} textToHighlight={str} />;
    };

    const _getListItems = () => {
        let items: any = [];
        for (let i = 0; i < listdata.length; i++) {
            let item = _getItem(listdata[i]);
            items.push(item.sitem);
        }
        return items;
    };
    return (
        <div>
            {detailId !== "undefined" ? (
                <Drawer
                    width={0}
                    title=""
                    placement="right"
                    onClose={() => {
                        setShowDetail(false);
                        setDetailId(undefined);
                    }}
                    visible={showDetail}
                    destroyOnClose={true}
                >
                    {detailType === "farmer" ? (
                        <Farmer
                            id={detailId}
                            onDetailClose={() => {
                                setShowDetail(false);
                                setDetailId(undefined);
                                setDetailType(undefined);
                            }}
                        />
                    ) : null}
                    {detailType === "companies" ? (
                        <Companies
                            id={detailId}
                            onDetailClose={() => {
                                setShowDetail(false);
                                setDetailId(undefined);
                                setDetailType(undefined);
                            }}
                        />
                    ) : null}
                    {detailType === "order" ? (
                        <Order
                            id={detailId}
                            onDetailClose={() => {
                                setShowDetail(false);
                                setDetailId(undefined);
                                setDetailType(undefined);
                            }}
                        />
                    ) : null}
                    {detailType === "labor" ? (
                        <Laborbuch
                            id={detailId}
                            onDetailClose={() => {
                                setShowDetail(false);
                                setDetailId(undefined);
                                setDetailType(undefined);
                            }}
                        />
                    ) : null}
                </Drawer>
            ) : null}
            {_getPagination()}
            {loading ? (
                <LoadMask />
            ) : listdata.length === 0 ? (
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
            ) : (
                _getListItems().map((item: any, i: number) => {
                    let vlink = document.location.protocol + "//" + document.location.hostname + item.link;
                    return (
                        <div
                            key={i}
                            className="globalsearch-item"
                            onClick={() => {
                                _navigate(item.id, item.type);
                            }}
                            title={vlink}
                        >
                            <div className="globalsearch-item-content-wrap">
                                <div className="globalsearch-item-icon-wrap">{item.icon}</div>
                                <div className="globalsearch-item-title">{item.title}</div>
                            </div>
                            {/* <div className="globalsearch-item-content">{item.content}</div> */}
                            <div className="globalsearch-item-content">{item.content}</div>
                            {/* <div className="globalsearch-item-link">{vlink}</div> */}
                        </div>
                    );
                })
            )}
            {_getPagination()}
        </div>
    );
};

export default GlobalSearchPage;
