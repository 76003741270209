/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    PageRegion,
    PageRegionFromJSON,
    PageRegionToJSON,
    Region,
    RegionFromJSON,
    RegionToJSON,
} from '../models';

export interface CreateRegionRequest {
    region: Region;
}

export interface DeleteRegionRequest {
    ids: Array<number>;
}

export interface FindByIdRequest {
    regionId: number;
}

export interface FindFilteredRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindForBundeslaenderIsosRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    iso?: Array<string>;
    startDate?: Date;
    endDate?: Date;
    search?: string;
}

export interface FindRegionForClientIdsRequest {
    clientIds: Array<string>;
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface UpdateRegionRequest {
    regionId: number;
    body: string;
}

/**
 * 
 */
export class RegionControllerApi extends runtime.BaseAPI {

    /**
     */
    async createRegionRaw(requestParameters: CreateRegionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Region>> {
        if (requestParameters.region === null || requestParameters.region === undefined) {
            throw new runtime.RequiredError('region','Required parameter requestParameters.region was null or undefined when calling createRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegionToJSON(requestParameters.region),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegionFromJSON(jsonValue));
    }

    /**
     */
    async createRegion(requestParameters: CreateRegionRequest, initOverrides?: RequestInit): Promise<Region> {
        const response = await this.createRegionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteRegionRaw(requestParameters: DeleteRegionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling deleteRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/del/{ids}`.replace(`{${"ids"}}`, encodeURIComponent(String(requestParameters.ids))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteRegion(requestParameters: DeleteRegionRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteRegionRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findByIdRaw(requestParameters: FindByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Region>> {
        if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
            throw new runtime.RequiredError('regionId','Required parameter requestParameters.regionId was null or undefined when calling findById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/{regionId}`.replace(`{${"regionId"}}`, encodeURIComponent(String(requestParameters.regionId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => RegionFromJSON(jsonValue));
    }

    /**
     */
    async findById(requestParameters: FindByIdRequest, initOverrides?: RequestInit): Promise<Region> {
        const response = await this.findByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFilteredRaw(requestParameters: FindFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageRegion>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageRegionFromJSON(jsonValue));
    }

    /**
     */
    async findFiltered(requestParameters: FindFilteredRequest = {}, initOverrides?: RequestInit): Promise<PageRegion> {
        const response = await this.findFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findForBundeslaenderIsosRaw(requestParameters: FindForBundeslaenderIsosRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Region>>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.iso) {
            queryParameters['iso'] = requestParameters.iso;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/regionenForBundeslaenderIsos/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(RegionFromJSON));
    }

    /**
     */
    async findForBundeslaenderIsos(requestParameters: FindForBundeslaenderIsosRequest = {}, initOverrides?: RequestInit): Promise<Array<Region>> {
        const response = await this.findForBundeslaenderIsosRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findRegionForClientIdsRaw(requestParameters: FindRegionForClientIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageRegion>> {
        if (requestParameters.clientIds === null || requestParameters.clientIds === undefined) {
            throw new runtime.RequiredError('clientIds','Required parameter requestParameters.clientIds was null or undefined when calling findRegionForClientIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.clientIds) {
            queryParameters['clientIds'] = requestParameters.clientIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/findRegionForClientIds/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageRegionFromJSON(jsonValue));
    }

    /**
     */
    async findRegionForClientIds(requestParameters: FindRegionForClientIdsRequest, initOverrides?: RequestInit): Promise<PageRegion> {
        const response = await this.findRegionForClientIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRegionRaw(requestParameters: UpdateRegionRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.regionId === null || requestParameters.regionId === undefined) {
            throw new runtime.RequiredError('regionId','Required parameter requestParameters.regionId was null or undefined when calling updateRegion.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateRegion.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/region/{regionId}`.replace(`{${"regionId"}}`, encodeURIComponent(String(requestParameters.regionId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateRegion(requestParameters: UpdateRegionRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateRegionRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
