/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CredentialRepresentation,
    CredentialRepresentationFromJSON,
    CredentialRepresentationFromJSONTyped,
    CredentialRepresentationToJSON,
} from './CredentialRepresentation';
import {
    FederatedIdentityRepresentation,
    FederatedIdentityRepresentationFromJSON,
    FederatedIdentityRepresentationFromJSONTyped,
    FederatedIdentityRepresentationToJSON,
} from './FederatedIdentityRepresentation';
import {
    SocialLinkRepresentation,
    SocialLinkRepresentationFromJSON,
    SocialLinkRepresentationFromJSONTyped,
    SocialLinkRepresentationToJSON,
} from './SocialLinkRepresentation';
import {
    UserConsentRepresentation,
    UserConsentRepresentationFromJSON,
    UserConsentRepresentationFromJSONTyped,
    UserConsentRepresentationToJSON,
} from './UserConsentRepresentation';

/**
 * 
 * @export
 * @interface UserRepresentation
 */
export interface UserRepresentation {
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    self?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    origin?: string;
    /**
     * 
     * @type {number}
     * @memberof UserRepresentation
     */
    createdTimestamp?: number;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    username?: string;
    /**
     * 
     * @type {boolean}
     * @memberof UserRepresentation
     */
    enabled?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRepresentation
     */
    totp?: boolean;
    /**
     * 
     * @type {boolean}
     * @memberof UserRepresentation
     */
    emailVerified?: boolean;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    firstName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    lastName?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    federationLink?: string;
    /**
     * 
     * @type {string}
     * @memberof UserRepresentation
     */
    serviceAccountClientId?: string;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserRepresentation
     */
    attributes?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<CredentialRepresentation>}
     * @memberof UserRepresentation
     */
    credentials?: Array<CredentialRepresentation>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRepresentation
     */
    disableableCredentialTypes?: Array<string>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRepresentation
     */
    requiredActions?: Array<string>;
    /**
     * 
     * @type {Array<FederatedIdentityRepresentation>}
     * @memberof UserRepresentation
     */
    federatedIdentities?: Array<FederatedIdentityRepresentation>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRepresentation
     */
    realmRoles?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserRepresentation
     */
    clientRoles?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<UserConsentRepresentation>}
     * @memberof UserRepresentation
     */
    clientConsents?: Array<UserConsentRepresentation>;
    /**
     * 
     * @type {number}
     * @memberof UserRepresentation
     */
    notBefore?: number;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof UserRepresentation
     */
    applicationRoles?: { [key: string]: Array<string>; };
    /**
     * 
     * @type {Array<SocialLinkRepresentation>}
     * @memberof UserRepresentation
     */
    socialLinks?: Array<SocialLinkRepresentation>;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserRepresentation
     */
    groups?: Array<string>;
    /**
     * 
     * @type {{ [key: string]: boolean; }}
     * @memberof UserRepresentation
     */
    access?: { [key: string]: boolean; };
}

export function UserRepresentationFromJSON(json: any): UserRepresentation {
    return UserRepresentationFromJSONTyped(json, false);
}

export function UserRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'self': !exists(json, 'self') ? undefined : json['self'],
        'id': !exists(json, 'id') ? undefined : json['id'],
        'origin': !exists(json, 'origin') ? undefined : json['origin'],
        'createdTimestamp': !exists(json, 'createdTimestamp') ? undefined : json['createdTimestamp'],
        'username': !exists(json, 'username') ? undefined : json['username'],
        'enabled': !exists(json, 'enabled') ? undefined : json['enabled'],
        'totp': !exists(json, 'totp') ? undefined : json['totp'],
        'emailVerified': !exists(json, 'emailVerified') ? undefined : json['emailVerified'],
        'firstName': !exists(json, 'firstName') ? undefined : json['firstName'],
        'lastName': !exists(json, 'lastName') ? undefined : json['lastName'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'federationLink': !exists(json, 'federationLink') ? undefined : json['federationLink'],
        'serviceAccountClientId': !exists(json, 'serviceAccountClientId') ? undefined : json['serviceAccountClientId'],
        'attributes': !exists(json, 'attributes') ? undefined : json['attributes'],
        'credentials': !exists(json, 'credentials') ? undefined : ((json['credentials'] as Array<any>).map(CredentialRepresentationFromJSON)),
        'disableableCredentialTypes': !exists(json, 'disableableCredentialTypes') ? undefined : json['disableableCredentialTypes'],
        'requiredActions': !exists(json, 'requiredActions') ? undefined : json['requiredActions'],
        'federatedIdentities': !exists(json, 'federatedIdentities') ? undefined : ((json['federatedIdentities'] as Array<any>).map(FederatedIdentityRepresentationFromJSON)),
        'realmRoles': !exists(json, 'realmRoles') ? undefined : json['realmRoles'],
        'clientRoles': !exists(json, 'clientRoles') ? undefined : json['clientRoles'],
        'clientConsents': !exists(json, 'clientConsents') ? undefined : ((json['clientConsents'] as Array<any>).map(UserConsentRepresentationFromJSON)),
        'notBefore': !exists(json, 'notBefore') ? undefined : json['notBefore'],
        'applicationRoles': !exists(json, 'applicationRoles') ? undefined : json['applicationRoles'],
        'socialLinks': !exists(json, 'socialLinks') ? undefined : ((json['socialLinks'] as Array<any>).map(SocialLinkRepresentationFromJSON)),
        'groups': !exists(json, 'groups') ? undefined : json['groups'],
        'access': !exists(json, 'access') ? undefined : json['access'],
    };
}

export function UserRepresentationToJSON(value?: UserRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'self': value.self,
        'id': value.id,
        'origin': value.origin,
        'createdTimestamp': value.createdTimestamp,
        'username': value.username,
        'enabled': value.enabled,
        'totp': value.totp,
        'emailVerified': value.emailVerified,
        'firstName': value.firstName,
        'lastName': value.lastName,
        'email': value.email,
        'federationLink': value.federationLink,
        'serviceAccountClientId': value.serviceAccountClientId,
        'attributes': value.attributes,
        'credentials': value.credentials === undefined ? undefined : ((value.credentials as Array<any>).map(CredentialRepresentationToJSON)),
        'disableableCredentialTypes': value.disableableCredentialTypes,
        'requiredActions': value.requiredActions,
        'federatedIdentities': value.federatedIdentities === undefined ? undefined : ((value.federatedIdentities as Array<any>).map(FederatedIdentityRepresentationToJSON)),
        'realmRoles': value.realmRoles,
        'clientRoles': value.clientRoles,
        'clientConsents': value.clientConsents === undefined ? undefined : ((value.clientConsents as Array<any>).map(UserConsentRepresentationToJSON)),
        'notBefore': value.notBefore,
        'applicationRoles': value.applicationRoles,
        'socialLinks': value.socialLinks === undefined ? undefined : ((value.socialLinks as Array<any>).map(SocialLinkRepresentationToJSON)),
        'groups': value.groups,
        'access': value.access,
    };
}

