import React from 'react';
import { Form, Row, Col, DatePicker, Button } from 'antd';
import moment from 'moment';
import i18n from '../../../misc/i18n';
import { conf } from '../../../../_conf';
import { withAuth } from "oidc-react";
import FsFrame from '../reportbuilder/fsframe';
import LandSelectForm from './landselectform';
import BundeslandSelectForm from './bundeslandselectform';
import RegionSelectForm from './regionselectform';
import PlzSelectForm from './plzselectform';
import AnalyseSelectForm from './analyseartselectform';
import ProbenartSelectForm from './probenartselectform';
import report from './_laborstatistik';

class Laborstatistik extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            baseUri: conf.uri.reports,
            reportUri: '',
            generatedUri: '',
            values: {},
            buttonDisabled: true,
            rerenderStamp: new Date().getTime(),
            isFullScreen: false,
            form: React.createRef(),
            formFields: report.formItems,
            formVisible: true,
            disabledFields: {
                probenart: true,
                land: false,
                bundesland: true,
                region: true,
                plz: true,
                analyseart: true,
                datum: false
            },
            language: ''
        };
    }

    ///ReportServer/Pages/ReportViewer.aspx?/Grenzwerte GrassSilage&CompanyId=80&EndDate=24.02.2021&Perimeter=10&StartDate=24.02.2019&Type=Gras_silage&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer
    setUri() {
        let query = '/ReportServer/Pages/ReportViewer.aspx?/Laborbuchstatistik&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer';
        let fields = this.state.formFields;
        let modified = 0;
        if (fields[0].value.length > 0) {
            if (fields[0].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[0].value.forEach((itm) => {
                    query += '&Land=' + itm.replace("+","%2B");
                });
                modified++;
            }
        }
        if (fields[1].value.length > 0) {
            if (fields[1].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[1].value.forEach((itm) => {
                    query += '&State=' + itm.replace("+","%2B");
                });
                modified++;
            }
        }
        if (fields[2].value?.length > 0) {
            query += '&StartDate=' + moment(fields[2].value[0]).format('YYYY-MM-DD');
            query += '&EndDate=' + moment(fields[2].value[1]).format('YYYY-MM-DD');
            modified++;
        }
        if (fields[3].value.length > 0) {
            if (fields[3].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[3].value.forEach((itm) => {
                    query += '&Region=' + itm.replace("+","%2B");
                });
                modified++;
            }
        }
        if (fields[4].value.length > 0) {
            if (fields[4].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[4].value.forEach((itm) => {
                    query += '&Postcode=' + itm.replace("+","%2B");
                });
                modified++;
            }
        }
        if (fields[5].value.length > 0) {
            if (fields[5].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[5].value.forEach((itm) => {
                    query += '&Sampletype=' + itm.replace("+","%2B");
                });
                modified++;
            }
        }
        if (fields[6].value.length > 0) {
            if (fields[6].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[6].value.forEach((itm) => {
                    query += '&Analysistype=' + itm.replace("+","%2B");
                });
                modified++;
            }
        }

        let language = '';
        if (this.state.language === 'de') {
            language = 'German';
        } else if (this.state.language === 'cz') {
            language = 'Czech Republic';
        } else {
            language = 'English';
        }
        query += '&Language=' + language;
        if (modified === fields.length) {
            this.setState({
                buttonDisabled: false,
                generatedUri: query,
                reportUri: query
            });
        } else {
            this.setState({
                buttonDisabled: true,
                reportUri: '',
                generatedUri: query
            });
        }
    }

    componentDidUpdate() {
        if (i18n.language.substr(0,2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0,2)
                },
                () => {
                    this.setUri();
                }
            );
        }
    }

    setDisabledFields(clear = false) {
        let disObjs = {
            probenart: true,
            land: false,
            bundesland: true,
            region: true,
            plz: true,
            analyseart: true,
            datum: false
        };
        if (clear) {
            this.setState({
                disabledFields: disObjs
            });
            return;
        }
        let formresponse = this.state.formFields;
        //Land Prüfen
        if (formresponse[0] != null && formresponse[0].value?.length > 0) {
            disObjs.bundesland = false;
            //Bundesland prüfen
            if (formresponse[1] != null && formresponse[1].value?.length > 0) {
                disObjs.region = false;
                //Region prüfen
                if (formresponse[3] != null && formresponse[3].value?.length > 0) {
                    disObjs.plz = false;
                    //PLZ prüfen
                    if (formresponse[4] != null && formresponse[4].value?.length > 0) {
                        disObjs.probenart = false;
                        //Probenart prüfen
                        if (formresponse[5] != null && formresponse[5].value?.length > 0) {
                            disObjs.analyseart = false;
                        }
                    }
                }
            }

            this.setState({
                disabledFields: disObjs
            });
            return;
        }
        this.setState({
            disabledFields: disObjs
        });
    }

    onChange = (newFields) => {
        this.setState(
            {
                formFields: newFields
            },
            () => {
                this.setDisabledFields();
                this.setUri();
            }
        );
    };
    render() {
        let disabled = this.state.disabledFields;
        let startDate = this.state.formFields[2].value[0] != null ? moment(this.state.formFields[2].value[0]).startOf("day") : undefined
        let endDate = this.state.formFields[2].value[1] != null ? moment(this.state.formFields[2].value[1]).endOf("day") : undefined
        return (
            <>
                <div className="list-detail-wrap">
                    <Row>
                        
                        <Col span={24}>
                            <div className={(this.state.formVisible) ? "report-form" : "report-form hidden"}>
                                <Form
                                    ref={this.state.form}
                                    size="small"
                                    fields={this.state.formFields}
                                    onFieldsChange={(_, allFields) => {
                                        this.onChange(allFields);
                                    }}
                                >
                                    <Row gutter={24} >
                                        <Col span={9} key={1}>
                                            <Row>
                                                <Col span={4}>
                                                    <div>{i18n.t('form.label.land') + ' :'}</div>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name="land" style={{marginBottom: "12px"}}>
                                                        <LandSelectForm startDate={startDate} endDate={endDate} defaultValue={[i18n.t('order.probenart.all')]} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>

                                        <Col span={9} key={2} >
                                            <Row>
                                                <Col span={5}>
                                                    <div>{i18n.t('form.label.bundesland') + ' :'}</div>
                                                </Col>
                                                <Col span={19}>
                                                    <Form.Item name="bundesland" style={{marginBottom: "12px"}}>
                                                        <BundeslandSelectForm
                                                            startDate={startDate} 
                                                            endDate={endDate} 
                                                            disabled={disabled.bundesland}
                                                            land={this.state.formFields[0].value}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={6} key={7}>
                                            <Row>
                                                <Col span={5}>
                                                    <div>{i18n.t('form.label.Datum') + ' :'}</div>
                                                </Col>
                                                <Col span={19}>
                                                    <Form.Item name="datum" style={{marginBottom: "12px"}}>
                                                        <DatePicker.RangePicker allowClear={false} disabled={disabled.datum} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={9} key={3} >
                                            <Row>
                                                <Col span={4}>
                                                    <div>{i18n.t('form.label.regionen') + ' :'}</div>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name="region" style={{marginBottom: "12px"}}>
                                                        <RegionSelectForm
                                                            startDate={startDate} 
                                                            endDate={endDate} 
                                                            land={this.state.formFields[0].value}
                                                            state={this.state.formFields[1].value}
                                                            disabled={disabled.region}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={9} key={4}>
                                            <Row> 
                                                <Col span={5}>
                                                    <div>{i18n.t('form.label.plz') + ' :'}</div>
                                                </Col>
                                                <Col span={19}>
                                                    <Form.Item name="plz" style={{marginBottom: "12px"}}>
                                                        <PlzSelectForm
                                                            startDate={startDate} 
                                                            endDate={endDate} 
                                                            land={this.state.formFields[0].value}
                                                            state={this.state.formFields[1].value}
                                                            region={this.state.formFields[3].value}
                                                            disabled={disabled.plz}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={6} key={15} ></Col>
                                        <Col span={9} key={5} >
                                            <Row>
                                                <Col span={4}>
                                                    <div>{i18n.t('form.label.probenart') + ' :'}</div>
                                                </Col>
                                                <Col span={20}>
                                                    <Form.Item name="type" style={{marginBottom: "12px"}}>
                                                        <ProbenartSelectForm
                                                            startDate={startDate} 
                                                            endDate={endDate} 
                                                            disabled={disabled.probenart}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                            land={this.state.formFields[0].value}
                                                            state={this.state.formFields[1].value}
                                                            plz={this.state.formFields[4].value}
                                                            region={this.state.formFields[3].value}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={9} key={6} >
                                            
                                            <Row>
                                                <Col span={5}>
                                                    <div>{i18n.t('form.label.analyseTyp')+ ' :'}</div>
                                                </Col>
                                                <Col span={19}>
                                                <Form.Item name="analyseart" style={{marginBottom: "12px"}}>
                                                <AnalyseSelectForm
                                                    startDate={startDate} 
                                                    endDate={endDate} 
                                                    disabled={disabled.analyseart}
                                                    defaultValue={[i18n.t('order.probenart.all')]}
                                                    land={this.state.formFields[0].value}
                                                    state={this.state.formFields[1].value}
                                                    plz={this.state.formFields[4].value}
                                                    region={this.state.formFields[3].value}
                                                    probenart={this.state.formFields[5].value}
                                                />
                                            </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="form-collapse-wrap">
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            formVisible: !this.state.formVisible
                                        })
                                    }}
                                >
                                    {this.state.formVisible ? <i className="fal fa-chevron-square-up"></i> : <i className="fal fa-chevron-square-down"></i>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FsFrame uri={this.state.reportUri} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withAuth(Laborstatistik);
