function getLinkPart(userData: any, type: any){
    if(userData !== null || userData !== undefined) {
        if(userData.profile.groups.indexOf('Administrator') >= 0) {
            if(type === "labor"){
                return "/labor/orders/"
            }else{
                return "/administration/" + type + "/"
            }
        }
        if(userData.profile.groups.indexOf('Consultant') >= 0) {
            return "/support/" + type + "/"
        }
        if(userData.profile.groups.indexOf('Farmer') >= 0) {
            return "/farmer/" + type + "/"
        }
        if(userData.profile.groups.indexOf('BusinessAdministrator') >= 0) {
            return "/businessadmin/" + type + "/"
        }
        // if(userData.profile.groups.indexOf('Extern') >= 0) {
        //     return "/businessadmin/" + type + "/"
        // }
        // if(userData.profile.groups.indexOf('Labor') >= 0) {
        //     return "/businessadmin/" + type + "/"
        // }
        if(userData.profile.groups.indexOf('Labor') >= 0) {
            if(type === "labor"){
                return "/labor/orders/"
            }else{
                return "/administration/" + type + "/"
            }
        }        
    }
    return false;
}

export { getLinkPart };