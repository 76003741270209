import React from "react";
import ajax from "./../../../misc/ajax";
import i18n from "./../../../misc/i18n";
import { Table, notification } from "antd";
import colrenderer from "./colrenderer";
import colfilters from "./colfilters";
import {Filter} from "../../../definitions/FilterEnum.ts"
const queryString = require('query-string');

export default class DataTable extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: 10,
            },
			params: {
                pagination: {
                    current: 1,
                    pageSize: 10,
                },
                sorter: {},
                filter: {}
            },
            qustr: ""
        }
    }

    componentDidMount(){
        this._doQuery();
    }

    _getFilterType(dataIndex){
        for(let i=0; i<this.props.cols.length; i++){
            if(this.props.cols[i].dataIndex === dataIndex){
                return this.props.cols[i].filter;
            }
        }
        return null;
    }

    _doQuery(){
        let p = this.state.params;
        let obj = {
            page: p.pagination.current-1,
            size: p.pagination.pageSize
        }

        if(typeof p.sorter.field !== "undefined"){
            let ts = {};
            ts["sort"] = p.sorter.field;
            ts["sort"] += ",";
            ts["sort"] += (p.sorter.order === "ascend") ? "asc" : "desc";
            obj = Object.assign(obj, ts);
        }

        if(Object.keys(p.filter).length !== 0 && p.filter.constructor === Object){
            let tmpf = [];
            for(let i in p.filter){
                let ftype = this._getFilterType(i)
                if(ftype === "number"){
                    tmpf.push([i+'=='+p.filter[i].join(',')])
                }                
                if(ftype === "text"){
                    tmpf.push([i+'=='+p.filter[i].join(',')])
                }
                if(ftype === "list"){
                    if(p.filter[i][0].length > 0){
                        tmpf.push([i+'=in=('+p.filter[i].join(',')+')'])
                    }
                }
                if(ftype === "truefalse"){
                    if(p.filter[i][0].length > 0){
                        tmpf.push([i+'=in=('+p.filter[i].join(',')+')'])
                    }
                }                
                if(ftype === "daterange"){
                    tmpf.push([i+'=ge='+p.filter[i][0][0].format("YYYYMMDDHHmmss")])
                    tmpf.push([i+'=le='+p.filter[i][0][1].format("YYYYMMDDHHmmss")])
                }
            }
            
            if(tmpf.length > 0){
                obj = Object.assign(obj, { search: tmpf.join(';') })
            }
        }

        let qstr = queryString.stringify(obj, {arrayFormat: 'bracket', sort: false, encode:false})

        this.setState({
            qustr: qstr
        },() => {
            if(typeof this.props.data !== "undefined"){
                let data = [];
                for(let i=0; i<this.props.data.length; i++){
                    data.push(
                        Object.assign(this.props.data[i], {key: i})
                    )
                }
                this.setState({
                    data: this.props.data
                })
            }else{
                this.fetchData()
            }
            
        })
    }

    handleTableChange(pagination, filter, sorter){
		let tfilter = {};
		let tsorter = {};
		for(let i in filter){
			if(filter[i] != null){
				tfilter[i] = filter[i]
			}
		}
		if(typeof sorter.order !== "undefined"){
			tsorter = {
				field: sorter.field,
				order: sorter.order
			}
		}

		let params = {
			pagination: pagination, 
			filter: tfilter, 
			sorter: tsorter
        }
        
        this.setState({
            params: params,
            pagination: pagination,
            filter: filter,
            sorter: sorter
        },() => {
            this._doQuery();
        });
    }

    _flattenObject(ob) {
        var toReturn = {};
        for (var i in ob) {
            if(!ob.hasOwnProperty(i)){ continue };
            if((typeof ob[i]) === 'object'){
                var flatObject = this._flattenObject(ob[i]);
                for(var x in flatObject) {
                    if(!flatObject.hasOwnProperty(x)){ continue };
                    toReturn[i + '.' + x] = flatObject[x];
                }
            }else{
                toReturn[i] = ob[i];
            }
        }
        return toReturn;
    };    

    _convertData(rawdata){
        let data = [];
        for(let i=0; i<rawdata.length; i++){
            data.push(Object.assign(rawdata[i], this._flattenObject(rawdata[i])));
        }
        return data;
    }

    async fetchData() {
        this.setState({
			loading: true,
		})
		
        await ajax.tRequest(this.props.uri+'?'+this.state.qustr, "GET", {}, {}, false, this.props.token)
        .then((response) => {
            if(typeof response.error === "undefined"){
                let data = [];
                let cdata = [];
                if(typeof response.content !== "undefined"){
                    cdata = this._convertData(response.content)
                }
				for(let i=0; i<cdata.length; i++){
					data.push(Object.assign(cdata[i], {key:i}))
				}
                this.setState({
					data: data,
					total: response.totalElements,
					pagination: {
                        total: response.totalElements,
                        pageSize: this.state.pagination.pageSize
					},
                    loading: false
                })
            }
        })
        .catch((error) => {
            this.setState({
                loading: false
            },() => {
                notification.error({
                    message: i18n.t("ajax.error")
                });
            })
        });
    }

	getColRenderer(col){
		return(colrenderer.render(col));
    }

	getColFilter(col){
        if(col.filter  === Filter.LIST){
            return(colfilters.render(col));
        }else if(col.filter === Filter.BOOLEAN){
            return(colfilters.render(col));
        }else if(col.filter === Filter.TEXT){
            return(colfilters.render(col));
        }else if(col.filter === Filter.DATERANGE){
            return(colfilters.render(col));
        }else if(col.filter === Filter.NUMBER){
            return(colfilters.render(col));
        }else if(col.filter === Filter.PROBENART){
            return(colfilters.render(col));
        }
        return col;
	}    

	getColSorter(col){
        return({sorter: true});
	} 

	doColumns(cols){
		cols = JSON.parse(JSON.stringify(cols));
		let ret = [];
		for(let i=0; i<cols.length; i++){
            let col = cols[i];
            let label = i18n.t("table.col.title." + col.title);
            col.title = label;
            col.label = label;
			if(typeof col.renderer !== "undefined"){
				Object.assign(col, this.getColRenderer(col))
			}
			if(typeof col.filter !== "undefined"){
				Object.assign(col, this.getColFilter(col))
            }
			if(typeof col.sort !== "undefined"){
				Object.assign(col, this.getColSorter(col))
            }
			ret.push(col);
		}
		return ret;
	}    

    render(){
        return(
            <div>
                <Table 
                    columns={(typeof this.props.cols !== "undefined") ? this.doColumns(this.props.cols) : []} 
                    loading={this.state.loading}
                    expandable={(typeof this.props.expandable !== "undefined") ? this.props.expandable : null}
                    size={(typeof this.props.cols !== "undefined") ? this.props.size : "default"}
                    dataSource={this.state.data} 
                    pagination={this.state.pagination}
                    scroll={{ 
                        // y: 440,
                        // scrollToFirstRowOnChange: true
                    }}

                    onChange={this.handleTableChange.bind(this)}
                    onRow={(record, index) => {
                        return {
                            onClick: e => {
                                if(this.props.onRowSelection){
                                    this.props.onRowSelection(record);
                                }
                            }
                          };
                    }}
                />
                {/* DEBUG */}
                <hr/>
                <a href={this.props.uri+'?'+this.state.qustr} target="_blank" rel="noopener noreferrer">{this.props.uri+'?'+this.state.qustr}</a>
            </div>
        )
    }
}