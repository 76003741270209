import React from "react";
import { Button, Input, Row, Col, Checkbox } from "antd";
// import BundeslandRender from "./../../../portal/sano/datatable/ProbenartRenderer";
// import ProbenartRenderer from "./../../../portal/sano/datatable/ProbenartRenderer";
import i18n from './../../../misc/i18n';
export default class ListFilter extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            showFilter: false,
            value: []
        }
    }

    render(){
        let cvalue = [];
        let dvalue = [];
        if(typeof(this.props.value) !== "undefined" && this.props.value !== null){
            let value = this.props.value;
            if(Array.isArray(value[0])){
                if(Array.isArray(value[0][0])){
                    cvalue = value[0][0]
                    for(let i=0; i<value[0][0].length; i++){
                        let v = (this.props.renderer === "bestellstatus") ? i18n.t("order.status."+value[0][0][i]) : value[0][0][i]
                        dvalue.push(v)
                    }
                }else{
                    cvalue = value[0]
                    for(let i=0; i<value[0].length; i++){
                        let v = (this.props.renderer === "bestellstatus") ? i18n.t("order.status."+value[0][i]) : value[0][i]
                        dvalue.push(v)
                    }
                }
            }
        }

        return(
            <>
                <div className="customfilter-wrap">
                    <Button onClick={() => {
                        this.setState({
                            showFilter: !this.state.showFilter
                        })
                    }}>
                        {this.props.placeholder}
                    </Button> 
                    <Input className="customfilter-value-wrap" value={dvalue.join(", ")} disabled/>
                </div>
                {(this.state.showFilter) 
                    ? 
                        <Checkbox.Group
                            style={{ width: '100%' }}
                            defaultValue={cvalue}
                            onChange={(e) => {
                                if(this.props.onChange){
                                    this.props.onChange(e ? [e] : null)
                                }
                            }}
                            ref={(node) => {
                                this.filterInput = node;
                            }}
                        >
                            <Row>
                                {this.props.filter_list.map((item, i) => (
                                    <Col span={24} key={i}>
                                        <Checkbox value={item}>
                                            {this.props.renderer === "bestellstatus"? i18n.t("order.status."+item) : item}
                                        </Checkbox>
                                    </Col>
                                ))}
                            </Row>
                        </Checkbox.Group>

                    : null
                }
            </>
        )        
    }

}