import {Filter} from '../../../definitions/FilterEnum'
import {Renderer} from '../../../definitions/RendererEnum'

import React from "react";
const importstatus = {
	"expandable": true,
    "columns": [
		{
        	"title": "id",
			"dataIndex": "importJobId",
			"align": "left",
			"filter": Filter.NUMBER,
			"sort": true,
			"responsive": ["lg"],
			"width":"6%"
        },
        {
			"title": "date",
			"dataIndex": "tsBeendet",
			"renderer": Renderer.RENDERDATETIME,
			"align":"left",
			"width":"11%",
			"filter": Filter.DATERANGE,
			"sort": true,
		},
		{
			"title": "betriebsId",
			"dataIndex": "euNummer",
			"align": "left",
			"filter": Filter.TEXT,
			"sort": true,
			// "width":"10%",
			// "responsive": ["lg"]
		},
		{
			"title": "system",
			"dataIndex": "systemName",
			"align": "left",
			"filter": Filter.LIST,
			"filter_list": ["CVAS", "ProbenB","LKV"],
			"sort": true,
			"width":"8%"			
		},
		{
			"title": "status",
			"dataIndex": "status",
			"renderer": Renderer.RENDERSTATUS,
			"align": "left",
			"filter": Filter.LIST,
			"width":"10%",
			"filter_list": ["ERFOLGREICH", "FEHLER", "GESTARTET", "NEU","WARNUNG"],
			"sort": true,
		},
		{
			"title": "message",
			"dataIndex": "statusText",
			"filter": Filter.TEXT,
			"responsive": ["lg"],
			"ellipsis": true,
			"width":"44%"
		}
	],
	"renderTitle": (rec) => {
		return(
			rec.systemName + " (" + rec.importJobId + " | " + rec.status + ")"
		)
	},
	"renderRecordSub": (rec) => {
		return(
			<>
				{(typeof(rec.sdnImportJobErrors) !== "undefined") 
					?
						<ul>
							{rec.sdnImportJobErrors.map((item, i) => {
								return(
									<li key={i}>{item.errorText}</li>
								)
							})}
						</ul>
					:null
				}
			</>
		)
	},
}

export default importstatus;