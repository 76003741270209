/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    CvasDokumentOhneInhalt,
    CvasDokumentOhneInhaltFromJSON,
    CvasDokumentOhneInhaltFromJSONTyped,
    CvasDokumentOhneInhaltToJSON,
} from './CvasDokumentOhneInhalt';

/**
 * 
 * @export
 * @interface Probenbestellung
 */
export interface Probenbestellung {
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    berater?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    firma?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    telefon?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    email1?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    crmNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    postleitzahRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    vorname?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    probenart?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    vornameRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    ortsteilRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    schnitt?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    nachname?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    sdnNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    ort?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    ortsteil?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    firmaRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    landRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    erpNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    email2?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    strasseNr?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    land?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    nachnameRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    euNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    postleitzahl?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    probenNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    laborant?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    strasseNrRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    ortRe?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Probenbestellung
     */
    gratisProbe?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    bemerkung?: string;
    /**
     * 
     * @type {Date}
     * @memberof Probenbestellung
     */
    tsSanoEingang?: Date;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof Probenbestellung
     */
    tsChange?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Probenbestellung
     */
    tsLaborEingang?: Date;
    /**
     * 
     * @type {Array<CvasDokumentOhneInhalt>}
     * @memberof Probenbestellung
     */
    cvasDocumentList?: Array<CvasDokumentOhneInhalt>;
    /**
     * 
     * @type {number}
     * @memberof Probenbestellung
     */
    betriebsNummer?: number;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    probenBezeichnung?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    siliermittelbezeichnung?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    behandlungsForm?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    emailLaborUser?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    analyseTyp?: string;
    /**
     * 
     * @type {number}
     * @memberof Probenbestellung
     */
    bestellId?: number;
    /**
     * 
     * @type {number}
     * @memberof Probenbestellung
     */
    siliermittel?: number;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    uiInsert?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    hybridSorte?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    kundenNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    schnitthoehe?: string;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    ertrag?: string;
    /**
     * 
     * @type {Date}
     * @memberof Probenbestellung
     */
    tsProbennahme?: Date;
    /**
     * 
     * @type {Date}
     * @memberof Probenbestellung
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    silonummer?: string;
    /**
     * 
     * @type {number}
     * @memberof Probenbestellung
     */
    betriebsId?: number;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    tierArt?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Probenbestellung
     */
    dsgvo?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    status?: string;
    /**
     * 
     * @type {Date}
     * @memberof Probenbestellung
     */
    tsCompleted?: Date;
    /**
     * 
     * @type {string}
     * @memberof Probenbestellung
     */
    labId?: string;
    /**
     * 
     * @type {number}
     * @memberof Probenbestellung
     */
    jahr?: number;
}

export function ProbenbestellungFromJSON(json: any): Probenbestellung {
    return ProbenbestellungFromJSONTyped(json, false);
}

export function ProbenbestellungFromJSONTyped(json: any, ignoreDiscriminator: boolean): Probenbestellung {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'berater': !exists(json, 'berater') ? undefined : json['berater'],
        'firma': !exists(json, 'firma') ? undefined : json['firma'],
        'telefon': !exists(json, 'telefon') ? undefined : json['telefon'],
        'email1': !exists(json, 'email1') ? undefined : json['email1'],
        'crmNummer': !exists(json, 'crmNummer') ? undefined : json['crmNummer'],
        'postleitzahRe': !exists(json, 'postleitzahRe') ? undefined : json['postleitzahRe'],
        'vorname': !exists(json, 'vorname') ? undefined : json['vorname'],
        'probenart': !exists(json, 'probenart') ? undefined : json['probenart'],
        'vornameRe': !exists(json, 'vornameRe') ? undefined : json['vornameRe'],
        'ortsteilRe': !exists(json, 'ortsteilRe') ? undefined : json['ortsteilRe'],
        'schnitt': !exists(json, 'schnitt') ? undefined : json['schnitt'],
        'nachname': !exists(json, 'nachname') ? undefined : json['nachname'],
        'sdnNummer': !exists(json, 'sdnNummer') ? undefined : json['sdnNummer'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'ortsteil': !exists(json, 'ortsteil') ? undefined : json['ortsteil'],
        'firmaRe': !exists(json, 'firmaRe') ? undefined : json['firmaRe'],
        'landRe': !exists(json, 'landRe') ? undefined : json['landRe'],
        'erpNummer': !exists(json, 'erpNummer') ? undefined : json['erpNummer'],
        'email2': !exists(json, 'email2') ? undefined : json['email2'],
        'strasseNr': !exists(json, 'strasseNr') ? undefined : json['strasseNr'],
        'land': !exists(json, 'land') ? undefined : json['land'],
        'nachnameRe': !exists(json, 'nachnameRe') ? undefined : json['nachnameRe'],
        'euNummer': !exists(json, 'euNummer') ? undefined : json['euNummer'],
        'postleitzahl': !exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'probenNummer': !exists(json, 'probenNummer') ? undefined : json['probenNummer'],
        'laborant': !exists(json, 'laborant') ? undefined : json['laborant'],
        'strasseNrRe': !exists(json, 'strasseNrRe') ? undefined : json['strasseNrRe'],
        'ortRe': !exists(json, 'ortRe') ? undefined : json['ortRe'],
        'gratisProbe': !exists(json, 'gratisProbe') ? undefined : json['gratisProbe'],
        'bemerkung': !exists(json, 'bemerkung') ? undefined : json['bemerkung'],
        'tsSanoEingang': !exists(json, 'tsSanoEingang') ? undefined : (new Date(json['tsSanoEingang'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'tsLaborEingang': !exists(json, 'tsLaborEingang') ? undefined : (new Date(json['tsLaborEingang'])),
        'cvasDocumentList': !exists(json, 'cvasDocumentList') ? undefined : ((json['cvasDocumentList'] as Array<any>).map(CvasDokumentOhneInhaltFromJSON)),
        'betriebsNummer': !exists(json, 'betriebsNummer') ? undefined : json['betriebsNummer'],
        'probenBezeichnung': !exists(json, 'probenBezeichnung') ? undefined : json['probenBezeichnung'],
        'siliermittelbezeichnung': !exists(json, 'siliermittelbezeichnung') ? undefined : json['siliermittelbezeichnung'],
        'behandlungsForm': !exists(json, 'behandlungsForm') ? undefined : json['behandlungsForm'],
        'emailLaborUser': !exists(json, 'emailLaborUser') ? undefined : json['emailLaborUser'],
        'analyseTyp': !exists(json, 'analyseTyp') ? undefined : json['analyseTyp'],
        'bestellId': !exists(json, 'bestellId') ? undefined : json['bestellId'],
        'siliermittel': !exists(json, 'siliermittel') ? undefined : json['siliermittel'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'hybridSorte': !exists(json, 'hybridSorte') ? undefined : json['hybridSorte'],
        'kundenNummer': !exists(json, 'kundenNummer') ? undefined : json['kundenNummer'],
        'schnitthoehe': !exists(json, 'schnitthoehe') ? undefined : json['schnitthoehe'],
        'ertrag': !exists(json, 'ertrag') ? undefined : json['ertrag'],
        'tsProbennahme': !exists(json, 'tsProbennahme') ? undefined : (new Date(json['tsProbennahme'])),
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'silonummer': !exists(json, 'silonummer') ? undefined : json['silonummer'],
        'betriebsId': !exists(json, 'betriebsId') ? undefined : json['betriebsId'],
        'tierArt': !exists(json, 'tierArt') ? undefined : json['tierArt'],
        'dsgvo': !exists(json, 'dsgvo') ? undefined : json['dsgvo'],
        'status': !exists(json, 'status') ? undefined : json['status'],
        'tsCompleted': !exists(json, 'tsCompleted') ? undefined : (new Date(json['tsCompleted'])),
        'labId': !exists(json, 'labId') ? undefined : json['labId'],
        'jahr': !exists(json, 'jahr') ? undefined : json['jahr'],
    };
}

export function ProbenbestellungToJSON(value?: Probenbestellung | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'berater': value.berater,
        'firma': value.firma,
        'telefon': value.telefon,
        'email1': value.email1,
        'crmNummer': value.crmNummer,
        'postleitzahRe': value.postleitzahRe,
        'vorname': value.vorname,
        'probenart': value.probenart,
        'vornameRe': value.vornameRe,
        'ortsteilRe': value.ortsteilRe,
        'schnitt': value.schnitt,
        'nachname': value.nachname,
        'sdnNummer': value.sdnNummer,
        'email': value.email,
        'ort': value.ort,
        'ortsteil': value.ortsteil,
        'firmaRe': value.firmaRe,
        'landRe': value.landRe,
        'erpNummer': value.erpNummer,
        'email2': value.email2,
        'strasseNr': value.strasseNr,
        'land': value.land,
        'nachnameRe': value.nachnameRe,
        'euNummer': value.euNummer,
        'postleitzahl': value.postleitzahl,
        'probenNummer': value.probenNummer,
        'laborant': value.laborant,
        'strasseNrRe': value.strasseNrRe,
        'ortRe': value.ortRe,
        'gratisProbe': value.gratisProbe,
        'bemerkung': value.bemerkung,
        'tsSanoEingang': value.tsSanoEingang === undefined ? undefined : (value.tsSanoEingang.toISOString()),
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'tsLaborEingang': value.tsLaborEingang === undefined ? undefined : (value.tsLaborEingang.toISOString()),
        'cvasDocumentList': value.cvasDocumentList === undefined ? undefined : ((value.cvasDocumentList as Array<any>).map(CvasDokumentOhneInhaltToJSON)),
        'betriebsNummer': value.betriebsNummer,
        'probenBezeichnung': value.probenBezeichnung,
        'siliermittelbezeichnung': value.siliermittelbezeichnung,
        'behandlungsForm': value.behandlungsForm,
        'emailLaborUser': value.emailLaborUser,
        'analyseTyp': value.analyseTyp,
        'bestellId': value.bestellId,
        'siliermittel': value.siliermittel,
        'uiInsert': value.uiInsert,
        'hybridSorte': value.hybridSorte,
        'kundenNummer': value.kundenNummer,
        'schnitthoehe': value.schnitthoehe,
        'ertrag': value.ertrag,
        'tsProbennahme': value.tsProbennahme === undefined ? undefined : (value.tsProbennahme.toISOString()),
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'silonummer': value.silonummer,
        'betriebsId': value.betriebsId,
        'tierArt': value.tierArt,
        'dsgvo': value.dsgvo,
        'status': value.status,
        'tsCompleted': value.tsCompleted === undefined ? undefined : (value.tsCompleted.toISOString()),
        'labId': value.labId,
        'jahr': value.jahr,
    };
}

