import React from 'react';
import { Form, Row, Col, DatePicker, Select, Switch, Button } from 'antd';
import moment from 'moment';
import i18n from '../../../misc/i18n';
import { conf } from '../../../../_conf';
import { withAuth } from 'oidc-react';
import FsFrame from '../reportbuilder/fsframe';
import report from './_lkvauswertung2';
import LKVBetriebSelectForm from '../lkvauswertung/lkvbetriebselectform';

class LKVAuswertung2 extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            baseUri: conf.uri.reports,
            reportUri: '',
            values: {},
            buttonDisabled: true,
            rerenderStamp: new Date().getTime(),
            isFullScreen: false,
            form: React.createRef(),
            formVisible: true,
            formFields: report.formItems,
            language: '',
            switchState: false
        };
    }

    ///ReportServer/Pages/ReportViewer.aspx?/Grenzwerte GrassSilage&CompanyId=80&EndDate=24.02.2021&Perimeter=10&StartDate=24.02.2019&Type=Gras_silage&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer
    setUri() {
        let query = '/ReportServer/Pages/ReportViewer.aspx?/LKVAuswertung2&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer';

        let companyField = this.findFormfield('companyId');
        if (companyField != null && typeof companyField.value !== 'undefined') {
            query += '&CompanyId=' + companyField.value.companyId;
        } else {
            if (localStorage.getItem('CompanyId') != null) {
                query += '&CompanyId=' + encodeURIComponent(localStorage.getItem('CompanyId'));
            }
        }

        if (this.state.switchState != null) {
            if (this.state.switchState) {
                query += '&Switch=Zeitraum';
            } else {
                query += '&Switch=Jahr';
            }
        }

        let datumField = this.findFormfield('datum');
        if (datumField != null) {
            if (datumField.value?.length > 0) {
                if (moment(datumField.value[0]).isAfter(datumField.value[1])) {
                    query += '&Timeperiod1=' + moment(datumField.value[1]).format('YYYY-MM-DD');
                    query += '&Timeperiod2=' + moment(datumField.value[0]).format('YYYY-MM-DD');
                } else {
                    query += '&Timeperiod1=' + moment(datumField.value[0]).format('YYYY-MM-DD');
                    query += '&Timeperiod2=' + moment(datumField.value[1]).format('YYYY-MM-DD');
                }
            }
        }
        let unit1Field = this.findFormfield('einheit1');
        if (unit1Field != null) {
            if (unit1Field.value != null) {
                query += '&Unit1=' + encodeURIComponent(unit1Field.value);
            }
        }
        let unit2Field = this.findFormfield('einheit2');
        if (unit2Field != null) {
            if (unit2Field.value != null) {
                query += '&Unit2=' + encodeURIComponent(unit2Field.value);
            }
        }

        let language = '';
        if (this.state.language === 'de') {
            language = 'German';
        } else if (this.state.language === 'cz') {
            language = 'Czech Republic';
        } else {
            language = 'English';
        }
        query += '&Language=' + language;

        this.setState({
            reportUri: query
        });
    }

    findFormfield(fieldname) {
        return this.state.formFields.find((obj) => {
            if (Array.isArray(obj.name)) {
                return obj.name.some((item) => {
                    return item === fieldname;
                });
            } else {
                return obj.name === fieldname;
            }
        });
    }

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2)
                },
                () => {
                    this.setUri();
                }
            );
        }
    }

    componentDidMount() {
        this.setState({}, () => this.setUri());
    }

    onChange = (newFields) => {
        this.setState(
            {
                formFields: newFields
            },
            () => {
                this.setUri();
            }
        );
    };

    updateSwitch(change) {
        this.setState({
            switchState: change
        });
    }

    render() {
        let unit1Field = report.formItems.find((obj) => {
            return obj.name === 'Einheit1';
        });
        let unit2Field = report.formItems.find((obj) => {
            return obj.name === 'Einheit2';
        });
        if (unit1Field == null || unit2Field == null) {
            return <div>{i18n.t('notification.lkvdata.error.body')}</div>;
        }
        return (
            <>
                <div className="list-detail-wrap">
                    <Row>
                        <Col span={24}>
                            <div className={this.state.formVisible ? 'report-form' : 'report-form hidden'}>
                                <Form
                                    ref={this.state.form}
                                    size="small"
                                    onFieldsChange={(_, allFields) => {
                                        this.onChange(allFields);
                                    }}
                                    initialValues={{
                                        einheit1: unit1Field.default,
                                        einheit2: unit2Field.default,
                                        datum: [moment(), moment().subtract(2, 'year')]
                                    }}
                                >
                                    <Row gutter={24}>
                                        <Col xs={24} md={8} xl={8} key={1}>
                                            <Row>
                                                <Col md={4} xl={4}>
                                                    <div>{i18n.t('form.label.report.label.CompanyId') + ' :'}</div>
                                                </Col>
                                                <Col md={20} xl={20}>
                                                    <Form.Item name="companyId" style={{ marginBottom: '12px' }}>
                                                        <LKVBetriebSelectForm />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={0} md={16} xl={16}></Col>
                                        <Col xs={24} md={6} xl={6} key={2}>
                                            <Row>
                                                <Col md={3} xl={7} style={{ maxWidth: '22.5%' }}>
                                                    <div>{i18n.t('form.label.report.label.date') + ' :'}</div>
                                                </Col>
                                                <Col md={6} xl={17}>
                                                    <Form.Item name="datum" style={{ marginBottom: '12px' }}>
                                                        <DatePicker.RangePicker allowClear={false} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} key={3}>
                                            <Row>
                                                <Col md={10} xl={10}>
                                                    <div>{i18n.t('form.label.monatlich')}</div>
                                                </Col>
                                                <Col md={3} xl={4}>
                                                    <Form.Item name="switch" style={{ marginBottom: '12px' }}>
                                                        <Switch
                                                            onChange={(change) => this.updateSwitch(change)}
                                                            checked={this.state.switchState}
                                                            size="small"
                                                            className="lkvSwitch"
                                                        />
                                                    </Form.Item>
                                                </Col>
                                                <Col md={8} xl={8}>
                                                    <div>{i18n.t('form.label.jaehrlich')}</div>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={0} md={12} xl={12}></Col>
                                        <Col xs={24} md={6} xl={6} key={4}>
                                            <Row>
                                                <Col md={3} xl={7} style={{ maxWidth: '22.5%' }}>
                                                    <div>{i18n.t('form.label.WertA') + ' :'}</div>
                                                </Col>
                                                <Col md={6} xl={17}>
                                                    <Form.Item name="einheit1" style={{ marginBottom: '12px' }}>
                                                        <Select
                                                            mode={unit1Field.multiple ? 'multiple' : null}
                                                            disabled={unit1Field.disabled ? true : false}
                                                            style={unit1Field.style}
                                                        >
                                                            {unit1Field.data.map((itm, y) => {
                                                                return (
                                                                    <Select.Option key={y} value={itm.key}>
                                                                        {i18n.t('form.label.lkv2Units.' + itm.key)}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} md={6} xl={6} key={5}>
                                            <Row>
                                                <Col md={3} xl={7} style={{ maxWidth: '22.5%' }}>
                                                    <div>{i18n.t('form.label.WertB') + ' :'}</div>
                                                </Col>
                                                <Col md={6} xl={17}>
                                                    <Form.Item name="einheit2" style={{ marginBottom: '12px' }}>
                                                        <Select
                                                            mode={unit2Field.multiple ? 'multiple' : null}
                                                            disabled={unit2Field.disabled ? true : false}
                                                            style={unit2Field.style}
                                                        >
                                                            {unit2Field.data.map((itm, y) => {
                                                                return (
                                                                    <Select.Option key={y} value={itm.key}>
                                                                        {i18n.t('form.label.lkv2Units.' + itm.key)}
                                                                    </Select.Option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={0} md={12} xl={12}></Col>
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="form-collapse-wrap">
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            formVisible: !this.state.formVisible
                                        });
                                    }}
                                >
                                    {this.state.formVisible ? <i className="fal fa-chevron-square-up"></i> : <i className="fal fa-chevron-square-down"></i>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FsFrame uri={this.state.reportUri} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}

export default withAuth(LKVAuswertung2);
