import React from 'react';
import { Button, Typography, Row, Col, message } from 'antd';
import i18n from '../../../../../misc/i18n';
const { Paragraph } = Typography;
interface IProps {
    value: string;
}

export default class PlaintextBundesland extends React.Component<IProps> {

    copyText(text: string) {
        //@ts-ignore

        //@ts-ignore
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            //@ts-ignore
            window.clipboardData.setData('Text', text);
            message.success(i18n.t("message.copiedClipboard"))
            return
        } else if (document.queryCommandSupported && document.queryCommandSupported('copy')) {
            var textarea = document.createElement('textarea');
            textarea.textContent = text;
            textarea.style.position = 'fixed'; // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand('copy'); // Security exception may be thrown by some browsers.
                message.success(i18n.t("message.copiedClipboard"))
                return
            } catch (ex) {
                console.warn('Copy to clipboard failed.', ex);
                message.error(i18n.t("message.notCopiedClipboard"))
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    render() {
        return (
            <Row>
                <Col>
                    <Paragraph className="deparagraph"> {this.props.value != null ? this.props.value : <>&nbsp; </>} </Paragraph>
                </Col>
                <Col>
                    <Button
                        onClick={() => {
                            this.copyText(this.props.value);
                        }}
                    >
                        <i className="far fa-copy"></i>
                    </Button>
                </Col>
            </Row>
        );
    }
}
