import React from 'react';
import { withAuth, AuthContextProps } from 'oidc-react';
import ListeFachadmin from '../listen/listeFachadmin/index';

interface IProps extends AuthContextProps {}

class NewUser extends React.Component<IProps> {
    render() {
        return <ListeFachadmin />;
    }
}

export default withAuth(NewUser);
