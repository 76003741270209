import React from "react";
import ListTable from "../../listdetail/listtable";
import _companytable from "./_companytable";
import { getRestApiConfig } from "../../../../../_conf";
import * as Api from "../../../../../api/rest";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from "../../../../misc/notification";
import { message } from "antd";
import helper from "../../listdetail/listtable/_helper";
import i18n from "../../../../misc/i18n";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { getLinkPart } from "../../../../misc/linkhelper";
import { enc } from "../../../../misc/crypt";

interface IProps extends AuthContextProps, RouteComponentProps {
    currentFarmer: number;
    history: any;
}

interface IState {
    listdata: Array<any>;
    loading: boolean;
    pageSize: number;
    pagination: any;
}

let klasse = class LKVExport extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            listdata: [],
            loading: false,
            pageSize: 10,
            pagination: {}
        };
    }
    _getApi() {
        return new Api.BetriebControllerApi(getRestApiConfig("company", this.props.userData));
    }
    componentDidMount() {
        this._fetchListData({});
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        if (params.search == null) {
            params.search = "sdnLandwirt.landwirtId==" + this.props.currentFarmer;
        } else {
            params.search = "sdnLandwirt.landwirtId==" + this.props.currentFarmer + ";" + params.search;
        }
        try {
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort
            };
            let resp = await this._getApi().findFiltered3(requestBody);
            this.setState({
                listdata: helper.prepareListData(resp.content),
                loading: false,
                pageSize: params.size,
                pagination: {
                    total: resp.totalElements,
                    pageSize: params.size
                }
            });
        } catch (e) {
            userNotification("error", "notification.company.get.error");
            this.setState({
                loading: false
            });
        }
    }

    copyText(text: string) {
        //@ts-ignore
        if (window.clipboardData && window.clipboardData.setData) {
            // Internet Explorer-specific code path to prevent textarea being shown while dialog is visible.
            //@ts-ignore
            window.clipboardData.setData("Text", text);
            message.success(i18n.t("table.col.title.eu_number") + i18n.t("message.copiedClipboardPlain"));
            return;
        } else if (document.queryCommandSupported && document.queryCommandSupported("copy")) {
            var textarea = document.createElement("textarea");
            textarea.textContent = text;
            textarea.style.position = "fixed"; // Prevent scrolling to bottom of page in Microsoft Edge.
            document.body.appendChild(textarea);
            textarea.select();
            try {
                document.execCommand("copy"); // Security exception may be thrown by some browsers.
                message.success(i18n.t("table.col.title.eu_number") + i18n.t("message.copiedClipboardPlain"));
                return;
            } catch (ex) {
                console.warn("Copy to clipboard failed.", ex);
                message.error(i18n.t("table.col.title.eu_number") + i18n.t("message.notCopiedClipboardPlain"));
                return false;
            } finally {
                document.body.removeChild(textarea);
            }
        }
    }

    render() {
        return (
            <ListTable
                renderTitle={_companytable.renderTitle}
                hasToolbar={false}
                delete={false}
                cols={_companytable.columns}
                data={this.state.listdata}
                fetchListData={(p: any) => this._fetchListData(p)}
                detailVisible={false}
                loading={this.state.loading}
                pagination={this.state.pagination}
                formitems={[]}
                onTableChange={(p: any) => {
                    this.setState(
                        {
                            pageSize: p.pagination.pageSize
                        },
                        () => {
                            this._fetchListData(p);
                        }
                    );
                }}
                onRowSelection={(rec: any) => {
                    let plink = getLinkPart(this.props.userData, "companies") + "?id=" + enc(rec.betriebsId);
                    this.props.history.push(plink);
                }}
            />
        );
    }
};

export default withAuth(withRouter(klasse));
