import React from 'react';
import { DeLayoutContainer } from "./layout";
import { ConfigProvider } from 'antd';
import { i18nApp } from "./../misc/i18n";
import { withAuth } from "oidc-react";

class Portal extends React.Component{

    constructor(props){
        super(props)
        this.state={
            retryCount : 0
        }
    }

    async componentDidMount(){
        this.props.userManager.events.addAccessTokenExpiring(function(){
            console.log("token expiring...");
        });

        this.props.userManager.events.addUserLoaded(function(){
            console.log("User loaded...");
        });

        this.props.userManager.events.addUserUnloaded(function(){
            console.log("User Unloaded...");
            if(window.location.pathname !== "/"){
                window.location.pathname = "/"
            }
        });

        this.props.userManager.events.addUserSignedIn(function(){
            console.log("User Signed in...");
        });

        this.props.userManager.events.addUserSignedOut(function(){
            console.log("User Signed Out...");
            
        });

        this.props.userManager.events.addAccessTokenExpired(async (e)=>{
            await this.props.userManager.removeUser()
            await this.props.signOut()
            console.log("token expired...",e);
            await this.props.userManager.clearStaleState()
            if(window.location.pathname !== "/"){
                window.location.pathname = "/"
            }
            
        });

        this.props.userManager.events.addSilentRenewError(async (e)=>{
            console.log("error on silent renew...",e);
            try{
                if(this.state.retryCount < 3){
                    this.setState({
                        retryCount : this.state.retryCount +1
                    },async ()=>{
                        await this.props.userManager.signinSilent()
                    })  
                }
            }catch(e){
                console.error("Retry Error: ",e)
                await this.props.userManager.clearStaleState()
                await this.props.userManager.removeUser()
                await this.props.signOut()
                await this.props.userManager.clearStaleState()
            }
            
        });
    }

    render(){
        return (
            <ConfigProvider locale={i18nApp.getLocale()}>
                <DeLayoutContainer />
            </ConfigProvider>
        )
    }
}

export default withAuth(Portal);
