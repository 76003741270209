import { conf } from "../../_conf";
const CryptoJS = require("crypto-js");

const SECRET = conf.version

const enc = (plainText: string) => {
    try{
        var b64 = CryptoJS.AES.encrypt(JSON.stringify(plainText), SECRET).toString();
        var e64 = CryptoJS.enc.Base64.parse(b64);
        var eHex = e64.toString(CryptoJS.enc.Hex);
        return eHex;
    }catch(e){
        return plainText;
    }
}

const dec = (cipherText: string) => {
    try{
        var reb64 = CryptoJS.enc.Hex.parse(cipherText);
        var bytes = reb64.toString(CryptoJS.enc.Base64);
        var decrypt = CryptoJS.AES.decrypt(bytes, SECRET);
        var plain = decrypt.toString(CryptoJS.enc.Utf8);
        return plain;
    }catch(e){
        return cipherText;
    }
}

export {enc, dec}