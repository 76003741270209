/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Landwirt,
    LandwirtFromJSON,
    LandwirtFromJSONTyped,
    LandwirtToJSON,
} from './Landwirt';

/**
 * 
 * @export
 * @interface Betrieb
 */
export interface Betrieb {
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    parameter?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    ort?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    plz?: string;
    /**
     * 
     * @type {number}
     * @memberof Betrieb
     */
    betriebsNummer?: number;
    /**
     * 
     * @type {boolean}
     * @memberof Betrieb
     */
    standardBetrieb?: boolean;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    postleitzahlRe?: string;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Betrieb
     */
    externeSysteme?: { [key: string]: string; };
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    ortRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    land?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    vornameRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    betriebsName?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    euNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    beschreibung?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    nachnameRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    ortsteilRe?: string;
    /**
     * 
     * @type {number}
     * @memberof Betrieb
     */
    betriebsId?: number;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    ortsteil?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    strasseNr?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    firmaRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    bundesland?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    strasseNrRe?: string;
    /**
     * 
     * @type {string}
     * @memberof Betrieb
     */
    landRe?: string;
    /**
     * 
     * @type {Array<number>}
     * @memberof Betrieb
     */
    regionen?: Array<number>;
    /**
     * 
     * @type {Landwirt}
     * @memberof Betrieb
     */
    landwirt?: Landwirt;
    /**
     * 
     * @type {Array<string>}
     * @memberof Betrieb
     */
    einsendeMails?: Array<string>;
}

export function BetriebFromJSON(json: any): Betrieb {
    return BetriebFromJSONTyped(json, false);
}

export function BetriebFromJSONTyped(json: any, ignoreDiscriminator: boolean): Betrieb {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'plz': !exists(json, 'plz') ? undefined : json['plz'],
        'betriebsNummer': !exists(json, 'betriebsNummer') ? undefined : json['betriebsNummer'],
        'standardBetrieb': !exists(json, 'standardBetrieb') ? undefined : json['standardBetrieb'],
        'postleitzahlRe': !exists(json, 'postleitzahlRe') ? undefined : json['postleitzahlRe'],
        'externeSysteme': !exists(json, 'externeSysteme') ? undefined : json['externeSysteme'],
        'ortRe': !exists(json, 'ortRe') ? undefined : json['ortRe'],
        'land': !exists(json, 'land') ? undefined : json['land'],
        'vornameRe': !exists(json, 'vornameRe') ? undefined : json['vornameRe'],
        'betriebsName': !exists(json, 'betriebsName') ? undefined : json['betriebsName'],
        'euNummer': !exists(json, 'euNummer') ? undefined : json['euNummer'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'nachnameRe': !exists(json, 'nachnameRe') ? undefined : json['nachnameRe'],
        'ortsteilRe': !exists(json, 'ortsteilRe') ? undefined : json['ortsteilRe'],
        'betriebsId': !exists(json, 'betriebsId') ? undefined : json['betriebsId'],
        'ortsteil': !exists(json, 'ortsteil') ? undefined : json['ortsteil'],
        'strasseNr': !exists(json, 'strasseNr') ? undefined : json['strasseNr'],
        'firmaRe': !exists(json, 'firmaRe') ? undefined : json['firmaRe'],
        'bundesland': !exists(json, 'bundesland') ? undefined : json['bundesland'],
        'strasseNrRe': !exists(json, 'strasseNrRe') ? undefined : json['strasseNrRe'],
        'landRe': !exists(json, 'landRe') ? undefined : json['landRe'],
        'regionen': !exists(json, 'regionen') ? undefined : json['regionen'],
        'landwirt': !exists(json, 'landwirt') ? undefined : LandwirtFromJSON(json['landwirt']),
        'einsendeMails': !exists(json, 'einsendeMails') ? undefined : json['einsendeMails'],
    };
}

export function BetriebToJSON(value?: Betrieb | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameter': value.parameter,
        'ort': value.ort,
        'plz': value.plz,
        'betriebsNummer': value.betriebsNummer,
        'standardBetrieb': value.standardBetrieb,
        'postleitzahlRe': value.postleitzahlRe,
        'externeSysteme': value.externeSysteme,
        'ortRe': value.ortRe,
        'land': value.land,
        'vornameRe': value.vornameRe,
        'betriebsName': value.betriebsName,
        'euNummer': value.euNummer,
        'beschreibung': value.beschreibung,
        'nachnameRe': value.nachnameRe,
        'ortsteilRe': value.ortsteilRe,
        'betriebsId': value.betriebsId,
        'ortsteil': value.ortsteil,
        'strasseNr': value.strasseNr,
        'firmaRe': value.firmaRe,
        'bundesland': value.bundesland,
        'strasseNrRe': value.strasseNrRe,
        'landRe': value.landRe,
        'regionen': value.regionen,
        'landwirt': LandwirtToJSON(value.landwirt),
        'einsendeMails': value.einsendeMails,
    };
}

