import React from 'react';
import { Tabs } from 'antd';

import i18n from '../../../misc/i18n';

import { withAuth, AuthContextProps } from 'oidc-react';
import ImportFailed from './events/ImportFailed';
import NewUser from './events/NewUser';
import ProbenstatusChange from './events/ProbenstatusChange';
const { TabPane } = Tabs;

interface IProps extends AuthContextProps {}

class Mailverwaltung extends React.Component<IProps> {
    render() {
        return (
            <Tabs type="card" style={{ marginTop: '16px', marginLeft: '16px', marginRight: '16px' }}>
                <TabPane tab={i18n.t('tab.Importfehler')} key="1">
                    <ImportFailed />
                </TabPane>

                <TabPane tab={i18n.t('tab.ProbenstatusChange')} key="3">
                    <ProbenstatusChange />
                </TabPane>
                <TabPane tab={i18n.t("tab.NewUser")} key="2">
                    <NewUser/>
                </TabPane>
            </Tabs>
        );
    }
}

export default withAuth(Mailverwaltung);
