import React from 'react';
import { Menu, Tabs, Tooltip } from 'antd';
// import { Link, NavLink } from 'react-router-dom';
import { NavLink } from 'react-router-dom';
import routes from './../../../misc/routes';
import i18n from './../../../misc/i18n';
import { withAuth } from 'oidc-react';

const { SubMenu } = Menu;
const { TabPane } = Tabs;

class MainMenu extends React.Component {
    constructor(props) {
        super(props);
        let tab = localStorage.getItem('demes-portal-menu-activeTab');
        if (tab != null) {
            tab = JSON.parse(tab);
            tab = tab + '';
            //Rückwärtskompatibel
            if (tab.indexOf('tab') === -1) {
                tab = 'tab_' + tab;
            }
        }

        this.state = {
            openKeys: [],
            openTabIdx: tab != null ? tab : 'tab_0',
        };
    }

    shouldComponentUpdate(nextProps, nextState) {
        return nextProps !== this.props || nextState !== this.state || nextProps.userData !== this.props.userData ? true : false;
    }

    getIconText(item, ct, children) {
        if (this.props.collapsed && ct === 1) {
            if (children) {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <span
                            className={item.icon}
                            style={{ border: 'solid 0px #FF0000', width: '40px', height: '30px', fontSize: '190%', margin: '7px 5px 0 -10px' }}
                        ></span>
                        <i
                            className="fal fa-angle-down"
                            style={{ border: 'solid 0px #FF0000', position: 'absolute', right: '5px', top: '15px', color: '#000' }}
                        ></i>
                    </div>
                );
            } else {
                return (
                    <div style={{ textAlign: 'center' }}>
                        <span
                            className={item.icon}
                            style={{ border: 'solid 0px #FF0000', width: '40px', height: '30px', fontSize: '190%', margin: '7px 5px 0 -10px' }}
                        ></span>
                    </div>
                );
            }
        } else {
            return (
                <span>
                    <i className={item.icon} style={{ fontSize: '100%', margin: '0 0 0 0' }}></i>
                    {i18n.t('navitem.' + item.title)}
                </span>
            );
            // return <span><i className={item.icon} style={{fontSize: "100%", margin: "0 0 0 0"}}></i>{item.title}</span>
        }
    }

    doMenu(item, ct) {
        ct++;
        if (item.children && item.children.length) {
            //if(this.props.userData !== null){
            return (
                <SubMenu key={item.id} title={this.getIconText(item, ct, true)}>
                    {item.children.map((iitem, i) => this.doMenu(iitem, ct))}
                </SubMenu>
            );
            //}else{
            //    return null
            //}
        } else {
            //if(this.props.userData !== null){
            return (
                <Menu.Item key={item.id} title={i18n.t('navitem.' + item.title)} disabled={item.disabled}>
                    <NavLink to={item.id}>{this.getIconText(item, ct, false)}</NavLink>
                </Menu.Item>
            );
            //}else{
            //    return null
            //}
        }
    }

    render() {
        let sel = routes.getSelection();
        // routes.setDocTitile(this.props.routes);
        routes.setDocTitile(this.props.routes);
        if (this.props.collapsed) {
            return (
                <div className="demes-mainmenu">
                    <Menu
                        defaultSelectedKeys={sel.defaultSelectedKeys}
                        defaultOpenKeys={sel.defaultOpenKeys}
                        mode="inline"
                        multiple={false}
                        style={{
                            background: this.props.background
                        }}
                    >
                        {this.props.userData !== null &&
                            this.props.tabs[this.state.openTabIdx.split('_')[1]] != null &&
                            this.props.tabs[this.state.openTabIdx.split('_')[1]].items.map((iitem, ii) => this.doMenu(iitem, 0))}
                    </Menu>
                </div>
            );
        } else {
            return (
                <React.Fragment>
                    <div className="demes-menu-outer-wrap">
                        {this.props.userData ? (
                            <Tabs
                                activeKey={this.state.openTabIdx + ''}
                                hideAdd
                                type={this.props.collapsed ? 'line' : 'card'}
                                onChange={(idx) => {
                                    this.setState(
                                        {
                                            openTabIdx: idx
                                        },
                                        () => {
                                            localStorage.setItem('demes-portal-menu-activeTab', JSON.stringify(this.state.openTabIdx));
                                        }
                                    );
                                }}
                            >
                                {this.props.userData !== null &&
                                    this.props.tabs.map((item, i) => {
                                        return (
                                            <TabPane
                                                tab={
                                                    <Tooltip title={i18n.t('tab.' + item.meta.title)}>
                                                        <i className={item.meta.icon} />
                                                    </Tooltip>
                                                }
                                                key={'tab_' + i}
                                                closable={false}
                                            >
                                                <div className="demes-mainmenu">
                                                    <Menu
                                                        defaultSelectedKeys={sel.defaultSelectedKeys}
                                                        defaultOpenKeys={sel.defaultOpenKeys}
                                                        mode="inline"
                                                        multiple={true}
                                                        style={{
                                                            background: this.props.background
                                                        }}
                                                    >
                                                        {item.items.map((iitem, ii) => this.doMenu(iitem, 0))}
                                                    </Menu>
                                                </div>
                                            </TabPane>
                                        );
                                    })}
                            </Tabs>
                        ) : null}

                        {!this.props.collapsed ? (
                            <div className="demes-menu-footer">
                                <div style={{ display: 'block' }}>{this.props.version}</div>
                                &copy; {new Date().getFullYear()} -&nbsp;
                                <a href="https://www.sano.de/" target="_blank" rel="noopener noreferrer">
                                    Sano -
                                </a>
                                &nbsp;
                                <NavLink to="/imprint">{i18n.t('navitem.imprint')}</NavLink> - <NavLink to="/privacy">{i18n.t('navitem.privacy')}</NavLink>
                            </div>
                        ) : null}
                    </div>
                </React.Fragment>
            );
        }
    }
}

export default withAuth(MainMenu);
