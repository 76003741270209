import React from 'react';
import { Result } from 'antd';

interface IProps {}

const Home = (props: IProps) => {
    return (
        <div className="demes-app-home-wrap">
            <Result title="HOME / START" />
        </div>
    );
};

export default Home;
