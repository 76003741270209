import React from "react";
import { Button, Input } from "antd";
import BundeslandRender from "./../../../portal/sano/datatable/bundeslandRenderer";
import { i18nApp } from './../../../misc/i18n';
export default class BundeslandFilter extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            showFilter: false,
            value: []
        }
    }
    
    render(){
        let value = [];
        let dvalue = [];
        if(typeof(this.props.value) !== "undefined"){
            value = this.props.value;
            if(Array.isArray(this.props.value)){
                if(Array.isArray(this.props.value[0])){
                    for(let i=0; i<this.props.value[0].length; i++){
                        dvalue.push(i18nApp.getBundeslandString(value[0][i]))
                    }
                }else{
                    for(let i=0; i<this.props.value.length; i++){
                        dvalue.push(i18nApp.getBundeslandString(value[i]))
                    }
                }
            }
        }

        return(
            <>
                <div className="customfilter-wrap">
                    <Button onClick={() => {
                        this.setState({
                            showFilter: !this.state.showFilter
                        })
                    }}>
                        {this.props.placeholder}
                    </Button> 
                    <Input className="customfilter-value-wrap" value={dvalue.join(", ")} disabled/>
                </div>
                {(this.state.showFilter) 
                    ? <BundeslandRender defaultCheckedKeys={value} handlePreSelect={(e) => {
                        if(this.props.onChange){
                            this.props.onChange(e)
                        }
                    }} />
                    : null
                }
            </>
        )

        // return(
        //     <Select defaultValue={value} value={this.state.value} placeholder={this.props.placeholder}
        //         mode="multiple"
        //         dropdownRender = {() => {
        //             return(
        //                 <BundeslandRender defaultCheckedKeys={value} handlePreSelect={(e) => {
        //                     if(this.props.onChange){
        //                         this.props.onChange(this.state.value)
        //                     }
        //                 }}
        //                 />
        //             )
        //         }}
        //     >
        //         {value.map((item, i) => {
        //             return(<Select.Option key={i} value={item}>{item}</Select.Option>)
        //         })}
        //     </Select>
        // )
    }

}
