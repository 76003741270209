export enum Renderer {
    BUNDESLAND = 'bundesland',
    RENDERDATETIME = 'renderDateTime',
    RENDERDATE = 'renderDate',
    RENDERSTATUS = 'renderStatus',
    BESTELLSTATUS = 'bestellstatus',
    PROBENART = 'probenart',
    MAILSTATUS = 'mailstatus',
    CHECKBOX = 'checkbox',
    BOOLEAN = 'boolean'
}
