const farmer = {
    detailProp: 'id',
    formitems: [
        {
            label: 'email',
            name: 'emailadresse',
            type: 'email',
            required: true,
            disabled: false
        },
        {
            label: 'land',
            name: 'land',
            type: 'text',
            required: true,
        },
        {
            label: 'role',
            name: 'rolle',
            type: 'plaintextWithDefault',
            default: "BusinessAdministrator",
            disabled: true,
        }
    ],
    "renderTitle": (rec) => {
        let title = (rec.emailadresse !== null) ? rec.emailadresse  : ""
        
        return(
            title
        )
    },
    columns: [
        {
            title: 'email',
            dataIndex: 'emailadresse',
            filter: 'text',
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'land',
            dataIndex: 'land',
            filter: 'text',
            sort: true
            // "responsive": ["lg"]
        }
    ]
};

export default farmer;
