import React from "react";
import { Select, Spin, Modal, Button } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";
import userNotification from "../../../../../misc/notification";
import i18n from "../../../../../misc/i18n";
import { getLinkPart } from '../../../../../misc/linkhelper';
import { withRouter } from "react-router-dom";
import { enc } from "../../../../../misc/crypt";

import "./farmerselect.css";  

let { confirm } = Modal;
let FarmerSelect = class FarmerSelect extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      selectdata: [],
      value: "",
      loading: false,
      obj: undefined
    };
    this.loadNewData = this.debounce(this.loadNewData, 800);
    this.updateStateEvent = this.updateStateEvent.bind(this);
  }

  debounce(func, wait, immediate) {
    var timeout;
    return function () {
      var context = this,
        args = arguments;
      var later = function () {
        timeout = null;
        if (!immediate) func.apply(context, args);
      };
      var callNow = immediate && !timeout;
      clearTimeout(timeout);
      timeout = setTimeout(later, wait);
      if (callNow) func.apply(context, args);
    };
  }
  updateStateEvent(e) {
    this.setState({
      landIso: e.detail,
    });
  }

  componentDidMount() {
    this._getSelectData();
  }

  componentDidUpdate() {
    if (
      this.props.data !== {} &&
      this.props.data.landwirt != null &&
      this.state.value === ""
    ) {
      let itm = this.props.data.landwirt;
      this.setState({
        value: itm.landwirtId,
      });
    }
  }

  async _getSelectData(landIso) {
    this.setState({
      loading: true,
    });

    
    try {
      let api = new Api.LandwirtControllerApi(
        getRestApiConfig("farmer", this.props.userData)
      );

      let requestBody = {
        search: "anonymisiertJn=='false'"
      }

      let resp = await api.findFiltered1(requestBody);
      if(this.props.data.landwirt != null && this.state.value === this.props.data.landwirtId && this.state.obj?.landwirtId !== this.props.data.landwirtId){
        resp.content.push(this.props.data.landwirt)
      }
      if(this.state.obj != null && resp.content.find((el)=>el.landwirtId === this.state.obj.landwirtId) === undefined){
        resp.content.push(this.state.obj)
      }
      if (resp.content != null) {
        this.setState({
          selectdata: resp.content !== undefined ? resp.content : [],
          loading: false,
        });
      }
    } catch (e) {
        console.error(e)
        this.setState({
            loading: false
        })
      userNotification("error", "notification.farmer.get.error");
    }
  }

  loadNewData = async (userInput) => {
    this.setState({
      loading: true,
      selectdata: [],
    });
    let api = new Api.LandwirtControllerApi(
      getRestApiConfig("farmer", this.props.userData)
    );

    try {
      let requestBody = {
        q: userInput
      }
      let erg = await api.findLandwirtByQuery(requestBody);
      if(this.props.data.landwirt != null && this.state.value === this.props.data.landwirtId && this.state.obj?.landwirtId !== this.props.data.landwirt.landwirtId){
        erg.content.push(this.props.data.landwirt)
      }
      if(this.state.obj != null && erg.content.find((el)=>el.landwirtId === this.state.obj.landwirtId) === undefined){
        erg.content.push(this.state.obj)
      }
      this.setState({
        selectdata: erg.content !== undefined ? erg.content : [],
        loading: false,
      });
    } catch (e) {
        this.setState({
            loading: false
        })
      userNotification("error", "notification.farmer.error");
    }
  };

  onHardChange = (value, option) => {
    // Im Detail prop die Werte setzen
    this.props.data.ort = option.obj.ort;
    this.props.data.plz = option.obj.plz;
    this.props.data.ortsteil = option.obj.ortsteil;
    this.props.data.land = option.obj.land;
    this.props.data.bundesland = option.obj.bundesland;
    this.props.data.strasseNr = option.obj.strasse;
    // Anderen Komponenten mitteilen das neue Daten vorhanden sind
    window.dispatchEvent(
      new CustomEvent("landwirtSelected", { detail: option.obj })
    );
  };
  checkFieldsFull = () => {
    function isEmpty(str) {
      return !str || 0 === str.length;
    }

    return (
      !isEmpty(this.props.data.ort) ||
      !isEmpty(this.props.data.plz) ||
      !isEmpty(this.props.data.ortsteil) ||
      !isEmpty(this.props.data.land) ||
      !isEmpty(this.props.data.bundesland) ||
      !isEmpty(this.props.data.strasse)
    );
  };

  onChange = (value, option) => {
    if (this.checkFieldsFull()) {
      confirm({
        title: i18n.t("farmerselect.title"),
        content: i18n.t("farmerselect.body"),
        onOk: () => {
          this.onHardChange(value, option);
        },
      });
    } else {
      this.onHardChange(value, option);
    }

    this.setState(
      {
        value,
        loading: false,
        obj:option.obj
      },
      () => {
        this.props.onChange(option.obj.landwirtId);
      }
    );
  };

  render() {
    
    const { loading, value } = this.state;
    return (
      <div className="farmerselect-wrap">
        <Button
          disabled={(value === "") ? true : false}
          onClick={() => {
          let plink = getLinkPart(this.props.userData, "farmer") + "?id=" + enc(this.state.value)
          this.props.history.push(plink)
        }}><i className="fas fa-external-link-alt"></i></Button>
        <Select
          // disabled={this.state.landIso === ""}
          disabled={this.props.disabled}
          value={value}
          notFoundContent={loading ? <Spin size="small" /> : null}
          showSearch
          onChange={this.onChange}
          onSearch={this.loadNewData}
          style={{ width: "100%" }}
          filterOption={false}
        >
          {this.state.selectdata.map((itm) => {
            return (
              
              <Select.Option
                key={itm.landwirtId}
                value={itm.landwirtId}
                obj={itm}
              >
                {(itm.vorname != null ? itm.vorname : "") +
                  " " +
                  (itm.nachname != null ? itm.nachname : "") +
                
                  (itm.plz != null ?  " | " +itm.plz : "") +
                  
                  (itm.land != null ? " | " +itm.land : "")}
              </Select.Option>
            );
          })}
        </Select>
      </div>
    );
  }
};

export default withAuth(withRouter(FarmerSelect));