import React from "react";
import { Input } from "antd";
import { useHistory } from "react-router-dom";
import i18n from '../../../misc/i18n';
const queryToStr = require('query-string');


function GlobalSearchField(props: any) {
    let history = useHistory();
    let oPath = "";

    if(history.location.pathname !== "/search/"){
        oPath = history.location.pathname
    }
    const result = queryToStr.parse(document.location.search);
    // const style= (props.style) ? props.style : null;
    return (
        <div className="globalsearchfield-wrap">
            <Input.Search 
                placeholder={i18n.t("search.find")} 
                defaultValue={result.query}
                allowClear 
                
                // style={style}
                onSearch={(v) => {
                    if(v === ""){
                        history.push(oPath);
                    }else{
                        history.push("/search/?query=" + v);
                    }
                }} 
                enterButton 
            />
        </div>
    );
}

export default GlobalSearchField