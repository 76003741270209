import {Filter} from "../../../definitions/FilterEnum"
import {Renderer} from "../../../definitions/RendererEnum"

const companies = {
	"detailProp": "betriebsId",
	"formitems": [{
		"label": "contact",
		"type": "divider"
	}, {
		"label": "betriebsNummer",
		"name": "betriebsId",
		"type": "plaintext",
		"disabled": true
	}, {
		"label": "betriebsName",
		"name": "betriebsName",
		"type": "plaintext",
		"disabled": false
	}, {
		"label": "landwirtId",
		"name": "landwirt",
		"type": "plaintextBetriebLandwirt",
	}, {
		"label": "land",
		"name": "land",
		"type": "plaintext",
	}, {
		"label": "plz",
		"name": "plz",
		"type": "plaintext"
	},   {
		"label": "ort",
		"name": "ort",
		"type": "plaintext"
	},{
		"label": "bundesland",
		"name": "bundesland",
		"type": "plaintextBundesland"
	}, {
		"label": "strasseNr",
		"name": "strasseNr",
		"type": "plaintext",
		"disabled": false
	},  {
		"label": "ortsteil",
		"name": "ortsteil",
		"type": "plaintext",
		"disabled": false
	}, {
		"label": "regionen",
		"name": "regionen",
		"type": "plaintextRegions",
		"disabled": false,
	}, 
	{
		"label": "standardBetrieb",
		"name": "standardBetrieb",
		"type": "boolean",
		"disabled": false
	},
	{
		"label": "beschreibung",
		"name": "beschreibung",
		"type": "plaintext",
		"disabled": false
	},
],

	"columns": [ {
		"title": "betriebsId",
		"dataIndex": "betriebsId",
		"filter": Filter.NUMBER,
		"sort": true,
		"align": "right",
		"width": "150px"
	},{
		"title": "land",
		"dataIndex": "land",
		"filter": Filter.TEXT,
		"sort": true,
    }, {
		"title": "bundesland",
		"renderer":Renderer.BUNDESLAND,
		"dataIndex": "bundesland",
		"filter": Filter.BUNDESLAND,
		"responsive": ["lg"]
    },{
		"title": "plz",
		"dataIndex": "plz",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
	}, {
		"title": "ort",
		"dataIndex": "ort",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
    }, {
		"title": "ortsteil",
		"dataIndex": "ortsteil",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
    },{
		"title": "nachname",
		"dataIndex": "lwnachname",
		// "dataIndex": "landwirt.nachname",
		"filter":Filter.TEXT,
		"sort": true,
		// "responsive": ["lg"]
	},{
		"title": "vorname",
		"dataIndex": "lwvorname",
		// "dataIndex": "landwirt.nachname",
		"filter": Filter.TEXT,
		"sort": true,
		// "responsive": ["lg"]
	} ]
}

export default companies;