import { Modal } from 'antd';
import { DataObject, ErrorListObject, FormItems, buildModalMessage, ErrorType, FormItem } from './validateData';
import i18n from './i18n';
let { confirm } = Modal;

function pushChangedErrors(errorList: Array<ErrorListObject>, item: FormItem, value: DataObject, oldValue: DataObject) {
    if (item.changeNotification === true && item.name != null && value.hasOwnProperty(item.name)) {
        if (value[item.name] !== oldValue[item.name]) {
            errorList.push({
                propName: item.label,
                type: ErrorType.changed
            });
        }
    }
}

function checkChanged(value: DataObject, oldValue: DataObject, items: FormItems, onOkCallback: Function, onCancelCallback: Function = () => {}) {
    let errors: Array<ErrorListObject> = [];
    let collapsible = items.formitems.find((formItem) => {
        return formItem.type === 'collapse';
    });
    if (collapsible != null) {
        for (const group of items.formitems[0].groups) {
            for (const item of group.items) {
                pushChangedErrors(errors, item, value, oldValue);
                if (item.name != null && item.name !== items.detailProp && value[item.name] === oldValue[item.name]) {
                    delete value[item.name];
                }
            }
        }
    } else {
        for (const item of items.formitems) {
            pushChangedErrors(errors, item, value, oldValue);
            if (item.name != null && value[item.name] === oldValue[item.name]) {
                delete value[item.name];
            }
        }
    }
    if (errors.length > 0) {
        confirm({
            title: i18n.t('formvalidation.changes'),
            content: buildModalMessage(errors),
            okText: i18n.t('formvalidation.ok'),
            cancelText: i18n.t('formvalidation.cancel'),
            onOk() {
                onOkCallback();
            },
            onCancel() {
                onCancelCallback();
            }
        });
    } else {
        onOkCallback();
    }
}

export { checkChanged };
