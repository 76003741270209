import React from 'react';
import { Button, Drawer } from 'antd';
import Detail from './detail';
import Toolbar from './toolbar';
import LoadMask from '../../../misc/loadmask';
import ListTable from './listtable';
import { AuthContextProps, withAuth } from 'oidc-react';
import { Tabs } from 'antd';
import MediaQuery from 'react-responsive';
import i18n from '../../../misc/i18n';

//Über die Property extraTabs kann ein Array mit Tabs welche Angezeit werden sollen übergeben werden
interface IState {}

interface IProps extends AuthContextProps {
    onDetailClose: Function;
    hasToolbar?: boolean;
    onNew?: Function;
    loading: boolean;
    columns: Object;
    listdata: any;
    delete?: boolean;
    pagination: {};
    renderTitle?: string;
    renderRecordSub?: string;
    type?: string;
    onDelete?: Function;
    detailProp: number;
    onTableChange: Function;
    fetchListData: Function;
    onRowSelection?: Function;
    detailClassName?: string;
    detailVisible: boolean;
    detailWidth?: number;
    showSaveButton?: boolean;
    onSave?: Function;
    formitems: Object|Array<any>;
    detaildata: Object;
    extraTabs?: any;
    disableAll?: boolean;
    onTabsChange?: any;
    refForForm?: any;
}

let list = class ListDetail extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {};
    }

    onDetailClose = () => {
        if (this.props.onDetailClose) {
            this.props.onDetailClose();
        }
    }

    componentDidMount(){
        this.setState({
            disableAll: this.props.disableAll
        })
    }

    render() {
        return (
            <div className="list-detail-wrap">
                <MediaQuery minWidth={1224}>
                    {this.props.hasToolbar ? (
                        <Toolbar>
                            <Button
                                type="primary"
                                onClick={() => {
                                    if (this.props.onNew) {
                                        this.props.onNew();
                                    }
                                }}
                            >
                                <i className="fal fa-plus"></i>
                            </Button>
                        </Toolbar>
                    ) : null}
                    {this.props.loading ? <LoadMask /> : null}
                </MediaQuery>
                <ListTable
                    showSelectedRow={this.props.detailVisible}
                    cols={this.props.columns}
                    data={this.props.listdata.length ? this.props.listdata : []}
                    delete={this.props.delete}
                    pagination={this.props.pagination}
                    renderTitle={this.props.renderTitle}
                    renderRecordSub={this.props.renderRecordSub}
                    type={this.props.type}
                    onDelete={(r: any) => {
                        if (this.props.onDelete) {
                            this.props.onDelete(r[this.props.detailProp]);
                        }
                    }}
                    onTableChange={(p: any) => {
                        if (this.props.onTableChange != null && typeof this.props.onTableChange === 'function') {
                            this.props.onTableChange(p);
                        }
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                if (this.props.fetchListData) {
                                    this.props.fetchListData(p);
                                }
                            }
                        );
                    }}
                    onRowSelection={
                        this.props.onRowSelection != null
                            ? (r: any) => {
                                  if (this.props.onRowSelection !== undefined) {
                                      this.props.onRowSelection(r[this.props.detailProp]);
                                  }
                              }
                            : undefined
                    }
                />
                <MediaQuery minWidth={1224}>
                    <Drawer
                        className={this.props.detailClassName}
                        forceRender={true}
                        destroyOnClose={true}
                        placement="right"
                        closable={false}
                        onClose={this.onDetailClose}
                        visible={this.props.detailVisible}
                        width={this.props.detailWidth != null ? this.props.detailWidth : 600}
                    >
                        <div style={{ padding: '20px' }}>
                            <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} onChange={this.props.onTabsChange}>
                                <Tabs.TabPane tab={i18n.t('navitem.register.allgemein')} key="1" >
                                    <Detail
                                        showSaveButton={(this.props.disableAll) ? false : this.props.showSaveButton}
                                        onSave={(data: any) => {
                                            if (this.props.onSave) {
                                                this.props.onSave(data);
                                            }
                                        }}
                                        formitems={this.props.formitems}
                                        data={this.props.detaildata}
                                        disableAll={this.props.disableAll}
                                        refForForm={this.props.refForForm}
                                    />
                                </Tabs.TabPane>
                                {this.props.extraTabs !== undefined && this.props.extraTabs.length !== 0 && this.props.extraTabs.map((tab: any) => tab)}
                            </Tabs>
                        </div>
                    </Drawer>
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                    <Drawer
                        className={this.props.detailClassName}
                        forceRender={true}
                        destroyOnClose={true}
                        placement="right"
                        closable={true}
                        onClose={this.onDetailClose}
                        visible={this.props.detailVisible}
                        width="100%"
                    >
                        <div style={{ padding: '20px' }}>
                            <Tabs defaultActiveKey="1" destroyInactiveTabPane={true} onChange={this.props.onTabsChange}>
                                <Tabs.TabPane tab={i18n.t('navitem.register.allgemein')} key="1">
                                    <Detail
                                        showSaveButton={(this.props.disableAll) ? false : this.props.showSaveButton}
                                        onSave={(data: any) => {
                                            if (this.props.onSave) {
                                                this.props.onSave(data);
                                            }
                                        }}
                                        formitems={this.props.formitems}
                                        data={this.props.detaildata}
                                        disableAll={this.props.disableAll}
                                        refForForm={this.props.refForForm}
                                    />
                                </Tabs.TabPane>
                                {this.props.extraTabs !== undefined && this.props.extraTabs.length !== 0 && this.props.extraTabs.map((tab: any) => tab)}
                            </Tabs>
                        </div>
                    </Drawer>
                </MediaQuery>
            </div>
        );
    }
};

export default withAuth(list);
