import React from "react";
import farmer from "./_farmer";
import ListTable from "../../listdetail/listtable";
import helper from "../../listdetail/listtable/_helper";
import { Modal, Button } from "antd";
import { getRestApiConfig } from "../../../../../_conf";
import { withAuth, AuthContextProps } from "oidc-react";
import * as Api from "../../../../../api/rest";
import i18n from "../../../../misc/i18n";
import userNotification from "../../../../misc/notification";

interface IProps extends AuthContextProps {
    disabled?: boolean;
    style?: any;
    onChange?: any;
}

type IState = {
    conf: any;
    data: Array<any>;
    loading: boolean;
    listdata: Array<any>;
    pagination: any;
    prePagination: any;
    pageSize: number;
    lookUpVisible: boolean;
    selectedRows: Array<any>;
    selectedRowKeys: Array<any>;
    preSelectedRowKeys: Array<any>;
    viewStr: string;
};
class FarmerIdSelect extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);

        this.state = {
            conf: farmer,
            data: [],
            loading: false,
            listdata: [],
            pagination: { pagination: { current: 1 } },
            prePagination: {},
            pageSize: 10,
            lookUpVisible: false,
            selectedRows: [],
            selectedRowKeys: [0],
            preSelectedRowKeys: [0],
            viewStr: ""
        };
    }

    _getApi() {
        return new Api.LandwirtControllerApi(getRestApiConfig("farmer", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({ pagination: { current: 1 } });
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        try {
            let params = helper.prepareListParams(p, this.state.pageSize);
            //(endDate?: Date, lands?: Array<string>, offset?: number, page?: any, paged?: boolean, pageNumber?: number, pageSize?: number, sampleType?: Array<string>, search?: string, size?: any, sort?: any, sortSorted?: boolean, sortUnsorted?: boolean, startDate?: Date, unpaged?: boolean, options: any = {})
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort
            };
            let resp = await this._getApi().findFiltered1(requestBody);
            this.setState(
                {
                    listdata: helper.prepareListData(resp.content),
                    loading: false,
                    pageSize: params.size,
                    pagination: {
                        total: resp.totalElements,
                        pageSize: params.size,
                        current: p.pagination?.current != null ? p.pagination.current : 1
                    }
                },
                () => {
                    if (this.state.selectedRowKeys[0] != null) {
                        if (this.state.selectedRowKeys[0] < this.state.pageSize) {
                            this.setState({
                                selectedRows: [this.state.listdata[this.state.selectedRowKeys[0]]]
                            });
                        } else {
                            this.setState({
                                selectedRowKeys: [0],
                                selectedRows: [this.state.listdata[0]]
                            });
                        }
                    }
                }
            );
        } catch (e) {
            userNotification("error", "notification.farmer.get.error");
            this.setState({
                loading: false
            });
        }
    }

    async _setSelection() {
        this.setState(
            {
                viewStr: this.state.selectedRows[0].landwirtId,
                lookUpVisible: false,
                preSelectedRowKeys: this.state.selectedRowKeys
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange({ farmerId: this.state.selectedRows[0].landwirtId }, {});
                }
            }
        );
    }

    render() {
        let displayStr;
        if (this.state.viewStr !== "") {
            displayStr = this.state.viewStr;
        } else {
            displayStr = i18n.t("report.label.selectFarmer");
        }

        return (
            <>
                <div className="company-select-wrap">
                    <div className="company-select-button">
                        <Button
                            disabled={this.props.disabled}
                            onClick={() => {
                                this.setState({
                                    lookUpVisible: !this.state.lookUpVisible,
                                    prePagination: this.state.pagination
                                });
                            }}
                        >
                            <i className="fal fa-search"></i>
                        </Button>
                    </div>
                    <div className="company-select-value" style={this.props.style}>
                        {displayStr}
                    </div>
                </div>
                <Modal
                    wrapClassName="company-select-wrap"
                    title={i18n.t("report.label.selectFarmer")}
                    visible={this.state.lookUpVisible}
                    width="80%"
                    zIndex={10000000}
                    onOk={() => {
                        this._setSelection();
                    }}
                    onCancel={() => {
                        this.setState(
                            {
                                lookUpVisible: !this.state.lookUpVisible,
                                selectedRowKeys: this.state.preSelectedRowKeys,
                                pagination: this.state.prePagination,
                                pageSize: this.state.prePagination.pageSize
                            },
                            () => {
                                this._fetchListData({ pagination: this.state.prePagination });
                            }
                        );
                    }}
                >
                    <ListTable
                        cols={farmer.columns}
                        data={this.state.listdata.length ? this.state.listdata : []}
                        delete={false}
                        pagination={this.state.pagination}
                        rowSelection={{
                            type: "radio",
                            onChange: (selectedRowKeys: any, selectedRows: any) => {
                                this.setState({
                                    selectedRowKeys: selectedRowKeys,
                                    selectedRows: selectedRows
                                });
                            },
                            selectedRowKeys: this.state?.selectedRowKeys != null ? this.state.selectedRowKeys : [0]
                        }}
                        onTableChange={(p: any) => {
                            this.setState(
                                {
                                    pageSize: p.pagination.pageSize
                                },
                                () => {
                                    this._fetchListData(p);
                                }
                            );
                        }}
                    />
                </Modal>
            </>
        );
    }
}

export default withAuth(FarmerIdSelect);
