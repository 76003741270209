import React from "react";
import { Row, Col, Button } from "antd";
import Form from "./../form";
import moment from "moment";
import i18n from "./../../../misc/i18n";
import reports from "./_reports";
import { conf, getImportApiConfig } from "./../../../../_conf";
import * as ImportApi from "../../../../api/importstatus";
import { withAuth } from "oidc-react";
import FsFrame from "./fsframe";
import userNotification from "../../../misc/notification";

const queryString = require("query-string");

class ReportBuilder extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            favorites: [],
            favVisible: false,
            activeFav: null,
            baseUri: conf.uri.reports,
            report: reports[this.props.props],
            reportName: reports[this.props.props].report,
            reportTitle: reports[this.props.props].reportTitle,
            itemsInFirstRow: reports[this.props.props].itemsInFirstRow,
            reportUri: "",
            iValues: {},
            // initialValues: null,
            // initialValues: this.getInitialValues(),
            activeFavName: null,
            rerenderStamp: new Date().getTime(),
            isFullScreen: false,
            CompanyId: null,
            language: "",
            formVisible: true
        };
        this.formRef = React.createRef();
        // this.handle = useFullScreenHandle();
    }

    _resizeIFrameToFitContent(iFrame) {
        iFrame.width = iFrame.contentWindow.document.body.scrollWidth;
        iFrame.height = iFrame.contentWindow.document.body.scrollHeight;
    }

    _getApi() {
        return new ImportApi.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));
    }

    setFormItems(items) {
        let formItems = items;
        for (let i = 0; i < formItems.length; i++) {
            formItems[i] = Object.assign(formItems[i], {
                // label: i18n.t("report.label." + formItems[i].name)
                label: "report.label." + formItems[i].name
            });
            if (typeof formItems[i].rawdata !== "undefined") {
                let tdata = [];
                for (let y = 0; y < formItems[i].rawdata.length; y++) {
                    tdata.push({
                        key: formItems[i].rawdata[y],
                        value: i18n.t("order.probenart." + formItems[i].rawdata[y])
                    });
                }
                formItems[i].data = tdata;
            }
        }
        return formItems;
    }

    updateProbennummerAlle() {
        let items = this.state.report.formItems;
        for (let i = 0; i < items.length; i++) {
            if (items[i].name === "SampleNumber") {
                items[i].data.forEach((el) => {
                    if (el.key === "all") {
                        el.value = i18n.t("report.value.all");
                    }
                });
            }
        }
        this.setState({
            report: {
                formItems: items
            }
        });
    }

    async componentDidMount() {
        let items = this.setFormItems(this.state.report.formItems);

        window.addEventListener("betriebChanged", (e) => {
            this.setInitialValues();
        });

        window.addEventListener("DOMContentLoaded", function (e) {
            // or, to resize all iframes:
            var iframes = document.querySelectorAll("iframe");
            for (var i = 0; i < iframes.length; i++) {
                // this._resizeIFrameToFitContent( iframes[i] );
            }
        });

        this.setState(
            {
                report: {
                    formItems: items
                }
            },
            () => {
                this.setInitialValues();
            }
        );
    }

    async setInitialValues() {
        let iValues = {};
        let formItems = this.state.report.formItems;
        let tdata = [];
        for (let i = 0; i < formItems.length; i++) {
            if (typeof formItems[i].default !== "undefined") {
                iValues[formItems[i].name] = formItems[i].default;
            }
            if (formItems[i].type === "select" && typeof formItems[i].rawdata !== "undefined" && formItems[i].rawdata.length <= 1) {
                formItems[i].disabled = true;
            }
        }
        iValues["CompanyId"] = localStorage.getItem("CompanyId");
        iValues = Object.assign(iValues, {
            date: [moment().subtract(2, "years"), moment()]
        });

        let pn = await this.getProbenNummer(iValues["Type"]);
        for (let i = 0; i < formItems.length; i++) {
            if (formItems[i].name === "SampleNumber") {
                tdata.push({
                    key: "all",
                    value: i18n.t("report.value.all")
                });
                for (let y = 0; y < pn.content.length; y++) {
                    tdata.push({
                        key: pn.content[y].probenNummer,
                        value: pn.content[y].probenNummer + " | " + moment(pn.content[y].tsProbennahme).format(i18n.t("report.dateformat"))
                    });
                }
                formItems[i].data = tdata;
                iValues["SampleNumber"] = ["all"];
            }
        }

        this.setState(
            {
                CompanyId: iValues["CompanyId"],
                iValues: iValues,
                report: {
                    formItems: formItems
                }
            },
            () => {
                this.setUriObjFromValues(iValues);
            }
        );
    }

    _toggleTooltip() {
        let items = this.state.report.formItems;
        for (let i = 0; i < items.length; i++) {
            if (items[i].type === "slider" && this.state.formVisible === true) {
                items[i] = Object.assign(items[i], {
                    tooltipVisible: true
                });
            } else {
                items[i] = Object.assign(items[i], {
                    tooltipVisible: false
                });
            }
        }

        this.setState({
            report: {
                formItems: items
            }
        });
    }

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2)
                },
                () => {
                    if (this.state.iValues != null) {
                        let items = this.setFormItems(this.state.report.formItems);
                        this.setState({
                            report: {
                                formItems: items
                            }
                        });
                        this.updateProbennummerAlle();
                        this.setUriObjFromValues(this.state.iValues);
                    }
                }
            );
        }
    }

    async getProbenNummer(type) {
        let probennummern = {
            content: []
        };

        let probenartStr = "";
        if (typeof type !== "undefined" && type.length > 0 && localStorage.getItem("CompanyId") != null) {
            probenartStr = ";probenart==" + type[0];

            try {
                let requestBody = {
                    size: 400,
                    search: "betriebsId==" + localStorage.getItem("CompanyId") + probenartStr + ";status==ERGEBNIS",
                    sort: "tsProbennahme,desc"
                };
                probennummern = await this._getApi().findFiltered(requestBody);
                if (probennummern == null || probennummern.content == null) {
                    return {
                        content: []
                    };
                }
                return probennummern;
            } catch (e) {
                userNotification("error", "notification.probes.get.error");
                return {
                    content: []
                };
            }
        }
        return {
            content: []
        };
    }

    getInitialValues() {
        return this.state.iValues;
    }

    setUriObjFromValues(values) {
        let obj = values;
        if (obj.date === undefined) {
            obj.date = [moment().subtract(2, "years"), moment()];
        }
        let uriObj = {
            CompanyId: obj.CompanyId,
            Perimeter: obj.Perimeter,
            Type: obj.Type,
            StartDate: moment(obj.date[0]).format("DD.MM.YYYY"),
            EndDate: moment(obj.date[1]).format("DD.MM.YYYY"),
            SampleNumber: obj.SampleNumber
        };
        if (typeof obj.SampleNumber !== "undefined" && obj.SampleNumber != null && obj.SampleNumber.indexOf("all") >= 0) {
            delete uriObj.SampleNumber;
        }
        let language = "";
        if (this.state.language === "de") {
            language = "German";
        } else if (this.state.language === "cz") {
            language = "Czech Republic";
        } else {
            language = "English";
        }

        if (obj.CompanyId != null) {
            let qustr = queryString.stringify(uriObj);
            this.setState({
                uriObj: uriObj,
                reportUri: this.state.baseUri + this.state.reportName + "&" + qustr + "&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer&Language=" + language,
                values: obj
            });
        }
    }

    async _companyIdChanged(values) {
        let formItems = this.state.report.formItems;

        let pn = await this.getProbenNummer(values.Type);
        let tdata = [];
        for (let i = 0; i < formItems.length; i++) {
            if (formItems[i].name === "SampleNumber") {
                tdata.push({
                    key: "all",
                    value: i18n.t("order.probenart.all")
                });
                for (let y = 0; y < pn.content.length; y++) {
                    tdata.push({
                        key: pn.content[y].probenNummer,
                        value: pn.content[y].probenNummer + " | " + moment(pn.content[y].tsProbennahme).format(i18n.t("report.dateformat"))
                    });
                }
                formItems[i].data = tdata;
                values["SampleNumber"] = ["all"];
                formItems[i].value = ["all"];
            }
        }

        this.setState(
            {
                CompanyId: values.CompanyId,
                iValues: values,
                report: {
                    formItems: formItems
                }
            },
            () => {
                this.setUriObjFromValues(values);
                // this.setInitialValues();
            }
        );
    }

    render() {
        let formItems = this.state.report.formItems;
        let iniValues = this.getInitialValues();
        return (
            <>
                <div className="list-detail-wrap">
                    <Row>
                        <Col span={24}>
                            <div className={this.state.formVisible ? "report-form" : "report-form-hidden"}>
                                <Form
                                    size="small"
                                    layout={{
                                        labelCol: { span: 0 },
                                        wrapperCol: { span: 17 }
                                    }}
                                    horizontal={false}
                                    isReportForm={true}
                                    itemsInFirstRow={this.state.itemsInFirstRow}
                                    values={iniValues}
                                    formItems={formItems}
                                    overlayVisible={this.state.favVisible ? false : true}
                                    onFieldsChange={(changedFields, allFields) => {
                                        let values = {};
                                        for (let i = 0; i < allFields.length; i++) {
                                            values[allFields[i].name[0]] = allFields[i].value;
                                        }
                                        if (this.state.CompanyId !== values.CompanyId) {
                                            this._companyIdChanged(values);
                                        } else {
                                            this.setUriObjFromValues(values);
                                        }
                                    }}
                                />
                            </div>
                            <div className="form-collapse-wrap">
                                <Button
                                    onClick={() => {
                                        this.setState(
                                            {
                                                formVisible: !this.state.formVisible
                                            },
                                            () => {
                                                this._toggleTooltip();
                                            }
                                        );
                                    }}
                                >
                                    {this.state.formVisible ? <i className="fal fa-chevron-square-up"></i> : <i className="fal fa-chevron-square-down"></i>}
                                </Button>
                                {/* {this.state.formVisible ? } */}
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FsFrame uri={this.state.reportUri} title={i18n.t("report.title." + this.state.reportTitle)} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withAuth(ReportBuilder);
