import React from "react";
import Location from "./tabs/location";
import FederalState from "./tabs/federalstate";
import Country from "./tabs/country";
import { Tabs } from 'antd';
import i18n from '../../../misc/i18n';
import { withAuth, AuthContextProps } from 'oidc-react';

interface IProps extends AuthContextProps {}

class GeoData extends React.Component<IProps>{

    _getUserPermissions() {
        let parseJwt = (token: any) => {
            var base64Url = token.split('.')[1];
            var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split('')
                    .map(function (c) {
                        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join('')
            );
            return JSON.parse(jsonPayload);
        };
        if (this.props.userData == null) {
            return {
                delete: false,
                onDelete: () => {},
                onNew: () => {},
                onSave: () => {},
                hasToolbar: false,
                showSaveButton: false
            };
        }

        let token = parseJwt(this.props.userData.access_token);
        let perm = {
            delete: false,
            onDelete: () => {},
            onNew: () => {},
            onSave: () => {},
            hasToolbar: false,
            showSaveButton: false
        };
        return({
            permission: perm,
            token: token
        });
    }

    render(){
        return(
            <Tabs type="card" destroyInactiveTabPane={true} style={{ marginTop: '16px', marginLeft: '16px', marginRight: '16px' }}>
                <Tabs.TabPane tab={i18n.t('tab.country')} key="1">
                    <Country {...this.props} perm={this._getUserPermissions()} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t('tab.federalstate')} key="2">
                    <FederalState {...this.props} perm={this._getUserPermissions()} />
                </Tabs.TabPane>
                <Tabs.TabPane tab={i18n.t("tab.location")} key="3">
                    <Location {...this.props} perm={this._getUserPermissions()} />
                </Tabs.TabPane>
            </Tabs>
        )
    }
}

export default withAuth(GeoData);