import React from 'react';
import { Result, Button } from 'antd';
import { AuthContextProps, withAuth } from "oidc-react";
import i18n from '../../misc/i18n';
interface IProps extends AuthContextProps {}

const Error404 = (props: IProps) => {
    //if(props.oidcUser ===null){
    //    document.location = "/"
    //}
    return (
        <div className="demes-app-error-wrap">
            <Result
                status="404"
                title="404"
                subTitle={i18n.t("404.text")}
                extra={
                    <Button
                        type="primary"
                        onClick={() => {
                            document.location.pathname = '/';
                        }}
                    >
                        {i18n.t("404.back")}
                    </Button>
                }
            />
        </div>
    );
};

export default withAuth(Error404);
