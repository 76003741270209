import * as React from 'react';
import Portal from './components/portal';
import { withTranslation } from 'react-i18next';
import { AuthProvider, UserManager } from 'oidc-react';
import axios from 'axios';
import './App.css';
import { conf } from './_conf.js';

const initUserManager = (props) => {
    const {
        authority,
        clientId,
        clientSecret,
        redirectUri,
        silentRedirectUri,
        postLogoutRedirectUri,
        responseType,
        scope,
        automaticSilentRenew,
        loadUserInfo,
        popupWindowFeatures,
        popupRedirectUri,
        popupWindowTarget,
        clockService,
        clockSkew
    } = props;
    return new UserManager({
        authority,
        client_id: clientId,
        client_secret: clientSecret,
        redirect_uri: redirectUri,
        silent_redirect_uri: silentRedirectUri || redirectUri,
        post_logout_redirect_uri: postLogoutRedirectUri || redirectUri,
        response_type: responseType || 'code',
        scope: scope || 'openid',
        loadUserInfo: loadUserInfo != null ? loadUserInfo : true,
        popupWindowFeatures: popupWindowFeatures,
        popup_redirect_uri: popupRedirectUri,
        popupWindowTarget: popupWindowTarget,
        automaticSilentRenew,
        clockService: clockService,
        clockSkew: clockSkew
    });
};

const userMngr = initUserManager(conf.oauth);

const App = class App extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            intervall: undefined
        };
    }
    componentDidMount() {
        let intervall = setInterval(async () => {
            let data = await axios.get('/wartung.json?t=' + new Date().getTime());
            if (data.data?.wartungsmodus) {
                window.location.replace('/?t=' + new Date().getTime());
            }
        }, 30000);

        this.setState({
            intervall: intervall
        });
    }
    componentWillUnmount() {
        clearInterval(this.state.intervall);
    }

    render() {
        var dualScreenLeft = window.screenLeft;
        var dualScreenTop = window.screenTop;

        let width = window.innerWidth ? window.innerWidth : document.documentElement.clientWidth ? document.documentElement.clientWidth : window.screen.width;
        let height = window.innerHeight
            ? window.innerHeight
            : document.documentElement.clientHeight
            ? document.documentElement.clientHeight
            : window.screen.height;

        var left = width / 2 - conf.oauth.popup_width / 2 + dualScreenLeft;
        var top = height / 2 - conf.oauth.popup_height / 2 + dualScreenTop;

        if (top < 0) {
            top = 120;
        }

        conf.oauth.popupWindowFeatures = conf.oauth.popupWindowFeatures + ',top=' + top + ',left=' + left + '';
        return (
            //@ts-ignore
            <AuthProvider
                {...conf.oauth}
                userManager={userMngr}
            >
                <div className="App">
                    {/* <CookieConsent /> */}
                    <Portal />
                </div>
            </AuthProvider>
        );
    }
};

export default withTranslation()(App);
