import React from "react";
import { Spin } from "antd";
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import TagSelect from "../../tagselect/tagselect";
import i18n from "../../../../misc/i18n";

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: any[];
    language: string;
    alli18n: string;
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    defaultValue: string[];
    startDate: any;
    endDate: any;
}

class LandSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            value: this.props.defaultValue ? this.props.defaultValue : [],
            language: "",
            alli18n: "order.probenart.all"
        };
    }

    componentDidMount() {
        this._getSelectData();
    }

    componentDidUpdate(prevProps: IProps) {
        if (i18n.language !== this.state.language) {
            let values = this.state.value;
            for (let i = 0; i < values.length; i++) {
                //Alte Übersetzung mit neuer austauschen
                if (values[i] === i18n.t(this.state.alli18n, { lng: this.state.language })) {
                    values[i] = i18n.t(this.state.alli18n);
                }
            }
            this.setState(
                {
                    language: i18n.language,
                    value: values
                },
                () => {
                    if (this.props.onChange != null) {
                        this.props.onChange(values, []);
                    }
                }
            );
        }
        //Wenn das Datum geändert wurde neue Daten laden
        if (this.props.startDate.toISOString() !== prevProps.startDate.toISOString() || this.props.endDate.toISOString() !== prevProps.endDate.toISOString()) {
            this._getSelectData();
        }
    }

    componentWillUnmount() {}

    async _getSelectData() {
        this.setState({
            loading: true
        });
        try {
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let requestBody = {
                size: 50,
                startDate: this.props.endDate,
                endDate: this.props.endDate
            };
            let resp = await api.findAllLandFromBetriebeTable(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }
    onSearch = async (value: string) => {
        this.setState({
            loading: true
        });
        if (value === "") {
            await this._getSelectData();
            return;
        }
        try {
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let requestBody = {
                size: 20,
                search: "iso=='" + value + "*'",
                startDate: this.props.startDate,
                endDate: this.props.endDate
            };
            let resp = await api.findAllLandFromBetriebeTable(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    };

    onChange = (value: string[], option: any) => {
        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }
        this.setState({
            value: value
        });

        this.setState({
            loading: false
        });
    };
    textRenderer(item: Api.Land) {
        return item.iso ? item.iso : "";
    }

    addAll(data: any[]) {
        if (data.length === 0) {
            return [];
        }
        let all = {
            iso: i18n.t(this.state.alli18n)
        };
        return [all, ...data];
    }

    render() {
        const { loading } = this.state;
        return (
            <TagSelect
                keyAttributeName="iso"
                valueAttributeName="iso"
                onChange={this.onChange}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onSearch={this.onSearch}
                selectdata={this.state.selectdata}
                textrender={this.textRenderer}
                style={{ width: "100%" }}
                selectAll={true}
                selectAlli18n={this.state.alli18n}
                value={this.state.value}
            />
        );
    }
}

export default withAuth(LandSelectForm);
