import React from 'react';
import { Form, Row, Col, Select, InputNumber, Checkbox, Divider, DatePicker, Button } from 'antd';
import i18n from '../../../misc/i18n';
import { conf } from '../../../../_conf';
import { withAuth } from 'oidc-react';
import FsFrame from '../reportbuilder/fsframe';
import SelectWithAll from './selectwithall';
import report from './_lkvauswertung';
import LKVBetriebSelectForm from './lkvbetriebselectform';
import moment from 'moment';
import { SelectWithAllTypes } from '../../../definitions/SelectWithAllTypes';

class LKVAuswertung extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            baseUri: conf.uri.reports,
            reportUri: '',
            values: {},
            buttonDisabled: true,
            rerenderStamp: new Date().getTime(),
            isFullScreen: false,
            formFields1: report.formItems,
            formFields2: report.formItems,
            formVisible: true,
            disabledFields: {
                probenart: true,
                land: false,
                bundesland: true,
                region: true,
                plz: true,
                analyseart: true,
                datum: false
            },
            language: '',
            compareActive: false
        };
    }

    formRef1 = React.createRef();
    formRef2 = React.createRef();

    defaultFormValues = {
        Ketose: this.findFormfield('Ketose', 3),
        Einheit: this.findFormfield('Einheit', 3),
        ZellzahlVon: this.findFormfield('ZellzahlVON', 3),
        ZellzahlBis: this.findFormfield('ZellzahlBIS', 3),
        MelktageVon: this.findFormfield('MelktageVON', 3),
        MelktageBis: this.findFormfield('MelktageBIS', 3),
        Lactation: this.findFormfield('Lactation', 3)
    };

    ///ReportServer/Pages/ReportViewer.aspx?/Grenzwerte GrassSilage&CompanyId=80&EndDate=24.02.2021&Perimeter=10&StartDate=24.02.2019&Type=Gras_silage&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer
    setUri() {
        let query = '/ReportServer/Pages/ReportViewer.aspx?/LKVAuswertung&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer';

        let companyId1 = this.findFormfield('companyId', 1);
        if (companyId1 != null && companyId1.value != null) {
            query += '&CompanyId=' + companyId1.value.companyId;
            // modified++;
            if (!this.state.compareActive) {
                query += '&CompanyId2=' + encodeURIComponent(companyId1.value.companyId);
            } else {
                let companyId2 = this.findFormfield('companyId', 2);
                if (companyId2 != null && companyId2.value != null) {
                    query += '&CompanyId2=' + encodeURIComponent(companyId2.value.companyId);
                }
            }
        } else {
            if (localStorage.getItem('CompanyId') != null) {
                query += '&CompanyId=' + encodeURIComponent(localStorage.getItem('CompanyId'));
                if (!this.state.compareActive) {
                    query += '&CompanyId2=' + encodeURIComponent(localStorage.getItem('CompanyId'));
                } else {
                    let companyId2 = this.findFormfield('companyId', 2);
                    if (companyId2 != null && companyId2.value != null) {
                        query += '&CompanyId2=' + encodeURIComponent(companyId2.value.companyId);
                    }
                }
            }
        }

        let ketose1 = this.findFormfield('Ketose', 1);
        if (ketose1 != null && ketose1.value?.length > 0) {
            if (!ketose1.value.includes(i18n.t('order.probenart.all'))) {
                for (let itm of ketose1.value) {
                    if (itm === 'Keine Ketose') {
                        query += '&Ketose=NULL';
                    } else {
                        query += '&Ketose=' + encodeURIComponent(itm);
                    }
                }
            } else {
                for (let itm of this.defaultFormValues.Ketose.data) {
                    if (itm.value === 'Keine Ketose') {
                        query += '&Ketose=NULL';
                    } else {
                        query += '&Ketose=' + encodeURIComponent(itm.value);
                    }
                }
            }
        }

        let unit1 = this.findFormfield('Einheit', 1);
        if (unit1 != null) {
            if (unit1.value != null) {
                query += '&Unit=' + unit1.value;
                query += '&Unit2=' + encodeURIComponent(unit1.value);
            } else {
                query += '&Unit=' + encodeURIComponent(this.defaultFormValues.Einheit.default);
                query += '&Unit2=' + encodeURIComponent(this.defaultFormValues.Einheit.default);
            }
        }
        // if (this.state.compareActive) {
        //     let unit2 = this.findFormfield('Einheit', 2);
        //     if (unit2 != null) {
        //         if (unit2.value != null) {
        //             query += '&Unit2=' + encodeURIComponent(unit2.value);
        //         } else {
        //             query += '&Unit2=' + encodeURIComponent(this.defaultFormValues.Einheit.default);
        //         }
        //     }
        // }

        let cellNumber1 = this.findFormfield('zellzahlVon', 1);
        if (cellNumber1 != null) {
            if (cellNumber1.value != null) {
                query += '&CellNumber=' + encodeURIComponent(cellNumber1.value);
                // modified++;
                if (!this.state.compareActive) {
                    query += '&CellNumber3=' + encodeURIComponent(cellNumber1.value);
                } else {
                    let cellNumber3 = this.findFormfield('zellzahlVon', 2);
                    if (cellNumber3 != null && cellNumber3.value != null) {
                        query += '&CellNumber3=' + encodeURIComponent(cellNumber3.value);
                    } else {
                        query += '&CellNumber3=' + encodeURIComponent(this.defaultFormValues.ZellzahlVon.default);
                    }
                }
            } else {
                query += '&CellNumber=' + encodeURIComponent(this.defaultFormValues.ZellzahlVon.default);
                if (!this.state.compareActive) {
                    query += '&CellNumber3=' + encodeURIComponent(this.defaultFormValues.ZellzahlVon.default);
                }
            }
        }

        let cellNumber2 = this.findFormfield('zellzahlBis', 1);
        if (cellNumber2 != null) {
            if (cellNumber2.value != null) {
                query += '&CellNumber2=' + encodeURIComponent(cellNumber2.value);
                // modified++;
                if (!this.state.compareActive) {
                    query += '&CellNumber4=' + encodeURIComponent(cellNumber2.value);
                } else {
                    let cellNumber4 = this.findFormfield('zellzahlBis', 2);
                    if (cellNumber4 != null && cellNumber4.value != null) {
                        query += '&CellNumber4=' + encodeURIComponent(cellNumber4.value);
                    } else {
                        query += '&CellNumber4=' + encodeURIComponent(this.defaultFormValues.ZellzahlBis.default);
                    }
                }
            } else {
                query += '&CellNumber2=' + encodeURIComponent(this.defaultFormValues.ZellzahlBis.default);
                if (!this.state.compareActive) {
                    query += '&CellNumber4=' + encodeURIComponent(this.defaultFormValues.ZellzahlBis.default);
                }
            }
        }

        let milkingDays1 = this.findFormfield('MelktageVon', 1);
        if (milkingDays1 != null) {
            if (milkingDays1.value != null) {
                query += '&MilkingDays=' + encodeURIComponent(milkingDays1.value);
                // modified++;
                if (!this.state.compareActive) {
                    query += '&MilkingDays3=' + encodeURIComponent(milkingDays1.value);
                }
            } else {
                query += '&MilkingDays=' + encodeURIComponent(this.defaultFormValues.MelktageVon.default);
                // modified++;
                if (!this.state.compareActive) {
                    query += '&MilkingDays3=' + encodeURIComponent(this.defaultFormValues.MelktageVon.default);
                }
            }
        }
        if (this.state.compareActive) {
            let milkingDays3 = this.findFormfield('MelktageVon', 2);
            if (milkingDays3 != null) {
                if (milkingDays3.value != null) {
                    query += '&MilkingDays3=' + encodeURIComponent(milkingDays3.value);
                } else {
                    query += '&MilkingDays3=' + encodeURIComponent(this.defaultFormValues.MelktageVon.default);
                }
            }
        }

        let milkingDays2 = this.findFormfield('MelktageBis', 1);
        if (milkingDays2 != null) {
            if (milkingDays2.value != null) {
                query += '&MilkingDays2=' + encodeURIComponent(milkingDays2.value);
                if (!this.state.compareActive) {
                    query += '&MilkingDays4=' + encodeURIComponent(milkingDays2.value);
                }
            } else {
                query += '&MilkingDays2=' + encodeURIComponent(this.defaultFormValues.MelktageBis.default);
                if (!this.state.compareActive) {
                    query += '&MilkingDays4=' + encodeURIComponent(this.defaultFormValues.MelktageBis.default);
                }
            }
        }
        if (this.state.compareActive) {
            let milkingDays4 = this.findFormfield('MelktageBis', 2);
            if (milkingDays4 != null) {
                if (milkingDays4.value != null) {
                    query += '&MilkingDays4=' + encodeURIComponent(milkingDays4.value);
                } else {
                    query += '&MilkingDays4=' + encodeURIComponent(this.defaultFormValues.MelktageBis.default);
                }
            }
        }

        let lactationMax = this.defaultFormValues.Lactation.max ? this.defaultFormValues.Lactation.max : 15;
        let lactation1 = this.findFormfield('Lactation', 1);
        if (lactation1 != null) {
            if (lactation1.value != null) {
                if (!Array.isArray(lactation1.value)) {
                    lactation1.value = [lactation1.value];
                }
                if (
                    !lactation1.value.some((value) => {
                        return value === i18n.t('order.probenart.all');
                    })
                ) {
                    for (let itm of lactation1.value) {
                        query += '&Lactation=' + encodeURIComponent(itm);
                        if (!this.state.compareActive) {
                            query += '&Lactation2=' + encodeURIComponent(itm);
                        }
                    }
                } else {
                    for (let i = 1; i <= lactationMax; i++) {
                        query += '&Lactation=' + encodeURIComponent(i);
                        if (!this.state.compareActive) {
                            query += '&Lactation2=' + encodeURIComponent(i);
                        }
                    }
                }
            } else {
                query += '&Lactation=' + encodeURIComponent(this.defaultFormValues.Lactation.default);
                if (!this.state.compareActive) {
                    query += '&Lactation2=' + encodeURIComponent(this.defaultFormValues.Lactation.default);
                }
            }
        }
        if (this.state.compareActive) {
            let lactation2 = this.findFormfield('Lactation', 2);
            if (lactation2 != null) {
                if (lactation2.value != null) {
                    if (!Array.isArray(lactation2.value)) {
                        lactation2.value = [lactation2.value];
                    }
                    if (
                        !lactation2.value.some((value) => {
                            return value === i18n.t('order.probenart.all');
                        })
                    ) {
                        for (let itm of lactation2.value) {
                            query += '&Lactation2=' + encodeURIComponent(itm);
                        }
                    } else {
                        for (let i = 1; i <= lactationMax; i++) {
                            query += '&Lactation2=' + encodeURIComponent(i);
                        }
                    }
                } else {
                    query += '&Lactation2=' + encodeURIComponent(this.defaultFormValues.Lactation.default);
                }
            }
        }

        let datum1 = this.findFormfield('Datum', 1);
        if (datum1 != null) {
            if (datum1.value?.length > 0) {
                if (moment(datum1.value[0]).isAfter(datum1.value[1])) {
                    query += '&Date1=' + moment(datum1.value[1]).format('YYYY-MM-DD');
                    query += '&Date11=' + moment(datum1.value[0]).format('YYYY-MM-DD');
                    if (!this.state.compareActive) {
                        query += '&Date2=' + moment(datum1.value[1]).format('YYYY-MM-DD');
                        query += '&Date22=' + moment(datum1.value[0]).format('YYYY-MM-DD');
                    }
                } else {
                    query += '&Date1=' + moment(datum1.value[0]).format('YYYY-MM-DD');
                    query += '&Date11=' + moment(datum1.value[1]).format('YYYY-MM-DD');
                    if (!this.state.compareActive) {
                        query += '&Date2=' + moment(datum1.value[0]).format('YYYY-MM-DD');
                        query += '&Date22=' + moment(datum1.value[1]).format('YYYY-MM-DD');
                    }
                }
            }
        }

        if (this.state.compareActive) {
            let datum2 = this.findFormfield('Datum', 2);
            if (datum2 != null) {
                if (datum2.value?.length > 0) {
                    if (moment(datum2.value[0]).isAfter(datum2.value[1])) {
                        query += '&Date2=' + moment(datum2.value[1]).format('YYYY-MM-DD');
                        query += '&Date22=' + moment(datum2.value[0]).format('YYYY-MM-DD');
                    } else {
                        query += '&Date2=' + moment(datum2.value[0]).format('YYYY-MM-DD');
                        query += '&Date22=' + moment(datum2.value[1]).format('YYYY-MM-DD');
                    }
                }
            }
        }

        let language = '';
        if (this.state.language === 'de') {
            language = 'German';
        } else if (this.state.language === 'cz') {
            language = 'Czech Republic';
        } else {
            language = 'English';
        }
        query += '&Language=' + language;

        if (this.state.compareActive) {
            query += '&Comparison=Yes';
        } else {
            query += '&Comparison=No';
        }

        this.setState({
            reportUri: query
        });
    }

    updateCompare = (e) => {
        if (e.target.checked) {
            this.setState(
                {
                    compareActive: true
                },
                () => {
                    this.setUri();
                }
            );
        } else {
            this.setState(
                {
                    compareActive: false,
                    formFields2: this.state.formFields1
                },
                () => {
                    this.setUri();
                }
            );
        }
    };

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2)
                },
                () => {
                    this.setUri();
                }
            );
        }
    }

    onChange = (newFields, betrieb) => {
        if (betrieb === 1) {
            this.setState(
                {
                    formFields1: newFields
                },
                () => {
                    this.setUri();
                }
            );
        } else {
            this.setState(
                {
                    formFields2: newFields
                },
                () => {
                    this.setUri();
                }
            );
        }
    };

    findFormfield(fieldname, number) {
        let fields;
        if (number === 1) {
            fields = this.state.formFields1;
        } else if (number === 3) {
            fields = report.formItems;
        } else {
            fields = this.state.formFields2;
        }
        return fields.find((obj) => {
            if (Array.isArray(obj.name)) {
                return obj.name.some((item) => {
                    return item === fieldname;
                });
            } else {
                return obj.name === fieldname;
            }
        });
    }

    handleUnitSelectChange = (value) => {
        this.formRef2.current.setFieldsValue({ Einheit: value });
    };

    render() {
        return (
            <>
                <div className="list-detail-wrap">
                    {this.renderForm(1)}
                    <Row>{this.state.compareActive && this.state.formVisible ? <Divider style={{ margin: '12px', background: '#a6a2a2' }} /> : null}</Row>
                    {this.renderForm(2)}
                    <Row>
                        <Col span={24}>
                            <Checkbox
                                defaultChecked={this.state.compareActive}
                                onChange={this.updateCompare}
                                style={this.state.formVisible ? { marginBottom: '10px' } : { display: 'none' }}
                            >
                                {i18n.t('form.label.Vergleichen')}
                            </Checkbox>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="form-collapse-wrap">
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            formVisible: !this.state.formVisible
                                        });
                                    }}
                                >
                                    {this.state.formVisible ? <i className="fal fa-chevron-square-up"></i> : <i className="fal fa-chevron-square-down"></i>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FsFrame uri={this.state.reportUri} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }

    renderForm(number) {
        return (
            <>
                <Row style={number === 2 && !this.state.compareActive ? { display: 'none' } : {}}>
                    <Col span={24}>
                        <div className={this.state.formVisible ? 'report-form' : 'report-form hidden'}>
                            <Form
                                ref={number === 1 ? this.formRef1 : this.formRef2}
                                size="small"
                                onFieldsChange={(_, allFields) => {
                                    this.onChange(allFields, number);
                                }}
                                initialValues={{
                                    Einheit: this.defaultFormValues.Einheit.default,
                                    zellzahlVon: this.defaultFormValues.ZellzahlVon.default,
                                    zellzahlBis: this.defaultFormValues.ZellzahlBis.default,
                                    MelktageVon: this.defaultFormValues.MelktageVon.default,
                                    MelktageBis: this.defaultFormValues.MelktageBis.default,
                                    Lactation: this.defaultFormValues.Lactation.default,
                                    Datum:
                                        number === 1 ? [moment(), moment().subtract(31, 'day')] : [moment().subtract(31, 'day'), moment().subtract(61, 'day')]
                                }}
                            >
                                <Row gutter={24}>
                                    <Col span={8} key={1}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.report.label.CompanyId') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="companyId" style={{ marginBottom: '12px' }}>
                                                    <LKVBetriebSelectForm />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    {number === 1 ? (
                                        <Col span={8} key={2}>
                                            <Row>
                                                <Col span={5}>
                                                    <div>{i18n.t('form.label.Ketose') + ' :'}</div>
                                                </Col>
                                                <Col span={19}>
                                                    <Form.Item name="Ketose" style={{ marginBottom: '12px' }}>
                                                        <SelectWithAll
                                                            data={this.defaultFormValues.Ketose.data}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                            disabled={number === 2 ? true : false}
                                                            allowClear={this.defaultFormValues.Ketose.allowClear}
                                                            dataType={SelectWithAllTypes.KETOSE}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                    ) : (
                                        <Col span={8} key={2} />
                                    )}
                                    <Col span={8} key={3}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.Einheit') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="Einheit" style={{ marginBottom: '12px' }}>
                                                    <Select
                                                        mode={this.defaultFormValues.Einheit.multiple ? 'multiple' : null}
                                                        disabled={this.defaultFormValues.Einheit.disabled || number === 2 ? true : false}
                                                        style={this.defaultFormValues.Einheit.style}
                                                        defaultValue={this.defaultFormValues.Einheit.default}
                                                        onChange={number === 1 ? this.handleUnitSelectChange : null}
                                                    >
                                                        {this.defaultFormValues.Einheit.data.map((itm, y) => {
                                                            return (
                                                                <Select.Option key={y} value={itm.key}>
                                                                    {i18n.t('form.label.lkv1Units.' + itm.key)}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} key={4}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.ZellzahlVon') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="zellzahlVon" style={{ marginBottom: '12px' }}>
                                                    <InputNumber min={1} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} key={5}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.ZellzahlBis') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="zellzahlBis" style={{ marginBottom: '12px' }}>
                                                    <InputNumber min={1} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} key={8}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.Lactation') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="Lactation" style={{ marginBottom: '12px' }}>
                                                    <SelectWithAll
                                                        defaultValue={[i18n.t('order.probenart.all')]}
                                                        allowClear={this.defaultFormValues.Lactation.allowClear}
                                                        dataType={SelectWithAllTypes.LACTATION}
                                                        maxLength={this.defaultFormValues.Lactation.max ? this.defaultFormValues.Lactation.max : 15}
                                                    />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} key={6}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.MelktageVon') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="MelktageVon" style={{ marginBottom: '12px' }}>
                                                    <Select
                                                        mode={this.defaultFormValues.MelktageVon.multiple ? 'multiple' : null}
                                                        disabled={this.defaultFormValues.MelktageVon.disabled ? true : false}
                                                        style={this.defaultFormValues.MelktageVon.style}
                                                        defaultValue={this.defaultFormValues.MelktageVon.default}
                                                    >
                                                        {this.defaultFormValues.MelktageVon.data.map((itm, y) => {
                                                            return (
                                                                <Select.Option key={y} value={itm.key}>
                                                                    {itm.value}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} key={7}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.MelktageBis') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="MelktageBis" style={{ marginBottom: '12px' }}>
                                                    <Select
                                                        mode={this.defaultFormValues.MelktageBis.multiple ? 'multiple' : null}
                                                        disabled={this.defaultFormValues.MelktageBis.disabled ? true : false}
                                                        style={this.defaultFormValues.MelktageBis.style}
                                                        defaultValue={this.defaultFormValues.MelktageBis.default}
                                                    >
                                                        {this.defaultFormValues.MelktageBis.data.map((itm, y) => {
                                                            return (
                                                                <Select.Option key={y} value={itm.key}>
                                                                    {itm.value}
                                                                </Select.Option>
                                                            );
                                                        })}
                                                    </Select>
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                    <Col span={8} key={9}>
                                        <Row>
                                            <Col span={5}>
                                                <div>{i18n.t('form.label.Datum') + ' :'}</div>
                                            </Col>
                                            <Col span={19}>
                                                <Form.Item name="Datum" style={{ marginBottom: '12px' }}>
                                                    <DatePicker.RangePicker style={{ width: '100%' }} allowClear={false} />
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Col>
                                </Row>
                            </Form>
                        </div>
                    </Col>
                </Row>
            </>
        );
    }
}

export default withAuth(LKVAuswertung);
