import React from 'react';
import { useRouteMatch, withRouter } from 'react-router-dom'
import Home from "./../../apps/home";
import Start from "./../../apps/start";
import DeIframe from "./../../apps/iframe";
import Default from "./../../apps/default";
import Component from "./../../apps/component";

const DeLayout = (props) => {
    // The `path` lets us build <Route> paths that are
    // relative to the parent route, while the `url` lets
    // us build relative links.
    // let { path, url } = useRouteMatch();
    let { path } = useRouteMatch();

    function getRoute(props, path){
        for(let i=0; i<props.routes.length; i++){
            if(props.routes[i].id === path){
                return props.routes[i];
            }
        }
        return false;
    }

    let r = getRoute(props, path);
    if(typeof r.app !== "undefined"){
        switch(r.app){
            case "public":
                return <Start />;
            case "Error404":
                return <div>Hi</div>
            case "home":
                return <Home /> 
            case "iframe":
                return <DeIframe src={r.uri} className="public-iframe"/>
            case "component":
                return <Component {...r} collapsed={props.collapsed} />;
            case "search":
                return <Component {...r} collapsed={props.collapsed} />;
                    default:
                return (
                    <React.Fragment>
                        <Default {...r} />
                    </React.Fragment>
                )
        }
    }else{
        return (
            <React.Fragment>
                <Start {...r} />
            </React.Fragment>
        )
    }
}

export default withRouter(DeLayout);