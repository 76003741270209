import React from "react";
import maillog from "./_logListeIT";
import * as Api from "../../../../../../api/rest";
import helper from "../../../listdetail/listtable/_helper";
import ListDetail from "../../../listdetail";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";
import userNotification from "../../../../../misc/notification";

class LogListeIT extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: 20,
            detailVisible: false,
            conf: maillog,
            ownloadLoading: false
        };
    }

    _getApi() {
        return new Api.MailControllerApi(getRestApiConfig("mail", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            detailVisible: false,
            detaildata: {},
            loading: false,
            pagination: {
                total: resp.totalElements,
                pageSize: this.state.pageSize
            }
        });
    }

    async _fetchListData(p) {
        if (p.filter == null) {
            p.filter = {
                ereignisId: []
            };
        }
        if (p.filter["ereignisId"] == null && p.filter["betreff"] != null) {
            p.filter["ereignisId"] = [...p.filter["betreff"]];
            delete p.filter["betreff"];
        }

        if (p.filter["ereignisId"] == null || !p.filter["ereignisId"].length || !p.filter["ereignisId"][0]?.length) {
            p.filter["ereignisId"] = [["EVENT_PROBENB_IMPORT_FAILED", "EVENT_CVAS_IMPORT_FAILED", "EVENT_LKV_IMPORT_FAILED"]];
        }

        this.setState({
            loading: true
        });

        let params = helper.prepareListParams(p, this.state.pageSize);
        try {
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort
            };
            let resp = await this._getApi().findMailsFiltered(requestBody);
            this._setListState(resp);
        } catch (e) {
            userNotification("error", "notification.mail.get.error");
            this.setState({
                loading: false
            });
        }
    }

    async _fetchDetailData(id) {
        let requestBody = {
            id: id
        };
        let resp = await this._getApi().findMailById(requestBody);
        if (resp != null) {
            this.setState({
                detaildata: resp,
                detailVisible: true,
                selectedRegion: id
            });
        }
    }

    render() {
        return (
            <ListDetail
                showSaveButton={false}
                renderTitle={this.state.conf.renderTitle}
                columns={this.state.conf.columns}
                listdata={this.state.listdata}
                formitems={this.state.conf.formitems}
                detailProp={this.state.conf.detailProp}
                detaildata={this.state.detaildata}
                fetchListData={(p) => this._fetchListData(p)}
                detailVisible={this.state.detailVisible}
                loading={this.state.loading}
                pagination={this.state.pagination}
                onDetailClose={() => {
                    this.setState({
                        detailVisible: false
                    });
                }}
                onRowSelection={(id) => {
                    this._fetchDetailData(id);
                }}
                onTableChange={(p) => {
                    this.setState(
                        {
                            pageSize: p.pagination.pageSize
                        },
                        () => {
                            this._fetchListData(p);
                        }
                    );
                }}
            />
        );
    }
}
export default withAuth(LogListeIT);
