import React from "react";
import { Button, Select } from "antd";

export default class SortField extends React.Component {
    
    constructor(props){
        super(props)
        this.state = {
            field: (this.props.sorter.field) ? this.props.sorter.field : "",
            order: (this.props.sorter.order) ? this.props.sorter.order : "default",
            sortDirs: {
                "default": { icon: <i className="fal fa-sort"></i>},
                "ascend": { icon: <i className="fad fa-sort-down"></i> },
                "descend": { icon: <i className="fad fa-sort-up"></i> }
            }
        }
    }

    _handleChange(){
        if(this.props.onChange){
            if(this.state.order === "default"){
                this.props.onChange({})
            }else{
                this.props.onChange({
                    field: this.state.field,
                    order: this.state.order
                })
            }
        }
    }

    render(){
        return(
            <div className="sortfield">
                <Select defaultValue={this.state.field} onChange={(e) => {
                        this.setState({
                            field: e
                        },() => {
                            this._handleChange()
                        })
                    }}
                >
                    {this.props.columns.map((col, i) => {
                        return(col.sort === true) ? <Select.Option key={i} value={col.dataIndex}>{col.title}</Select.Option> : null
                    })}
                </Select>
                <Button disabled={(this.state.field === "") ? true : false} onClick={() => {
                        let order = this.state.order;
                        if(this.state.order === "default"){ order = "ascend" }
                        if(this.state.order === "ascend"){ order = "descend" }
                        if(this.state.order === "descend"){ order = "default" }
                        this.setState({
                            order: order
                        },() => {
                            this._handleChange()
                        })
                    }}
                >
                    {this.state.sortDirs[this.state.order].icon}
                </Button>
            </div>
       )
    }
}
