import parseJWT from "./../permission"

let companiesDetailFilter = (obj, oidcUser)=>{
    let token = parseJWT(oidcUser.access_token)
    // let role = null
    let edit = false;

    let editExternals = false;

    if(token.realm_access.roles.indexOf("Administrator") >= 0 || token.realm_access.roles.indexOf("BusinessAdministrator") >= 0){
        edit = true;        
    }

    if(token.realm_access.roles.indexOf("Administrator") >= 0){
        editExternals = true;
    }
 
    // token.realm_access.roles.forEach((keycloakRole) => {
    //     keycloakRole = keycloakRole.toLowerCase()
    //     if(keycloakRole === "administrator"){
    //         role = 6
    //     }
    //     if (keycloakRole === "businessadministrator"  && role < 5) {
    //         role = 5
    //     }else if((keycloakRole === "berater" || keycloakRole === "consultant") && role < 2){
    //         role = 2
    //     }else if((keycloakRole === "farmer" || keycloakRole === "landwirt") && role < 2){
    //         role = 1
    //     }
    // })

    if(!edit){
        obj.formitems.forEach((element,index) => {
            if(element.type !== "divider"){
                // element.type = "plaintext"
                element.disabled = (edit) ? false : true;
            }
        })
    }

    if(!editExternals){
        let neu = []
        obj.formitems.forEach((element,index) => {
            if(!element.label.includes("externeSysteme")){
                neu.push(element)
            }
        })
        obj.formitems = neu;
    }
    return obj
}

export default companiesDetailFilter;