import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";
import userNotification from "../../../../../misc/notification";
let GeoOrt = class GeoOrt extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectdata: [],
            value: "",
            valueObj: null,
            landIso: "*",
            loading: false,
            disabled: true,
            aktPostleitzahl: "*"
        };
        this.loadNewData = this.debounce(this.loadNewData, 600);
        this.updateSelectedOrt = this.updateSelectedOrt.bind(this);
        this.updateStateEvent = this.updateStateEvent.bind(this);
        this.updateByLandwirt = this.updateByLandwirt.bind(this);
    }
    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
    updateStateEvent(e) {
        this.setState({
            landIso: e.detail
        });
    }
    updateByLandwirt(e) {
        this.setState(
            {
                value: e.detail.ort
            },
            () => {
                this.props.onChange(e.detail.ort);
            }
        );
    }

    async updateSelectedOrt(e) {
        let itm = e.detail;
        this.setState({
            loading: true,
            selectdata: [],
            aktPostleitzahl: itm.postleitzahl != null ? itm.postleitzahl : "*"
        });
        let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
        let searchString =
            "iso==" +
            this.state.landIso +
            ";postleitzahl=='" +
            (this.state.aktPostleitzahl != null && this.state.aktPostleitzahl !== "*"
                ? this.state.aktPostleitzahl
                : itm.postleitzahl != null
                ? itm.postleitzahl
                : "*") +
            "'";
        try {
            let requestBody = {
                search: searchString
            };
            let body = await api.findFilteredPlz(requestBody);
            if (body.content !== undefined) {
                if (body.content.length === 1) {
                    this.setState(
                        {
                            valueObj: body.content[0],
                            value: itm.ort,
                            selectdata: body.content,
                            loading: false,
                            disabled: true
                        },
                        () => {
                            this.props.onChange(itm.ort);
                        }
                    );
                } else {
                    this.setState({
                        selectdata: body.content !== undefined ? body.content : [],
                        loading: false,
                        value: null,
                        valueObj: null,
                        disabled: false
                    });
                }
            }
        } catch (e) {
            userNotification("error", "notification.geodb.place.error");
            this.setState({
                loading: false
            });
        }
    }
    componentDidMount() {
        window.addEventListener("landSelected", this.updateStateEvent, true);
        window.addEventListener("plzSelected", this.updateSelectedOrt, true);
        window.addEventListener("landwirtSelected", this.updateByLandwirt, true);
        this._getSelectData();
    }
    componentWillUnmount() {
        window.removeEventListener("plzSelected", this.updateSelectedOrt, true);
        window.removeEventListener("landSelected", this.updateStateEvent, true);
        window.removeEventListener("landwirtSelected", this.updateByLandwirt, true);
    }

    componentDidUpdate() {
        if (this.props.data !== {} && this.props.data.ort != null && this.state.value === "") {
            this.setState(
                {
                    value: this.props.data.ort
                },
                () => {
                    this.props.onChange(this.props.data.ort);
                }
            );
        }
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        if (this.state.landIso === "*") {
            return;
        }
        try {
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let requestBody = {
                search: "iso==" + this.state.landIso + ";postleitzahl==*"
            };
            let resp = await api.findFilteredPlz(requestBody);
            this.setState({
                selectdata: resp.content !== undefined ? resp.content : [],
                loading: false
            });
        } catch (e) {
            userNotification("error", "notification.geodb.place.error");
            this.setState({
                loading: false
            });
        }
    }

    loadNewData = async (userInput) => {
        this.setState({
            loading: true,
            selectdata: []
        });
        try {
            let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
            let isoPLZ = "iso==" + this.state.landIso + ";postleitzahl=='" + (this.state.aktPostleitzahl != null ? this.state.aktPostleitzahl : "*") + "'";
            let searchString = userInput != null && userInput !== "" ? isoPLZ + ";ort=='" + userInput + "*'" : isoPLZ;
            let requestBody = {
                search: searchString
            };
            let body = await api.findFilteredPlz(requestBody);
            this.setState({
                selectdata: body.content !== undefined ? body.content : [],
                loading: false
            });
        } catch (e) {
            userNotification("error", "notification.geodb.place.error");
            this.setState({
                loading: false
            });
        }
    };
    onChange = (value, option) => {
        window.dispatchEvent(new CustomEvent("ortSelected", { detail: option.obj }));
        this.setState(
            {
                value,
                valueObj: option.obj,
                selectdata: [],
                loading: false
            },
            () => {
                this.props.onChange(value);
            }
        );
    };

    render() {
        const { loading, value, disabled } = this.state;
        return (
            <Select
                disabled={disabled}
                value={value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                showSearch
                onChange={this.onChange}
                filterOption={false}
                onSearch={this.loadNewData}
            >
                {this.state.selectdata.map((itm) => {
                    return (
                        <Select.Option key={itm.id + "_" + itm.ort} value={itm.ort} obj={itm}>
                            {itm.ort}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
};

export default withAuth(GeoOrt);
