import React from 'react';
import laborbuch from './_laborbuch';
import ListDetail from '../listdetail';
import Detail from '../listdetail/detail';
import helper from '../listdetail/listtable/_helper';
import LoadMask from '../../../misc/loadmask';
import { getImportApiConfig } from '../../../../_conf';
import { withAuth } from 'oidc-react';
import * as Api from '../../../../api/importstatus';
import userNotification from '../../../misc/notification';
import { isAdmin, isBusinessadmin } from '../../../misc/getUserRoles';
import { Tabs } from 'antd';
import moment from 'moment';
import i18n from '../../../misc/i18n';
import { isFarmer } from '../../../misc/getUserRoles';
import ResultDownload from '../resultdownload';
import cloneDeep from 'lodash.clonedeep';
import { dec } from '../../../misc/crypt';
const queryToStr = require('query-string');

class Laborbuch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            currentCompany: undefined,
            data: [],
            loading: false,
            listdata: {},
            detaildata: {},
            pagination: {},
            pageSize: 20,
            conf: this.getLaborbuchLayout(),
            downloadLoading: false,
            detailVisible: false
        };
    }
    getLaborbuchLayout() {
        let labor = cloneDeep(laborbuch);
        if (!isAdmin(this.props.userData) && !isBusinessadmin(this.props.userData)) {
            labor.formitemsSecondTab.forEach((element) => {
                if (element.name === 'status') {
                    element.type = 'labstatusplaintext';
                }
            });
        }
        return labor;
    }

    _getApi() {
        return new Api.ProbenbestellungControllerApi(getImportApiConfig('probeorder', this.props.userData));
    }

    async componentDidMount() {
        if(typeof(this.props.id) !== "undefined"){
            this._fetchDetailData(this.props.id)
        }else{
            await this._fetchListData({});
            const result = queryToStr.parse(document.location.search);
            if(typeof(result.id) !== "undefined" && result.id !== ""){
                let id = dec(result.id);
                this._fetchDetailData(id)
            }
        }  
    }

    async _fetchListData(p) {
        this.setState({
            currentSearchParameters: p
        });
        if (p.sorter == null || Object.keys(p.sorter).length === 0) {
            p.sorter = {};
            p.sorter.order = 'descend';
            p.sorter.field = 'tsProbennahme';
        }
        this.setState({
            loading: true
        });
        let excludeDeaktiviert = 'status=out=(DEAKTIVIERT)';
        let params = helper.prepareListParams(p, this.state.pageSize);

        if (params.search != null && !params.search.includes('status')) {
            params.search = excludeDeaktiviert + ';' + params.search;
        } else {
            if (params.search == null) {
                params.search = excludeDeaktiviert;
            }
        }

        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        }
        let resp = await this._getApi()
            .findFiltered(requestBody)
            .catch(() => {
                userNotification('error', 'notification.order.get.error');
                this.setState({
                    loading: false
                });
            });
        if (resp != null) {
            this.setState({
                listdata: helper.prepareListData(resp.content),
                loading: false,
                // detailVisible: false,
                pageSize: params.size,
                pagination: {
                    total: resp.totalElements,
                    pageSize: params.size
                }
            });
        }
    }

    async _fetchDetailData(id) {
        try {
            let requestBody = {
                probenId: id
            }
            let resp = await this._getApi().findProbenbestellungById(requestBody);
            if (resp.tsLaborEingang != null) {
                resp.tsLaborEingang = moment(resp.tsLaborEingang);
            }
            if (resp.tsSanoEingang != null) {
                resp.tsSanoEingang = moment(resp.tsSanoEingang);
            }

            let labor = this.getLaborbuchLayout();
            labor.formitems = this.filterFormitems(resp);

            this.setState({
                detaildata: resp,
                detailVisible: true,
                conf: labor
            });
        } catch (e) {
            userNotification('error', 'notification.order.content.get.error');
        }
    }

    async _updateData(id, data) {
        if (data.tsLaborEingang != null) {
            data.tsLaborEingang = data.tsLaborEingang.format();
        }
        try {
            let requestBody = {
                id: id,
                body: data
            }
            await this._getApi().updateProbenbestellung(requestBody);
            userNotification('success', 'notification.order.content.update.success');
            this._fetchListData(this.state.currentSearchParameters);
        } catch (e) {
            userNotification('error', 'notification.order.content.update.error');
        }
    }
    filterFormitems(detaildata) {
        let labor = this.getLaborbuchLayout();
        let probe = labor.formitems
            .find((obj) => {
                return obj.type === 'collapse';
            })
            ?.groups?.find((obj) => {
                return obj.label === 'probe';
            });

        let items = probe.items;

        if (items == null) {
            return labor.formitems;
        }
        let newItems = [];
        if (
            detaildata.probenart == null ||
            (detaildata.probenart.toLowerCase().indexOf('maize') === -1 && detaildata.probenart.toLowerCase().indexOf('gras') === -1)
        ) {
            for (let element of items) {
                if (element.label != null) {
                    if (element.label.toLowerCase().indexOf('hybridsorte') === -1) {
                        if (element.label.toLowerCase().indexOf('schnitthoehe') === -1) {
                            if (element.label.toLowerCase().indexOf('ertrag') === -1) {
                                newItems.push(element);
                            }
                        }
                    }
                }
            }
            probe.items = newItems;
        }
        return labor.formitems;
    }
    shouldShow(itm) {
        if (isFarmer(this.props.userData) && itm.dokumentArt.toLowerCase() === 'xml') {
            return false;
        }
        return true;
    }
    getProtectedCVASList() {
        let cvasList = [];
        if (this.state.detaildata != null && this.state.detaildata.cvasDocumentList != null) {
            this.state.detaildata.cvasDocumentList.forEach((e) => {
                if (this.shouldShow(e)) {
                    cvasList.push(e);
                }
            });
        }

        return cvasList;
    }

    render() {
        let extratabs = [
            <Tabs.TabPane tab={i18n.t('navitem.eingabe')} key="2">
                <Detail
                    className="labbook-big"
                    showSaveButton={true}
                    onSave={(data) => {
                        this._updateData(this.state.detaildata[this.state.conf.detailProp], data);
                    }}
                    formitems={this.state.conf.formitemsSecondTab}
                    data={this.state.detaildata}
                />
            </Tabs.TabPane>
        ];
        if (this.state.detaildata.status === 'ERGEBNIS') {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t('navitem.dateien')} key="3">
                    {
                        //<Row style={{marginBottom:"20px"}}><Col span={5}>{i18n.t("form.label.Probennummer")+": "}</Col><Col>{this.state.detaildata.probenNummer}</Col></Row>
                    }
                    <ResultDownload dataSource={this.getProtectedCVASList()} />
                </Tabs.TabPane>
            );
        }
        return (
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail
                    detailClassName="labbook"
                    columns={this.state.conf.columns}
                    renderTitle={this.state.conf.renderTitle}
                    listdata={this.state.listdata}
                    pagination={this.state.pagination}
                    showSaveButton={false}
                    formitems={this.state.conf.formitems}
                    detailProp={this.state.conf.detailProp}
                    detaildata={this.state.detaildata}
                    fetchListData={(p) => this._fetchListData(p)}
                    detailVisible={this.state.detailVisible}
                    loading={this.state.loading}
                    onDetailClose={() => {
                        this.setState({
                            detailVisible: false
                        },() => {
                            if(this.props.onDetailClose){
                                this.props.onDetailClose()
                            }
                        });
                    }}
                    onRowSelection={(id) => {
                        this._fetchDetailData(id);
                    }}
                    onTableChange={(p) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                    extraTabs={extratabs}
                />
            </>
        );
    }
}

export default withAuth(Laborbuch);
