/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Betrieb,
    BetriebFromJSON,
    BetriebFromJSONTyped,
    BetriebToJSON,
} from './Betrieb';

/**
 * 
 * @export
 * @interface BetriebWithRoles
 */
export interface BetriebWithRoles {
    /**
     * 
     * @type {Betrieb}
     * @memberof BetriebWithRoles
     */
    betrieb?: Betrieb;
    /**
     * 
     * @type {Array<string>}
     * @memberof BetriebWithRoles
     */
    roles?: Array<string>;
}

export function BetriebWithRolesFromJSON(json: any): BetriebWithRoles {
    return BetriebWithRolesFromJSONTyped(json, false);
}

export function BetriebWithRolesFromJSONTyped(json: any, ignoreDiscriminator: boolean): BetriebWithRoles {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'betrieb': !exists(json, 'betrieb') ? undefined : BetriebFromJSON(json['betrieb']),
        'roles': !exists(json, 'roles') ? undefined : json['roles'],
    };
}

export function BetriebWithRolesToJSON(value?: BetriebWithRoles | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'betrieb': BetriebToJSON(value.betrieb),
        'roles': value.roles,
    };
}

