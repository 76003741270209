import React from 'react';
import moment from 'moment';
import { Form, Input, Button, DatePicker, InputNumber } from 'antd';
import BundeslandFilter from "./bundeslandfilter";
import ProbenartFilter from "./probenartfilter";
import ListFilter from "./listfilter";
import i18n from './../../../misc/i18n';
import "./colfilter.css";

const colfilters = {
   
    render(props) {
        return {
            _doSimpleType(type, title){
                switch(type){
                    case 'list':
                        return (
                            <ListFilter filter_list={this.filter_list} renderer={this.renderer} placeholder={i18n.t('global.search') + " " + title} />
                        );                                            
                    case 'boolean':
                        return (
                            <ListFilter filter_list={this.filter_list} renderer={this.renderer} placeholder={i18n.t('global.search') + " " + title} />
                        );                                            
                    case 'number': 
                        return (
                            <InputNumber placeholder={i18n.t('global.search') + " " + title} />
                        )
                    case 'daterange': 
                        return(
                            <DatePicker.RangePicker
                                format={i18n.t('format.date')}
                                allowClear={false}
                            />
                        )
                    case 'date': 
                        return (
                            <DatePicker placeholder={i18n.t('global.search') + " " + title}
                                allowClear={true}
                            />
                        )
                    case 'bundesland': 
                        return(
                            <BundeslandFilter placeholder={i18n.t('global.search') + " " + title} />
                        )
                    case 'probenart':
                        return (
                            <ProbenartFilter placeholder={i18n.t('global.search') + " " + title} />
                        )
                    default:
                        return (
                            <Input placeholder={i18n.t('global.search') + " " + title} allowClear={true} />
                        )
                }
            },            
            _doType(setSelectedKeys, selectedKeys, confirm) {
                switch(props.filter.type){
                    case 'number': 
                        return (
                            <InputNumber placeholder={i18n.t('global.search')}
                                onChange={(e) => {this.handlePreSelect( e ? [e] : null, setSelectedKeys ); }}
                                onPressEnter={() => {this.handlePreSelect( selectedKeys, confirm, this.dataIndex ); }}
                            />
                        )
                    case 'date': 
                        return (
                            <DatePicker placeholder={i18n.t('global.search')}
                                format="DD.MM.YYYY"
                                allowClear={false}
                                onChange={(e) => {
                                    e = moment(e.startOf('day')).format();
                                    this.handlePreSelect( e ? [e] : null, setSelectedKeys);
                                }}
                            />
                        )
                    default:
                        return (
                            <Input placeholder={i18n.t('global.search')}
                                onChange={(e) => {
                                    this.handlePreSelect( e.target.value ? [e.target.value] : null, setSelectedKeys ); 
                                }}
                                onPressEnter={() => {
                                    this.handlePreSelect( selectedKeys, confirm, this.dataIndex ); 
                                }}
                            />
                        )
                }
            },

            handlePreSelect(val, setSelectedKeys) {
                let values = [];
                if(val !== null){
                    for(let i=0; i<val.length; i++){
                        values.push(val[i])
                    }
                }
                setSelectedKeys(values ? values : []);
            },

            filterIcon: <i className="fal fa-search"></i>,

            filterDropdown({ setSelectedKeys, selectedKeys, confirm, clearFilters}) {
                return (
                    <div className="responsive-table-filter-wrap">
                        <Form
                            ref={(node) => {
                                this.filterForm = node;
                            }}
                        >
                            <Form.Item name={this.dataIndex}>
                                {this._doType(setSelectedKeys,selectedKeys,confirm)}
                            </Form.Item>
                        </Form>
                        <div className="responsive-table-filter-button-wrap">
                            <Button onClick={() => {
                                    this.filterForm.resetFields([this.dataIndex]);
                                    clearFilters();
                                }}
                            >{i18n.t('btn.reset')}</Button>
                            <Button type="primary" onClick={() => {
                                    confirm();
                                }}
                            >{i18n.t('btn.ok')}</Button>
                        </div>
                    </div>
                );
            }
        };
    }
};

export default colfilters;
