import React from "react";
import { Button, Popconfirm } from "antd";
import * as Api from "../../../../api/rest";
import { getRestApiConfig } from "../../../../_conf";
import { Form } from "antd";
import { IsoCountry } from './../../sano/form/editors/geolocation';
import i18n from "../../../misc/i18n";
import userNotification from "../../../misc/notification";
import { withAuth } from "oidc-react";

class ExportCompany extends React.Component {

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            country: undefined
        }
    }

    _getApi() {
        return new Api.BetriebControllerApi(getRestApiConfig('company', this.props.userData));
    }

    async _doExport(){
        userNotification('info', 'notification.company.export.info')
        let requestBody = {
            lands: this.state.country
        }
        await this._getApi().generateBulkXmls(requestBody)
        .catch(() => {
            userNotification('error', 'notification.company.export.error');
            this.setState({
                loading: false
            });
        });
    }

    render(){
        return(
            <div className="export-wrapper">
                <div className="export-item">
                    <div className="export-item-action">
                        <Form>
                            <Form.Item
                                label={i18n.t("form.label.country")}
                                name="country"
                            >
                                <IsoCountry 
                                    // mode="multiple"
                                    onChange={(v) => {
                                        this.setState({
                                            country: v
                                        })
                                    }}
                                />
                            </Form.Item>

                            <Form.Item>
                                <Popconfirm
                                    title={i18n.t("question.exportcompany")}
                                    onConfirm={() => {
                                        this._doExport()
                                    }}
                                    okText={i18n.t("btn.yes")}
                                    cancelText={i18n.t("btn.no")}
                                    disabled={(this.state.country === undefined || this.state.country.length === 0) ? true : false}
                                >
                                    <Button type="primary" htmlType="submit" disabled={(this.state.country === undefined || this.state.country.length === 0) ? true : false}>
                                        {i18n.t("btn.export")}
                                    </Button>
                                </Popconfirm>
                            </Form.Item>
                        </Form>
                    </div>
                </div>
            </div>
        )
    }

}

export default withAuth(ExportCompany)