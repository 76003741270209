import React from 'react';
import { Slider } from 'antd';
import { AuthContextProps, withAuth } from "oidc-react";
import { SliderValue } from 'antd/lib/slider';

interface IState {
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
}

class Perimeterform extends React.Component<IProps, IState> {
  
    onChange = (value: SliderValue) => {
        if (this.props.onChange != null) {
            this.props.onChange(value, {});
        }

        this.setState({
            loading: false
        });
    };
    
    render() {
        return (
            <Slider min={10} max={100} step={10} defaultValue={10} onAfterChange={this.onChange}/>
        );
    }
}

export default withAuth(Perimeterform);
