import React from 'react';
import { Select } from 'antd';
import i18n from '../../../../../../misc/i18n';

export default class LabStatusDropdown extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
        this.updateStateEvent = this.updateStateEvent.bind(this)
    }

    onChange = (value,key) => {
        window.dispatchEvent(new CustomEvent("labbook_statusChangedDropdown",{detail:value}))
        this.props.onChange(value);
        this.setState({
            value: value
        })
    };
    updateStateEvent(e) {
        this.props.onChange(e.detail)
        this.setState(
            {
                value: e.detail
            }
        )
    }
    componentDidMount() {
        window.addEventListener("labbook_changeStatus", this.updateStateEvent, true);
    }
    componentWillUnmount() {
        window.removeEventListener("labbook_changeStatus", this.updateStateEvent, true);
    }

    render() {
        return (
            <Select mode={this.props.item.multiple ? 'multiple' : null} disabled={this.props.item.disabled ? true : false} value={this.state.value} onChange={this.onChange}>
                {this.props.item.data.map((itm , y ) => {
                    return (
                        <Select.Option key={y} value={itm.key}>
                            {i18n.t("order.status."+itm.value) }
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}
