import React from 'react';
import { Tabs } from 'antd';
import { withAuth } from "oidc-react";
import i18n from '../../../misc/i18n';
import LogListeIT from './loglisten/logListeIT';
import LogListeLandwirt from './loglisten/logListeLandwirt';
import LogListeFachadmin from './loglisten/logListeFachadmin';
const { TabPane } = Tabs;
class MailLog extends React.Component {

    render() {

        return (
            <Tabs type="card" style={{"marginTop":"16px",marginLeft:"16px",marginRight:"16px"}}>
                    <TabPane tab={i18n.t("tab.Importfehler")} key="111">
                        <LogListeIT/>
                    </TabPane>
                    
                    <TabPane tab={i18n.t("tab.ProbenstatusChange")} key="333">
                        <LogListeLandwirt/>
                    </TabPane>
                    <TabPane tab={i18n.t("tab.NewUser")} key="222">
                        <LogListeFachadmin/>
                    </TabPane>
                </Tabs>
        );
    }
}
export default withAuth(MailLog);
