import {Filter} from '../../../../../definitions/FilterEnum'
import {Renderer} from '../../../../../definitions/RendererEnum'


const companies = {
	"detailProp": "betriebsId",
	"formitems": [{
		"label": "contact",
		"type": "divider"
	}, {
		"label": "betriebsName",
		"name": "betriebsName",
		"type": "text",
		"disabled": false
	}, {
		"label": "betriebsNummer",
		"name": "betriebsNummer",
		"type": "text",
		"disabled": false
	}, {
		"label": "strasseNr",
		"name": "strasseNr",
		"type": "text",
		"disabled": false
	}, {
		"label": "plz",
		"name": "plz",
		"type": "text",
		"disabled": false
	}, {
		"label": "ort",
		"name": "ort",
		"type": "text",
		"disabled": false
	}, {
		"label": "land",
		"name": "land",
		"type": "text",
		"disabled": false
	}, {
		"label": "bundesland",
		"name": "bundesland",
		"type": "text",
		"disabled": false
	}, {
		"label": "regionen",
		"name": "regionen",
		"type": "select",
		"disabled": false,
		"multiple": true,
		"data": []
	}, {
		"label": "landwirtId",
		"name": "landwirtId",
		"type": "select",
		"disabled": false,
		"data": []
	}, {
		"label": "standardBetrieb",
		"name": "standardBetrieb",
		"type": "boolean",
		"disabled": false
	}, {
		"label": "beschreibung",
		"name": "beschreibung",
		"type": "textarea",
		"disabled": false
	}, {
		"label": "externeSysteme",
		"type": "divider"
	}, {
		"label": "externeSystemeDDW",
		"name": "externeSystemeDDW",
		"type": "text",
		"disabled": false
	}, {
		"label": "externeSystemeCVAS",
		"name": "externeSystemeCVAS",
		"type": "text",
		"disabled": false
	}, {
		"label": "externeSystemeLKV",
		"name": "externeSystemeLKV",
		"type": "text",
		"disabled": false
	}, {
		"label": "externeSystemeCRM",
		"name": "externeSystemeCRM",
		"type": "text",
		"disabled": false
	}, {
		"label": "externeSystemeERP",
		"name": "externeSystemeERP",
		"type": "text",
		"disabled": false
	}
],

	"columns": [{
		"title": "landwirt",
		"dataIndex": "lwnachname",
		"filter": Filter.TEXT,
		"sort": false,
		"responsive": ["lg"]
	},{
		"title": "betriebsId",
		"dataIndex": "betriebsId",
		"filter": "number",
		"sort": true,
	}, {
		"title": "plz",
		"dataIndex": "plz",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
	}, {
		"title": "ort",
		"dataIndex": "ort",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
    }, {
		"title": "bundesland",
		"renderer": Renderer.BUNDESLAND,
		"dataIndex": "bundesland",
		"filter": Filter.BUNDESLAND,
		"responsive": ["lg"]
    }, {
		"title": "land",
		"dataIndex": "land",
		"filter": Filter.TEXT,
		"sort": true,
		"responsive": ["lg"]
    }]

}

export default companies;