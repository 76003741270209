import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import en_US from 'antd/es/locale/en_US';
import de_DE from 'antd/es/locale/de_DE';
import cs_CZ from "antd/es/locale/cs_CZ";
import moment from "moment";
import 'moment/locale/de';
import 'moment/locale/en-gb';

i18n
    // load translation using http -> see /public/locales (i.e. https://github.com/i18next/react-i18next/tree/master/example/react/public/locales)
    // learn more: https://github.com/i18next/i18next-http-backend
    .use(Backend)
    // .use(reactI18nextModule)
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        backend: {
            requestOptions:{
                cache: 'no-cache'
            }
        },
        fallbackLng: 'en',
        debug: false,
        interpolation: {
            escapeValue: true, // not needed for react as it escapes by default
        },
        react: {
            wait: true
        }
    }
);

const i18nApp = {
    getLocale(){
        if(i18n.language === "de"){
            moment.locale('de');
            return de_DE;
        }else if(i18n.language === "cz"){
            moment.locale('cz');
            return cs_CZ;
        }else{
            moment.locale('en');
            return en_US;
        }
    },
    getBundeslandString(bundeslandIso:string){
        return i18n.t("iso.bundesland."+bundeslandIso)
    },
    getProbenartString(probenart: string){
        return i18n.t("order.probenart."+probenart)
    }
}

export default i18n;
export { i18nApp };