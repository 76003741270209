/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface BundeslandFilter
 */
export interface BundeslandFilter {
    /**
     * 
     * @type {string}
     * @memberof BundeslandFilter
     */
    name?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof BundeslandFilter
     */
    children?: Array<string>;
}

export function BundeslandFilterFromJSON(json: any): BundeslandFilter {
    return BundeslandFilterFromJSONTyped(json, false);
}

export function BundeslandFilterFromJSONTyped(json: any, ignoreDiscriminator: boolean): BundeslandFilter {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'name': !exists(json, 'name') ? undefined : json['name'],
        'children': !exists(json, 'children') ? undefined : json['children'],
    };
}

export function BundeslandFilterToJSON(value?: BundeslandFilter | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'name': value.name,
        'children': value.children,
    };
}

