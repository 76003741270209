import { message } from "antd";

class ajax_class {

    IsJsonString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    async tRequest(url, method = "GET", get = {}, post = null, handleError = true, token){
        let https = require("https");
        let agent = new https.Agent({
            rejectUnauthorized: false
        })
        let retval = {};
        let params = {
            agent,
            method: method, // *GET, POST, PUT, DELETE, etc.
            headers: {
                "Authorization": "Bearer " + token,
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client

            // credentials: 'same-origin',
            
        }


        // params = {
        //     agent,
        //     method: method, // *GET, POST, PUT, DELETE, etc.
        //     mode: 'no-cors', // no-cors, *cors, same-origin
        //     cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        //     credentials: 'same-origin', // include, *same-origin, omit
        //     headers: {
        //         "Authorization": "Bearer " + token,
        //     },
        //     redirect: 'follow', // manual, *follow, error
        //     referrerPolicy: 'no-referrer', // no-referrer, *client
        // }

        
        if(method === "POST"){
            params.body = (this.IsJsonString(post)) ? JSON.stringify(post) : post
        }

        await fetch(url, params)        
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok', response);
                }else{
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json();
                    } else {
                        return response.text();
                    }
                }
            })                
            .then((returnedResponse) => {
                retval = returnedResponse;
                return(retval);
            })
            .catch((error) => {
                if(handleError){
                    message.error(error.message);
                }else{
                    retval = {
                        error: error
                    };
                }
                return Promise.reject(retval)
            })
        return retval;
    }

    async request(url, method = "GET", get = {}, post = null, handleError = true, token){
        let https = require("https");
        let agent = new https.Agent({
            rejectUnauthorized: false
        })
        let retval = {};

        let params = {
            agent,
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }
        
        if(method === "POST"){
            if(this.IsJsonString(post)){
                params.body = JSON.stringify(post) // body data type must match "Content-Type" header
            }else{
                params.body = post // body data type must match "Content-Type" header
            }
        }
        
        await fetch(url, params)        
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok', response);
                }else{
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json();
                    } else {
                        return response.text();
                    }
                }
            })                
            .then((returnedResponse) => {
                retval = returnedResponse;
            })
            .catch((error) => {
                retval = {
                    error: error
                };
                return(error);
            });
        return retval;
    
    }
    
//-----------------------------------------------------------------

async insertRequest(url, method = "GET", get = {}, post = null, handleError = true, token){
    let https = require("https");
    let agent = new https.Agent({
        rejectUnauthorized: false
    })
    let retval = {};

    let params = {
        agent,
        method: method, // *GET, POST, PUT, DELETE, etc.
        mode: 'cors', // no-cors, *cors, same-origin
        cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
        credentials: 'same-origin', // include, *same-origin, omit
        headers: {
            'Content-Type': 'application/json',
        },
        redirect: 'follow', // manual, *follow, error
        referrerPolicy: 'no-referrer', // no-referrer, *client
    }
    
    if(method === "POST"){
        if(this.IsJsonString(post)){
            params.body = JSON.stringify(post) // body data type must match "Content-Type" header
        }else{
            params.body = post // body data type must match "Content-Type" header
        }
    }
    
    await fetch(url, params)        
        .then((response) => {
            if (!response.ok) {
                throw new Error('Network response was not ok', response);
            }else{
                const contentType = response.headers.get("content-type");
                if (contentType && contentType.indexOf("application/json") !== -1) {
                    return response.json();
                } else {
                    return response.text();
                }
            }
        })                
        .then((returnedResponse) => {
            retval = returnedResponse;
        })
        .catch((error) => {
            retval = {
                error: error
            };
            return(error);
        });
    return retval;

}

//-------------------------------------------------------------
    async request_BAK(url, method = "GET", get = {}, post = null, handleError = true, token){
        let https = require("https");
        let agent = new https.Agent({
            rejectUnauthorized: false
        })
        let retval = {};

        let params = {
            agent,
            method: method, // *GET, POST, PUT, DELETE, etc.
            mode: 'cors', // no-cors, *cors, same-origin
            cache: 'no-cache', // *default, no-cache, reload, force-cache, only-if-cached
            credentials: 'same-origin', // include, *same-origin, omit
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
            },
            redirect: 'follow', // manual, *follow, error
            referrerPolicy: 'no-referrer', // no-referrer, *client
        }
        
        if(method === "POST"){
            if(this.IsJsonString(post)){
                params.body = JSON.stringify(post) // body data type must match "Content-Type" header
            }else{
                params.body = post // body data type must match "Content-Type" header
            }
        }
        
        await fetch(url, params)        
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok', response);
                }else{
                    const contentType = response.headers.get("content-type");
                    if (contentType && contentType.indexOf("application/json") !== -1) {
                        return response.json();
                    } else {
                        return response.text();
                    }
                }
            })                
            .then((returnedResponse) => {
                retval = returnedResponse;
            })
            .catch((error) => {
                retval = {
                    error: error
                };
                return(error);
            });
        return retval;
    
    }
}

const ajax = new ajax_class();
export { ajax_class }
export default ajax;
