import React from 'react';
// import { Typography } from 'antd';
import moment from "moment";
import i18n from "./../../../../../misc/i18n";
// const {Paragraph} = Typography
interface IProps{
    value: string
}

export default class PlainTimeDisplay extends  React.Component<IProps>{

    render(){
        return (
            <div className="deparagraph"> {moment(this.props.value).format(i18n.t("format.datetime"))} </div>
        )
            
    }

}
