/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface LkvDokument
 */
export interface LkvDokument {
    /**
     * 
     * @type {number}
     * @memberof LkvDokument
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof LkvDokument
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    dokumentName?: string;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    mimeTyp?: string;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    externeNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    dokumentArt?: string;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof LkvDokument
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof LkvDokument
     */
    importJobId?: string;
    /**
     * 
     * @type {Date}
     * @memberof LkvDokument
     */
    dateierstellungsdatum900003?: Date;
}

export function LkvDokumentFromJSON(json: any): LkvDokument {
    return LkvDokumentFromJSONTyped(json, false);
}

export function LkvDokumentFromJSONTyped(json: any, ignoreDiscriminator: boolean): LkvDokument {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'dokumentName': !exists(json, 'dokumentName') ? undefined : json['dokumentName'],
        'mimeTyp': !exists(json, 'mimeTyp') ? undefined : json['mimeTyp'],
        'externeNummer': !exists(json, 'externeNummer') ? undefined : json['externeNummer'],
        'dokumentArt': !exists(json, 'dokumentArt') ? undefined : json['dokumentArt'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'importJobId': !exists(json, 'importJobId') ? undefined : json['importJobId'],
        'dateierstellungsdatum900003': !exists(json, 'dateierstellungsdatum900003') ? undefined : (new Date(json['dateierstellungsdatum900003'])),
    };
}

export function LkvDokumentToJSON(value?: LkvDokument | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'dokumentName': value.dokumentName,
        'mimeTyp': value.mimeTyp,
        'externeNummer': value.externeNummer,
        'dokumentArt': value.dokumentArt,
        'uiInsert': value.uiInsert,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'importJobId': value.importJobId,
        'dateierstellungsdatum900003': value.dateierstellungsdatum900003 === undefined ? undefined : (value.dateierstellungsdatum900003.toISOString()),
    };
}

