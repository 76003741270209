import React from 'react';
import { Select, Spin } from 'antd';
import { AuthContextProps, withAuth } from "oidc-react";
import i18n from '../../../../misc/i18n';

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: string;
    language: string;
    alli18n: string;
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    disabled: boolean | undefined;
    defaultValue: string;
}

class CalculationSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [{value:"% / ppm",key:"% / ppm"},{value:"mEq",key:"mEq"}],
            loading: false,
            value: "% / ppm",
            language: "",
            alli18n:"order.probenart.all"
        };
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        try {
                this.setState({
                    selectdata: ["% / ppm","mEq"],
                    loading: false
                });
            
        } catch (e) {
            this.setState({
                loading: false
            });
        }
    }


    onChange = (value: string, option: any) => {

        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };

    addAll(items : any[]){
        if(items.length > 0){
            return [i18n.t(this.state.alli18n), ...items]
        }
        return items   
    }

    render() {
        const { loading } = this.state;
        return (
            <Select
                defaultValue={"% / ppm"}
                //value={this.props.value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                style={{ width: '100%' }}
                disabled={this.props.disabled}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => { return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}}
            >
                {this.state.selectdata.map((itm, y) => {
                    return (
                        <Select.Option key={itm.key} value={itm.key}>
                            {itm.value}
                        </Select.Option>
                    );
                })}
            </Select>

        );
    }
}

export default withAuth(CalculationSelectForm);
