import {Filter} from '../../../../../definitions/FilterEnum'
const farmer = {
    detailProp: 'landwirtId',
    formitems: [
        {
            label: 'nachname',
            name: 'nachname',
            type: 'text',
            disabled: false,
            required: true
        },
        {
            label: 'vorname',
            name: 'vorname',
            type: 'text',
            disabled: false
        },
        {
            label: 'email',
            name: 'email',
            type: 'text',
            disabled: false
        },
        {
            label: 'land',
            name: 'land',
            type: 'geoland',
            disabled: false,
            data: [],
            required: true
        }
    ],
    renderTitle: (rec) => {
        let title = (rec.vorname !== null) ? rec.vorname + " " : ""
        title += rec.nachname
        return(
            title
        )
    },
    columns: [
        {
            "title": "nachname",
            "dataIndex": "nachname",
            "filter": Filter.TEXT,
            "sort": true,
            // "responsive": ["lg"]
        }, {		
            "title": "vorname",
            "dataIndex": "vorname",
            "filter": Filter.TEXT,
            "sort": true,
            // "responsive": ["lg"]
        }, {
            "title": "land",
            "dataIndex": "land",
            "filter": Filter.TEXT,
            "sort": true,
            // "responsive": ["lg"]
        },{
            "title": "email",
            "dataIndex": "email",
            "filter": Filter.TEXT,
            "sort": true,
            "responsive": ["lg"]
        }
    ]
};

export default farmer;
