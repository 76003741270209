/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SdnNachrichtWrapper
 */
export interface SdnNachrichtWrapper {
    /**
     * 
     * @type {number}
     * @memberof SdnNachrichtWrapper
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    text?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    betreff?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnNachrichtWrapper
     */
    tsGesendet?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    ereignisId?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    emailadresse?: string;
    /**
     * 
     * @type {boolean}
     * @memberof SdnNachrichtWrapper
     */
    gesendetJn?: boolean;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnNachrichtWrapper
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnNachrichtWrapper
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtWrapper
     */
    status?: string;
}

export function SdnNachrichtWrapperFromJSON(json: any): SdnNachrichtWrapper {
    return SdnNachrichtWrapperFromJSONTyped(json, false);
}

export function SdnNachrichtWrapperFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdnNachrichtWrapper {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'text': !exists(json, 'text') ? undefined : json['text'],
        'betreff': !exists(json, 'betreff') ? undefined : json['betreff'],
        'tsGesendet': !exists(json, 'tsGesendet') ? undefined : (new Date(json['tsGesendet'])),
        'ereignisId': !exists(json, 'ereignisId') ? undefined : json['ereignisId'],
        'emailadresse': !exists(json, 'emailadresse') ? undefined : json['emailadresse'],
        'gesendetJn': !exists(json, 'gesendetJn') ? undefined : json['gesendetJn'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'status': !exists(json, 'status') ? undefined : json['status'],
    };
}

export function SdnNachrichtWrapperToJSON(value?: SdnNachrichtWrapper | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'text': value.text,
        'betreff': value.betreff,
        'tsGesendet': value.tsGesendet === undefined ? undefined : (value.tsGesendet.toISOString()),
        'ereignisId': value.ereignisId,
        'emailadresse': value.emailadresse,
        'gesendetJn': value.gesendetJn,
        'uiInsert': value.uiInsert,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'status': value.status,
    };
}

