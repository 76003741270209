import React from 'react';
import { Modal, Button } from 'antd';
import i18n from '../misc/i18n';
import { Card } from 'antd';

const { Meta } = Card;

interface IProps {
    onOk: ((e: React.MouseEvent<HTMLElement, MouseEvent>) => void) | undefined;
    visible: boolean;
}

interface IState {
    activeTab: string;
}

class About extends React.Component<IProps, IState> {
    state = {
        activeTab: 'general'
    };

    getMenu() {
        if (this.state.activeTab === 'general') {
            return (
                <div>
                    <img src="/img/splash_03.png" alt="/img/splash_03.png" />
                </div>
            );
        } else {
            return null;
        }
    }

    render() {
        return (
            <Modal
                title={i18n.t('dlg.about.title')}
                visible={this.props.visible}
                onCancel={this.props.onOk}
                width={300}
                footer={[
                    <Button key="ok" type="primary" onClick={this.props.onOk}>
                        OK
                    </Button>
                ]}
            >
                <div className="about-modal">
                    <Card style={{ width: 300 }} bordered={false} cover={<img alt="example" src="/img/de-logo_profile-transp.png" />}>
                        <Meta
                            title="DEMES"
                            description={
                                <div>
                                    Version 7.0.3
                                    <br />
                                    by DE software & control GmbH
                                </div>
                            }
                        />
                    </Card>
                </div>
            </Modal>
        );
    }
}

export default About;
