/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface Landwirt
 */
export interface Landwirt {
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    parameter?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Landwirt
     */
    emailbenachrichtigungJn?: boolean;
    /**
     * 
     * @type {number}
     * @memberof Landwirt
     */
    landwirtId?: number;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    nachname?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    email?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    vorname?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    strasse?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    telefonNummer?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof Landwirt
     */
    tsInsert?: Date;
    /**
     * 
     * @type {Array<number>}
     * @memberof Landwirt
     */
    betriebsIds?: Array<number>;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    uiChange?: string;
    /**
     * 
     * @type {Date}
     * @memberof Landwirt
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    ortsteil?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    ort?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    land?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    bundesland?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    plz?: string;
    /**
     * 
     * @type {string}
     * @memberof Landwirt
     */
    beschreibung?: string;
    /**
     * 
     * @type {boolean}
     * @memberof Landwirt
     */
    anonymisiertJn?: boolean;
    /**
     * 
     * @type {{ [key: string]: string; }}
     * @memberof Landwirt
     */
    externeSysteme?: { [key: string]: string; };
}

export function LandwirtFromJSON(json: any): Landwirt {
    return LandwirtFromJSONTyped(json, false);
}

export function LandwirtFromJSONTyped(json: any, ignoreDiscriminator: boolean): Landwirt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'parameter': !exists(json, 'parameter') ? undefined : json['parameter'],
        'emailbenachrichtigungJn': !exists(json, 'emailbenachrichtigungJn') ? undefined : json['emailbenachrichtigungJn'],
        'landwirtId': !exists(json, 'landwirtId') ? undefined : json['landwirtId'],
        'nachname': !exists(json, 'nachname') ? undefined : json['nachname'],
        'email': !exists(json, 'email') ? undefined : json['email'],
        'vorname': !exists(json, 'vorname') ? undefined : json['vorname'],
        'strasse': !exists(json, 'strasse') ? undefined : json['strasse'],
        'telefonNummer': !exists(json, 'telefonNummer') ? undefined : json['telefonNummer'],
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'betriebsIds': !exists(json, 'betriebsIds') ? undefined : json['betriebsIds'],
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'ortsteil': !exists(json, 'ortsteil') ? undefined : json['ortsteil'],
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'land': !exists(json, 'land') ? undefined : json['land'],
        'bundesland': !exists(json, 'bundesland') ? undefined : json['bundesland'],
        'plz': !exists(json, 'plz') ? undefined : json['plz'],
        'beschreibung': !exists(json, 'beschreibung') ? undefined : json['beschreibung'],
        'anonymisiertJn': !exists(json, 'anonymisiertJn') ? undefined : json['anonymisiertJn'],
        'externeSysteme': !exists(json, 'externeSysteme') ? undefined : json['externeSysteme'],
    };
}

export function LandwirtToJSON(value?: Landwirt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'parameter': value.parameter,
        'emailbenachrichtigungJn': value.emailbenachrichtigungJn,
        'landwirtId': value.landwirtId,
        'nachname': value.nachname,
        'email': value.email,
        'vorname': value.vorname,
        'strasse': value.strasse,
        'telefonNummer': value.telefonNummer,
        'uiInsert': value.uiInsert,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'betriebsIds': value.betriebsIds,
        'uiChange': value.uiChange,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'ortsteil': value.ortsteil,
        'ort': value.ort,
        'land': value.land,
        'bundesland': value.bundesland,
        'plz': value.plz,
        'beschreibung': value.beschreibung,
        'anonymisiertJn': value.anonymisiertJn,
        'externeSysteme': value.externeSysteme,
    };
}

