import React from "react";
import { Button, Modal, Input } from "antd";
import anonymise from "./_anonymise";
import Form from "./../form";
import { getRestApiConfig } from "../../../../_conf";
import * as Api from "../../../../api/rest";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from './../../../misc/notification';
import i18n from "../../../misc/i18n";

interface IProps extends AuthContextProps {
    currentFarmer: number;
    // userData: User;
    detailData: any;
    disableAll: boolean;
    onAnonymise: Function;
}

interface IState {
    promtVisible: boolean;
    isValid: boolean;
    formRef: any;
}

class Anonymise extends React.Component<IProps, IState> {

    constructor(props: IProps) {
        super(props);

        this.state = {
            promtVisible: false,
            isValid: false,
            formRef: React.createRef()
        }
    }

    _getApi() {
        return new Api.LandwirtControllerApi(getRestApiConfig("farmer", this.props.userData));
    }

    async _anonymise(){
        let requestBody = {
            landwirtId : this.props.currentFarmer,
        }
        await this._getApi().anonymize(requestBody);


        userNotification("success", "notification.company.update.success");
        this.setState({
            promtVisible: !this.state.promtVisible
        },() => {
            if(this.props.onAnonymise){
                this.props.onAnonymise()
            }
        })
    }

    render() {
        if(this.props.disableAll){
            return (
                <>
                    <Form
                        ref={this.state.formRef}
                        horizontal={false}
                        formItems={anonymise.formitems}
                        values={this.props.detailData}
                    />
                    <div className="anonymise-msg"><i className="far fa-exclamation-triangle"></i> {i18n.t("anonymise.msg")}</div>
                </>
            )
        }else{
            return (
                <>
                    <Button onClick={() => {
                        this.setState({
                            promtVisible: true
                        })
                    }}>{i18n.t("anonymise.btn")}</Button>
                    <br /><br />
                    <div className="anonymise-msg"><i className="far fa-exclamation-triangle"></i> {i18n.t("anonymise.msg1")}</div>
                    <Modal
                        title={i18n.t("anonymise.win.title")}
                        visible={this.state.promtVisible}
                        closable={false}
                        footer={[
                            <Button key="ok"  type="primary" onClick={() => {
                                this._anonymise()
                            }} disabled={!this.state.isValid}>
                                {i18n.t("btn.ok")}
                            </Button>,
                            <Button key="cancel" onClick={() => {
                                this.setState({
                                    promtVisible: !this.state.promtVisible
                                })
                            }}>
                                {i18n.t("btn.cancel")}
                            </Button>,
                        ]}
                    >
                        {i18n.t("anonymise.win.description")}
                        <Input onChange={(e) => {
                            if(e.target.value === "anonymise"){
                                this.setState({
                                    isValid: true
                                })
                            }else{
                                this.setState({
                                    isValid: false
                                })
                            }
                        }}></Input>
                    </Modal>            

                </>
            );
        }
    }
}

export default withAuth(Anonymise);
