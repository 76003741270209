import React from 'react';
import { Form, Row, Col, DatePicker, Button } from 'antd';
import moment from 'moment';
import i18n from '../../../misc/i18n';
import { conf } from '../../../../_conf';
import { withAuth } from 'oidc-react';
import FsFrame from '../reportbuilder/fsframe';
import CalculationSelectForm from './calculationselectform';
import PerimeterForm from './perimeterform';
import ProbenartSelectForm from './probenartselectform';
import CompanyIdSelectForm from './companyidselectform';
import report from './_xrfreport';

class XRFReport extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            baseUri: conf.uri.reports,
            reportUri: '',
            generatedUri: '',
            values: {},
            buttonDisabled: true,
            rerenderStamp: new Date().getTime(),
            isFullScreen: false,
            form: React.createRef(),
            formFields: report.formItems,
            formVisible: true,
            disabledFields: {
                probenart: true,
                land: false,
                bundesland: true,
                region: true,
                plz: true,
                analyseart: true,
                datum: false
            },
            language: ''
        };
    }

    setUri() {
        let query = '/ReportServer/Pages/ReportViewer.aspx?/XRFAuswertung&rc:Zoom=Page%20Width&rc:Stylesheet=HtmlViewer';
        let fields = this.state.formFields;
        let modified = 0;

        if (
            fields[0].value != null &&
            fields[0].value !== '' &&
            Object.keys(fields[0].value).length !== 0 &&
            fields[0].value.constructor === Object &&
            fields[0].value.land != null &&
            fields[0].value.companyId != null
        ) {
            query += '&CompanyId=' + fields[0].value.companyId;
            query += '&Land=' + encodeURIComponent(fields[0].value.land);
            modified++;
        } else {
            if (localStorage.getItem('CompanyId') != null) {
                query += '&CompanyId=' + localStorage.getItem('CompanyId');
                modified++;
            }
        }

        if (fields[1].value != null && fields[1].value !== '') {
            query += '&Perimeter=' + fields[1].value;
            modified++;
        }

        if (fields[2].value.length > 0) {
            if (fields[2].value.includes(i18n.t('order.probenart.all'))) {
                modified++;
            } else {
                fields[2].value.forEach((itm) => {
                    query += '&Type=' + encodeURIComponent(itm);
                });
                modified++;
            }
        }

        if (fields[3].value?.length > 0) {
            query += '&StartDate=' + moment(fields[3].value[0]).format('YYYY-MM-DD');
            query += '&EndDate=' + moment(fields[3].value[1]).format('YYYY-MM-DD');
            modified++;
        }
        if (fields[4].value != null && fields[4].value !== '') {
            query += '&Calculation=' + encodeURIComponent(fields[4].value);
            modified++;
        }

        let language = '';
        if (this.state.language === 'de') {
            language = 'German';
        } else if (this.state.language === 'cz') {
            language = 'Czech Republic';
        } else {
            language = 'English';
        }
        query += '&Language=' + language;

        if (modified === fields.length) {
            this.setState({
                buttonDisabled: false,
                generatedUri: query,
                reportUri: query
            });
        } else {
            this.setState({
                buttonDisabled: true,
                reportUri: '',
                generatedUri: query
            });
        }
    }

    componentDidMount() {
        this.setState({}, () => this.setUri());
    }

    componentDidUpdate() {
        if (i18n.language.substr(0, 2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0, 2)
                },
                () => {
                    this.setUri();
                }
            );
        }
    }

    setDisabledFields(clear = false) {
        let disObjs = {
            land: true,
            companyId: false
        };
        if (clear) {
            this.setState({
                disabledFields: disObjs
            });
            return;
        }
        let formresponse = this.state.formFields;
        //Land Prüfen
        if (formresponse[0] != null && formresponse[0].value?.length > 0) {
            disObjs.land = false;
            //Land prüfen und CompanyId freigeben
            if (formresponse[1] != null && formresponse[1].value?.length > 0) {
                disObjs.companyId = false;
            }

            this.setState({
                disabledFields: disObjs
            });
            return;
        }
        this.setState({
            disabledFields: disObjs
        });
    }

    onChange = (newFields) => {
        this.setState(
            {
                formFields: newFields
            },
            () => {
                this.setDisabledFields();
                this.setUri();
            }
        );
    };
    render() {
        let disabled = this.state.disabledFields;
        let startDate = this.state.formFields[3].value[0] != null ? moment(this.state.formFields[3].value[0]).startOf('day') : undefined;
        let endDate = this.state.formFields[3].value[1] != null ? moment(this.state.formFields[3].value[1]).endOf('day') : undefined;
        return (
            <>
                <div className="list-detail-wrap">
                    <Row>
                        <Col span={24}>
                            <div className={this.state.formVisible ? 'report-form' : 'report-form hidden'}>
                                <Form
                                    ref={this.state.form}
                                    size="small"
                                    fields={this.state.formFields}
                                    onFieldsChange={(_, allFields) => {
                                        this.onChange(allFields);
                                    }}
                                >
                                    <Row gutter={24}>
                                        <Col xs={24} md={12} xl={8} key={1}>
                                            <Row>
                                                <Col xs={8} lg={8} xl={4}>
                                                    <div>{i18n.t('form.label.report.label.CompanyId') + '*:'}</div>
                                                </Col>
                                                <Col xs={15} lg={14} xl={20}>
                                                    <Form.Item name="companyId" style={{ marginBottom: '12px' }}>
                                                        <CompanyIdSelectForm
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            disabled={disabled.companyId}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                            probenart={this.state.formFields[2].value}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={12} xl={6} key={2}>
                                            <Row>
                                                <Col xs={8} lg={8} xl={6}>
                                                    <div>{i18n.t('form.label.report.label.Perimeter') + ' :'}</div>
                                                </Col>
                                                <Col xs={15} lg={14} xl={17}>
                                                    <Form.Item name="perimeter" style={{ marginBottom: '12px' }}>
                                                        <PerimeterForm />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={0} lg={0} xl={10} />
                                        <Col xs={24} lg={12} xl={3} key={7}>
                                            <Row>
                                                <Col xs={8} lg={8} xl={6}>
                                                    <div>{i18n.t('form.label.report.label.Type') + ' :'}</div>
                                                </Col>
                                                <Col xs={15} lg={14} xl={18}>
                                                    <Form.Item name="type" style={{ marginBottom: '12px' }}>
                                                        <ProbenartSelectForm
                                                            startDate={startDate}
                                                            endDate={endDate}
                                                            defaultValue={[i18n.t('order.probenart.all')]}
                                                        />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={12} xl={5} key={3}>
                                            <Row>
                                                <Col xs={8} lg={8} xl={6}>
                                                    <div>{i18n.t('form.label.report.label.date') + ' :'}</div>
                                                </Col>
                                                <Col xs={15} lg={14} xl={18}>
                                                    <Form.Item name="datum" style={{ marginBottom: '12px' }}>
                                                        <DatePicker.RangePicker allowClear={false} disabled={disabled.datum} />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col xs={24} lg={12} xl={9} key={4}>
                                            <Row>
                                                <Col xs={8} lg={8} xl={6}>
                                                    <div>{i18n.t('form.label.report.label.calculation') + '*:'}</div>
                                                </Col>
                                                <Col xs={15} lg={14} xl={10}>
                                                    <Form.Item name="calculation" style={{ marginBottom: '12px' }}>
                                                        <CalculationSelectForm />
                                                    </Form.Item>
                                                </Col>
                                            </Row>
                                        </Col>
                                        <Col span={5} />
                                    </Row>
                                </Form>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <div className="form-collapse-wrap">
                                <Button
                                    onClick={() => {
                                        this.setState({
                                            formVisible: !this.state.formVisible
                                        });
                                    }}
                                >
                                    {this.state.formVisible ? <i className="fal fa-chevron-square-up"></i> : <i className="fal fa-chevron-square-down"></i>}
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24}>
                            <FsFrame uri={this.state.reportUri} />
                        </Col>
                    </Row>
                </div>
            </>
        );
    }
}
export default withAuth(XRFReport);
