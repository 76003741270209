import React from 'react';
import { Spin } from 'antd';
import { AuthContextProps, withAuth } from 'oidc-react';
import TagSelect from '../../tagselect/tagselect';
import i18n from '../../../../misc/i18n';
import { SelectWithAllTypes } from '../../../../definitions/SelectWithAllTypes';

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: any[];
    language: string;
    alli18n: string;
    disabled?: boolean;
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    defaultValue: string[];
    // initialValues: string[];
    data: any[];
    disabled?: boolean;
    allowClear?: boolean;
    dataType?: SelectWithAllTypes;
    maxLength?: number;
}

class SelectWithAll extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata:
                this.props.dataType != null && this.props.dataType === SelectWithAllTypes.LACTATION
                    ? Array.from({ length: this.props.maxLength ? this.props.maxLength : 10 }, (_, i) => i + 1).map((itm, y) => {
                          return { key: '' + y, value: '' + itm };
                      })
                    : [],
            loading: false,
            value: this.props.defaultValue ? this.props.defaultValue : [],
            language: '',
            alli18n: 'order.probenart.all',
            disabled: this.props.disabled ? this.props.disabled : false
        };
    }

    componentDidMount() {
        if (this.props.dataType === SelectWithAllTypes.KETOSE) {
            this._getSelectData();
        }
    }

    componentDidUpdate(prevProps: IProps) {
        if (i18n.language !== this.state.language) {
            let values = this.state.value;
            for (let i = 0; i < values.length; i++) {
                //Alte Übersetzung mit neuer austauschen
                if (values[i] === i18n.t(this.state.alli18n, { lng: this.state.language })) {
                    values[i] = i18n.t(this.state.alli18n);
                }
            }
            this.setState(
                {
                    language: i18n.language,
                    value: values
                },
                () => {
                    if (typeof this.props.onChange !== 'undefined') {
                        this.props.onChange(values, []);
                    }
                }
            );
        }
    }

    async _getSelectData() {
        this.setState({
            selectdata: this.props.data
        });
    }

    onChange = (value: string[], option: any) => {
        if (value.length === 0) {
            value = this.state.value;
        }
        if (value.includes(i18n.t(this.state.alli18n)) && !this.state.value.includes(i18n.t(this.state.alli18n))) {
            value = [i18n.t(this.state.alli18n)];
            // Prüft ob alle vorausgewählt ist und nun eine spezifiesche Auswahl getroffen wurde und entfernt dann den Eintrag "Alle"
        } else if (
            this.state.value.length === 1 &&
            this.state.value.includes(i18n.t(this.state.alli18n)) &&
            value.includes(i18n.t(this.state.alli18n)) &&
            value.length > 1
        ) {
            value = value.filter((e) => e !== i18n.t(this.state.alli18n));
        }
        if (typeof this.props.onChange !== 'undefined') {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };
    textRenderer = (item: any) => {
        if (this.props?.dataType != null) {
            switch (this.props.dataType) {
                case SelectWithAllTypes.KETOSE:
                    if (i18n.t('form.label.lkv1Ketose.' + item.key).includes('form.label.lkv1Ketose')) {
                        return i18n.t('form.label.lkv1Ketose.Alle');
                    }
                    return item.key ? i18n.t('form.label.lkv1Ketose.' + item.key) : '';
                case SelectWithAllTypes.LACTATION:
                    return item.key === i18n.t('order.probenart.all') ? i18n.t('order.probenart.all') : item.value;
                default:
                    return item.value;
            }
        } else {
            return item.value;
        }
    }

    addAll(data: any[]) {
        if (data.length === 0) {
            return [];
        }
        let all = {
            iso: i18n.t(this.state.alli18n)
        };
        return [all, ...data];
    }

    render() {
        const { loading } = this.state;
        return (
            <TagSelect
                keyAttributeName="key"
                valueAttributeName="value"
                onChange={this.onChange}
                notFoundContent={loading ? <Spin size="small" /> : null}
                selectdata={this.state.selectdata}
                textrender={this.textRenderer}
                style={{ width: '100%' }}
                selectAll={true}
                selectAlli18n={this.state.alli18n}
                value={this.state.value}
                disabled={this.state.disabled}
                allowClear={this.props.allowClear}
            />
        );
    }
}

export default withAuth(SelectWithAll);
