import React from 'react';
import { Tree, Spin } from 'antd';
import * as Api from '../../../../api/importstatus';
import { getImportApiConfig } from '../../../../_conf';
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from '../../../misc/notification';
import i18n from '../../../misc/i18n';


interface IProps extends AuthContextProps {
    handlePreSelect: Function;
    setSelectedKeys: Function;
    defaultCheckedKeys: [] | undefined;
}

interface IState {
    data: Array<any>;
    loading: boolean;
    language: string
}

interface TreeNode {
    title: string;
    key: string;
}

let ProbenartRender = class ProbenartRenderer extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            data: [],
            loading: false,
            language: ''
        };
    }

    async loadData(){
        let api = new Api.ProbenbestellungControllerApi(getImportApiConfig('probeorder', this.props.userData));
        this.setState({
            loading: true
        });
        try {
            let body = await api.findDistinctSampleType();
            let ergs: Array<TreeNode> = [];
            body.forEach((el) => {
                let obj: TreeNode = {
                    title: i18n.t('order.probenart.' + el).includes('order.probenart') ? el : i18n.t('order.probenart.' + el),
                    key: el
                };
                ergs.push(obj);
            });
            this.setState({
                data: ergs,
                loading: false
            });
        } catch (e) {
            this.setState({
                loading: false
            });
            userNotification('error', 'notification.probetype.error');
        }
    }

    async componentDidMount() {
       await this.loadData() 
    }

    componentDidUpdate() {
        if (i18n.language.substr(0,2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0,2)
                },
                () => {
                    this.loadData();
                }
            );
        }
    }

    render() {
        if (this.state.loading) {
            return <Spin />;
        }
        return (
            <Tree
                checkable
                // @ts-ignore
                defaultCheckedKeys={(this.props.defaultCheckedKeys) ? this.props.defaultCheckedKeys[0] : []}
                onCheck={(checkedKeys, e) => {
                    this.props.handlePreSelect(checkedKeys, this.props.setSelectedKeys);
                }}
                treeData={this.state.data}
            ></Tree>
        );
    }
};

export default withAuth(ProbenartRender);
