/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface UserConsentRepresentation
 */
export interface UserConsentRepresentation {
    /**
     * 
     * @type {string}
     * @memberof UserConsentRepresentation
     */
    clientId?: string;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserConsentRepresentation
     */
    grantedClientScopes?: Array<string>;
    /**
     * 
     * @type {number}
     * @memberof UserConsentRepresentation
     */
    createdDate?: number;
    /**
     * 
     * @type {number}
     * @memberof UserConsentRepresentation
     */
    lastUpdatedDate?: number;
    /**
     * 
     * @type {Array<string>}
     * @memberof UserConsentRepresentation
     */
    grantedRealmRoles?: Array<string>;
}

export function UserConsentRepresentationFromJSON(json: any): UserConsentRepresentation {
    return UserConsentRepresentationFromJSONTyped(json, false);
}

export function UserConsentRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): UserConsentRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'clientId': !exists(json, 'clientId') ? undefined : json['clientId'],
        'grantedClientScopes': !exists(json, 'grantedClientScopes') ? undefined : json['grantedClientScopes'],
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'lastUpdatedDate': !exists(json, 'lastUpdatedDate') ? undefined : json['lastUpdatedDate'],
        'grantedRealmRoles': !exists(json, 'grantedRealmRoles') ? undefined : json['grantedRealmRoles'],
    };
}

export function UserConsentRepresentationToJSON(value?: UserConsentRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'clientId': value.clientId,
        'grantedClientScopes': value.grantedClientScopes,
        'createdDate': value.createdDate,
        'lastUpdatedDate': value.lastUpdatedDate,
        'grantedRealmRoles': value.grantedRealmRoles,
    };
}

