import React from "react";
import farmerConf from "./_farmer";
import helper from "./../listdetail/listtable/_helper";
import * as Api from "../../../../api/rest/";
import { getRestApiConfig } from "./../../../../_conf";
import ListDetail from "./../listdetail/";
import { withAuth } from "oidc-react";
import userNotification from "./../../../misc/notification";
import farmerDetailFilter from "../../../misc/permissionFilter/farmerDetailFilter";
import { validateData, RequestType } from "./../../../misc/validateData";
import { checkChanged } from "./../../../misc/checkChanged";
import LoadMask from "../../../misc/loadmask";
import { Tabs } from "antd";
import i18n from "../../../misc/i18n";
import CompanyTable from "./companytable";
import Rights from "./rights";
import Anonymise from  "./../anonymise";
import showPopUpError from "../../../misc/popUpError";
import { PopUpErrorTypes } from "../../../definitions/popUpErrorEnum";
import { dec } from "../../../misc/crypt";
const punycode = require('punycode');
const queryToStr = require("query-string");

class Farmer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: 20,
            detailVisible: false,
            conf: farmerDetailFilter(farmerConf, this.props.userData),
            disableAll: false,
            prevTab: '1'
        };
        this.formRef = React.createRef();
    }

    _getApi() {
        return new Api.LandwirtControllerApi(getRestApiConfig("farmer", this.props.userData));
    }

    _getInsertWrapper(data) {
        let ret = {
            beschreibung: data.beschreibung,
            bundesland: data.bundesland,
            email: (typeof(data.email) !== "undefined") ? punycode.toUnicode(data.email) : "",
            externeSysteme: {
                DDW: data.externeSystemeDDW,
                CVAS: data.externeSystemeCVAS,
                LKV: data.externeSystemeLKV,
                CRM: data.externeSystemeCRM,
                ERP: data.externeSystemeERP
            },
            land: data.land,
            ortsteil: data.ortsteil,
            landwirtId: data.landwirtId,
            nachname: data.nachname,
            ort: data.ort,
            parameter: data.parameter,
            plz: data.plz,
            strasse: data.strasse,
            telefonNummer: data.telefonNummer,
            vorname: data.vorname,
            emailbenachrichtigungJn: data.emailbenachrichtigungJn
        };
        return ret;
    }

    _getUpdateWrapper(data) {
        data = Object.assign(data, {
            externeSysteme: {
                CRM: data.externeSystemeCRM,
                CVAS: data.externeSystemeCVAS,
                DDW: data.externeSystemeDDW,
                ERP: data.externeSystemeERP,
                LKV: data.externeSystemeLKV
            }
        });
        //Prüfen ob die externen systeme in der data Variable angegeben sind. Wenn nicht -> wert nicht geändert und aus detaildata laden. Wenn dort nicht vorhanden auf "" setzen
        data.externeSysteme.CRM =
            data.externeSysteme.CRM != null
                ? data.externeSysteme.CRM
                : this.state.detaildata.externeSysteme.CRM != null
                ? this.state.detaildata.externeSysteme.CRM
                : "";
        data.externeSysteme.CVAS =
            data.externeSysteme.CVAS != null
                ? data.externeSysteme.CVAS
                : this.state.detaildata.externeSysteme.CVAS != null
                ? this.state.detaildata.externeSysteme.CVAS
                : "";
        data.externeSysteme.ERP =
            data.externeSysteme.ERP != null
                ? data.externeSysteme.ERP
                : this.state.detaildata.externeSysteme.ERP != null
                ? this.state.detaildata.externeSysteme.ERP
                : "";
        data.externeSysteme.DDW =
            data.externeSysteme.DDW != null
                ? data.externeSysteme.DDW
                : this.state.detaildata.externeSysteme.DDW != null
                ? this.state.detaildata.externeSysteme.DDW
                : "";
        data.externeSysteme.LKV =
            data.externeSysteme.LKV != null
                ? data.externeSysteme.LKV
                : this.state.detaildata.externeSysteme.LKV != null
                ? this.state.detaildata.externeSysteme.LKV
                : "";

        if(typeof(data.email) !== "undefined"){
            data.email = punycode.toUnicode(data.email);
        }
        
        delete data.externeSystemeCRM;
        delete data.externeSystemeCVAS;
        delete data.externeSystemeDDW;
        delete data.externeSystemeERP;
        delete data.externeSystemeLKV;
        delete data.disableAll;

        return data;
    }

    _setListState(resp, params) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: true,
            // detaildata: {},
            loading: false,
            pageSize: params.size,
            pagination: {
                current: params.page + 1,
                total: resp.totalElements,
                pageSize: params.size
            }
        });
    }

    async componentDidMount() {
        if (typeof this.props.id !== "undefined") {
            this._fetchDetailData(this.props.id);
        } else {
            await this._fetchListData({});
            const result = queryToStr.parse(document.location.search);
            if (typeof result.id !== "undefined" && result.id !== "") {
                let id = dec(result.id);
                this._fetchDetailData(id);
            }
        }
    }

    async _fetchListData(p) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        };
        let resp = await this._getApi()
            .findFiltered1(requestBody)
            .catch(() => {
                userNotification("error", "notification.farmer.get.error");
            });
        if (resp != null) {
            this._setListState(resp, params);
        }
    }

    async _fetchDetailData(id) {
        let requestBody = {
            landwirtId: id
        };
        let landwirt = await this._getApi()
            .findById1(requestBody)
            .catch(() => {
                userNotification("error", "notification.farmer.get.error");
            });
        if (landwirt !== undefined) {
            if (typeof landwirt.externeSysteme === "undefined") {
                landwirt = Object.assign(landwirt, {
                    externeSysteme: {
                        DDW: "",
                        CVAS: "",
                        LKV: "",
                        CRM: "",
                        ERP: ""
                    }
                });
            }
            landwirt.externeSysteme.DDW = typeof landwirt.externeSysteme.DDW !== "undefined" ? landwirt.externeSysteme.DDW : "";
            landwirt.externeSysteme.CVAS = typeof landwirt.externeSysteme.CVAS !== "undefined" ? landwirt.externeSysteme.CVAS : "";
            landwirt.externeSysteme.LKV = typeof landwirt.externeSysteme.LKV !== "undefined" ? landwirt.externeSysteme.LKV : "";
            landwirt.externeSysteme.CRM = typeof landwirt.externeSysteme.CRM !== "undefined" ? landwirt.externeSysteme.CRM : "";
            landwirt.externeSysteme.ERP = typeof landwirt.externeSysteme.ERP !== "undefined" ? landwirt.externeSysteme.ERP : "";
            landwirt.externeSystemeDDW = landwirt.externeSysteme.DDW;
            landwirt.externeSystemeERP = landwirt.externeSysteme.ERP;
            landwirt.externeSystemeCRM = landwirt.externeSysteme.CRM;
            landwirt.externeSystemeLKV = landwirt.externeSysteme.LKV;
            landwirt.externeSystemeCVAS = landwirt.externeSysteme.CVAS;

            landwirt.disableAll = landwirt.anonymisiertJn;

            if(landwirt.disableAll){
                this.setState({
                    disableAll: true
                })
            }else{
                this.setState({
                    disableAll: false
                })
            }
            this.parseFarmer(landwirt);
            this.setState({
                detaildata: landwirt,
                detailVisible: true
            });
        }
    }

    async _insertData(data) {
        let requestBody = {
            landwirt: this._getInsertWrapper(data)
        };
        let result = await this._getApi()
            .createLandwirt(requestBody)
            .catch((resp) => {
                try {
                    resp.json().then((errorResponse) =>
                        errorResponse.message != null && errorResponse.message === "0002"
                            ? showPopUpError(PopUpErrorTypes.EMAIL)
                            : userNotification("error", "notification.farmer.update.error")
                    );
                } catch (error) {
                    userNotification("error", "notification.farmer.update.error");
                }
            });
        if (result != null) {
            userNotification("success", "notification.farmer.insert.success");
            this._fetchDetailData(result.landwirtId);
            this.setState(
                {
                    detailVisible: true
                },
                () => {
                    this._fetchListData({});
                }
            );
        }
    }

    async _updateData(id, data) {
        let requestBody = {
            landwirtId: id,
            body: this._getUpdateWrapper(data)
        };
        let result = await this._getApi()
            .updateLandwirt(requestBody)
            .catch((resp) => {
                try {
                    resp.json().then((errorResponse) =>
                        errorResponse.message != null && errorResponse.message === "0002"
                            ? showPopUpError(PopUpErrorTypes.EMAIL)
                            : userNotification("error", "notification.farmer.update.error")
                    );
                } catch (error) {
                    userNotification("error", "notification.farmer.update.error");
                }
            });
        if (result != null) {
            userNotification("success", "notification.farmer.update.success");
            this.setState(
                {
                    detailVisible: true
                },
                () => {
                    this._fetchListData({});
                }
            );
        }
    }

    async _deleteData(id) {
        try {
            let requestBody = {
                ids: [id]
            };
            await this._getApi().deleteLandwirte(requestBody);
            userNotification("success", "notification.farmer.delete.success");
            this._fetchListData({});
        } catch (e) {
            if (e.status + "" === "406") {
                userNotification("error", "notification.farmer.delete.assigned");
            } else {
                userNotification("error", "notification.farmer.delete.error");
            }
        }
    }

    getUserPermissions() {
        let parseJwt = (token) => {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        };
        if (this.props.userData == null) {
            return {
                delete: false,
                onDelete: () => {},
                onNew: () => {},
                onSave: () => {},
                hasToolbar: false,
                showSaveButton: false,
                isBusinessAdministrator: false,
                idAdministrator: false
            };
        }

        let token = parseJwt(this.props.userData.access_token);
        let perm = {
            delete: false,
            onDelete: () => {},
            onNew: () => {},
            onSave: () => {},
            hasToolbar: false,
            showSaveButton: false,
            isBusinessAdministrator: false,
            idAdministrator: false
        };
        token.realm_access.roles.forEach((role) => {
            if (role === "BusinessAdministrator" || role === "Administrator") {
                perm = {
                    delete: true,
                    hasToolbar: true,
                    isBusinessAdministrator: token.realm_access.roles.indexOf("BusinessAdministrator") >= 0 ? true : false,
                    isAdministrator: token.realm_access.roles.indexOf("Administrator") >= 0 ? true : false,
                    onDelete: (id) => this._deleteData(id),
                    onNew: () => {
                        this.setState({
                            detaildata: {
                                new: true
                            },
                            disableAll: false,
                            detailVisible: true
                        });
                    },
                    onSave: async (data) => {
                        if (this.state.detaildata.new != null) {
                            if (validateData(data, this.state.detaildata, farmerConf, RequestType.post)) {
                                this._insertData(data);
                            }
                        } else {
                            for (const key in this.state.detaildata) {
                                if (Object.hasOwnProperty.call(this.state.detaildata, key)) {
                                    const element = this.state.detaildata[key];
                                    if (data[key] == null) {
                                        data[key] = element;
                                    }
                                }
                            }

                            try {
                                let requestBody = {
                                    landwirtId: this.state.detaildata.landwirtId
                                };
                                let farmerObject = await this._getApi().findById1(requestBody);
                                if (farmerObject !== undefined) {
                                    this.parseFarmer(farmerObject);
                                    if (validateData(data, farmerObject, farmerConf, RequestType.patch)) {
                                        checkChanged(
                                            data,
                                            farmerObject,
                                            farmerConf,
                                            () => {
                                                this._updateData(this.state.detaildata[this.state.conf.detailProp], data);
                                            },
                                            () => {}
                                        );
                                    }
                                }
                            } catch (error) {
                                userNotification("error", "notification.farmer.get.error");
                            }
                        }
                    },
                    showSaveButton: true
                };
            }
        });
        return perm;
    }

    parseFarmer = (farmer) => {
        if (typeof farmer.externeSysteme === "undefined") {
            farmer = Object.assign(farmer, {
                externeSysteme: {
                    DDW: "",
                    CVAS: "",
                    LKV: "",
                    CRM: "",
                    ERP: ""
                }
            });
        }
        farmer.externeSysteme.DDW = typeof farmer.externeSysteme.DDW !== "undefined" ? farmer.externeSysteme.DDW : "";
        farmer.externeSysteme.CVAS = typeof farmer.externeSysteme.CVAS !== "undefined" ? farmer.externeSysteme.CVAS : "";
        farmer.externeSysteme.LKV = typeof farmer.externeSysteme.LKV !== "undefined" ? farmer.externeSysteme.LKV : "";
        farmer.externeSysteme.CRM = typeof farmer.externeSysteme.CRM !== "undefined" ? farmer.externeSysteme.CRM : "";
        farmer.externeSysteme.ERP = typeof farmer.externeSysteme.ERP !== "undefined" ? farmer.externeSysteme.ERP : "";
        farmer.externeSystemeDDW = farmer.externeSysteme.DDW;
        farmer.externeSystemeERP = farmer.externeSysteme.ERP;
        farmer.externeSystemeCRM = farmer.externeSysteme.CRM;
        farmer.externeSystemeLKV = farmer.externeSysteme.LKV;
        farmer.externeSystemeCVAS = farmer.externeSysteme.CVAS;
    };

    _onTabsChange = (activeKey) => {
        if (activeKey !== '1' && this.state.prevTab === '1') {
            let currentValues = this.formRef.current.formRef.current.getFieldsValue(true);
            let newDetailData = {};
            for (let key in this.state.detaildata) {
                newDetailData[key] = currentValues[key];
            }

            this.setState({
                detaildata: newDetailData,
                prevTab: activeKey
            });
        } else if (activeKey === '1'){
            this.setState({
                prevTab: '1'
            });
        }
    };

    render() {
        let formitems = this.state.conf.formitems;
        let permission = this.getUserPermissions();
        let extratabs = [
            <Tabs.TabPane tab={i18n.t("navitem.companies")} key="2">
                <CompanyTable currentFarmer={this.state.detaildata.landwirtId} />
            </Tabs.TabPane>
        ];
        if (permission.isBusinessAdministrator || permission.isAdministrator) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.rights")} key="3">
                    <Rights currentFarmer={this.state.detaildata.landwirtId} userData={this.props.userData} disableAll={this.state.disableAll} />
                </Tabs.TabPane>
            );
        }
        if (permission.isAdministrator) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.anonymise")} key="4">
                    <Anonymise 
                        currentFarmer={this.state.detaildata.landwirtId} 
                        userData={this.props.userData} 
                        detailData={this.state.detaildata} 
                        disableAll={this.state.disableAll}
                        onAnonymise={() => {
                            this.setState({
                                detailVisible: false
                            },() => {
                                this._fetchListData({})
                            })
                            
                        }}
                    />
                </Tabs.TabPane>
            );
        }


        return (
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail
                    disableAll={this.state.disableAll}
                    refForForm={this.formRef}
                    showSaveButton={permission.showSaveButton}
                    renderTitle={this.state.conf.renderTitle}
                    hasToolbar={permission.hasToolbar}
                    delete={permission.delete}
                    columns={this.state.conf.columns}
                    listdata={this.state.listdata}
                    formitems={formitems}
                    detailProp={this.state.conf.detailProp}
                    detaildata={this.state.detaildata}
                    fetchListData={(p) => this._fetchListData(p)}
                    detailVisible={this.state.detailVisible}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onSave={(data) => {
                        permission.onSave(data);
                    }}
                    onDetailClose={() => {
                        this.setState(
                            {
                                detailVisible: false,
                                prevTab: '1'
                            },
                            () => {
                                if (this.props.onDetailClose) {
                                    this.props.onDetailClose();
                                }
                            }
                        );
                    }}
                    onTableChange={(p) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                    onRowSelection={(id) => this._fetchDetailData(id)}
                    onDelete={(id) => {
                        permission.onDelete(id);
                    }}
                    onNew={() => permission.onNew()}
                    extraTabs={this.state.detaildata.new != null ? undefined : extratabs}
                    onTabsChange={this._onTabsChange}
                />
            </>
        );
    }
}
export default withAuth(Farmer);
