import React from 'react';
import i18n from './i18n';
import { PopUpErrorTypes } from '../definitions/popUpErrorEnum';
import { Modal } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';

let { warning } = Modal;

function showPopUpError(errorType: PopUpErrorTypes) {
    let displayContent = '';
    switch (errorType) {
        case PopUpErrorTypes.EMAIL:
            displayContent = i18n.t('formvalidation.usedEmail');
            break;
        case PopUpErrorTypes.EUNUMMER:
            displayContent = i18n.t('notification.company.double.body');
            break;
        case PopUpErrorTypes.LKV:
            displayContent = i18n.t('formvalidation.usedLKV');
            break;
    }
    return warning({
        title: i18n.t('formvalidation.warning'),
        icon: <ExclamationCircleOutlined />,
        content: displayContent
    });
}

export default showPopUpError;
