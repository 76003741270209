import moment from 'moment';
import i18n from '../../../misc/i18n';
const report = {
    formItems: [
        {
            name: 'land',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'bundesland',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'datum',
            value: [moment().subtract(2, 'years'), moment()]
        },
        {
            name: 'region',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'plz',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'type',
            value: [],
            default:[i18n.t('order.probenart.all')]
        },
        {
            name: 'analyseart',
            value: [],
            default:[i18n.t('order.probenart.all')]
        }
    ]
};

export default report;
