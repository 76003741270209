import React from 'react';
import DEParagraph from "../../DEparagraph"
export default class LabStatusPlaintext extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            value: this.props.value
        };
        this.updateStateEvent = this.updateStateEvent.bind(this)
    }

    onChange = (value,key) => {  
        this.props.onChange(value)
        this.setState({
            value: value
        })
        
    };
    updateStateEvent(e) {
        this.props.onChange(e.detail)
        this.setState(
            {
                value: e.detail
            }
        )
        
    }
    componentDidMount() {
        window.addEventListener("labbook_changeStatus", this.updateStateEvent, true);
        window.addEventListener("labbook_statusChangedDropdown", this.updateStateEvent, true);
    }
    componentWillUnmount() {
        window.removeEventListener("labbook_changeStatus", this.updateStateEvent, true);
        window.removeEventListener("labbook_statusChangedDropdown", this.updateStateEvent, true);
    }

    render() {
        return (
            <DEParagraph value={this.state.value}/>
        );
    }
}
