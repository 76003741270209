/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Betrieb,
    BetriebFromJSON,
    BetriebToJSON,
    ImportJobErrorDokumentInhalt,
    ImportJobErrorDokumentInhaltFromJSON,
    ImportJobErrorDokumentInhaltToJSON,
    PageSdnImportJob,
    PageSdnImportJobFromJSON,
    PageSdnImportJobToJSON,
    SdnImportJobError,
    SdnImportJobErrorFromJSON,
    SdnImportJobErrorToJSON,
} from '../models';

export interface CreateErrorFileRequest {
    id: number;
}

export interface FindErrorsForJobRequest {
    id: number;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface FindFiltered1Request {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

/**
 * 
 */
export class ImportControllerApi extends runtime.BaseAPI {

    /**
     */
    async createErrorFileRaw(requestParameters: CreateErrorFileRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<ImportJobErrorDokumentInhalt>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling createErrorFile.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/importjob/errorfile/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => ImportJobErrorDokumentInhaltFromJSON(jsonValue));
    }

    /**
     */
    async createErrorFile(requestParameters: CreateErrorFileRequest, initOverrides?: RequestInit): Promise<ImportJobErrorDokumentInhalt> {
        const response = await this.createErrorFileRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebeRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<Betrieb>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/importjob/betriebeTemp`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(BetriebFromJSON));
    }

    /**
     */
    async findBetriebe(initOverrides?: RequestInit): Promise<Array<Betrieb>> {
        const response = await this.findBetriebeRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findErrorsForJobRaw(requestParameters: FindErrorsForJobRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<SdnImportJobError>>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findErrorsForJob.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/importjob/errors/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(SdnImportJobErrorFromJSON));
    }

    /**
     */
    async findErrorsForJob(requestParameters: FindErrorsForJobRequest, initOverrides?: RequestInit): Promise<Array<SdnImportJobError>> {
        const response = await this.findErrorsForJobRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFiltered1Raw(requestParameters: FindFiltered1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageSdnImportJob>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["apiKey"] = this.configuration.apiKey("apiKey"); // apiKey authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/importjob/importjobs`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSdnImportJobFromJSON(jsonValue));
    }

    /**
     */
    async findFiltered1(requestParameters: FindFiltered1Request = {}, initOverrides?: RequestInit): Promise<PageSdnImportJob> {
        const response = await this.findFiltered1Raw(requestParameters, initOverrides);
        return await response.value();
    }

}
