import React from "react";
// import { message, notification } from "antd";
import { Tabs } from "antd";
import i18n from "../../../misc/i18n";

interface IState {
    data: object
    loading: boolean
}
interface IProps{}

export default class Account extends React.Component<IProps,IState>{

    constructor(props: IProps){
        super(props);
        this.state = {
            data: {},
            loading: false
        }
    }

    componentDidMount(){
        // this.fetchData();
    }

    // async fetchData() {
    //     this.setState({
    //         loading: true
    //     })
    //     await ajax.tRequest(account.baseUri, "GET", {}, {}, false, auth.getCookieValue("demes-access_token"))
    //     .then((response) => {
    //         if(typeof response.error === "undefined"){
    //             this.setState({
    //                 data: response,
    //                 loading: false
    //             })
    //         }
    //     })
    //     .catch((error) => {
    //         this.setState({
    //             loading: false
    //         },() => {
    //             notification.error({
    //                 message: i18n.t("account.ajax.error")
    //             });
    //         })
    //     });
    // }

    // getLoadMask(){
    //     return (this.state.loading)
    //     ?   <LoadMask collapsed={this.props.collapsed} />
    //     : null
    // }

    render(){
        return(
            <div style={{padding: "20px"}}>
                <Tabs defaultActiveKey="1">
                    <Tabs.TabPane tab={i18n.t("account.header.allgemein")} key="1">

                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("account.header.betriebe")} key="2">

                    </Tabs.TabPane>
                    <Tabs.TabPane tab={i18n.t("account.header.bestellungen")} key="3">

                    </Tabs.TabPane>
                </Tabs>
            </div>
        )
    }


/*
    render(){
        let formItems = account.formItems;
        for(let i=0; i<formItems.length; i++){
            formItems[i].label = i18n.t("account." + formItems[i].name) 
        }            
        return(
            <div style={{padding: "20px 0 0 0"}}>
                {this.getLoadMask()}
                <Form 
                    layout={{
                        labelCol: { span: 3 },
                        wrapperCol: { span: 19 },
                    }}
                    validateMessages={account.validateMessages}
                    values={this.state.data}                                
                    formItems={formItems}
                    onFieldsChange={(changedFields, allFields) => {}}
                />
                {/ * <pre>{JSON.stringify(this.props, null, 2)}</pre> * /}
                {/ * <pre>{JSON.stringify(this.state, null, 2)}</pre> * /}
            {/ * <pre>{JSON.stringify(auth.getJWTData(), null, 2)}</pre> * /}
            </div>
        )
    }
*/
}
