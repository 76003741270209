import React from 'react';
import moment from 'moment';
import { Tag, Button, Tooltip } from 'antd';
import i18n, { i18nApp } from '../../../misc/i18n';
import { CheckSquareFilled, BorderOutlined } from '@ant-design/icons';

const punycode = require('punycode/');

const colrenderer = {
    renderDateTime(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                if(text != null){
                    return moment(text).format(i18n.t('format.datetime'));
                }
                return "-";
            }
        });
        return col;
    },
    renderDate(col, text, record, index) {
        col = Object.assign(col, { render: (text, record) => moment(text).format(i18n.t('format.date')) });
        return col;
    },
    renderBundeslandName(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                if (text != null) {
                    let value = (text === "") ? "" :  i18nApp.getBundeslandString(text)
                    if(value.substr(0, 3) === "iso"){
                        value = text;
                    }
                    return value;
                } else {
                    return '';
                }
            }
        });
        return col;
    },
    renderBestellStatus(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                if (text != null) {
                    return i18n.t('order.status.' + text);
                } else {
                    return '';
                }
            }
        });
        return col;
    },

    renderProbenartName(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                if (text != null) {
                    return i18n.t('order.probenart.' + text);
                } else {
                    return '';
                }
            }
        });
        return col;
    },

    renderStatus(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                let color = 'green';
                if (text === 'FEHLER') {
                    color = 'red';
                }
                if (text === 'GESTARTET' || text === 'NEU' || text === 'WARNUNG') {
                    color = 'orange';
                }
                return (
                    <Tag color={color} key={text}>
                        {text.toUpperCase()}
                    </Tag>
                );
            }
        });
        return col;
    },

    renderMailStatus(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                let color = 'orange';
                if (text === 'ERROR') {
                    color = 'red';
                }
                if (text === 'SUCCESS') {
                    color = 'green';
                }
                if (text === undefined || text === null) {
                    return <div></div>;
                }
                return (
                    <Tag color={color} key={text}>
                        {text}
                    </Tag>
                );
            }
        });
        return col;
    },

    renderDownload(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                return (
                    <Tooltip title="Download">
                        <Button
                            type="default"
                            shape="round"
                            onClick={(e, record) => {
                                e.preventDefault();
                            }}
                        >
                            <i className="fal fa-cloud-download-alt"></i>
                        </Button>
                    </Tooltip>
                );
            }
        });
        return col;
    },

    renderDeleteBtn(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                return (
                    <Tooltip title="Delete">
                        <Button
                            type="default"
                            shape="round"
                            onClick={(e, record) => {
                                if (col.onDelete) {
                                    col.onDelete(record);
                                    e.preventDefault();
                                }
                            }}
                        >
                            <i className="fal fa-trash-alt"></i>
                        </Button>
                    </Tooltip>
                );
            }
        });
        return col;
    },

    renderCheckbox(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                if(record.gratisProbe){
                    return <CheckSquareFilled style={{color: "#f8af00"}}/>
                }else{
                    return <BorderOutlined />
                };
            }
        });
        return col;
    },

    renderBoolean(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                return (text) ? <i className="far fa-check-square"></i> : <i className="far fa-square"></i>
            }
        });
        return col;
    },

    renderEmail(col, text, record, index) {
        col = Object.assign(col, {
            render: (text, record) => {
                return(punycode.toUnicode(text))
            }
        });
        return col;
    },


    render(col) {
        if (typeof col.renderer !== 'undefined') {
            switch (col.renderer) {
                case 'renderDeleteBtn':
                    return this.renderDeleteBtn(col);
                case 'renderDownload':
                    return this.renderDownload(col);
                case 'renderDateTime':
                    return this.renderDateTime(col);
                case 'renderDate':
                    return this.renderDate(col);
                case 'renderStatus':
                    return this.renderStatus(col);
                case 'mailstatus':
                    return this.renderMailStatus(col);
                case 'checkbox':
                    return this.renderCheckbox(col);
                case 'boolean':
                    return this.renderBoolean(col);
                // case 'email':
                //     return this.renderEmail(col);
                default:
                    return col;
            }
        }
    }
};

export default colrenderer;
