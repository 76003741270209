import {  Input } from 'antd';
import React from "react";

export default class DistrictAutoinsert extends React.Component{
    constructor(props){
        super(props)
        this.state = {
            value: props.data.ortsteil != null ? props.data.ortsteil : ""
        }
    }

    componentDidUpdate() {
        if (this.props.data !== {} && this.props.data.ortsteil != null ) {
            this.setState({
                value: this.props.data.ortsteil
            },() => {
                this.props.onChange(this.props.data.ortsteil)
            })
        }
    }
    onChange = (e) => {
        let val = e.target.value;
        this.setState({
            value : val,
        }, () => {
            this.props.onChange(val)
        });
    };

    render(){
        return (
            <Input
            value={this.state.value || ''}
            onChange={this.onChange}
        />
        );
        
    }
}