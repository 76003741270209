import React from "react";
import regions from "./_regions";
import helper from "./../listdetail/listtable/_helper";
import * as Api from "../../../../api/rest/";
import ListDetail from "./../listdetail/";
import { Tabs } from "antd";
import { getRestApiConfig } from "./../../../../_conf";
import Security from "../companies/security/security";
import { withAuth } from "oidc-react";
import i18n from "../../../misc/i18n";
import userNotification from "../../../misc/notification";
import LoadMask from "../../../misc/loadmask";

class Regions extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            selectedRegion: undefined,
            pageSize: 20,
            detailVisible: false,
            conf: regions,
            pagination: {}
        };
    }

    _getApi() {
        return new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));
    }

    _getInsertWrapper(data) {
        return {
            regionId: 0,
            regionName: data.regionName,
            regionTyp: data.regionTyp,
            land: data.land
        };
    }

    _setListState(resp, params) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: false,
            // detaildata: {},
            loading: false,
            pagination: {
                current: params.page + 1,
                total: resp.totalElements,
                pageSize: this.state.pageSize
            }
        });
    }

    _getUpdateWrapper(id, data) {
        let p = {};
        p[this.state.conf.detailProp] = id;
        p["changes"] = data;
        return p;
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    async _fetchListData(p) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        };
        let resp = await this._getApi()
            .findFiltered(requestBody)
            .catch(() => {
                userNotification("error", "notification.region.get.error");
            });
        if (resp != null) {
            this._setListState(resp, params);
        }
    }

    async _fetchDetailData(id) {
        let p = {};
        p[this.state.conf.detailProp] = id;
        let requestBody = {
            regionId: id
        };
        let resp = await this._getApi()
            .findById(requestBody)
            .catch(() => {
                userNotification("error", "notification.region.get.error");
            });
        if (resp != null) {
            this.setState({
                detaildata: resp,
                detailVisible: true,
                selectedRegion: id
            });
        }
    }

    async _insertData(data) {
        let requestBody = {
            region: this._getInsertWrapper(data)
        };
        let resp = await this._getApi()
            .createRegion(requestBody)
            .catch(() => {
                userNotification("error", "notification.region.insert.error");
            });
        if (resp != null) {
            userNotification("success", "notification.region.insert.success");
            this._fetchDetailData(resp.regionId);
            this._fetchListData({});
        }
    }

    async _updateData(id, data) {
        let requestBody = {
            regionId: id,
            body: data
        };
        let resp = await this._getApi()
            .updateRegion(requestBody)
            .catch(() => {
                userNotification("error", "notification.region.update.error");
            });
        if (resp != null) {
            userNotification("success", "notification.region.update.success");
            this._fetchListData({});
        }
    }

    async _deleteData(id) {
        let requestBody = {
            ids: [id]
        };
        let resp = await this._getApi()
            .deleteRegion(requestBody)
            .catch(() => {
                userNotification("error", "notification.region.delete.error");
            });
        if (resp != null) {
            userNotification("success", "notification.region.delete.success");
            this._fetchListData({});
        }
    }

    getUserPermissions() {
        let parseJwt = (token) => {
            var base64Url = token.split(".")[1];
            var base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
            var jsonPayload = decodeURIComponent(
                atob(base64)
                    .split("")
                    .map(function (c) {
                        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
                    })
                    .join("")
            );

            return JSON.parse(jsonPayload);
        };
        if (this.props.userData == null) {
            return {
                delete: false,
                add: false,
                security: false,
                hasToolbar: false,
                showDetail: false,
                onRowSelection: (id) => {}
            };
        }
        let token = parseJwt(this.props.userData.access_token);
        let perm = {
            delete: false,
            add: false,
            security: false,
            hasToolbar: false,
            showDetail: false,
            onRowSelection: (id) => {}
        };
        token.realm_access.roles.forEach((role) => {
            if (role.toLowerCase() === "administrator") {
                perm = {
                    delete: true,
                    add: true,
                    hasToolbar: true,
                    security: true,
                    showDetail: true,
                    onRowSelection: (id) => {
                        this._fetchDetailData(id);
                    }
                };
            }
            if (role.toLowerCase() === "businessadministrator") {
                perm = {
                    delete: false,
                    add: false,
                    hasToolbar: false,
                    security: true,
                    showDetail: true,
                    onRowSelection: (id) => {
                        this._fetchDetailData(id);
                    }
                };
            }
        });
        return perm;
    }

    render() {
        let formitems = this.state.conf.formitems;
        let perm = this.getUserPermissions();
        return (
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail
                    hasToolbar={perm.hasToolbar}
                    renderTitle={this.state.conf.renderTitle}
                    delete={perm.delete}
                    columns={this.state.conf.columns}
                    listdata={this.state.listdata}
                    formitems={formitems}
                    detailProp={this.state.conf.detailProp}
                    detaildata={this.state.detaildata}
                    fetchListData={(p) => this._fetchListData(p)}
                    detailVisible={perm.showDetail ? this.state.detailVisible : false}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onSave={(data) => {
                        helper.isObjEmpty(this.state.detaildata)
                            ? this._insertData(data)
                            : this._updateData(this.state.detaildata[this.state.conf.detailProp], data);
                    }}
                    onDetailClose={() => {
                        this.setState({
                            detailVisible: false
                        });
                    }}
                    onRowSelection={(id) => {
                        perm.onRowSelection(id);
                    }}
                    onDelete={(id) => this._deleteData(id)}
                    onNew={() => {
                        this.setState({
                            detaildata: {},
                            detailVisible: true
                        });
                    }}
                    onTableChange={(p) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                    extraTabs={[
                        <Tabs.TabPane tab={i18n.t("navitem.register.userAdd")} key="4">
                            <Security currentCompany={this.state.selectedRegion} parentgroup="Region" />
                        </Tabs.TabPane>
                    ]}
                />
            </>
        );
    }
}
export default withAuth(Regions);
