import React from "react";
import { Space } from "antd";
export default class Toolbar extends React.Component{

    render(){
        return(
        <div className="toolbar-wrap">
            <Space>
                {this.props.children}
            </Space>
        </div>
        )
    }

}