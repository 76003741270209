/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    NachrichtEmpfaengerWrapper,
    NachrichtEmpfaengerWrapperFromJSON,
    NachrichtEmpfaengerWrapperToJSON,
    PageNachrichtEmpfaengerWrapper,
    PageNachrichtEmpfaengerWrapperFromJSON,
    PageNachrichtEmpfaengerWrapperToJSON,
    PageSdnNachrichtWrapper,
    PageSdnNachrichtWrapperFromJSON,
    PageSdnNachrichtWrapperToJSON,
    SdnNachricht,
    SdnNachrichtFromJSON,
    SdnNachrichtToJSON,
    SdnNachrichtEmpfaenger,
    SdnNachrichtEmpfaengerFromJSON,
    SdnNachrichtEmpfaengerToJSON,
    SdnNachrichtWrapper,
    SdnNachrichtWrapperFromJSON,
    SdnNachrichtWrapperToJSON,
} from '../models';

export interface AddRecipientRequest {
    sdnNachrichtEmpfaenger: SdnNachrichtEmpfaenger;
}

export interface DeleteRecipientRequest {
    id: number;
}

export interface FindMailByIdRequest {
    id: number;
}

export interface FindMailsFilteredRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindRecipientRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    role?: Array<string>;
}

export interface FindRecipientByIdRequest {
    id: number;
}

export interface NewCustomMailRequest {
    sdnNachricht: SdnNachricht;
}

export interface NewMailRequest {
    event: string;
    requestBody: { [key: string]: string; };
    mainMail?: string;
    landwirte?: Array<string>;
    landwirtId?: number;
}

export interface UpdateRecipientRequest {
    id: number;
    sdnNachrichtEmpfaenger: SdnNachrichtEmpfaenger;
}

/**
 * 
 */
export class MailControllerApi extends runtime.BaseAPI {

    /**
     */
    async addRecipientRaw(requestParameters: AddRecipientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sdnNachrichtEmpfaenger === null || requestParameters.sdnNachrichtEmpfaenger === undefined) {
            throw new runtime.RequiredError('sdnNachrichtEmpfaenger','Required parameter requestParameters.sdnNachrichtEmpfaenger was null or undefined when calling addRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/recipient`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: SdnNachrichtEmpfaengerToJSON(requestParameters.sdnNachrichtEmpfaenger),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async addRecipient(requestParameters: AddRecipientRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.addRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteRecipientRaw(requestParameters: DeleteRecipientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling deleteRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/recipient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteRecipient(requestParameters: DeleteRecipientRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findDistinctEventsRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<Array<string>>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/distinctEvents`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse<any>(response);
    }

    /**
     */
    async findDistinctEvents(initOverrides?: RequestInit): Promise<Array<string>> {
        const response = await this.findDistinctEventsRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findMailByIdRaw(requestParameters: FindMailByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<SdnNachrichtWrapper>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findMailById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => SdnNachrichtWrapperFromJSON(jsonValue));
    }

    /**
     */
    async findMailById(requestParameters: FindMailByIdRequest, initOverrides?: RequestInit): Promise<SdnNachrichtWrapper> {
        const response = await this.findMailByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findMailsFilteredRaw(requestParameters: FindMailsFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageSdnNachrichtWrapper>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageSdnNachrichtWrapperFromJSON(jsonValue));
    }

    /**
     */
    async findMailsFiltered(requestParameters: FindMailsFilteredRequest = {}, initOverrides?: RequestInit): Promise<PageSdnNachrichtWrapper> {
        const response = await this.findMailsFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findRecipientRaw(requestParameters: FindRecipientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageNachrichtEmpfaengerWrapper>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.role) {
            queryParameters['role'] = requestParameters.role;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/recipient`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageNachrichtEmpfaengerWrapperFromJSON(jsonValue));
    }

    /**
     */
    async findRecipient(requestParameters: FindRecipientRequest = {}, initOverrides?: RequestInit): Promise<PageNachrichtEmpfaengerWrapper> {
        const response = await this.findRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findRecipientByIdRaw(requestParameters: FindRecipientByIdRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<NachrichtEmpfaengerWrapper>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling findRecipientById.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/recipient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => NachrichtEmpfaengerWrapperFromJSON(jsonValue));
    }

    /**
     */
    async findRecipientById(requestParameters: FindRecipientByIdRequest, initOverrides?: RequestInit): Promise<NachrichtEmpfaengerWrapper> {
        const response = await this.findRecipientByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newCustomMailRaw(requestParameters: NewCustomMailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.sdnNachricht === null || requestParameters.sdnNachricht === undefined) {
            throw new runtime.RequiredError('sdnNachricht','Required parameter requestParameters.sdnNachricht was null or undefined when calling newCustomMail.');
        }

        const queryParameters: any = {};

        if (requestParameters.sdnNachricht !== undefined) {
            queryParameters['sdnNachricht'] = requestParameters.sdnNachricht;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/newcustom`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async newCustomMail(requestParameters: NewCustomMailRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.newCustomMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async newMailRaw(requestParameters: NewMailRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.event === null || requestParameters.event === undefined) {
            throw new runtime.RequiredError('event','Required parameter requestParameters.event was null or undefined when calling newMail.');
        }

        if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
            throw new runtime.RequiredError('requestBody','Required parameter requestParameters.requestBody was null or undefined when calling newMail.');
        }

        const queryParameters: any = {};

        if (requestParameters.event !== undefined) {
            queryParameters['event'] = requestParameters.event;
        }

        if (requestParameters.mainMail !== undefined) {
            queryParameters['mainMail'] = requestParameters.mainMail;
        }

        if (requestParameters.landwirte) {
            queryParameters['landwirte'] = requestParameters.landwirte;
        }

        if (requestParameters.landwirtId !== undefined) {
            queryParameters['landwirtId'] = requestParameters.landwirtId;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/new`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.requestBody,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async newMail(requestParameters: NewMailRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.newMailRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateRecipientRaw(requestParameters: UpdateRecipientRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id','Required parameter requestParameters.id was null or undefined when calling updateRecipient.');
        }

        if (requestParameters.sdnNachrichtEmpfaenger === null || requestParameters.sdnNachrichtEmpfaenger === undefined) {
            throw new runtime.RequiredError('sdnNachrichtEmpfaenger','Required parameter requestParameters.sdnNachrichtEmpfaenger was null or undefined when calling updateRecipient.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/mail/recipient/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: SdnNachrichtEmpfaengerToJSON(requestParameters.sdnNachrichtEmpfaenger),
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateRecipient(requestParameters: UpdateRecipientRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateRecipientRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
