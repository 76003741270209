/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import {
    Bundesland,
    BundeslandFromJSON,
    BundeslandFromJSONTyped,
    BundeslandToJSON,
} from './Bundesland';

/**
 * 
 * @export
 * @interface PostleitzahlOrt
 */
export interface PostleitzahlOrt {
    /**
     * 
     * @type {string}
     * @memberof PostleitzahlOrt
     */
    id?: string;
    /**
     * 
     * @type {Bundesland}
     * @memberof PostleitzahlOrt
     */
    bundesland?: Bundesland;
    /**
     * 
     * @type {string}
     * @memberof PostleitzahlOrt
     */
    ort?: string;
    /**
     * 
     * @type {string}
     * @memberof PostleitzahlOrt
     */
    postleitzahl?: string;
    /**
     * 
     * @type {string}
     * @memberof PostleitzahlOrt
     */
    isoBundesland?: string;
}

export function PostleitzahlOrtFromJSON(json: any): PostleitzahlOrt {
    return PostleitzahlOrtFromJSONTyped(json, false);
}

export function PostleitzahlOrtFromJSONTyped(json: any, ignoreDiscriminator: boolean): PostleitzahlOrt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'bundesland': !exists(json, 'bundesland') ? undefined : BundeslandFromJSON(json['bundesland']),
        'ort': !exists(json, 'ort') ? undefined : json['ort'],
        'postleitzahl': !exists(json, 'postleitzahl') ? undefined : json['postleitzahl'],
        'isoBundesland': !exists(json, 'isoBundesland') ? undefined : json['isoBundesland'],
    };
}

export function PostleitzahlOrtToJSON(value?: PostleitzahlOrt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'bundesland': BundeslandToJSON(value.bundesland),
        'ort': value.ort,
        'postleitzahl': value.postleitzahl,
        'isoBundesland': value.isoBundesland,
    };
}

