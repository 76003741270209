import { Filter } from '../../../../definitions/FilterEnum';

const regions = {
    detailProp: 'regionId',
    formitems: [
        {
            label: 'regionName',
            name: 'regionName',
            type: 'plaintext',
            disabled: false
        },
        {
            label: 'regionTyp',
            name: 'regionTyp',
            type: 'select',
            rawdata: [
                { key: 'Country', value: 'Country' },
                { key: 'Region', value: 'Region' }
            ],
            data: [
                { key: 'Country', value: 'Country' },
                { key: 'Region', value: 'Region' }
            ],
            disabled: true
        },
        {
            label: 'land',
            name: 'land',
            type: 'plaintext',
            disabled: false
        }
    ],
    columns: [
        {
            title: 'regionName',
            dataIndex: 'regionName',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'regionTyp',
            dataIndex: 'regionTyp',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'land',
            dataIndex: 'land',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        }
    ]
};

export default regions;
