import * as RestApi from "./api/rest";
import * as ImportApi from "./api/importstatus";
const width = 600;
const height = 600;
const path_port = window.location.protocol + "//" + window.location.hostname + (window.location.port !== "" ? ":" + window.location.port : "");

let getRestApiConfig = (module, oidcUser) => {
    let restConfigs = ["company", "region", "farmer", "groups", "security", "geo", "mail", "version"];
    module = module.toLowerCase();
    let key = "";
    if (oidcUser !== null) {
        conf.uri.token = oidcUser.access_token;
        key = "Bearer " + conf.uri.token;
    } else {
        key = undefined;
    }
    if (restConfigs.includes(module)) {
        return new RestApi.Configuration({
            basePath: "/rest",
            accessToken: key
        });
    } else {
        throw new Error("module parameter not in [" + restConfigs.toString() + "]");
    }
};

let getImportApiConfig = (module, oidcUser) => {
    let importConfigs = ["importstate", "probeorder", "lkv"];
    module = module.toLowerCase();
    let key = "";
    if (oidcUser !== null) {
        conf.uri.token = oidcUser.access_token;
        key = "Bearer " + conf.uri.token;
    } else {
        key = undefined;
    }

    if (importConfigs.includes(module)) {
        return new ImportApi.Configuration({
            basePath: "/import",
            accessToken: key
        });
    } else {
        throw new Error("module parameter not in [" + importConfigs.toString() + "]");
    }
};

const conf = {
    uri: {
        nav: "/localapi/mainnav.json",
        dynToken: "",
        token: "",
        reports: "/ReportServer/Pages/ReportViewer.aspx?"
    },
    oauth: {
        popup_width: width,
        popup_height: height,
        clientId: "portal",
        redirectUri: path_port + "/",
        popupRedirectUri: path_port + "/",
        autoSignIn: false,
        popupWindowFeatures: "location=no,toolbar=no,width=" + width + ",height=" + height + "",
        responseType: "code",
        post_logout_redirect_uri: path_port + "/",
        scope: "profile email openid",
        //authority: "https://support.de-gmbh.com:44390/auth/realms/sdn_portal/.well-known/openid-configuration",
        authority: "https://dev0010-204/auth/realms/sdn_portal/.well-known/openid-configuration",
        silent_redirect_uri: path_port + "/",
        automaticSilentRenew: true,
        loadUserInfo: true,
        clockSkew: 1800
    },
    version: "Version: 32 / 2024-03-26"
};

const confDev = {
    uri: {
        token: null,
        dynToken: null,
        nav: "/localapi/mainnav.json",
        reports: path_port + "/ReportServer/Pages/ReportViewer.aspx?"
    },

    oauth: {
        popup_width: width,
        popup_height: height,
        clientId: "portal",
        redirectUri: path_port + "/",
        popupRedirectUri: path_port + "/",
        popupWindowFeatures: "location=no,toolbar=no,width=" + width + ",height=" + height + "",
        responseType: "code",
        postLogoutRedirectUri: path_port + "/",
        scope: "profile email openid",
        authority: path_port + "/auth/realms/sdn_portal/.well-known/openid-configuration",
        // silentRedirectUri: path_port + "/authentication/silent_callback",
        silent_redirect_uri: path_port + "/",
        automaticSilentRenew: true,
        loadUserInfo: true,
        autoSignIn: false,
        clockSkew: 1800
    },
    version: "Version: 32 / 2024-03-26"

};
let toExport = process.env.NODE_ENV === "production" ? confDev : conf;
export { toExport as conf };
export { getRestApiConfig };
export { getImportApiConfig };
