interface tParams {
    page: number;
    size: number;
    sort: undefined | Array<string>;
    search: undefined | string;
}

const helper = {
    isObjEmpty(p: any) {
        return Object.keys(p).length !== 0 && p.constructor === Object ? false : true;
    },

    prepareListParams(p: any, pageSize: number) {
        let params: tParams = {
            page: typeof p.pagination === 'undefined' ? 0 : p.pagination.current - 1,
            size: pageSize,
            sort: undefined,
            search: undefined
        };

        let sortString = undefined
        if (typeof p.sorter !== 'undefined' && typeof p.sorter.field !== 'undefined') {
            let sortorder = p.sorter.order === 'descend' ? 'desc' : 'asc';
            if (p.sorter.field === 'lwnachname' || p.sorter.field === 'lwvorname' || p.sorter.field === 'lwId') {
                if (p.sorter.field === 'lwId') {
                    sortString = 'sdnLandwirt.landwirtId,' + sortorder;
                } else if (p.sorter.field === 'lwvorname') {
                    sortString = 'sdnLandwirt.vorname,' + sortorder;
                } else {
                    sortString = 'sdnLandwirt.nachname,' + sortorder;
                }
            } else {
                if (p.sorter.field === 'labId') {
                    sortString = 'cvasSanoLaboratory.labId,' + sortorder;
                }
                else if (p.sorter.field === 'tsCompleted') {
                        sortString = 'cvasSanoLaboratory.tsCompleted,' + sortorder;
                } else {
                    sortString = p.sorter.field + ',' + sortorder;
                }
            }
            params.sort = [sortString]
        }

        if (typeof p.filter !== 'undefined' && Object.keys(p.filter).length !== 0 && p.filter.constructor === Object) {
            let tfilter = [];
            for (let i in p.filter) {
                if (p.filter[i] != null) {
                    let landwirtFix = i !== 'lwnachname' ? i : 'sdnLandwirt.nachname';
                    landwirtFix = landwirtFix !== 'lwId' ? landwirtFix : 'sdnLandwirt.landwirtId';
                    landwirtFix = landwirtFix !== 'lwvorname' ? landwirtFix : 'sdnLandwirt.vorname';
                    landwirtFix = landwirtFix !== 'labId' ? landwirtFix : 'cvasSanoLaboratory.labId';
                    if (
                        (typeof p.filter[i][0] === 'object' && p.filter[i][0].length > 1) ||
                        i === 'bundesland' ||
                        i === 'land' ||
                        i === 'status' ||
                        i === 'systemName' ||
                        i === 'isoBundesland' ||
                        i === 'isoLand' ||
                        i === 'iso'
                    ) {
                        if (i === 'land') {
                            if (p.filter[i].length === 1) {
                                tfilter.push(landwirtFix + "=='" + p.filter[i][0] + "'");
                            } else {
                                for (let k = 0; k < p.filter[i].length; k++) {
                                    p.filter[i][k] = p.filter[i][k].replace('*', '');
                                }

                                tfilter.push(landwirtFix + '=in=(' + p.filter[i] + ')');
                            }
                        } else {
                            if (
                                i === 'tsProbennahme' ||
                                i === 'tsBeendet' ||
                                i === 'dateierstellungsdatum900003' ||
                                i === 'importDate' ||
                                i === 'tsGesendet' ||
                                i === 'tsInsert'
                            ) {
                                tfilter.push(i + "=ge='" + p.filter[i][0][0] + "';" + i + "=le='" + p.filter[i][0][1] + "'");
                            } else {
                                if (p.filter[i][0] != null && p.filter[i][0].length > 0 && i !== 'gratisProbe') {
                                    tfilter.push(landwirtFix + '=in=(' + p.filter[i] + ')');
                                }
                            }
                        }
                    } else {
                        if (i === 'probenart') {
                            let str = '';
                            for (let field = 0; field < p.filter[i].length; field++) {
                                str += 'probenart==' + p.filter[i][field];
                                if (field !== p.filter[i].length - 1) {
                                    str += ',';
                                }
                            }
                            tfilter.push(str);
                        } else {
                            if (Array.isArray(p.filter[i][0])) {
                                if (p.filter[i][0].length > 0) {
                                    tfilter.push(landwirtFix + "=='" + p.filter[i] + "'");
                                }
                            } else {
                                tfilter.push(landwirtFix + "=='" + p.filter[i] + "'");
                            }
                        }
                    }
                }
            }
            let fstr = tfilter.join(';');
            if (fstr.length > 0) {
                params.search = fstr;
            }
        }
        return params;
    },

    prepareListData(data: any) {
        let rdata = [];
        if (data != null) {
            rdata = data;
            for (let i = 0; i < rdata.length; i++) {
                if (rdata[i].landwirt != null && rdata[i].landwirt.nachname != null) {
                    rdata[i] = Object.assign(rdata[i], {
                        lwnachname: rdata[i].landwirt.nachname,
                        lwvorname: rdata[i].landwirt.vorname,
                        lwId: rdata[i].landwirt.landwirtId
                    });
                }
                rdata[i] = Object.assign(rdata[i], { key: i });
            }
        }
        return rdata;
    }
};

export default helper;
