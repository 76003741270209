import React from "react";
import devmocks from "./../devmocks/devmocks";

export default class NavTopRight extends React.Component {

    render(){
        return(
            <div className="demes-navtopright-wrap">
                <div className="demes-navtopright-toolbar">
                    {devmocks.hmenu}
                </div>
                <div className="demes-navtopright-body">
                    <div className="demes-navtopright-content">
                        {devmocks.content}
                    </div>
                    <div className="demes-navtopright-rightnav">
                        {devmocks.accordion({topnav: true})}
                    </div>
                </div>
            </div>
        )
    }
}