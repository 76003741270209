import {Filter} from '../../../../../definitions/FilterEnum'
import {Renderer} from '../../../../../definitions/RendererEnum'
const maillog = {
	detailProp:"id",
	formitems: [
        {
            "label": "id",
            "name": "id",
            "type": "plaintext",
            "disabled": false
        }, {
            "label": "email",
            "name": "emailadresse",
            "type": "plaintext",
            "disabled": false
        },
		{
            "label": "betreff",
            "name": "betreff",
            "type": "plaintext",
            "disabled": false
        },{
            "label": "message",
            "name": "text",
            "type": "plaintext",
            "disabled": false
        },{
            "label": "gesendet",
            "name": "tsGesendet",
            "type": "plaintimedisplay",
            "disabled": false
        }
    ],
    "columns": [
		{
        	"title": "id",
			"dataIndex": "id",
			"align": "right",
			"filter": Filter.NUMBER,
			"sort": true,
			"width":"10%"
        },
		{
			"title": "email",
			"dataIndex": "emailadresse",
			"align": "left",
			"filter": Filter.TEXT,
			"sort": true,
			"width":"35%"
		},
        {
			"title": "date",
			"dataIndex": "tsGesendet",
			"renderer": Renderer.RENDERDATETIME,
			"filter": Filter.DATERANGE,
			"sort": true,
		},
		{
			"title": "status",
			"dataIndex": "status",
			"renderer": Renderer.MAILSTATUS,
			"align": "left",
			"filter": Filter.LIST,
			"filter_list": ["INPROGRESS", "SUCCESS", "ERROR"],
			"sort": true,
			"width:":"5%"
		},
		{
			"title": "betreff",
			"dataIndex": "betreff",
			"filter": Filter.TEXT,
			"responsive": ["lg"],
			"ellipsis": true,
			"width":"20%",
			"sort":true
		}
	],
	"renderTitle": (rec) => {
		return(
			rec.emailadresse
		)
	},
}

export default maillog;