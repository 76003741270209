import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { withAuth } from "oidc-react";
import userNotification from "../../../../../misc/notification";
let GeoRegion = class GeoRegion extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            selectdata: [],
            value: [],
            initialRegions: [],
            landIso: "",
            loading: false
        };
        this.loadNewData = this.debounce(this.loadNewData, 800);
        this.updateStateEvent = this.updateStateEvent.bind(this);
    }
    debounce(func, wait, immediate) {
        var timeout;
        return function () {
            var context = this,
                args = arguments;
            var later = function () {
                timeout = null;
                if (!immediate) func.apply(context, args);
            };
            var callNow = immediate && !timeout;
            clearTimeout(timeout);
            timeout = setTimeout(later, wait);
            if (callNow) func.apply(context, args);
        };
    }
    updateStateEvent(e) {
        let api = new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));
        if (!this.state.value.includes(e.detail)) {
            let requestBody = {
                search: "regionName==" + e.detail
            };
            api.findFiltered(requestBody).then((body) => {
                if (body.content !== undefined && body.content.length === 1 && !this.state.value.includes(e.detail)) {
                    this.setState(
                        {
                            value: [...this.state.value, body.content[0].regionName],
                            initialRegions: [...this.state.initialRegions, body.content[0]]
                        },
                        () => {
                            let ret = [];
                            if (this.state.initialRegions.length > 0) {
                                this.state.initialRegions.forEach((element) => {
                                    ret.push(element.regionId);
                                });
                            } else {
                                this.props.data.regionen.forEach((element) => {
                                    ret.push(element);
                                });
                            }
                            this.props.onChange(ret);
                        }
                    );
                } else {
                    if (body.content.length === 0) {
                        //userNotification("error", "notification.region.count.zero")
                    } else if (body.content.length > 1) {
                        //userNotification("error", "notification.region.count.many")
                    }
                }
            });
        }
    }

    async componentDidMount() {
        window.addEventListener("landSelected", this.updateStateEvent, true);
        if (this.props.data.regionen != null && this.props.data.regionen.length > 0) {
            let idString = "";
            this.props.data.regionen.forEach((e, index) => {
                idString += "" + e;
                if (index !== this.props.data.regionen.length - 1) {
                    idString += ",";
                }
            });
            let api = new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));
            let requestBody = {
                search: "regionId=in=(" + idString + ")",
                size: 300
            };
            let regionen = await api.findFiltered(requestBody);
            this.setState(
                {
                    initialRegions: regionen.content != null ? regionen.content : []
                },
                () => {
                    this.parseValues();
                }
            );
        }

        this._getSelectData();
    }
    componentWillUnmount() {
        window.removeEventListener("landSelected", this.updateStateEvent, true);
    }

    parseValues() {
        if (
            Object.keys(this.props.data).length !== 0 &&
            this.props.data.constructor === Object &&
            this.props.data.regionen != null &&
            this.state.value.length === 0
        ) {
            if (this.props.data.regionen.length > 0) {
                let values = [];
                this.state.initialRegions.forEach((itm) => {
                    if (this.props.data.regionen.includes(itm.regionId)) {
                        values.push(itm.regionName);
                    }
                });
                if (values.length > 0) {
                    this.setState({
                        value: values
                    });
                }
            }
        }
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        try {
            let api = new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));

            let requestBody = {
                size: 300
            };

            let resp = await api.findFiltered(requestBody);
            if (resp !== undefined && resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            this.setState({
                loading: false
            });
            userNotification("error", "notification.region.get.error");
        }
    }

    loadNewData = (userInput) => {
        this.setState({
            loading: true,
            selectdata: []
        });
        let api = new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));
        let searchString = userInput != null && userInput !== "" ? "regionName=='" + userInput + "*'" : undefined;
        let requestBody = {
            search: searchString,
            size: 300
        };
        api.findFiltered(requestBody)
            .catch(() => {
                userNotification("error", "notification.region.get.error");
            })
            .then((body) => {
                if (body.content !== undefined) {
                    this.setState({
                        selectdata: body.content !== undefined ? body.content : [],
                        loading: false
                    });
                }
            });
    };
    onChange = (value, option) => {
        this.setState({
            value: value,
            loading: false
        });
        let ret = [];
        for (let x = 0; x < option.length; x++) {
            if (option[x].obj != null && option[x].obj.regionId != null) {
                ret.push(option[x].obj.regionId);

                let arr = this.state.initialRegions;
                let exists = arr.find((region) => region.regionName === option[x].obj.regionName);
                if (exists === undefined) {
                    arr.push(option[x].obj);
                    this.setState({
                        initialRegions: [...this.state.initialRegions, option[x].obj]
                    });
                }
            } else {
                let found = this.state.initialRegions.find((region) => region.regionName === value[x]);
                if (found != null) {
                    ret.push(found.regionId);
                }
            }
        }
        this.props.onChange(ret);

        let regionArray = this.state.initialRegions;
        for (let i = regionArray.length - 1; i >= 0; i--) {
            let found = false;
            value.forEach((e) => {
                if (regionArray[i].regionName === e) {
                    found = true;
                }
            });
            if (!found) {
                regionArray.splice(i, 1);
            }
        }

        this.setState({
            initialRegions: regionArray
        });
    };

    render() {
        const { loading, value } = this.state;
        return (
            <Select
                mode="multiple"
                value={value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                showSearch
                onChange={this.onChange}
                filterOption={false}
                onSearch={this.loadNewData}
                disabled={this.props.disabled}
            >
                {this.state.selectdata.map((itm) => {
                    return (
                        <Select.Option key={itm.regionId} value={itm.regionName} obj={itm}>
                            {itm.regionName}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
};

export default withAuth(GeoRegion);
