import React from "react";
import {Button, Drawer} from "antd";
import { withAuth } from "oidc-react";
import { isFarmer } from '../../../misc/getUserRoles';
import ResultDownload from '../../../portal/sano/resultdownload';
import i18n from '../../../misc/i18n';

class OrderDownload extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            visible: false,
            detaildata: {},
            cvasList: []
        }
    }

    componentDidMount(){
        this.getProtectedCVASList()
    }

    shouldShow(itm) {
        if (isFarmer(this.props.userData) && itm.dokumentArt.toLowerCase() === 'xml') {
            return false;
        }
        return true;
    }

    getProtectedCVASList() {
        let cvasList = [];
        if(this.props.item?.cvasDocumentList === undefined){
            return []
        }
        this.props.item.cvasDocumentList.forEach((e) => {
            if (this.shouldShow(e)) {
                cvasList.push(e);
            }
        });
        this.setState({
            cvasList: cvasList
        });
    }    

    _getDownloads(){
        if(typeof(this.props.item) !== "undefined" && typeof(this.props.item.cvasDocumentList) !== "undefined" && this.props.item.cvasDocumentList.length > 0){
            let list = [];
            for(let i=0; i<this.props.item.cvasDocumentList.length; i++){
                if(this.props.item.cvasDocumentList[i].dokumentArt !== "XML"){
                    list.push(this.props.item.cvasDocumentList[i])
                }
            }
            return(
                <ResultDownload dataSource={list} />
            )
        }else{
            return(null)
        }

    }

    render(){
        return(
            <div className="resp-order-download-wrap">
                <Button onClick={() => {
                    this.setState({
                        visible: !this.state.visible
                    })
                }} disabled={(this.state.cvasList.length === 0) ? true : false}>{i18n.t('navitem.dateien')}</Button>
                <Drawer
                    forceRender={true}
                    width={"100%"}
                    title={i18n.t('navitem.dateien')}
                    placement="right"
                    closable={true}
                    onClose={() => {
                        this.setState({
                            visible: !this.state.visible
                        })
                    }}
                    visible={this.state.visible}
                >
                    {this._getDownloads()}
                </Drawer>

            </div>
        )
    }
}

export default withAuth(OrderDownload)
