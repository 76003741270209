import React from 'react';
import { Button, Modal } from "antd";
import LoadMaskFrame from './../../../../misc/loadmaskframe';

export default class FsFrame extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            loading: true,
            modalVisible: false
        }
    }

    render(){
        let uri = (this.props.uri === "") ? "about:blank" : this.props.uri
        return(
            <>
                <div className="fsframe-wrap">
                    {
                        (this.state.loading && uri !== "about:blank")
                        ? <LoadMaskFrame />
                        : null
                    }
                    <Button style={{float: "right"}} onClick={() => {
                        this.setState({
                            modalVisible: true
                        })
                    }}>
                        <i className="fal fa-window-maximize"></i>
                    </Button>
                    <iframe 
                        className="fsframe" 
                        src={uri} 
                        title={uri} 
                        frameBorder="0" 
                        onLoad={() => {
                            this.setState({
                                loading: false
                            })
                        }}
                    />
                </div>
                <Modal
                    title={<span>&nbsp;</span>}
                    visible={this.state.modalVisible}
                    onOk={() => {
                        this.setState({
                            modalVisible: false
                        })
                    }}
                    onCancel={() => {
                        this.setState({
                            modalVisible: false
                        })
                    }}
                    centered={true}
                    width="100%"
                    zIndex={100000000000}
                    footer={null}
                    bodyStyle={{height: "100%", padding: "10px"}}
                    wrapClassName="fsmdwrap"
                >
                    <iframe className="fsframe" src={uri} title={uri} frameBorder="0" />
                </Modal>
            </>
        )
    }
}
