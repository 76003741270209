let parseJwt = (token) => {
    var base64Url = token.split('.')[1];
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
    }).join(''));

    return JSON.parse(jsonPayload);
};


let filterMenue = (menue, oidcUser) => {
    if (oidcUser != null) {
        let token = parseJwt(oidcUser.access_token);
        let toShow = [];
        for (let tab of menue.tabs) {
            
            for (let role of token.realm_access.roles) {
                let found = false;
                for (let tabRole of tab.meta.roles){
                    if(role.toLowerCase() === tabRole.toLowerCase()){
                        toShow.push(tab)
                        found = true
                        break;
                    }
                }
                if (found) {
                    break;
                }
            }    
        }
        for(let tab of toShow){
            let newTabItems = [];
            for(let item of tab.items){
                if(item.roles != null){
                    if(Array.isArray(item.roles)){
                        for(let itemRole in item.roles){
                            let found = false;
                            for(let role of token.realm_access.roles){
                                if(role.toLowerCase() === item.roles[itemRole].toLowerCase()){
                                    
                                    newTabItems.push(item);
                                    found = true;
                                    break;
                                }
                            }
                            if (found) {
                                break;
                            }
                        }
                    }  
                }else{
                    newTabItems.push(item)
                }
            }
            tab.items = newTabItems;
        }
        let newMenue = {
            meta: menue.meta,
            tabs: toShow
        }
        return newMenue

    } else {
        let newMenue = {
            meta: menue.meta,
            tabs: []
        }
        return newMenue
    }

}

export default filterMenue;