/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Landwirt,
    LandwirtFromJSON,
    LandwirtToJSON,
    PageLandwirt,
    PageLandwirtFromJSON,
    PageLandwirtToJSON,
} from '../models';

export interface AnonymizeRequest {
    landwirtId: number;
}

export interface CreateLandwirtRequest {
    landwirt: Landwirt;
}

export interface DeleteLandwirteRequest {
    ids: Array<number>;
}

export interface FindById1Request {
    landwirtId: number;
}

export interface FindFiltered1Request {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindLandwirtByQueryRequest {
    q: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface UpdateLandwirtRequest {
    landwirtId: number;
    body: string;
}

/**
 * 
 */
export class LandwirtControllerApi extends runtime.BaseAPI {

    /**
     */
    async anonymizeRaw(requestParameters: AnonymizeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        if (requestParameters.landwirtId === null || requestParameters.landwirtId === undefined) {
            throw new runtime.RequiredError('landwirtId','Required parameter requestParameters.landwirtId was null or undefined when calling anonymize.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt/anonymize/{landwirtId}`.replace(`{${"landwirtId"}}`, encodeURIComponent(String(requestParameters.landwirtId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async anonymize(requestParameters: AnonymizeRequest, initOverrides?: RequestInit): Promise<void> {
        await this.anonymizeRaw(requestParameters, initOverrides);
    }

    /**
     */
    async createLandwirtRaw(requestParameters: CreateLandwirtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Landwirt>> {
        if (requestParameters.landwirt === null || requestParameters.landwirt === undefined) {
            throw new runtime.RequiredError('landwirt','Required parameter requestParameters.landwirt was null or undefined when calling createLandwirt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LandwirtToJSON(requestParameters.landwirt),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandwirtFromJSON(jsonValue));
    }

    /**
     */
    async createLandwirt(requestParameters: CreateLandwirtRequest, initOverrides?: RequestInit): Promise<Landwirt> {
        const response = await this.createLandwirtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteLandwirteRaw(requestParameters: DeleteLandwirteRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling deleteLandwirte.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt/del/{ids}`.replace(`{${"ids"}}`, encodeURIComponent(String(requestParameters.ids))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteLandwirte(requestParameters: DeleteLandwirteRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteLandwirteRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findById1Raw(requestParameters: FindById1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Landwirt>> {
        if (requestParameters.landwirtId === null || requestParameters.landwirtId === undefined) {
            throw new runtime.RequiredError('landwirtId','Required parameter requestParameters.landwirtId was null or undefined when calling findById1.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt/{landwirtId}`.replace(`{${"landwirtId"}}`, encodeURIComponent(String(requestParameters.landwirtId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandwirtFromJSON(jsonValue));
    }

    /**
     */
    async findById1(requestParameters: FindById1Request, initOverrides?: RequestInit): Promise<Landwirt> {
        const response = await this.findById1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFiltered1Raw(requestParameters: FindFiltered1Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageLandwirt>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLandwirtFromJSON(jsonValue));
    }

    /**
     */
    async findFiltered1(requestParameters: FindFiltered1Request = {}, initOverrides?: RequestInit): Promise<PageLandwirt> {
        const response = await this.findFiltered1Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findLandwirtByQueryRaw(requestParameters: FindLandwirtByQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageLandwirt>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling findLandwirtByQuery.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageLandwirtFromJSON(jsonValue));
    }

    /**
     */
    async findLandwirtByQuery(requestParameters: FindLandwirtByQueryRequest, initOverrides?: RequestInit): Promise<PageLandwirt> {
        const response = await this.findLandwirtByQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async updateLandwirtRaw(requestParameters: UpdateLandwirtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.landwirtId === null || requestParameters.landwirtId === undefined) {
            throw new runtime.RequiredError('landwirtId','Required parameter requestParameters.landwirtId was null or undefined when calling updateLandwirt.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateLandwirt.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/landwirt/{landwirtId}`.replace(`{${"landwirtId"}}`, encodeURIComponent(String(requestParameters.landwirtId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateLandwirt(requestParameters: UpdateLandwirtRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateLandwirtRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
