import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../api/importstatus";
import { getImportApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import i18n from "../../../../misc/i18n";
import userNotification from "../../../../misc/notification";

interface IState {
    selectdata: Array<any>;
    loading: boolean;
    value: string[];
    language: string;
    alli18n: string;
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    disabled: boolean | undefined;
    defaultValue: string[] | undefined;
    startDate: any;
    endDate: any;
}

class ProbenartSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            value: this.props.defaultValue ? this.props.defaultValue : [],
            language: "",
            alli18n: "order.probenart.all"
        };
    }
    componentDidMount() {
        this._getSelectData();
    }

    componentDidUpdate(prevProps: IProps) {
        if (i18n.language !== this.state.language) {
            let values = this.state.value;
            for (let i = 0; i < values.length; i++) {
                //Alte Übersetzung mit neuer austauschen
                if (values[i] === i18n.t(this.state.alli18n, { lng: this.state.language })) {
                    values[i] = i18n.t(this.state.alli18n);
                }
            }
            this.setState(
                {
                    language: i18n.language,
                    value: values
                },
                () => {
                    if (this.props.onChange != null) {
                        this.props.onChange(values, []);
                    }
                }
            );
        }
        //Wenn das Datum geändert wurde neue Daten laden
        if (this.props.startDate.toISOString() !== prevProps.startDate.toISOString() || this.props.endDate.toISOString() !== prevProps.endDate.toISOString()) {
            this._getSelectData();
        }
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        try {
            let api = new Api.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));

            let resp = await api.findDistinctSampleTypeForXRF();

            if (resp !== undefined) {
                this.setState({
                    selectdata: resp,
                    loading: false
                });
            } else {
                this.setState({
                    selectdata: resp,
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.probetype.error");
            this.setState({
                loading: false
            });
        }
    }

    onChange = (value: string[], option: any) => {
        if (value.includes(i18n.t(this.state.alli18n)) && !this.state.value.includes(i18n.t(this.state.alli18n))) {
            value = [i18n.t(this.state.alli18n)];
            // Prüft ob alle vorausgewählt ist und nun eine spezifiesche Auswahl getroffen wurde und entfernt dann den Eintrag "Alle"
        } else if (
            this.state.value.length === 1 &&
            this.state.value.includes(i18n.t(this.state.alli18n)) &&
            value.includes(i18n.t(this.state.alli18n)) &&
            value.length > 1
        ) {
            value = value.filter((e) => e !== i18n.t(this.state.alli18n));
        }
        if (value.length === 0) {
            value = [i18n.t(this.state.alli18n)];
        }
        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };
    addAll(items: any[]) {
        if (items.length > 0) {
            let all = i18n.t(this.state.alli18n);
            return [all, ...items];
        }
        return items;
    }

    render() {
        const { loading } = this.state;
        return (
            <Select
                mode="multiple"
                value={this.props.value}
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                style={{ width: "100%" }}
                disabled={this.props.disabled}
                showSearch
                optionFilterProp="children"
                filterOption={(input, option) => {
                    return option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;
                }}
            >
                {this.addAll(this.state.selectdata).map((itm, y) => {
                    return (
                        <Select.Option key={y} value={itm}>
                            {i18n.t("order.probenart." + itm).indexOf("order.probenart.") !== -1 ? itm : i18n.t("order.probenart." + itm)}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
}

export default withAuth(ProbenartSelectForm);
