import React from "react";
import helper from "../../../listdetail/listtable/_helper";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import _ListeLandwirt from "./_listeLandwirt";
import ListDetail from "../../../listdetail";
import { withAuth, AuthContextProps } from "oidc-react";
import userNotification from "../../../../../misc/notification";

interface ListeLandwirtProps extends AuthContextProps {}

interface ListeLandwirtState {
    loading: boolean;
    listdata: any;
    detaildata: any;
    pageSize: number;
    detailVisible: boolean;
    conf: any;
    pagination: any;
    currentEmpfaenger: number;
}

class ListeLandwirt extends React.Component<ListeLandwirtProps, ListeLandwirtState> {
    constructor(props: ListeLandwirtProps) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: 10,
            detailVisible: false,
            conf: _ListeLandwirt,
            pagination: {},
            currentEmpfaenger: 0
        };
    }

    _getApi() {
        return new Api.LandwirtControllerApi(getRestApiConfig("farmer", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp: any, params: any) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: false,
            detaildata: {},
            loading: false,
            pageSize: params.size,
            pagination: {
                total: resp.totalElements,
                pageSize: params.size
            }
        });
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        try {
            let params = helper.prepareListParams(p, this.state.pageSize);
            if (params.search === undefined) {
                params.search = "emailbenachrichtigungJn==true";
            } else {
                params.search = "(" + params.search + ");emailbenachrichtigungJn==true";
            }
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort
            };
            let resp = await this._getApi().findFiltered1(requestBody);
            if (resp != null) {
                this._setListState(resp, params);
            }
        } catch (e) {
            userNotification("error", "notification.farmer.get.error");
            this.setState({
                loading: false
            });
        }
    }

    render() {
        let formitems = this.state.conf.formitems;
        return (
            <ListDetail
                renderTitle={this.state.conf.renderTitle}
                hasToolbar={false}
                delete={false}
                columns={this.state.conf.columns}
                listdata={this.state.listdata}
                formitems={formitems}
                detailProp={this.state.conf.detailProp}
                detaildata={this.state.detaildata}
                fetchListData={(p: any) => this._fetchListData(p)}
                detailVisible={this.state.detailVisible}
                loading={this.state.loading}
                pagination={this.state.pagination}
                onDetailClose={() => {
                    this.setState({
                        detailVisible: false
                    });
                }}
                onTableChange={(p: any) => {
                    this.setState(
                        {
                            pageSize: p.pagination.pageSize
                        },
                        () => {
                            this._fetchListData(p);
                        }
                    );
                }}
            />
        );
    }
}
export default withAuth(ListeLandwirt);
