import React from 'react';
import { Button, Popconfirm } from 'antd';
import i18n from './../../../../misc/i18n';
import colfilters from './../../datatable/colfilters';
import colrenderer from './../../datatable/colrenderer';
import { Filter } from '../../../../definitions/FilterEnum';
import { Renderer } from '../../../../definitions/RendererEnum';
import ResponsiveTable from './../../../../misc/responsivetable';

export default class ListTable extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            data: [],
            pagination: {
                current: 1,
                pageSize: 20,
            },
            params: {
                pagination: {
                    current: 1,
                    pageSize: 20,
                },
                sorter: {},
                filter: {}
            },
            qustr: '',
            delRow: null,
            selectedRowIndex: undefined,
        };
    }

    getColRenderer(col) {
        if (col.renderer === Renderer.BUNDESLAND) {
            return colrenderer.renderBundeslandName(col);
        }
        if (col.renderer === 'probenart') {
            return colrenderer.renderProbenartName(col);
        }
        if (col.renderer === 'bestellstatus') {
            return colrenderer.renderBestellStatus(col);
        }
        return colrenderer.render(col);
    }

    getColFilter(col) {
        if (col.filter === Filter.LIST) {
            return colfilters.render(col);
        } else if (col.filter === Filter.BOOLEAN) {
            return colfilters.render(col);
        } else if (col.filter === Filter.TEXT) {
            return colfilters.render(col);            
        } else if (col.filter === Filter.DATERANGE) {
            return colfilters.render(col);
        } else if (col.filter === Filter.NUMBER) {
            return colfilters.render(col);
        } else if (col.filter === Filter.BUNDESLAND) {
            return colfilters.render(col);
        } else if (col.filter === Filter.COUNTRY) {
            return colfilters.render(col);
        } else if (col.filter === 'probenart') {
            return colfilters.render(col);
        }

        return col;
    }

    getColSorter(col) {
        return { sorter: true };
    }

    doColumns(cols) {
        cols = JSON.parse(JSON.stringify(cols));
        let ret = [];
        for (let i = 0; i < cols.length; i++) {
            let col = cols[i];
            let label = i18n.t("table.col.title." + col.title);
            col.title = label;
            col.label = label;
            if (typeof col.renderer !== 'undefined') {
                Object.assign(col, this.getColRenderer(col));
            }
            if (typeof col.filter !== 'undefined') {
                Object.assign(col, this.getColFilter(col));
            }
            if (typeof col.sort !== 'undefined') {
                Object.assign(col, this.getColSorter(col));
            }
            col.filterIcon = (
                <span className="col-filter-icon-wrap">
                    <i className="fas fa-search"></i>
                </span>
            );
            ret.push(col);
        }

        if (this.props.delete) {
            let col = {
                width: 50,
                render: (text, row, index) => {
                    return (
                        <Popconfirm
                            title={i18n.t('table.delete.text')}
                            onConfirm={(e) => {
                                if (this.props.onDelete) {
                                    this.props.onDelete(this.state.delRow);
                                }
                                e.stopPropagation();
                            }}
                            onCancel={(e) => {
                                e.stopPropagation();
                            }}
                            okText={i18n.t('table.delete.yes')}
                            cancelText={i18n.t('table.delete.no')}
                        >
                            <Button
                                type="default"
                                shape="round"
                                size="small"
                                onClick={(e) => {
                                    this.setState({
                                        delRow: row
                                    });
                                    e.stopPropagation();
                                }}
                            >
                                <i className="fal fa-trash-alt"></i>
                            </Button>
                        </Popconfirm>
                    );
                }
            };
            ret.unshift(col);
        }
        if (this.props.download) {
            let col = {
                width: 50,
                render: (text, row, index) => {
                    return (
                        <Button
                            type="default"
                            shape="round"
                            size="small"
                            style={{cursor:"pointer"}}
                            onClick={(e) => {
                                if(this.props.onDownload){
                                    this.props.onDownload(row);
                                }else{
                                    console.warn("listtable.js onDownload callback is undefined")
                                }
                                
                            }}
                        >
                            <i className="fas fa-download"></i>
                        </Button>
                    );
                }
            };
            ret.unshift(col);
        }
        return ret;
    }
        
    handleTableChange(pagination, filter, sorter){

		let tfilter = {};
		let tsorter = {};
		for(let i in filter){
			if(filter[i] != null){
				tfilter[i] = filter[i]
			}
		}
		if(typeof(sorter.order) !== "undefined"){
			tsorter = {
				field: sorter.field,
				order: sorter.order
			}
		}

		let params = {
			pagination: pagination, 
			filter: tfilter, 
			sorter: tsorter
        }

        this.setState(
            {
                params: params,
                pagination: pagination,
                filter: filter,
                sorter: sorter
            },
            () => {
                if (this.props.onTableChange) {
                    this.props.onTableChange({
                        params: this.state.params,
                        pagination: this.state.pagination,
                        filter: this.state.filter,
                        sorter: this.state.sorter
                    });
                }
            }
        );
    }
    getRowClassName = (record,index)=>{
        if(this.props.onRowSelection == null && this.props.renderRecordSub == null && this.props.onDownload == null && this.props.rowSelection == null){
            return "notClickable"
        }
        if(index === this.state.selectedRowIndex && this.props.showSelectedRow){
            return "selectedRowSano"
        }
    }

    render() {
        let pObj = this.props.pagination != null ? this.props.pagination : {};
        let paginationObj = Object.assign(pObj, {
            showSizeChanger: true
            // current: 1
        });
        if (this.props.hidePagination) {
            paginationObj = {
                disabled: true,
                hideOnSinglePage: true
            };
        }
        
        let cursorStyle = {"cursor":"pointer"}
        return (
            <div className="list-wrap">
                <ResponsiveTable
                    rowClassName={this.getRowClassName}
                    style = {this.props.onRowSelection != null? cursorStyle : this.props.rowSelection != null && this.props.rowSelection != null? cursorStyle: undefined}
                    loading={this.props.loading}
                    size="small"
                    className={this.props.className}
                    columns={this.doColumns(this.props.cols)}
                    dataSource={this.props.data}
                    pagination={paginationObj}
                    type={this.props.type}
                    renderTitle={this.props.renderTitle}
                    renderRecordSub={this.props.renderRecordSub}
                    // pagination={this.props.pagination}
                    rowSelection={this.props.rowSelection}
                    onRow={this.props.onRowSelection != null?(record, index) => {
                        
                        return {
                            onClick: (e) => {
                                this.setState({
                                    selectedRowIndex:index
                                })
                                if (this.props.onRowSelection) {
                                    this.props.onRowSelection(record,index);
                                }
                            }
                        };
                    }:undefined}
                    onChange={this.handleTableChange.bind(this)}
                    expandable={this.props.expandable}
                />
            </div>
        );
    }
}
