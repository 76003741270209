import React from 'react';
import { Button, Space } from 'antd';

import TopMenuNotLoggedIn from "./topmenunotloggedin";
import TopMenuLoggedIn from "./topmenuloggedin";
import { AuthContextProps, withAuth } from "oidc-react";

interface IProps extends AuthContextProps{
    collapsed : boolean,
    onCollapse: Function
}

interface IState{
    about: boolean,
    help: boolean,
    collapsed: boolean,
    responsive: object,
    time?: number
}



class TopMenu extends React.Component<IProps,IState> {

    state = {
        about: false,
        help: false,
        collapsed: this.props.collapsed,
        responsive: {}
    };

    _setResponsive(values : object){
        this.setState({
            responsive: values
        })
    }
    
    render(){
        return(
            <div className="topmenu-layout-header">
                <div className="topmenu-layout-header-left">
                    <Space>
                        <Button onClick={() => {
                            this.setState({
                                collapsed: !this.state.collapsed
                                },() =>{
                                    if(this.props.onCollapse){
                                        this.props.onCollapse()
                                    }
                                })
                            }}
                        > 
                            {
                                (this.state.collapsed)
                                ? <i className="fal fa-chevron-double-right"></i>
                                : <i className="fal fa-chevron-double-left"></i>
                            }
                        </Button>
                    </Space>
                </div>

                <div className="topmenu-layout-header-center">
                    <h1>SDN Service Portal</h1>
                </div>

                <div className={this.props.userData !== null ? "topmenu-layout-header-right-loggedin":"topmenu-layout-header-right"}>
                    {
                        (this.props.userData !== null)
                        ? <TopMenuLoggedIn {...this.props} onLangChange={() => {
                            this.setState({
                                time: new Date().getTime()
                            })
                        }} />
                        : <TopMenuNotLoggedIn {...this.props} />
                    }    
                </div>
            </div>
        )
    }
}

export default withAuth(TopMenu);