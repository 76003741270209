import React from "react";
import SplitterLayout from 'react-splitter-layout';
import devmocks from "./../devmocks/devmocks";
import 'react-splitter-layout/lib/index.css';

export default class SplitVertical extends React.Component{

    render(){
        return(
            <div className="demes-split-wrap">
                <SplitterLayout vertical={true}>
                    <div>{devmocks.content}</div>
                    <div>{devmocks.content}</div>
                </SplitterLayout>
            </div>
        )
    }
}