import React from 'react';
import companies from './_companies';
import helper from '../listdetail/listtable/_helper';
import ListDetail from './../listdetail/';
import { Tabs } from 'antd';
import Security from './security/security';
import { getRestApiConfig } from './../../../../_conf';
import { withAuth, AuthContextProps } from 'oidc-react';
import * as Api from '../../../../api/rest/';
import i18n from './../../../misc/i18n';
import { validateData, RequestType } from './../../../misc/validateData';
import userNotification from './../../../misc/notification';
import { checkChanged } from './../../../misc/checkChanged';
import Sichtbarkeit from './sichtbarkeit';
import companiesDetailFilter from '../../../misc/permissionFilter/companiesDetailFilter';
//@ts-ignore Typedeclaration nicht verfügbar
// import MediaQuery from 'react-responsive';
import LKVExport from "./lkvexport/index";
import showPopUpError from "../../../misc/popUpError";
import { PopUpErrorTypes } from "../../../definitions/popUpErrorEnum";
import SubmitMails from "./submitMails";
import { dec } from "../../../misc/crypt";
import Anonymise from  "./../anonymise";
import cloneDeep from 'lodash.clonedeep';
const punycode = require('punycode');
const queryToStr = require("query-string");

interface IState {
    currentCompany: any;
    data: Array<any>;
    loading: boolean;
    listdata: Object | any;
    detaildata: Object | any;
    olddetaildata: Object;
    pagination: Object;
    pageSize: number;
    conf: typeof companiesDetailFilter | any;
    lookupdata: {
        landwirte: Array<any>;
        regionen: Array<any>;
    };
    oldDetailData: Array<any> | any;
    detailVisible: boolean;
    disableAll: boolean;
    prevTab: string;
}
interface IProps extends AuthContextProps {
    id: any;
    onDetailClose: any;
}

class Companies extends React.Component<IProps, IState> {
    formRef: React.RefObject<any>;
    constructor(props: IProps) {
        super(props);
        this.state = {
            currentCompany: undefined,
            data: [],
            loading: false,
            listdata: {},
            detaildata: {},
            olddetaildata: {},
            pagination: {},
            pageSize: 20,
            conf: companiesDetailFilter(companies, this.props.userData),
            lookupdata: {
                landwirte: [],
                regionen: []
            },
            oldDetailData: [],
            detailVisible: false,
            disableAll: true,
            prevTab: '1'
        };
        this.formRef = React.createRef();
    }

    _getApi() {
        return new Api.BetriebControllerApi(getRestApiConfig('company', this.props.userData));
    }

    async componentDidMount() {
        if (typeof this.props.id !== 'undefined') {
            this._fetchDetailData(this.props.id);
        } else {
            await this._fetchListData({});
            const result = queryToStr.parse(document.location.search);
            if (typeof result.id !== 'undefined' && result.id !== '') {
                let id = dec(result.id);
                this._fetchDetailData(id);
            }
        }
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        try {
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort
            };
            let resp = await this._getApi().findFiltered3(requestBody);
            this.setState({
                listdata: helper.prepareListData(resp.content),
                // detailVisible: false,
                // detaildata: {},
                loading: false,
                pageSize: params.size,
                pagination: {
                    current: params.page + 1,
                    total: resp.totalElements,
                    pageSize: params.size
                }
            });
        } catch (e) {
            userNotification('error', 'notification.company.get.error');
            this.setState({
                loading: false
                // detailVisible: false
            });
        }
    }

    async _fetchDetailData(id: any) {
        this.setState({
            currentCompany: id
        });
        try {
            let requestBody = {
                betriebsId: id
            };
            let betrieb: any = await this._getApi().findById2(requestBody);
            this.parseCompany(betrieb);

            betrieb.disableAll = betrieb.anonymisiertJn;
          
            this.setState({
                detaildata: betrieb,
                oldDetailData: betrieb,
                detailVisible: true,
                disableAll: betrieb.disableAll
            });
        } catch (e) {
            userNotification('error', 'notification.company.get.error');
        }
    }

    parseCompany = (company: any) => {
        if (typeof company.externeSysteme === 'undefined') {
            company = Object.assign(company, {
                externeSysteme: {
                    DDW: '',
                    CVAS: '',
                    LKV: '',
                    CRM: '',
                    ERP: ''
                }
            });
        }
        company.externeSysteme.DDW = typeof company.externeSysteme.DDW !== 'undefined' ? company.externeSysteme.DDW : '';
        company.externeSysteme.CVAS = typeof company.externeSysteme.CVAS !== 'undefined' ? company.externeSysteme.CVAS : '';
        company.externeSysteme.LKV = typeof company.externeSysteme.LKV !== 'undefined' ? company.externeSysteme.LKV : '';
        company.externeSysteme.CRM = typeof company.externeSysteme.CRM !== 'undefined' ? company.externeSysteme.CRM : '';

        //ERP Nummer aus Landwirt
        company.lw_ERP = company.landwirt?.externeSysteme?.ERP != null ? company.landwirt?.externeSysteme?.ERP : "";

        company = Object.assign(company, {
            externeSystemeDDW: company.externeSysteme.DDW,
            externeSystemeCVAS: company.externeSysteme.CVAS,
            externeSystemeLKV: company.externeSysteme.LKV,
            externeSystemeCRM: company.externeSysteme.CRM,
            externeSystemeERP: company.externeSysteme.ERP
        });
        if (company.landwirt != null) {
            if (company.landwirt.landwirtId != null) {
                company.landwirtId = company.landwirt.landwirtId;
            }
            if (company.landwirt.nachname != null) {
                company.lwnachname = company.landwirt.nachname;
            }
        } else {
            company.lwnachname = ' ';
        }
    };

    async _insertData(data: any) {
        let idata: any = {
            betriebsId: 0,
            betriebsName: data.betriebsName,
            betriebsNummer: data.betriebsNummer,
            bundesland: data.bundesland,
            euNummer: data.euNummer,
            externeSysteme: {
                DDW: data.externeSystemeDDW,
                CVAS: data.externeSystemeCVAS,
                LKV: data.externeSystemeLKV,
                CRM: data.externeSystemeCRM,
                ERP: data.externeSystemeERP
            },
            landwirt: {
                landwirtId: data.landwirtId
            },
            land: data.land,
            ortsteil: data.ortsteil,
            ort: data.ort,
            plz: data.plz,
            postleitzahlRe: data.postleitzahlRe,
            ortRe: data.ortRe,
            strasseNrRe: data.strasseNrRe,
            ortsteilRe: data.ortsteilRe,
            regionen: data.regionen,
            standardBetrieb: data.standardBetrieb !== undefined ? true : false,
            strasseNr: data.strasseNr,
            beschreibung: data.beschreibung,
            einsendeMails: [],
            firmaRe: data.firmaRe,
            landRe: data.landRe,
            nachnameRe: data.nachnameRe,
            vornameRe: data.vornameRe
        };
        try {
            let requestBody = {
                betrieb: idata
            };
            let data = await this._getApi().createBetrieb(requestBody);
            userNotification('success', 'notification.company.insert.success');
            this._fetchDetailData(data.betriebsId);
            this._fetchListData({});
        } catch (e) {
            if (e.status + '' === 406 + '') {
                try {
                    e.json().then((errorResponse: any) =>
                        errorResponse.message != null
                            ? errorResponse.message === '1402'
                                ? showPopUpError(PopUpErrorTypes.EUNUMMER)
                                : errorResponse.message === '1407'
                                ? showPopUpError(PopUpErrorTypes.LKV)
                                : userNotification('error', 'notification.company.insert.error')
                            : userNotification('error', 'notification.company.insert.error')
                    );
                } catch (error) {
                    userNotification('error', 'notification.company.insert.error');
                }
            } else {
                userNotification('error', 'notification.company.insert.error');
            }
        }
    }

    async _updateData(id: number, data: any) {
        data = Object.assign(data, {
            externeSysteme: {
                CRM: data.externeSystemeCRM,
                CVAS: data.externeSystemeCVAS,
                DDW: data.externeSystemeDDW,
                ERP: data.externeSystemeERP,
                LKV: data.externeSystemeLKV
            }
        });

        if (data.externeSysteme.CRM == null || data.externeSysteme.CRM.length === 0) {
            delete data.externeSysteme.CRM;
        }
        if (data.externeSysteme.CVAS == null || data.externeSysteme.CVAS.length === 0) {
            delete data.externeSysteme.CVAS;
        }
        if (data.externeSysteme.DDW == null || data.externeSysteme.DDW.length === 0) {
            delete data.externeSysteme.DDW;
        }
        if (data.externeSysteme.ERP == null || data.externeSysteme.ERP.length === 0) {
            delete data.externeSysteme.ERP;
        }
        if (data.externeSysteme.LKV == null || data.externeSysteme.LKV.length === 0) {
            delete data.externeSysteme.LKV;
        }
        if (Object.keys(data.externeSysteme).length === 0 && data.externeSysteme.constructor === Object) {
            delete data.externeSysteme;
        }

        data.landwirt = {
            landwirtId: data.landwirtId
        };
        delete data.landwirtId;
        if (Object.keys(data.landwirt).length === 0 || data.landwirt.landwirtId == null) {
            delete data.landwirt;
        }

        delete data.externeSystemeCRM;
        delete data.externeSystemeCVAS;
        delete data.externeSystemeDDW;
        delete data.externeSystemeERP;
        delete data.externeSystemeLKV;
        delete data.lwnachname;
        delete data.disableAll;

        try {
            let requestBody = {
                betriebsId: id,
                body: data
            };
            await this._getApi().updateBetrieb(requestBody);
            userNotification('success', 'notification.company.update.success');
            this.setState(
                {
                    detailVisible: true
                },
                () => {
                    this._fetchListData({});
                }
            );
        } catch (e) {
            if (e.status + '' === 406 + '') {
                try {
                    e.json().then((errorResponse: any) =>
                        errorResponse.message != null
                            ? errorResponse.message === '1402'
                                ? showPopUpError(PopUpErrorTypes.EUNUMMER)
                                : errorResponse.message === '1407'
                                ? showPopUpError(PopUpErrorTypes.LKV)
                                : userNotification('error', 'notification.company.update.error')
                            : userNotification('error', 'notification.company.update.error')
                    );
                } catch (error) {
                    userNotification('error', 'notification.company.update.error');
                }
            } else {
                userNotification('error', 'notification.company.update.error');
            }
        }
    }

    async _deleteData(id: number) {
        this.setState({
            loading: true
        });
        try {
            let requestBody = {
                ids: [id]
            };
            await this._getApi().deleteBetriebe(requestBody);
            userNotification('success', 'notification.company.delete.success');
            this.setState({
                loading: false
            });
            this._fetchListData({});
        } catch (e) {
            if (e.status === 406) {
                userNotification('error', 'notification.company.delete.probeexists');
            } else {
                userNotification('error', 'notification.company.delete.error');
            }

            this.setState({
                loading: false
            });
        }
    }

    onClose = () => {
        this.setState({
            detailVisible: false
        });
    };

    hasRole(role: string) {
        if (this.props.userData !== null && typeof this.props.userData !== 'undefined') {
            let token = this.parseJwt(this.props.userData.access_token);
            return token.realm_access.roles.indexOf(role) >= 0 ? true : false;
        }
        return false;
    }

    parseJwt(token: string) {
        var base64Url = token.split('.')[1];
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        var jsonPayload = decodeURIComponent(
            atob(base64)
                .split('')
                .map(function (c) {
                    return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
                })
                .join('')
        );
        return JSON.parse(jsonPayload);
    }

    getUserPermissions() {
        if (this.props.userData == null) {
            return {
                delete: false,
                add: false,
                security: false,
                sichtbarkeit: false,
                onDelete: () => {},
                onNew: () => {},
                onSave: () => {},
                hasToolbar: false,
                showSaveButton: false
            };
        }
        let token = this.parseJwt(this.props.userData.access_token);
        let perm = {
            delete: false,
            add: false,
            security: false,
            sichtbarkeit: false,
            onDelete: (r: any) => {},
            onNew: (data: any) => {},
            onSave: (data: any) => {},
            hasToolbar: false,
            showSaveButton: false
        };

        token.realm_access.roles.forEach((role: string) => {
            if (role === 'BusinessAdministrator' || role === 'Administrator') {
                perm = {
                    delete: true,
                    add: true,
                    hasToolbar: true,
                    security: true,
                    sichtbarkeit: true,
                    onDelete: (r: any) => {
                        this._deleteData(r);
                    },
                    onNew: (data) => {
                        this.setState({
                            detaildata: {
                                new: true
                            },
                            disableAll: false,
                            detailVisible: true
                        });
                    },
                    onSave: async (data) => {
                        if (this.state.detaildata.new != null) {
                            if (validateData(data, this.state.detaildata, companies, RequestType.post)) {
                                this._insertData(data);
                            }
                        } else {
                            for (const key in this.state.detaildata) {
                                if (Object.hasOwnProperty.call(this.state.detaildata, key)) {
                                    const element = this.state.detaildata[key];
                                    if (data[key] == null) {
                                        data[key] = element;
                                    }
                                }
                            }
                            try {
                                let requestBody = {
                                    betriebsId: this.state.currentCompany
                                };
                                let company: any = await this._getApi().findById2(requestBody);
                                this.parseCompany(company);

                                if (validateData(data, company, companies, RequestType.patch)) {
                                    checkChanged(
                                        data,
                                        company,
                                        companies,
                                        () => {
                                            this._updateData(this.state.detaildata[this.state.conf.detailProp], data);
                                        },
                                        () => {}
                                    );
                                }
                            } catch (e) {
                                userNotification('error', 'notification.company.get.error');
                            }
                        }
                        this._fetchListData({});
                    },
                    showSaveButton: true
                };
            }
        });
        return perm;
    }

    updateMails = (newMails: string[]) => {
        for(let i=0; i<newMails.length; i++){
            newMails[i] = punycode.toUnicode(newMails[i]);
        }
        let newData = { einsendeMails: newMails };
        if (validateData(newData, this.state.detaildata, companies, RequestType.patch)) {
            checkChanged(
                newData,
                this.state.detaildata,
                companies,
                () => {
                    this._updateData(this.state.detaildata[this.state.conf.detailProp], newData);
                    let oldDetailData = cloneDeep(this.state.detaildata);
                    oldDetailData.einsendeMails = newData.einsendeMails;
                    this.setState({
                        detaildata: oldDetailData
                    });
                },
                () => {}
            );
        }
    };

    _onTabsChange = (activeKey: any) => {
        if (activeKey !== '1' && this.state.prevTab === '1') {
            let currentValues = this.formRef.current.formRef.current.getFieldsValue(true);
            let newDetailData: any = {};
            for (let key in this.state.detaildata) {
                newDetailData[key] = currentValues[key];
            }
            this.setState({
                detaildata: newDetailData,
                prevTab: activeKey
            });
        } else if (activeKey === '1') {
            this.setState({
                prevTab: '1'
            });
        }
    };

    render() {
        let formitems = companies.formitems;
        let permission = this.getUserPermissions();
        let extratabs = [];
        if (permission.security && this.state.detaildata.new == null) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.register.userAdd")} key="2">
                    <Security currentCompany={this.state.currentCompany} parentgroup="Betrieb" disableAll={this.state.disableAll} />
                </Tabs.TabPane>
            );
        }
        if (permission.sichtbarkeit && this.state.detaildata.new == null) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t('navitem.register.sichtbarkeit')} key="3">
                    <Sichtbarkeit currentCompany={this.state.currentCompany} />
                </Tabs.TabPane>
            );
        }
        if (this.state.detaildata.new == null) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t('navitem.register.lkvdateien')} key="4">
                    <LKVExport currentCompany={this.state.currentCompany} />
                </Tabs.TabPane>
            );
        }
        if (this.state.detaildata.new == null && !this.hasRole('Farmer') && !this.hasRole('Consultant')) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.register.submitMails")} key="5">
                    <SubmitMails oldMails={this.state.detaildata.einsendeMails} updateMails={this.updateMails} disableAll={this.state.disableAll} />
                </Tabs.TabPane>
            );
        }
        if (this.hasRole("Administrator") && this.state.detaildata.anonymisiertJn) {
            extratabs.push(
                <Tabs.TabPane tab={i18n.t("navitem.anonymise")} key="6">
                    <Anonymise 
                        currentFarmer={this.state.detaildata.landwirt.landwirtId} 
                        detailData={this.state.detaildata} 
                        disableAll={this.state.disableAll} 
                        onAnonymise={() => {}}
                    />
                </Tabs.TabPane>
            );
        }

        return (
            <ListDetail
                refForForm={this.formRef}
                showSaveButton={permission.showSaveButton}
                renderTitle={this.state.conf.renderTitle}
                hasToolbar={permission.hasToolbar}
                delete={permission.delete}
                columns={this.state.conf.columns}
                listdata={this.state.listdata}
                formitems={formitems}
                detailProp={this.state.conf.detailProp}
                detaildata={this.state.detaildata}
                fetchListData={(p: any) => this._fetchListData(p)}
                detailVisible={this.state.detailVisible}
                loading={this.state.loading}
                pagination={this.state.pagination}
                disableAll={this.state.disableAll}
                onSave={(data: any) => {
                    permission.onSave(data);
                }}
                onDetailClose={() => {
                    this.setState(
                        {
                            detailVisible: false,
                            prevTab: '1'
                        },
                        () => {
                            if (this.props.onDetailClose) {
                                this.props.onDetailClose();
                            }
                        }
                    );
                }}
                onTableChange={(p: any) => {
                    this.setState(
                        {
                            pageSize: p.pagination.pageSize
                        },
                        () => {
                            this._fetchListData(p);
                        }
                    );
                }}
                onRowSelection={(id: any) => this._fetchDetailData(id)}
                onDelete={(id: any) => {
                    permission.onDelete(id);
                }}
                onNew={(data: any) => permission.onNew(data)}
                extraTabs={this.state.detaildata.new != null ? undefined : extratabs}
                onTabsChange={this._onTabsChange}
            />
        );
    }
}

export default withAuth(Companies);
