import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";


interface IState{
    selectdata: Array<any>;
    value : Array<any>;
    loading: boolean;
}

interface IProps extends AuthContextProps{
    onChange : Function
}

let selectLand = class selectLand extends React.Component<IProps,IState> {

    constructor(props : IProps) {
        super(props)
        this.state = {
            selectdata: [],
            value: [],
            loading: false
        }
    }

    componentDidMount() {
        this._getSelectData();
    }

    componentWillUnmount(){
    }

    async _getSelectData() {
        this.setState({
            loading: true
        })
        let api = new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData))
        let resp = await api.findAllLand();
        if (resp.content !== undefined) {
            this.setState({
                selectdata: (resp.content !== undefined) ? resp.content : [],
                loading: false,
            });
        }
    }

    onChange = (value : Array<string>) => {
        this.setState({
            value,
            loading: false,
        },()=>{
            this.props.onChange(value)
        });
    };

    render() {
        const { loading, value } = this.state;
        return (
            <Select
                value={value}
                mode="multiple"
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                filterOption={false}
                style={{width: "100%"}}
            >
                {this.state.selectdata.map((itm, y) => {
                    return (
                        <Select.Option
                            key={y}
                            value={itm.iso}
                        >
                            {itm.iso}
                        </Select.Option>
                    )
                })}
            </Select>
        )
    }

}

export default withAuth(selectLand);