import React from "react";
import { Button, Input } from "antd";
// import BundeslandRender from "./../../../portal/sano/datatable/ProbenartRenderer";
import ProbenartRenderer from "./../../../portal/sano/datatable/ProbenartRenderer";
import i18n from './../../../misc/i18n';
export default class ProbenartFilter extends React.Component{

    constructor(props){
        super(props);
        this.state = {
            showFilter: false,
            value: []
        }
    }
    
    render(){
        let value = [];
        let dvalue = [];
        if(typeof(this.props.value) !== "undefined"){
            value = this.props.value;
            if(Array.isArray(this.props.value)){
                if(Array.isArray(this.props.value[0])){
                    for(let i=0; i<this.props.value[0].length; i++){
                        let v = i18n.t('order.probenart.' + value[0][i]).includes('order.probenart') ? value[0][i] : i18n.t('order.probenart.' + value[0][i])
                        dvalue.push(v)
                    }
                }else{
                    for(let i=0; i<this.props.value.length; i++){
                        let v = i18n.t('order.probenart.' + value[i]).includes('order.probenart') ? value[i] : i18n.t('order.probenart.' + value[i])
                        dvalue.push(v)
                    }
                }
            }
        }

        return(
            <>
                <div className="customfilter-wrap">
                    <Button onClick={() => {
                        this.setState({
                            showFilter: !this.state.showFilter
                        })
                    }}>
                        {this.props.placeholder}
                    </Button> 
                    <Input className="customfilter-value-wrap" value={dvalue.join(", ")} disabled/>
                </div>
                {(this.state.showFilter) 
                    ? <ProbenartRenderer defaultCheckedKeys={value} handlePreSelect={(e) => {
                        if(this.props.onChange){
                            this.props.onChange(e)
                        }
                    }} />
                    : null
                }
            </>
        )
    }

}
