import React from "react";
import { Tabs, Tag, Empty } from 'antd';
import i18n from "./../../../misc/i18n";
import { AuthContextProps } from 'oidc-react';
import { withAuth } from 'oidc-react';
import GlobalSearchPage from "./globalsearchpage";

import {
    isAdmin,
    isBusinessadmin,
    isConsultant,
    isFarmer,
    isExtern,
    isLabor
} from "./../../../misc/getUserRoles";

const queryToStr = require('query-string');

interface GlobalSearchProps extends AuthContextProps {};

interface GlobalSearchState {
    tab1Title: any;
    tab2Title: any;
    tab3Title: any;
    tab4Title: any;
}

class GlobalSearchTabs extends React.Component<GlobalSearchProps, GlobalSearchState> {

    constructor(props: GlobalSearchProps) {
        super(props);
        
        this.state = {
            tab1Title: i18n.t('navitem.companies'),
            tab2Title: i18n.t('navitem.farmer'),
            tab3Title: i18n.t('navitem.ordersimple'),
            tab4Title: i18n.t('navitem.labor')
        };   
    }

    _onLoad1 = (count: number) => {
        this.setState({
            tab1Title: <><Tag>{count}</Tag> {i18n.t('navitem.companies')}</>
        })
    }

    _onLoad2 = (count: number) => {
        this.setState({
            tab2Title: <><Tag>{count}</Tag> {i18n.t('navitem.farmer')}</>
        })
    }
    
    _onLoad3 = (count: number) => {
        this.setState({
            tab3Title: <><Tag>{count}</Tag> {i18n.t('navitem.ordersimple')}</>
        })
    }    

    _onLoad4 = (count: number) => {
        this.setState({
            tab4Title: <><Tag>{count}</Tag> {i18n.t('navitem.labor')}</>
        })
    }    

    render() {
        const qstr = queryToStr.parse(document.location.search);
        let tabsObj = {
            companies: <GlobalSearchPage 
                title={this.state.tab1Title}
                key="companies"
                type="companies"
                searchString={qstr.query}
                onLoad={this._onLoad1}
            />,
            farmer: <GlobalSearchPage 
                title={this.state.tab2Title}
                key="farmer"
                type="farmer"
                searchString={qstr.query}
                onLoad={this._onLoad2}
            />,
            order: <GlobalSearchPage 
                title={this.state.tab3Title}
                key="order"
                type="order"
                searchString={qstr.query}
                onLoad={this._onLoad3}
            />,
            labor: <GlobalSearchPage 
                title={this.state.tab4Title}
                key="labor"
                type="labor"
                searchString={qstr.query}
                onLoad={this._onLoad4}
            />
        }

        let tabs: any = [];
        if(typeof(this.props.userData) !== "undefined" && this.props.userData !== null){
            if(isAdmin(this.props.userData)){
                tabs = [tabsObj.companies, tabsObj.farmer, tabsObj.order, tabsObj.labor]
            }else if(isConsultant(this.props.userData)) {
                tabs = [tabsObj.companies, tabsObj.farmer, tabsObj.order]
            }else if(isFarmer(this.props.userData)) {
                tabs = [tabsObj.companies, tabsObj.order]
            }else if(isBusinessadmin(this.props.userData)) {
                tabs = [tabsObj.companies, tabsObj.farmer, tabsObj.order]
            }else if(isLabor(this.props.userData)) {
                tabs = [tabsObj.labor]
            }else if(isExtern(this.props.userData)) {
                tabs = []
            }      
        }

        return(
            <div className="list-detail-wrap globalsearch-tabs-wrap">
                {(tabs.length === 0)
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                :
                    <Tabs defaultActiveKey="0">
                        {tabs.map((item: any , i: any) => {
                            return (
                                <Tabs.TabPane tab={item.props.title} key={i} forceRender={true}>
                                    {item}
                                </Tabs.TabPane>
                            )
                        })}
                    </Tabs>
                }
            </div>
        )
    }

}
export default withAuth(GlobalSearchTabs);