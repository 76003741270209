import React from 'react';
import DeLayout from './../../layout';
import Header from './header';
import { Space } from 'antd';
import { withAuth } from 'oidc-react';
import { NavLink } from 'react-router-dom';
import i18n from './../../../../misc/i18n';

class Tablet extends React.Component {
    render() {
        return (
            <div className="tablet-wrap">
                <Header {...this.props} />
                <div className="tablet-content-wrap">
                    <DeLayout routes={this.props.routes} collapsed={this.props.collapsed} />
                </div>

                <div className="tablet-footer-wrap">
                    <Space>
                        {this.props.version}
                        &copy; {new Date().getFullYear()} -&nbsp;
                        <a href="https://www.sano.de/" target="_blank" rel="noopener noreferrer">
                            Sano
                        </a>&nbsp;
                        -<NavLink to="/imprint">{i18n.t('navitem.imprint')}</NavLink> - <NavLink to="/privacy">{i18n.t('navitem.privacy')}</NavLink>
                    </Space>
                </div>
            </div>
        );
    }
}

export default withAuth(Tablet);
