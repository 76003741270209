/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import {
    Betrieb,
    BetriebFromJSON,
    BetriebToJSON,
    BetriebsIds,
    BetriebsIdsFromJSON,
    BetriebsIdsToJSON,
    PageBetrieb,
    PageBetriebFromJSON,
    PageBetriebToJSON,
    PageBetriebWithRoles,
    PageBetriebWithRolesFromJSON,
    PageBetriebWithRolesToJSON,
} from '../models';

export interface CreateBetriebRequest {
    betrieb: Betrieb;
}

export interface DeleteBetriebeRequest {
    ids: Array<number>;
}

export interface FindBetriebByQueryRequest {
    q: string;
    page?: number;
    size?: number;
    sort?: Array<string>;
}

export interface FindBetriebeForClientIdsRequest {
    clientIds: Array<string>;
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindBetriebeForRegionsRequest {
    regions: Array<string>;
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindBetriebeWithRolesForLandwirtRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindBetriebsauswahlFilteredRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    sampleType?: Array<string>;
}

export interface FindById2Request {
    betriebsId: number;
}

export interface FindFiltered3Request {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindForLKVRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
}

export interface FindForXRFRequest {
    page?: number;
    size?: number;
    sort?: Array<string>;
    search?: string;
    lands?: Array<string>;
    sampleType?: Array<string>;
    startDate?: Date;
    endDate?: Date;
}

export interface GenerateBulkXmlsRequest {
    lands?: Array<string>;
}

export interface UpdateBetriebRequest {
    betriebsId: number;
    body: string;
}

/**
 * 
 */
export class BetriebControllerApi extends runtime.BaseAPI {

    /**
     */
    async createBetriebRaw(requestParameters: CreateBetriebRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Betrieb>> {
        if (requestParameters.betrieb === null || requestParameters.betrieb === undefined) {
            throw new runtime.RequiredError('betrieb','Required parameter requestParameters.betrieb was null or undefined when calling createBetrieb.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: BetriebToJSON(requestParameters.betrieb),
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BetriebFromJSON(jsonValue));
    }

    /**
     */
    async createBetrieb(requestParameters: CreateBetriebRequest, initOverrides?: RequestInit): Promise<Betrieb> {
        const response = await this.createBetriebRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async deleteBetriebeRaw(requestParameters: DeleteBetriebeRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids','Required parameter requestParameters.ids was null or undefined when calling deleteBetriebe.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/del/{ids}`.replace(`{${"ids"}}`, encodeURIComponent(String(requestParameters.ids))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async deleteBetriebe(requestParameters: DeleteBetriebeRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.deleteBetriebeRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebByQueryRaw(requestParameters: FindBetriebByQueryRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        if (requestParameters.q === null || requestParameters.q === undefined) {
            throw new runtime.RequiredError('q','Required parameter requestParameters.q was null or undefined when calling findBetriebByQuery.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.q !== undefined) {
            queryParameters['q'] = requestParameters.q;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findBetriebByQuery(requestParameters: FindBetriebByQueryRequest, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findBetriebByQueryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebeByUserRegionenRaw(initOverrides?: RequestInit): Promise<runtime.ApiResponse<BetriebsIds>> {
        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/farmsByUserRegions`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BetriebsIdsFromJSON(jsonValue));
    }

    /**
     */
    async findBetriebeByUserRegionen(initOverrides?: RequestInit): Promise<BetriebsIds> {
        const response = await this.findBetriebeByUserRegionenRaw(initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebeForClientIdsRaw(requestParameters: FindBetriebeForClientIdsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        if (requestParameters.clientIds === null || requestParameters.clientIds === undefined) {
            throw new runtime.RequiredError('clientIds','Required parameter requestParameters.clientIds was null or undefined when calling findBetriebeForClientIds.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.clientIds) {
            queryParameters['clientIds'] = requestParameters.clientIds;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/findBetriebeForClientIds/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findBetriebeForClientIds(requestParameters: FindBetriebeForClientIdsRequest, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findBetriebeForClientIdsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebeForRegionsRaw(requestParameters: FindBetriebeForRegionsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        if (requestParameters.regions === null || requestParameters.regions === undefined) {
            throw new runtime.RequiredError('regions','Required parameter requestParameters.regions was null or undefined when calling findBetriebeForRegions.');
        }

        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.regions) {
            queryParameters['regions'] = requestParameters.regions;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/findBetriebeForRegions/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findBetriebeForRegions(requestParameters: FindBetriebeForRegionsRequest, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findBetriebeForRegionsRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebeWithRolesForLandwirtRaw(requestParameters: FindBetriebeWithRolesForLandwirtRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetriebWithRoles>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/betriebeWithRolesForLandwirt/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebWithRolesFromJSON(jsonValue));
    }

    /**
     */
    async findBetriebeWithRolesForLandwirt(requestParameters: FindBetriebeWithRolesForLandwirtRequest = {}, initOverrides?: RequestInit): Promise<PageBetriebWithRoles> {
        const response = await this.findBetriebeWithRolesForLandwirtRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findBetriebsauswahlFilteredRaw(requestParameters: FindBetriebsauswahlFilteredRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.sampleType) {
            queryParameters['sampleType'] = requestParameters.sampleType;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/betriebsauswahl/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findBetriebsauswahlFiltered(requestParameters: FindBetriebsauswahlFilteredRequest = {}, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findBetriebsauswahlFilteredRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findById2Raw(requestParameters: FindById2Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<Betrieb>> {
        if (requestParameters.betriebsId === null || requestParameters.betriebsId === undefined) {
            throw new runtime.RequiredError('betriebsId','Required parameter requestParameters.betriebsId was null or undefined when calling findById2.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/{betriebsId}`.replace(`{${"betriebsId"}}`, encodeURIComponent(String(requestParameters.betriebsId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => BetriebFromJSON(jsonValue));
    }

    /**
     */
    async findById2(requestParameters: FindById2Request, initOverrides?: RequestInit): Promise<Betrieb> {
        const response = await this.findById2Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findFiltered3Raw(requestParameters: FindFiltered3Request, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findFiltered3(requestParameters: FindFiltered3Request = {}, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findFiltered3Raw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findForLKVRaw(requestParameters: FindForLKVRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/betriebeForLKV/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findForLKV(requestParameters: FindForLKVRequest = {}, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findForLKVRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async findForXRFRaw(requestParameters: FindForXRFRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<PageBetrieb>> {
        const queryParameters: any = {};

        if (requestParameters.page !== undefined) {
            queryParameters['page'] = requestParameters.page;
        }

        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }

        if (requestParameters.sort) {
            queryParameters['sort'] = requestParameters.sort;
        }

        if (requestParameters.search !== undefined) {
            queryParameters['search'] = requestParameters.search;
        }

        if (requestParameters.lands) {
            queryParameters['lands'] = requestParameters.lands;
        }

        if (requestParameters.sampleType) {
            queryParameters['sampleType'] = requestParameters.sampleType;
        }

        if (requestParameters.startDate !== undefined) {
            queryParameters['startDate'] = (requestParameters.startDate as any).toISOString();
        }

        if (requestParameters.endDate !== undefined) {
            queryParameters['endDate'] = (requestParameters.endDate as any).toISOString();
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/betriebeForXRF/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => PageBetriebFromJSON(jsonValue));
    }

    /**
     */
    async findForXRF(requestParameters: FindForXRFRequest = {}, initOverrides?: RequestInit): Promise<PageBetrieb> {
        const response = await this.findForXRFRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async generateBulkXmlsRaw(requestParameters: GenerateBulkXmlsRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<void>> {
        const queryParameters: any = {};

        if (requestParameters.lands) {
            queryParameters['lands'] = requestParameters.lands;
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/generateBulkXmls/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     */
    async generateBulkXmls(requestParameters: GenerateBulkXmlsRequest = {}, initOverrides?: RequestInit): Promise<void> {
        await this.generateBulkXmlsRaw(requestParameters, initOverrides);
    }

    /**
     */
    async updateBetriebRaw(requestParameters: UpdateBetriebRequest, initOverrides?: RequestInit): Promise<runtime.ApiResponse<string>> {
        if (requestParameters.betriebsId === null || requestParameters.betriebsId === undefined) {
            throw new runtime.RequiredError('betriebsId','Required parameter requestParameters.betriebsId was null or undefined when calling updateBetrieb.');
        }

        if (requestParameters.body === null || requestParameters.body === undefined) {
            throw new runtime.RequiredError('body','Required parameter requestParameters.body was null or undefined when calling updateBetrieb.');
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.apiKey) {
            headerParameters["Authorization"] = this.configuration.apiKey("Authorization"); // bearer authentication
        }

        if (this.configuration && this.configuration.accessToken) {
            // oauth required
            headerParameters["Authorization"] = await this.configuration.accessToken("bearer2", []);
        }

        const response = await this.request({
            path: `/betrieb/{betriebsId}`.replace(`{${"betriebsId"}}`, encodeURIComponent(String(requestParameters.betriebsId))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: requestParameters.body as any,
        }, initOverrides);

        return new runtime.TextApiResponse(response) as any;
    }

    /**
     */
    async updateBetrieb(requestParameters: UpdateBetriebRequest, initOverrides?: RequestInit): Promise<string> {
        const response = await this.updateBetriebRaw(requestParameters, initOverrides);
        return await response.value();
    }

}
