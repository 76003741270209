import parseJWT from "../permission"
import { User } from 'oidc-client';
import FormFieldsConfiguration from '../../definitions/FormFieldsConfiguration';
import Formitem from '../../definitions/Formitem';


let farmerDetailFilter = (obj:FormFieldsConfiguration, oidcUser : User)=>{
    let token = parseJWT(oidcUser.access_token)
    let role : number  = -1
    token.realm_access.roles.forEach((keycloakRole:string) => {
        keycloakRole = keycloakRole.toLowerCase()
        if ((keycloakRole === "businessadministrator" || keycloakRole === "administrator") && role < 5) {
            role = 5
        }else if((keycloakRole === "berater" || keycloakRole === "consultant") && role < 2){
            role = 2
        }else if((keycloakRole === "farmer" || keycloakRole === "landwirt") && role < 2){
            role = 1
        }
    })
    let newItems : Array<Formitem> = []
    obj.formitems.forEach((element:any) => {
        if(element.label.includes("externeSysteme")){
            if(role >= 5 ){
                newItems.push(element)
            }else if(role >= 2 ){
                element.disabled = true
                newItems.push(element)
            }
        }else{
            newItems.push(element)
        }

    });
    if(role < 5){
        newItems.forEach((element,index) => {
            if(element.type !== "divider" && element.type !== "copytextplain" && element.type !== "boolean" && element.type !== "collapse"){
            element.type = "plaintext"
            }
            if (element.type === "boolean"){
                element.disabled = true;
            }
            if(element.groups){
                element.groups.forEach((grp, grpIndex) => {
                    if(grp.items){
                        grp.items.forEach((grpItem, grpItmIndex) => {
                            if(grpItem.type !== "divider" && grpItem.type !== "copytextplain" && grpItem.type !== "boolean" && !grpItem.type?.includes("plain")){
                                grpItem.type = "plaintext"
                            }
                            if (grpItem.type === "boolean"){
                                grpItem.disabled = true;
                            }
                        })
                    }
                })
            }
        })
    }
    obj.formitems = newItems
    return obj
}

export default farmerDetailFilter;