import React from 'react';
import { withAuth, AuthContextProps } from 'oidc-react';
import ListeLandwirt from '../listen/listeLandwirt/ListeLandwirt';


interface IProps extends AuthContextProps {}

class ProbenstatusChange extends React.Component<IProps> {
    render() {
        return <ListeLandwirt />;
    }
}

export default withAuth(ProbenstatusChange);
