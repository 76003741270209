import React from "react";
import { Table } from "antd";
import RespTable from "./resptable";
import MediaQuery from 'react-responsive';
import colfilter from "./colfilter/colfilter";

export default class ResponsiveTable extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            table: {
                pagination: {}, 
                filters: {}, 
                sorter: {}, 
            },
        }
    }

    _doCol = function(){
        let cols = this.props.columns;
        for(let i=0; i<cols.length; i++){
            cols[i] = (typeof(cols[i].filter) !== "undefined") ? Object.assign(colfilter.render(cols[i]), cols[i]) : cols[i];
            cols[i] = (typeof(cols[i].sort) !== "undefined" && cols[i].sort) ? Object.assign({sorter:(a, b) => {}}, cols[i]) : cols[i];
        }
        return cols        
    }

    _handleChange(pagination, filters, sorter){
        let psorter = {}
        if(typeof(sorter.field) !== "undefined" && typeof(sorter.order) !== "undefined"){
            psorter= {
                field: sorter.field,
                order: sorter.order
            }
        }        
        this.setState({
            table: {
                pagination: pagination, 
                filters: filters, 
                sorter: psorter, 
            },
        },() => {
            if(this.props.onChange){
                this.props.onChange(
                    this.state.table.pagination, 
                    this.state.table.filters, 
                    this.state.table.sorter, 
                )
            }
        })
    }

    render(){
        let props = Object.assign({...this.props}, {columns: this._doCol()})
        return(
            <div className="resp-table-wrap">
                <MediaQuery minWidth={1224}>
                    <Table  {...props} onChange={(pagination, filters, sorter) => { this._handleChange(pagination, filters, sorter) }} />
                </MediaQuery>
                <MediaQuery maxWidth={1224}>
                    <RespTable {...props} onChange={(pagination, filters, sorter) => { this._handleChange(pagination, filters, sorter) }} />
                </MediaQuery>
            </div>
        )
    }
}