import React from "react";
import ListDetail from '../../listdetail';
import federalstate from "./_federalstate";
import helper from './../../listdetail/listtable/_helper';
import LoadMask from '../../../../misc/loadmask';
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { checkChanged } from '../../../../misc/checkChanged';
import userNotification from '../../../../misc/notification';
import { validateData, RequestType } from './../../../../misc/validateData';
import { withAuth } from "oidc-react";


class FederalState extends React.Component{

    constructor(props){
        super(props)
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: federalstate.defaultPageSize,
            detailVisible: false,
        }
    }

    _getApi() {
        return new Api.GeoControllerApi(getRestApiConfig('geo', this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp, params){
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: false,
            // detaildata: {},
            loading: false,
            pageSize: params.size,
            pagination: {
                current: params.page+1,
                total: resp.totalElements,
                pageSize: params.size,
                pageSizeOptions: federalstate.pageSizeOptions
            }
        });
    }

    _purgeData(data){
        delete data["email"];
        delete data["tsChange"]
        delete data["tsInsert"]
        delete data["uiChange"]
        delete data["uiInsert"]
        return(data)
    }    

    async _fetchListData(p) {
        this.setState({
            loading: true
        });
        let params = helper.prepareListParams(p, this.state.pageSize);
        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        }
        let resp = await this._getApi()
            .findAllBundesland(requestBody)
            .catch(() => {
                userNotification('error', 'notification.federalstate.get.error');
            });
        if (resp != null) {
            this._setListState(resp, params);
        }
    }

    async _fetchDetailData(id) {
        let requestBody = {
            bundeslandId: id 
        }
        let resp = await this._getApi()
            .findBundeslandById(requestBody)
            .catch(() => {
                userNotification('error', 'notification.federalstate.get.error');
            });
        this.setState({
            detaildata: resp,
            detailVisible: true
        });
    }

    async _insertData(data) {
        data = this._purgeData(data)
        
        let requestBody = {
            bundesland: data
        }
        let result = await this._getApi()
            .createBundesLand(requestBody)
            .catch((resp) => {
                try {
                    resp.json().then((errorResponse) => {
                    errorResponse.message != null && errorResponse.message === '2410'
                        ? userNotification('error', 'notification.federalstate.insert.errorisobundesland')
                        : userNotification('error', 'notification.federalstate.insert.error')
                    });
                } catch (error) {
                    userNotification('error', 'notification.federalstate.insert.error');
                }
            });
        if (result != null) {
            userNotification('success', 'notification.federalstate.insert.success');
            this._fetchDetailData(result.bundeslandId)
            this._fetchListData({});
        }
     }

    async _updateData(id, data) {
        data = this._purgeData(data)

        if(data.iso === this.state.detaildata.iso){
            delete data["iso"];
        }
        if(data.isoBundesland === this.state.detaildata.isoBundesland){
            delete data["isoBundesland"];
        }
        if(data.name === this.state.detaildata.name){
            delete data["name"];
        }

        let requestBody = {
            bundeslandId: id,
            body: data            
        }
        let result = await this._getApi()
        .updateBundesland(requestBody)
        .catch((resp) => {
            try {
                resp.json().then((errorResponse) => {
                errorResponse.message != null && errorResponse.message === '2410'
                    ? userNotification('error', 'notification.federalstate.update.errorisobundesland')
                    : userNotification('error', 'notification.federalstate.update.error')
                });
            } catch (error) {
                userNotification('error', 'notification.federalstate.update.error');
            }
        });
        if (result != null) {
            userNotification('success', 'notification.federalstate.update.success');
            this._fetchListData({});
        }        
    }

    async _deleteData(id) {
        try {
            let requestBody = {
                bundeslandId: id
            }
            await this._getApi().deleteBundesLand(requestBody);
            userNotification('success', 'notification.federalstate.delete.success');
            this._fetchListData({});
        } catch (e) {
            if (e.status + '' === '406') {
                userNotification('error', 'notification.federalstate.delete.assigned');
            } else {
                userNotification('error', 'notification.federalstate.delete.error');
            }
        }
    }

    _getUserPermissions() {
        let perm = this.props.perm;
        
        perm.token.realm_access.roles.forEach((role) => {
            if (role === 'Administrator') {
                perm.permission = {
                    delete: true,
                    hasToolbar: true,
                    showSaveButton: true,
                    onDelete: (id) => this._deleteData(id),
                    onNew: () => {
                        this.setState({
                            detaildata: {
                                new: true
                            },
                            detailVisible: true
                        });
                    },
                    onSave: (data) => {
                        if (this.state.detaildata.new != null) {
                            if (validateData(data, this.state.detaildata, federalstate, RequestType.post)) {
                                this._insertData(data);
                            }
                        } else {
                            if (validateData(data, this.state.detaildata, federalstate, RequestType.patch)) {
                                checkChanged(
                                    data,
                                    this.state.detaildata,
                                    federalstate,
                                    () => {
                                        this._updateData(this.state.detaildata[federalstate.detailProp], data);
                                    },
                                    () => {}
                                );
                            }
                        }
                    },
                };
            }
        });
        return perm.permission;
    }

    render(){

        let permission = this._getUserPermissions()

        let props = {
            detailProp: federalstate.detailProp,
            columns: federalstate.columns,
            formitems: federalstate.formitems,

            detaildata: this.state.detaildata,
            loading: this.state.loading,
            listdata: this.state.listdata,
            detailVisible: this.state.detailVisible,

            hasToolbar: permission.hasToolbar,
            delete: permission.delete,

            pagination: this.state.pagination,
            renderTitle: federalstate.renderTitle,

            fetchListData: (p) => {
                this._fetchListData(p)
            },

            onSave: (data) => {
                permission.onSave(data);
            },

            onTableChange: (p) => {
                this.setState({
                    pageSize: p.pagination.pageSize
                },() => {
                    this._fetchListData(p);
                });
            },
            onRowSelection: (id) => {
                this._fetchDetailData(id)
            },
            onDelete: (id) => {
                permission.onDelete(id);
            },
            onDetailClose: () => {
                this.setState({
                    detailVisible: false
                });
            },
            onNew: () => {
                permission.onNew()
            }
        }

        return(
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail {...props}/>
            </>
        )
    }
}

export default withAuth(FederalState)