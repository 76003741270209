import React from "react";
import helper from "../../../listdetail/listtable/_helper";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import _listeIT from "./_listeIT";
import ListDetail from "../../../listdetail";
import { withAuth, AuthContextProps } from "oidc-react";
import userNotification from "../../../../../misc/notification";
import { RequestType, validateData } from "../../../../../misc/validateData";
import LoadMask from "../../../../../misc/loadmask";

interface ListeITProps extends AuthContextProps {}

interface ListeITState {
    loading: boolean;
    listdata: any;
    detaildata: any;
    pageSize: number;
    detailVisible: boolean;
    conf: any;
    pagination: any;
    currentEmpfaenger: number;
}

class ListeIT extends React.Component<ListeITProps, ListeITState> {
    constructor(props: ListeITProps) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: 10,
            detailVisible: false,
            conf: _listeIT,
            pagination: {},
            currentEmpfaenger: 0
        };
    }

    _getApi() {
        return new Api.MailControllerApi(getRestApiConfig("mail", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp: any, params: any) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: false,
            detaildata: {},
            loading: false,
            pageSize: params.size,
            pagination: {
                current: params.page + 1,
                total: resp.totalElements,
                pageSize: params.size
            }
        });
    }

    async _fetchListData(p: any) {
        this.setState({
            loading: true
        });
        try {
            let params = helper.prepareListParams(p, this.state.pageSize);
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort,
                role: ["Administrator"]
            };
            let resp = await this._getApi().findRecipient(
                requestBody
            );
            if (resp != null) {
                this._setListState(resp, params);
            }
        } catch (e) {
            userNotification("error", "notification.mail.get.error");
            this.setState({
                loading: false
            });
        }
    }

    async _fetchDetailData(id: number) {
        this.setState({
            currentEmpfaenger: id
        });
        try {
            let requestBody = {
                id: id
            };
            let empfaenger = await this._getApi().findRecipientById(requestBody);
            this.setState({
                detaildata: empfaenger,
                detailVisible: true
            });
        } catch (e) {
            console.error(e);
            userNotification("error", "notification.mail.get.error");
        }
    }

    async _insertData(data: any) {
        try {
            let requestBody = {
                sdnNachrichtEmpfaenger: data
            };
            let result = await this._getApi().addRecipient(requestBody);
            if (result != null) {
                userNotification("success", "notification.mail.insert.success");
                this._fetchListData({});
            }
        } catch (e) {
            userNotification("error", "notification.mail.insert.error");
        }
    }

    async _updateData(id: number, data: any) {
        try {
            let requestBody = {
                id: id,
                sdnNachrichtEmpfaenger: data
            };
            let result = await this._getApi().updateRecipient(requestBody);
            if (result != null) {
                userNotification("success", "notification.mail.update.success");
                this._fetchListData({});
            }
        } catch (e) {
            console.error(e);
            userNotification("error", "notification.mail.update.error");
        }
    }

    async _deleteData(id: number) {
        try {
            let requestBody = {
                id: id
            };
            await this._getApi().deleteRecipient(requestBody);
            userNotification("success", "notification.mail.delete.success");
            this._fetchListData({});
        } catch (e) {
            console.error(e);
            userNotification("error", "notification.mail.delete.error");
        }
    }
    getDefaultValues() {
        let data = {};
        this.state.conf.formitems.forEach((element: any) => {
            if (element.default != null) {
                //@ts-ignore
                data[element.name] = element.default;
            }
        });
        return data;
    }

    render() {
        let formitems = this.state.conf.formitems;
        return (
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail
                    renderTitle={this.state.conf.renderTitle}
                    hasToolbar={true}
                    delete={true}
                    columns={this.state.conf.columns}
                    listdata={this.state.listdata}
                    formitems={formitems}
                    detailProp={this.state.conf.detailProp}
                    detaildata={this.state.detaildata}
                    fetchListData={(p: any) => this._fetchListData(p)}
                    detailVisible={this.state.detailVisible}
                    loading={this.state.loading}
                    pagination={this.state.pagination}
                    onSave={(data: any) => {
                        if (this.state.detaildata.new) {
                            if (validateData(data, this.state.detaildata, this.state.conf, RequestType.post)) {
                                this._insertData(data);
                                this.setState({
                                    detaildata: {
                                        new: false
                                    }
                                });
                            }
                        } else {
                            if (validateData(data, this.state.detaildata, this.state.conf, RequestType.patch)) {
                                this._updateData(this.state.currentEmpfaenger, data);
                            }
                        }
                    }}
                    onDetailClose={() => {
                        this.setState({
                            detailVisible: false
                        });
                    }}
                    onTableChange={(p: any) => {
                        this.setState(
                            {
                                pageSize: p.pagination.pageSize
                            },
                            () => {
                                this._fetchListData(p);
                            }
                        );
                    }}
                    onRowSelection={(id: number) => this._fetchDetailData(id)}
                    onDelete={(id: number) => {
                        this._deleteData(id);
                    }}
                    onNew={() => {
                        //let data :any = this.getDefaultValues()
                        // data["new"] = true
                        this.setState({
                            detaildata: {
                                new: true
                            },
                            detailVisible: true
                        });
                    }}
                />
            </>
        );
    }
}
export default withAuth(ListeIT);
