import React from "react";
import companies from "./_companies";
import ListTable from "../../listdetail/listtable";
import helper from "../../listdetail/listtable/_helper";
import { Modal, Button } from "antd";
import { getRestApiConfig } from "../../../../../_conf";
import { withAuth } from "oidc-react";
import * as Api from "../../../../../api/rest";
import i18n from "../../../../misc/i18n";
import filter_companies from "./filter_companies";
import userNotification from "../../../../misc/notification";
import areArraysEqual from "../../../../misc/arraysEqual";
class CompanyIdSelectForm extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            conf: filter_companies(companies, this.props.userData),
            data: [],
            loading: false,
            listdata: {},
            pagination: {},
            pageSize: 10,
            selection: {},
            preSelection: {},
            selected: false,
            lookUpVisible: false,
            selectedRows: {},
            selectedRowKeys: null,
            preSelectedRowKeys: null,
            viewStr: "",
            alli18n: "order.probenart.all"
        };
        this.rowSelection = {
            type: "radio",
            onChange: (selectedRowKeys, selectedRows) => {
                this._setPreSelection(selectedRowKeys, selectedRows);
            }
        };
    }

    _getApi() {
        return new Api.BetriebControllerApi(getRestApiConfig("company", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }
    componentDidUpdate(prevProps) {
        if (this.props.probenart.length !== prevProps.probenart.length) {
            this.setState({ selectedRowKeys: null, selectedRows: {}, preSelectedRowKeys: null, viewStr: "", currentCompany: undefined }, () => {
                this.props.onChange({}, {});
                this._fetchListData({});
            });
        } else {
            if (this.props.probenart.length > 0) {
                if (!areArraysEqual(this.props.probenart, prevProps.probenart)) {
                    this.setState({ selectedRowKeys: null, selectedRows: {}, preSelectedRowKeys: null, viewStr: "", currentCompany: undefined }, () => {
                        this.props.onChange({}, {});
                        this._fetchListData({});
                    });
                }
            }
        }
    }

    async _fetchListData(p) {
        this.setState({
            loading: true
        });

        if(!p.filter){
            p.filter = {
                anonymisiertJn: []
            };
                
        }

        // anonymisierte Betrieb aus der Liste immer raus filtern
        p.filter.anonymisiertJn = ["false"];
        
        try {
            let params = helper.prepareListParams(p, this.state.pageSize);
            //(endDate?: Date, lands?: Array<string>, offset?: number, page?: any, paged?: boolean, pageNumber?: number, pageSize?: number, sampleType?: Array<string>, search?: string, size?: any, sort?: any, sortSorted?: boolean, sortUnsorted?: boolean, startDate?: Date, unpaged?: boolean, options: any = {})
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort,
                sampleType: this.props.probenart
            };
            let resp = await this._getApi().findForXRF(requestBody);
            this.setState(
                {
                    listdata: helper.prepareListData(resp.content, "euNummer"),
                    detailVisible: false,
                    detaildata: {},
                    loading: false,
                    pageSize: params.size,
                    pagination: {
                        total: resp.totalElements,
                        pageSize: params.size
                    }
                },
                async () => {
                    if(localStorage.getItem("CompanyId") != null){
                        const exists = await this._exists4SampleType(localStorage.getItem("CompanyId"));

                        if(!exists){
                            localStorage.removeItem("CompanyId");
                        }
                    }

                    if (localStorage.getItem("CompanyId") === null && resp.content.length > 0) {
                        this._fetchDetailData(resp.content[0].betriebsId);
                        this.setState(
                            {
                                selectedRows: resp.content[0]
                            },
                            () => {
                                this._setSelection();
                            }
                        );
                    } else {
                        this._fetchDetailData(localStorage.getItem("CompanyId"));
                    }
                }
            );
        } catch (e) {
            userNotification("error", "notification.company.get.error");
            this.setState({
                loading: false
            });
        }
    }

    _setPreSelection(selectedRowKeys, selectedRows) {
        this.setState({
            preSelectedRowKeys: selectedRowKeys,
            selectedRows: selectedRows[0]
        });
    }

    async _exists4SampleType(id) {
        try {
            let requestBody = {
                search: "betriebsId==" + id,
                sampleType: this.props.probenart !== undefined ? this.props.probenart : []
            };
            let erg = await this._getApi().findForXRF(requestBody);
            if (erg.content.length > 0) {
                return true;
            }
        } catch (e) {}
        return false;
    }

    async _fetchDetailData(id) {
        try {
            let requestBody = {
                search: "betriebsId==" + id,
                sampleType: this.props.probenart
            };
            let erg = await this._getApi().findForXRF(requestBody);
            if (erg.content.length > 0) {
                this.setState({
                    currentCompany: id
                });
                let requestBody = {
                    betriebsId: id
                };
                let betrieb = await this._getApi().findById2(requestBody);
                if (betrieb.euNummer == null) {
                    this.setState({
                        viewStr: " " + betrieb.betriebsId + " - " + betrieb.plz + " - " + betrieb.land
                    });
                } else {
                    this.setState({
                        viewStr: " " + betrieb.landwirt.nachname + " - " + betrieb.euNummer + " - " + betrieb.plz + " - " + betrieb.land
                    });
                }
                this.props.onChange({ companyId: id, land: betrieb.land }, {});
            }
        } catch (e) {}
    }

    async _setSelection() {
        await this._fetchDetailData(this.state.selectedRows.betriebsId);
        if (this.state.selectedRows.betriebsId != null) {
            localStorage.setItem("CompanyId", this.state.selectedRows.betriebsId);
            this.props.onChange({ companyId: this.state.selectedRows.betriebsId, land: this.state.selectedRows.land }, {});
        }

        this.setState(
            {
                lookUpVisible: false,
                selectedRowKeys: this.state.preSelectedRowKeys
            },
            () => {
                if (this.props.onChange) {
                    this.props.onChange({ companyId: this.state.selectedRows.betriebsId, land: this.state.selectedRows.land }, {});
                }
            }
        );
    }

    render() {
        let displayStr = "";
        if (this.state.viewStr !== "") {
            displayStr += " " + this.state.viewStr;
        } else {
            displayStr = " " + i18n.t("report.label.selectCompany");
        }

        return (
            <>
                <div className="company-select-wrap">
                    <div className="company-select-button">
                        <Button
                            disabled={this.props.disabled}
                            onClick={() => {
                                this.setState({
                                    lookUpVisible: !this.state.lookUpVisible,
                                    rowSelection: {
                                        selectedRowKeys: this.state.selectedRowKeys
                                    }
                                });
                            }}
                        >
                            <i className="fal fa-search"></i>
                        </Button>
                    </div>
                    <div className="company-select-value" style={this.props.style}>
                        {displayStr}
                    </div>
                </div>
                <Modal
                    wrapClassName="company-select-wrap"
                    title="Betriebe"
                    visible={this.state.lookUpVisible}
                    width="80%"
                    zIndex={10000000}
                    onOk={() => {
                        this._setSelection();
                    }}
                    onCancel={() => {
                        this.setState({
                            lookUpVisible: !this.state.lookUpVisible,
                            selected: false
                        });
                    }}
                >
                    <ListTable
                        cols={companies.columns}
                        data={this.state.listdata.length ? this.state.listdata : []}
                        delete={false}
                        pagination={this.state.pagination}
                        rowSelection={this.rowSelection}
                        renderTitle={companies.renderTitle}
                        onTableChange={(p) => {
                            this.setState(
                                {
                                    pageSize: p.pagination.pageSize
                                },
                                () => {
                                    this._fetchListData(p);
                                }
                            );
                        }}
                    />
                </Modal>
            </>
        );
    }
}

export default withAuth(CompanyIdSelectForm);
