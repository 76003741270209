import React from 'react';
import { Input } from 'antd';
import i18n from "../../../../../misc/i18n"
interface IProps{
    value: string
}

export default class PlaintextBundesland extends  React.Component<IProps>{

    render(){
        return (
            <Input value={this.props.value != null ? i18n.t("order.probenart."+this.props.value)+"" : ""} /> 

        )
            
    }

}
