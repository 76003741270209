import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
interface IProps {
    value: any;
    onChange: Function;
    data: any;  
}
interface IState{
    value :any,
    status:string
}

export default class LabTimepickerSano extends React.Component<IProps,IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            value: this.props.value,
            status: this.props.data.status 
        };
        this.updateStateEvent = this.updateStateEvent.bind(this)
    }
    updateStateEvent(e:any) {
        this.setState(
            {
                status: e.detail
            }
        )
    }
    componentDidMount() {
        window.addEventListener("labbook_changeStatus", this.updateStateEvent, true);
        window.addEventListener("labbook_statusChangedDropdown", this.updateStateEvent, true);
    }
    componentWillUnmount() {
        window.removeEventListener("labbook_changeStatus", this.updateStateEvent, true);
        window.removeEventListener("labbook_statusChangedDropdown", this.updateStateEvent, true);
    }

    onChange = (value: any) => {
        if (value != null) {
            
            if (this.props.data.tsLaborEingang == null && this.state.value == null && this.state.status !== "ERGEBNIS" && this.state.status !== "DEAKTIVIERT") {
                window.dispatchEvent(new CustomEvent("labbook_changeStatus",{detail:"EINGANG_SANO"}))
                this.setState({
                    status: "EINGANG_SANO"
                })
            }
            this.props.onChange(value.format());
            this.setState({
                value : value.format()
            })
        }else{
            if(this.props.data.status === "EINGANG_SANO" || this.state.status === "EINGANG_LABOR" || this.state.status === "PROBENAHME"){
                window.dispatchEvent(new CustomEvent("labbook_changeStatus",{detail:"PROBENAHME"}))
                this.setState({
                    status: "PROBENAHME"
                })
            }
            window.dispatchEvent(new CustomEvent("labbook_changetsLabor",{detail:null}))
            this.props.onChange(value)
            this.setState({
                value: null
            })
            
        }
    };

    render() {
        // @ts-ignore
        return <DatePicker showTime value={this.state.value != null ? moment(this.state.value) : null} className="de-rangepicker-wrap" onChange={this.onChange} />;
    }
}
