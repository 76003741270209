import React from 'react';
import {  Spin } from 'antd';
import * as Api from '../../../../../api/rest';
import { getRestApiConfig } from '../../../../../_conf';
import { AuthContextProps, withAuth } from "oidc-react";
import TagSelect from '../../tagselect/tagselect';
import i18n from '../../../../misc/i18n';
import areArraysEqual from "../../../../misc/arraysEqual"
import userNotification from '../../../../misc/notification';
interface IState {
    selectdata: Array<any>;
    loading: boolean;
    state: string[];
    land: string[];
    value: string[];
}

interface IProps extends AuthContextProps {
    onChange: ((value: any, option: any) => void) | undefined;
    value: any;
    state: string[];
    land: string[];
    disabled: boolean | undefined;
    defaultValue: string[];
    startDate: any;
    endDate:any;
}

class RegionSelectForm extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            loading: false,
            value: this.props.defaultValue ? this.props.defaultValue : [],
            land: [],
            state: []
        };
    }

    componentDidUpdate(prevProps: IProps) {
        if (this.props.land.length !== this.state.land.length || this.props.state.length !== this.state.state.length) {
            this.setState(
                {
                    land: this.props.land,
                    state: this.props.state
                },
                () => {
                    let newVal = this.props.defaultValue ? this.props.defaultValue : []
                    if(this.props.land.length === 0) {
                        newVal = []
                    }
                    if (this.props.onChange !== undefined) {
                        this.props.onChange(newVal, {});
                    }
                    this.setState({
                        value: newVal
                    });
                    if(this.props.land.length > 0 && this.props.state.length > 0){
                        this._getSelectData();
                    }
                }
            );
        }else{
            if(this.props.land.length > 0 && this.props.state.length > 0){
                if(!areArraysEqual(this.props.land,this.state.land) || !areArraysEqual(this.props.state,this.state.state)){
                    this.setState(
                        {
                            land: this.props.land,
                            state: this.props.state
                        },
                        () => {
                            if (this.props.onChange !== undefined) {
                                this.props.onChange(this.props.defaultValue ? this.props.defaultValue : [], {});
                            }
                            this.setState({
                                value: this.props.defaultValue ? this.props.defaultValue : []
                            });
                            this._getSelectData();
                        }
                    );
                }
            }
            
        }
        //Wenn das Datum geändert wurde neue Daten laden
        if(this.props.startDate.toISOString() !== prevProps.startDate.toISOString() || this.props.endDate.toISOString() !== prevProps.endDate.toISOString()){
            this._getSelectData();
        }
    }

    async _getSelectData() {
        this.setState({
            loading: true
        });
        if (this.state.state.length === 0) {
            return;
        }
        let api = new Api.RegionControllerApi(getRestApiConfig('geo', this.props.userData));
        try {
            if (this.state.state.includes(i18n.t('order.probenart.all'))) {
                let query = undefined;
                if (this.state.land.length > 0) {
                    let landquery = undefined;
                    if (!this.state.land.includes(i18n.t('order.probenart.all'))) {
                        landquery = 'land=in=(' + this.state.land + ')';
                    }
                    query = landquery;
                }
                let requestBody = {
                    size: 30,
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    search: query
                }
                let resp = await api.findForBundeslaenderIsos(requestBody);
                if (resp !== undefined) {
                    this.setState({
                        selectdata: resp !== undefined ? resp : [],
                        loading: false
                    });
                }
            } else {
                let requestBody = {
                    size: 30,
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    iso: this.state.state
                }
                let resp = await api.findForBundeslaenderIsos(requestBody);
                if (resp !== undefined) {
                    this.setState({
                        selectdata: resp !== undefined ? resp : [],
                        loading: false
                    });
                }
            }
        } catch (e) {
            userNotification("error","notification.region.get.error")
            this.setState({
                loading: false
            });
        }
    }
    onSearch = async (value: string) => {
        this.setState({
            loading: true
        });
        if (value === '') {
            await this._getSelectData();
            return;
        }

        let api = new Api.RegionControllerApi(getRestApiConfig('geo', this.props.userData));
        try {
            if (this.state.state.includes(i18n.t('order.probenart.all'))) {
                let query = "regionName=='" + value + "*'";
                if (this.state.land.length > 0) {
                    let landquery = '';
                    if (!this.state.land.includes(i18n.t('order.probenart.all'))) {
                        landquery = ';land=in=(' + this.state.land + ')';
                    }
                    query = query + landquery;
                } else {
                    return;
                }
                let requestBody = {
                    size: 30,
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    search: query
                }
                let resp = await api.findForBundeslaenderIsos(requestBody);
                if (resp !== undefined) {
                    this.setState({
                        selectdata: resp !== undefined ? resp : [],
                        loading: false
                    });
                }
            } else {
                let query = "regionName=='" + value + "*'";
                let requestBody = {
                    size: 30,
                    startDate: this.props.startDate,
                    endDate: this.props.endDate,
                    search: query,
                    iso: this.state.state
                }
                let resp = await api.findForBundeslaenderIsos(requestBody);
                if (resp !== undefined) {
                    this.setState({
                        selectdata: resp !== undefined ? resp : [],
                        loading: false
                    });
                }
            }
        } catch (e) {
            userNotification("error","notification.region.get.error")
            this.setState({
                loading: false
            });
        }
    }

    onChange = (value: string[], option: any) => {
        if (this.props.onChange != null) {
            this.props.onChange(value, option);
        }

        this.setState({
            loading: false,
            value: value
        });
    };
    textRenderer = (item: Api.Region)=>{
        return item.regionName ? item.regionName : '';
    }

    render() {
        const { loading } = this.state;
        return (
            <TagSelect
                disabled={this.props.disabled}
                keyAttributeName="regionName"
                valueAttributeName="regionName"
                onChange={this.onChange}
                notFoundContent={loading ? <Spin size="small" /> : null}
                selectdata={this.state.selectdata}
                textrender={this.textRenderer}
                style={{ width: '100%' }}
                selectAll={true}
                selectAlli18n='order.probenart.all'
                value={this.state.value}
                onSearch={this.onSearch}
                showSearch={true}
            />
        );
    }
}

export default withAuth(RegionSelectForm);
