import React from 'react';
import { DatePicker } from 'antd';
import moment from 'moment';
interface IProps {
    value: any;
    onChange: Function;
    data: any;
}
interface IState{
    value : any,
    status : string
}

export default class LabTimepickerLabor extends React.Component<IProps,IState> {
    constructor(props: IProps) {

        super(props);
        this.state = {
            value: this.props.value,
            status : this.props.data.status
        };
        this.updateStateEvent = this.updateStateEvent.bind(this)
        this.updateValueEvent = this.updateValueEvent.bind(this)
    }
    updateStateEvent(e:any) {
        this.setState(
            {
                status: e.detail
            }
        )
    }
    updateValueEvent(e:any){
        this.props.onChange(e.detail)
        this.setState({
            value: e.detail
        })
    }
    componentDidMount() {
        window.addEventListener("labbook_changeStatus", this.updateStateEvent, true);
        window.addEventListener("labbook_changetsLabor", this.updateValueEvent, true);
        window.addEventListener("labbook_statusChangedDropdown", this.updateStateEvent, true);
    }
    componentWillUnmount() {
        window.removeEventListener("labbook_changeStatus", this.updateStateEvent, true);
        window.removeEventListener("labbook_changetsLabor", this.updateValueEvent, true);
        window.removeEventListener("labbook_statusChangedDropdown", this.updateStateEvent, true);
    }

    onChange = (value: any) => {
        if (value != null) {
            if (this.state.value == null && this.state.status === "EINGANG_SANO") {
                window.dispatchEvent(new CustomEvent("labbook_changeStatus",{detail:"EINGANG_LABOR"}))
            }
            this.setState({
                value: value.format()
            },()=>{
                this.props.onChange(value);
            })
        }else{
            if(this.state.status === "EINGANG_LABOR"){
                window.dispatchEvent(new CustomEvent("labbook_changeStatus",{detail:"EINGANG_SANO"}))
            }
            
            this.setState({
                value: value
            },()=>{
                this.props.onChange(value)
            })
        }
    };

    render() {
        // @ts-ignore
        return <DatePicker showTime value={this.state.value != null ? moment(this.state.value) : null} className="de-rangepicker-wrap" onChange={this.onChange} />;
    }
}
