import React from "react";
import ListDetail from "../../listdetail";
import country from "./_country";
import helper from "./../../listdetail/listtable/_helper";
import LoadMask from "../../../../misc/loadmask";
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { checkChanged } from "../../../../misc/checkChanged";
import userNotification from "../../../../misc/notification";
import { validateData, RequestType } from "./../../../../misc/validateData";
import { withAuth } from "oidc-react";

class Country extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            listdata: {},
            detaildata: {},
            pageSize: country.defaultPageSize,
            detailVisible: false,
            pagination: {}
        };
    }

    _getApi() {
        return new Api.GeoControllerApi(getRestApiConfig("geo", this.props.userData));
    }

    async componentDidMount() {
        await this._fetchListData({});
    }

    _setListState(resp, params) {
        this.setState({
            listdata: helper.prepareListData(resp.content),
            // detailVisible: false,
            // detaildata: {},
            loading: false,
            pageSize: params.size,
            pagination: {
                current: params.page + 1,
                total: resp.totalElements,
                pageSize: params.size,
                pageSizeOptions: country.pageSizeOptions
            }
        });
    }

    _purgeData(data) {
        delete data["email"];
        delete data["tsChange"];
        delete data["tsInsert"];
        delete data["uiChange"];
        delete data["uiInsert"];
        return data;
    }

    async _fetchListData(p) {
        this.setState({
            loading: true
        });

        let params = helper.prepareListParams(p, this.state.pageSize);

        let requestBody = {
            size: params.size,
            search: params.search,
            page: params.page,
            sort: params.sort
        };
        let resp = await this._getApi()
            .findAllLand(requestBody)
            .catch(() => {
                userNotification("error", "notification.country.get.error");
            });
        if (resp != null) {
            this._setListState(resp, params);
        }
    }

    async _fetchDetailData(id) {
        let requestBody = {
            iso: id
        };
        let resp = await this._getApi()
            .findLandById(requestBody)
            .catch(() => {
                userNotification("error", "notification.country.get.error");
            });
        this.setState({
            detaildata: resp,
            detailVisible: true
        });
    }

    async _insertData(data) {
        data = this._purgeData(data);

        data["gesperrtJn"] = !("gesperrtJn" in data) ? false : data["gesperrtJn"];
        let requestBody = {
            land: data
        };
        let result = await this._getApi()
            .createLand(requestBody)
            .catch((resp) => {
                try {
                    resp.json().then((errorResponse) => userNotification("error", "notification.country.insert.error"));
                } catch (error) {
                    userNotification("error", "notification.country.insert.error");
                }
            });
        if (result != null) {
            userNotification('success', 'notification.country.insert.success');
            this._fetchDetailData(result.iso)
            this._fetchListData({});
        }
    }

    async _updateData(id, data) {
        data = this._purgeData(data);

        data["gesperrtJn"] = !("gesperrtJn" in data) ? false : data["gesperrtJn"];
        delete data["iso"];

        let requestBody = { iso: id, body: data };
        let result = await this._getApi()
            .updateLand(requestBody)
            .catch((resp) => {
                try {
                    resp.json().then((errorResponse) => {
                        errorResponse.message != null && errorResponse.message === "2411"
                            ? userNotification("error", "notification.country.update.erroriso")
                            : userNotification("error", "notification.country.update.error");
                    });
                } catch (error) {
                    userNotification("error", "notification.country.update.error");
                }
            });
        if (result != null) {
            userNotification("success", "notification.country.update.success");
            this._fetchListData({});
        }
    }

    async _deleteData(id) {
        try {
            let requestBody = {
                iso: id
            };
            await this._getApi().deleteLand(requestBody);
            userNotification("success", "notification.country.delete.success");
            this._fetchListData({});
        } catch (e) {
            if (e.status + "" === "406") {
                userNotification("error", "notification.country.delete.assigned");
            } else {
                userNotification("error", "notification.country.delete.error");
            }
        }
    }

    _getUserPermissions() {
        let perm = this.props.perm;

        perm.token.realm_access.roles.forEach((role) => {
            if (role === "Administrator") {
                perm.permission = {
                    delete: true,
                    hasToolbar: true,
                    showSaveButton: true,
                    onDelete: (id) => this._deleteData(id),
                    onNew: () => {
                        this.setState({
                            detaildata: {
                                new: true
                            },
                            detailVisible: true
                        });
                    },
                    onSave: (data) => {
                        if (this.state.detaildata.new != null) {
                            if (validateData(data, this.state.detaildata, country, RequestType.post)) {
                                this._insertData(data);
                            }
                        } else {
                            if (validateData(data, this.state.detaildata, country, RequestType.patch)) {
                                checkChanged(
                                    data,
                                    this.state.detaildata,
                                    country,
                                    () => {
                                        this._updateData(this.state.detaildata[country.detailProp], data);
                                    },
                                    () => {}
                                );
                            }
                        }
                    }
                };
            }
        });
        return perm.permission;
    }

    render() {
        let permission = this._getUserPermissions();
        let props = {
            detailProp: country.detailProp,
            columns: country.columns,
            formitems: country.formitems,

            detaildata: this.state.detaildata,
            loading: this.state.loading,
            listdata: this.state.listdata,
            detailVisible: this.state.detailVisible,

            hasToolbar: permission.hasToolbar,
            delete: permission.delete,

            pagination: this.state.pagination,
            renderTitle: country.renderTitle,

            fetchListData: (p) => {
                this._fetchListData(p);
            },

            onSave: (data) => {
                permission.onSave(data);
            },

            onTableChange: (p) => {
                this.setState(
                    {
                        pagination: p.pagination,
                        pageSize: p.pagination.pageSize
                    },
                    () => {
                        this._fetchListData(p);
                    }
                );
            },
            onRowSelection: (id) => {
                this._fetchDetailData(id);
            },
            onDelete: (id) => {
                permission.onDelete(id);
            },
            onDetailClose: () => {
                this.setState({
                    detailVisible: false
                });
            },
            onNew: () => {
                permission.onNew();
            }
        };

        return (
            <>
                {this.state.loading ? <LoadMask /> : null}
                <ListDetail {...props} />
            </>
        );
    }
}

export default withAuth(Country);
