import React from "react";
import ListTable from "../../listdetail/listtable";
import _lkvexport from "./_lkvexport"
import { getImportApiConfig } from "../../../../../_conf";
import * as Api from "../../../../../api/importstatus";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from '../../../../misc/notification';
import helper from '../../listdetail/listtable/_helper';
interface IProps extends AuthContextProps{
    currentCompany : number
}

interface IState{
    listdata: Array<any>,
    loading : boolean
    pageSize: number;
    pagination: any;
}

let klasse = class LKVExport extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props)

        this.state = {
            listdata: [],
            loading: false,
            pageSize: 10,
            pagination: {},
        }
    }
    _getApi() {

        return (new Api.LkvControllerApi(getImportApiConfig("lkv", this.props.userData)));
    }
    componentDidMount(){
        this._fetchListData({})
    }

    async _fetchListData(p:any) {
        this.setState({
            loading: true
        })
        try {
            let params = helper.prepareListParams(p, this.state.pageSize);
            let requestBody = {
                size: params.size,
                search: params.search,
                page: params.page,
                sort: params.sort,
                betriebsId: this.props.currentCompany
            }
            let resp = await this._getApi().findFilteredLkvDocuments(requestBody)
            this.setState({
                listdata: resp.content != null? resp.content : [],
                loading: false,
            })
        } catch (e) {
            userNotification("error", "notification.lkvdata.error")
            this.setState({
                loading: false,
            })
        }
    }

    saveByteArray(reportName:string, byte:any) {
        let blob = new Blob([byte], { type: 'text/plain' });
        
        let link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.click();
    }
    base64ToArrayBuffer(base64:string) {
        let binaryString = window.atob(base64);
        let binaryLen = binaryString.length;
        let bytes = new Uint8Array(binaryLen);
        for (let i = 0; i < binaryLen; i++) {
            let ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }

    onDownload = async (row:any)=>{
        this.setState({
            loading: true
        })
        try {
            let requestBody = {
                importJobId: row.importJobId
            }
            let resp = await this._getApi().findLkvDokumentInhalt(requestBody)
            if (resp  != null) {

                if(resp.dokumentInhalt != null){
                    let bytes = this.base64ToArrayBuffer(resp.dokumentInhalt);
                    this.saveByteArray(row.dokumentName, bytes);
                }
                
                
            }
            this.setState({
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false
            })
            console.error(e)
            userNotification('error', 'notification.order.content.get.error');

        }
    }

    render(){
        return(
            <>
            <ListTable
                renderTitle={_lkvexport.renderTitle}
                hasToolbar={false}
                delete={false}
                download={true}
                onDownload={this.onDownload}
                cols={_lkvexport.columns}
                data={this.state.listdata}
                fetchListData={(p: any) => this._fetchListData(p)}
                detailVisible={false}
                loading={this.state.loading}
                pagination={this.state.pagination}
                formitems={[]}
                onTableChange={(p: any) => {
                    this.setState(
                        {
                            pageSize: p.pagination.pageSize
                        },
                        () => {
                            this._fetchListData(p);
                        }
                    );
                }}
            />
            </>
            
        )
    }
}

export default withAuth(klasse)