import React from 'react';
import {XRFReportRegionalTemplate} from "./index"

class XRFReportMais extends React.Component {
    render(){
        return( 
            <XRFReportRegionalTemplate probenart="Maize_silage"/>
        )
    }
}
class XRFReportGras extends React.Component {
    render(){
        return( 
            <XRFReportRegionalTemplate probenart="Gras_silage"/>
        )
    }
}
export {
    XRFReportMais,
    XRFReportGras
} 