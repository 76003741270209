import {Filter} from '../../../../definitions/FilterEnum'
import {Renderer} from '../../../../definitions/RendererEnum'

const country = {
    detailProp: 'iso',
    defaultPageSize: 10,
    // pageSizeOptions: [15,30,45,60,75,90,100],
    columns:[{
        title: 'isoLand',
        dataIndex: 'iso',
        // filter: Filter.TEXT,
        filter: Filter.COUNTRY,
        sort: true
    },{
        title: 'name',
        dataIndex: 'name',
        filter: Filter.TEXT,
        sort: true
    },{
        title: 'locked',
        dataIndex: 'gesperrtJn',
        filter: Filter.BOOLEAN,
		filter_list: [{label: "TRUE", value: true}, {label: "FALSE", value: false}],
        renderer: Renderer.BOOLEAN,
        sort: true,
        align: "center"
    },{
        title: 'tsChange',
        dataIndex: 'tsChange',
        filter: Filter.DATERANGE,
        sort: true,
        renderer: Renderer.RENDERDATETIME,
    },{
        title: 'tsInsert',
        dataIndex: 'tsInsert',
        filter: Filter.DATERANGE,
        sort: true,
        renderer: Renderer.RENDERDATETIME,

    }],
    formitems:[{
        label: 'isoLand',
        name: 'iso',
        type: 'text',
        disabled: false,
        required: true
    },{
        label: 'name',
        name: 'name',
        type: 'text',
        disabled: false,
        required: true
    },{
        label: 'locked',
        name: 'gesperrtJn',
        type: 'boolean',
        disabled: false,
        required: false
    }],
    renderTitle: (rec) => {
        let title = rec.name !== null ? rec.name + ' (' + rec.iso + ')' : '';
        return title;
    },

// -------------------------

    listresp: {},
    detaildata: {}

}

export default country;