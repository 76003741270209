const reports = {
    "report1": {
        "reportTitle": "report1",
        "report": "/Grenzwerte CornSilage",
        "itemsInFirstRow": 2,
        "formItems": [{
            "name": "CompanyId",
            "type": "companyselect",
            "uriName": "CompanyId",
            "disabled": false,
            "required": true,
            "probetype" : ["Maize_silage"],
            "style": {
                "width": "415px",
            }
        },{
            "name": "Perimeter",
            "type": "slider",
            "disabled": false,
            "uriName": "Perimeter",
            "default": 10,
            "min": 10,
            "max": 100,
            "step": 10,
            "style": {
                "width": "250px",
            }
        },{
            "name": "Type",
            "type": "select",
            "disabled": false,
            "uriName": "Type",
            "multiple": false,
            "rawdata": ["Maize_silage"],
            "data": ["Maize_silage"],
            "default": ["Maize_silage"],
            "style": {
                "width": "150px",
                "margin": "0 20px 0 0"
            }
        },{
            "name": "date",
            "type": "daterange",
            "disabled": false,
            "uriName": ["StartDate","EndDate"],
            "style": {
                "width": "250px",
                "margin": "0 -10px 0 0"
            }
        },
        {
            "name": "SampleNumber",
            "type": "samplenumber",
            "disabled": false,
            "uriName": "SampleNumber",
            "multiple": true,
            "data": [],
            "style": {
                "width": "250px",
            }
        }]
    },
    "report2": {
        "reportTitle": "report2",
        "report": "/Grenzwerte GrassSilage",
        "itemsInFirstRow": 2,
        "formItems": [{
            "name": "CompanyId",
            "type": "companyselect",
            "uriName": "CompanyId",
            "disabled": false,
            "required": true,
            "probetype" : ["Gras_silage"],
            "style": {
                "width": "415px",
            }
        },{
            "name": "Perimeter",
            "type": "slider",
            "disabled": false,
            "uriName": "Perimeter",
            "default": 10,
            "min": 10,
            "max": 100,
            "step": 10,
            "style": {
                "width": "250px",
            }
        },{
            "name": "Type",
            "type": "select",
            "disabled": false,
            "uriName": "Type",
            "multiple": false,
            "rawdata": ["Gras_silage"],
            "data": ["Gras_silage"],
            "default": ["Gras_silage"],
            "style": {
                "width": "150px",
                "margin": "0 20px 0 0"
            }
        },{
            "name": "date",
            "type": "daterange",
            "disabled": false,
            "uriName": ["StartDate","EndDate"],
            "style": {
                "width": "250px",
            }
        },
        {
            "name": "SampleNumber",
            "type": "samplenumber",
            "disabled": false,
            "uriName": "SampleNumber",
            "multiple": true,
            "data": [],
            "style": {
                "width": "250px",
            }
        }]
    },
    "report3": {
        "reportTitle": "report3",
        "report": "/Interner Betriebsvergleich",
        "itemsInFirstRow": 1,
        "formItems": [{
            "name": "CompanyId",
            "type": "companyselect",
            "uriName": "CompanyId",            
            "probetype" : ["Gras_silage", "Maize_silage"],
            "disabled": false,
            "required": true,
            "style": {
                "width": "410px",
            }
        },{
            "name": "Type",
            "type": "select",
            "disabled": false,
            "uriName": "Type",
            "multiple": false,
            "rawdata": ["Gras_silage", "Maize_silage"],
            "data": ["Gras_silage", "Maize_silage"],
            "default": ["Gras_silage"],
            "style": {
                "width": "150px",
                "margin": "0 20px 0 0"
            }
        },{
            "name": "date",
            "type": "daterange",
            "disabled": false,
            "uriName": ["StartDate","EndDate"],
            "style": {
                "width": "250px",
            }
        }]
    },
    "report4": {
        "reportTitle": "report4",
        "report": "/Regionaler Betriebsvergleich GRASS",
        "itemsInFirstRow": 2,
        "formItems": [{
            "name": "CompanyId",
            "type": "companyselect",
            "uriName": "CompanyId",
            "probetype": ["Gras_silage"],
            "disabled": false,
            "required": true,
            "style": {
                "width": "410px",
            }
        },{
            "name": "Perimeter",
            "type": "slider",
            "disabled": false,
            "uriName": "Perimeter",
            "default": 10,
            "min": 10,
            "max": 100,
            "step": 10,
            "style": {
                "width": "250px",
            }

        },{
            "name": "Type",
            "type": "select",
            "disabled": false,
            "uriName": "Type",
            "multiple": false,
            "rawdata": ["Gras_silage"],
            "data": ["Gras_silage"],
            "default": ["Gras_silage"],
            "style": {
                "width": "150px",
                "margin": "0 20px 0 0"
            }
        },{
            "name": "date",
            "type": "daterange",
            "disabled": false,
            "uriName": ["StartDate","EndDate"],
            "style": {
                "width": "250px",
            }
        }]
    },
    "report5": {
        "reportTitle": "report5",
        "report": "/Regionaler Betriebsvergleich MAIS",
        "itemsInFirstRow": 2,
        "formItems": [{
            "name": "CompanyId",
            "type": "companyselect",
            "uriName": "CompanyId",
            "probetype" : ["Maize_silage"],
            "disabled": false,
            "required": true,
            "style": {
                "width": "410px",
            }
        },{
            "name": "Perimeter",
            "type": "slider",
            "disabled": false,
            "uriName": "Perimeter",
            "default": 10,
            "min": 10,
            "max": 100,
            "step": 10,
            "style": {
                "width": "250px",
            }
        },{
            "name": "Type",
            "type": "select",
            "disabled": false,
            "uriName": "Type",
            "multiple": false,
            "rawdata": ["Maize_silage"],
            "data": ["Maize_silage"],
            "default": ["Maize_silage"],
            "style": {
                "width": "150px",
                "margin": "0 20px 0 0"
            }
        },{
            "name": "date",
            "type": "daterange",
            "disabled": false,
            "uriName": ["StartDate","EndDate"],
            "style": {
                "width": "250px",
            }
        }]
    }
}

export default reports;