import React from 'react';
import { Typography } from 'antd';
import { i18nApp } from "../../../../../misc/i18n"
const {Paragraph} = Typography
interface IProps{
    value: string
}

export default class PlaintextBundesland extends  React.Component<IProps>{

    render(){
        return (
            <Paragraph className="deparagraph"> {this.props.value != null ? i18nApp.getBundeslandString(this.props.value) : <>&nbsp; </> } </Paragraph>

        )
            
    }

}
