/* tslint:disable */
/* eslint-disable */
/**
 * Importer Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface ImportJobErrorDokumentInhalt
 */
export interface ImportJobErrorDokumentInhalt {
    /**
     * 
     * @type {string}
     * @memberof ImportJobErrorDokumentInhalt
     */
    dokumentInhalt?: string;
}

export function ImportJobErrorDokumentInhaltFromJSON(json: any): ImportJobErrorDokumentInhalt {
    return ImportJobErrorDokumentInhaltFromJSONTyped(json, false);
}

export function ImportJobErrorDokumentInhaltFromJSONTyped(json: any, ignoreDiscriminator: boolean): ImportJobErrorDokumentInhalt {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'dokumentInhalt': !exists(json, 'dokumentInhalt') ? undefined : json['dokumentInhalt'],
    };
}

export function ImportJobErrorDokumentInhaltToJSON(value?: ImportJobErrorDokumentInhalt | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'dokumentInhalt': value.dokumentInhalt,
    };
}

