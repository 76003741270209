import React from "react";
import LoadMask from "../../../misc/loadmask";
import { getImportApiConfig } from "../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import * as Api from "../../../../api/importstatus";
import userNotification from "../../../misc/notification";
import { List, Row, Col, Button } from "antd";

interface IProps extends AuthContextProps {
    onDownload: Function;
    dataSource: Array<any>;
}

interface IState {
    downloadLoading: boolean;
}

let ResutlDownload = class ResultDownload extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            downloadLoading: false
        };
    }
    _getApi() {
        return new Api.ProbenbestellungControllerApi(getImportApiConfig("probeorder", this.props.userData));
    }
    base64ToArrayBuffer(base64: string) {
        var binaryString = window.atob(base64);
        var binaryLen = binaryString.length;
        var bytes = new Uint8Array(binaryLen);
        for (var i = 0; i < binaryLen; i++) {
            var ascii = binaryString.charCodeAt(i);
            bytes[i] = ascii;
        }
        return bytes;
    }
    saveByteArray(reportName: any, byte: any) {
        let blob = new Blob();
        if (reportName.includes(".xml")) {
            blob = new Blob([byte], { type: "application/xml" });
        } else {
            blob = new Blob([byte], { type: "application/pdf" });
        }
        let link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        let fileName = reportName;
        link.download = fileName;
        link.click();
    }

    async onDownload(data: any) {
        this.setState({
            downloadLoading: true
        });
        try {
            let requestBody = {
                probenNummer: data.probenNummer,
                dokumentArt: data.dokumentArt
            };

            let cvasResp = await this._getApi().findCvasDokumentInhalt(requestBody);
            if (cvasResp != null) {
                this.setState({
                    downloadLoading: false
                });
                if (cvasResp.dokumentInhalt != null) {
                    let bytes = this.base64ToArrayBuffer(cvasResp.dokumentInhalt);
                    this.saveByteArray(data.dokumentName, bytes);
                }
            }
        } catch (e) {
            userNotification("error", "notification.order.content.get.error");
            this.setState({
                downloadLoading: false
            });
        }
    }

    render() {
        return (
            <List
                size="small"
                style={{ width: "100%" }}
                bordered={false}
                dataSource={this.props.dataSource}
                renderItem={(item) => {
                    return (
                        <List.Item>
                            <Row style={{ width: "100%" }}>
                                <Col span={3}>
                                    <Button
                                        type="default"
                                        shape="round"
                                        size="small"
                                        onClick={(e) => {
                                            this.onDownload(item);
                                        }}
                                    >
                                        <i className="fas fa-download"></i>
                                    </Button>
                                    {this.state.downloadLoading && <LoadMask />}
                                </Col>
                                <Col span={8} style={{ float: "left" }}>
                                    {item.dokumentArt}
                                </Col>
                                <Col span={13} style={{ float: "left" }}>
                                    {item.dokumentName}
                                </Col>
                            </Row>
                        </List.Item>
                    );
                }}
            />
        );
    }
};

export default withAuth(ResutlDownload);
