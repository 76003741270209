import React from "react";
import ListTable from "./../../listdetail/listtable";
import sichtbarkeit from "./_sichtbarkeit"
import { getRestApiConfig } from "./../../../../../_conf";
import * as Api from "../../../../../api/rest/";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from './../../../../misc/notification';
import {Spin} from "antd"

interface IProps extends AuthContextProps{
    currentCompany : string
}

interface IState{
    listdata: Array<any>,
    loading : boolean
}

let klasse = class Sichtbarkeit extends React.Component<IProps,IState>{
    constructor(props:IProps){
        super(props)

        this.state = {
            listdata: [],
            loading: false
        }
    }
    _getApi() {

        return (new Api.PermissionControllerApi(getRestApiConfig("security", this.props.userData)));
    }
    componentDidMount(){
        this._fetchListData()
    }
    async _fetchListData() {
        this.setState({
            loading: true
        })
        try {
            let requestBody = {
                betrieb: this.props.currentCompany
            }
            let resp = await this._getApi().findAllPermittedUsersForBetrieb(requestBody)
            let modified : Array<any> = []
            resp.forEach(el =>{
                
                let obj = {}
                Object.assign(obj,el)
                Object.assign(obj,{
                    sichtbarkeitCountry: el.attributes != null ? el.attributes.Country : null
                })
                modified.push(obj)
            })
            this.setState({
                listdata: modified,
                loading: false,
            })
        } catch (e) {
            userNotification("error", "notification.user.get.error")
            this.setState({
                loading: false,
            })
        }
    }

    render(){
        return(
            <>
            <ListTable
                    loading={this.state.loading ? { indicator: <Spin spinning={true} ></Spin>} : undefined}
                    cols={sichtbarkeit.columns}
                    data={(this.state.listdata.length) ? this.state.listdata : []}
                    delete={false}
                    hidePagination={true}
                />
            </>
            
        )
    }
}

export default withAuth(klasse)