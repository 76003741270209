import React from 'react';
// import i18n from "./../misc/i18n";
import HelpTree from "./helptree";
import HelpContent from "./helpcontent";

class Help extends React.Component {
    constructor(props){
        super(props)
        this.state = {
            selectedKeys: [],
        };
        this.lRef = React.createRef();
    }

    componentDidMount(){
        if(this.props.helpkey !== undefined){
            this.setState( {
                selectedKeys: [this.props.helpkey],
            });
        }
    }

    shouldComponentUpdate(nextProps, nextState){
        return (nextProps !== this.props || nextState !== this.state) ? true : false;
    }
    
    render(){

        return(
            <div className="demes-help-container">
                <div className="demes-help-tree">
                    <HelpTree 
                        helpkey={this.props.helpkey} 
                        onSelect={(keys) => {
                            this.setState({
                                selectedKeys: []
                            }, () => {
                                this.setState({
                                    selectedKeys: keys
                                })
                            })
                        }} 
                    />
                    <pre>{JSON.stringify(this.props, null, 2)}</pre>
                </div>
                <div className="demes-help-content">
                    {
                        (this.state.selectedKeys.length > 0)
                        ? <HelpContent selected={this.state.selectedKeys} />
                        : null
                    }
                </div>
            </div>            
        )
    }
}

export default Help;