import React from "react";
import { Collapse } from 'antd';

export interface IAccordionProps{
    topnav: boolean
}

export default class Accordion extends React.Component<IAccordionProps>{
    render(){
        //@ts-ignore
        let length = this.props.children != null ? this.props.children.length : 0;
        let cssclass = "demes-accordion demes-accordion";
        cssclass += " demes-accordion-" + length
        cssclass += (this.props.topnav) ? "-wn" : "-won";

        return (
            <div className={cssclass}>
				<Collapse accordion bordered={false}>
                    {this.props.children}
				</Collapse>
            </div>
        )
    }
}
