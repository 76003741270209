import React from "react";
import {  Input } from "antd";
import { withAuth } from "oidc-react";
import { i18nApp } from "../../../../../misc/i18n"

let GeoBundesland = class GeoBundesland extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            value: "",
            disabled: true,
        }
        this.updateSelectedOrt = this.updateSelectedOrt.bind(this)
        this.updateByLandwirt = this.updateByLandwirt.bind(this)
    }



    updateSelectedOrt(e) {
        let itm = e.detail;
        let toShow = itm.bundesland.isoBundesland;
        this.setState(
            {
                value: toShow
            }, () => {
                this.props.onChange(toShow)
            }
        )
    }
    updateByLandwirt(e) {
        this.setState(
            {
                value: e.detail.bundesland
            }, () => {
                this.props.onChange(e.detail.bundesland)
            }
        )
    }


    async componentDidMount() {
        window.addEventListener("landwirtSelected",this.updateByLandwirt, true)
        window.addEventListener("plzSelected", this.updateSelectedOrt,true);
        window.addEventListener("ortSelected", this.updateSelectedOrt,true);
    }
    componentWillUnmount() {
        window.removeEventListener("ortSelected", this.updateSelectedOrt,true);
        window.removeEventListener("plzSelected", this.updateSelectedOrt,true);
        window.removeEventListener("landwirtSelected",this.updateByLandwirt, true)
    }
    componentDidUpdate() {
        if (this.props.data && this.props.data !== {} && this.props.data.bundesland != null && this.state.value === "") {
            this.setState({
                value: this.props.data.bundesland
            },()=>{
                this.props.onChange(this.props.data.bundesland)
            })
        }
    }

    render() {

        let value = (this.state.value === "") ? "" : i18nApp.getBundeslandString(this.state.value)
        if(value.substr(0, 3) === "iso"){
            value = this.state.value;
        }

        return (
            <Input
                disabled={this.props.disabled}
                value={value}
            />
        )
    }

}

export default withAuth(GeoBundesland);