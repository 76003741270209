import React from "react";
import { Typography } from "antd";
import * as Api from "../../../../../../api/rest";
import { getRestApiConfig } from "../../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import userNotification from "../../../../../misc/notification";

const { Paragraph } = Typography;
interface IProps extends AuthContextProps {
    value: Array<number>;
}
interface IState {
    regionIds: Array<number>;
    regionObjs: Array<Api.Region>;
}
let klasse = class PlaintextRegions extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            regionIds: [],
            regionObjs: []
        };
    }
    componentDidUpdate() {
        if (this.props.value != null && this.props.value.length !== this.state.regionIds.length) {
            this.setState({
                regionIds: this.props.value
            });
            this._getData();
        }
    }
    _getApi() {
        return new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));
    }

    async _getData() {
        if (this.props.value.length === 0) {
            return;
        }
        let filter = "regionId=in=(" + this.props.value + ")";
        try {
            let requestBody = {
                size: 20,
                search: filter
            };
            let resp = await this._getApi().findFiltered(requestBody);
            if (resp != null) {
                this.setState({
                    regionObjs: resp.content != null ? resp.content : []
                });
            }
        } catch (e) {
            userNotification("error", "notification.region.get.error");
        }
    }
    getText() {
        if (this.state.regionObjs.length === 0) {
            return <>&nbsp; </>;
        }
        let str = "";
        this.state.regionObjs.forEach((el, i) => {
            if (i === 0) {
                str += el.regionName;
            } else {
                str += ", " + el.regionName;
            }
        });
        return str;
    }

    render() {
        return <Paragraph className="deparagraph"> {this.getText()} </Paragraph>;
    }
};

export default withAuth(klasse);
