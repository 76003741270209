import React from "react";
//import auth from "./../../../misc/auth";
import DataTable from "./../datatable";
import translation from "./_translation";

export default class Translation extends React.Component{

    render(){
        return(
            <div className="sano-translation-wrap">
            <DataTable
                //token={auth.getCookieValue("demes-access_token")}
                // uri={importstatus.baseUri}
                cols={translation.columns}
                size="small"
                // expandable={this.getExpandable()}
            />
            </div>
        )
    }

}