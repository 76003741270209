import i18n from "../../../misc/i18n"
const report = {
    formItems: [
        {
            name: 'land',
            value: [],
            default:[i18n.t('form.label.lkv1Ketose.Alle')]
        },
        {
            name: 'Ketose',
            data: [{key:"Keine Ketose",value:"Keine Ketose"},{key:"Ketose",value:"Ketose"},{key:"Energiemangel",value:"Energiemangel"}],
            default:[i18n.t('form.label.lkv1Ketose.Alle')],
            allowClear: false
        },
        {
            name: 'Einheit',
            data: [{key:"MilchKg",value:"MilchKg"},{key:"Fett%",value:"Fett%"},{key:"Eiweiss%",value:"Eiweiss%"},{key:"Laktose%",value:"Laktose%"},{key:"Zellzahl",value:"Zellzahl"},{key:"Harnstoff",value:"Harnstoff"},{key:"FE",value:"FE"}],
            default: "MilchKg",
            disabled: false
        },
        {
            name: 'ZellzahlVON',
            default: 1
        },
        {
            name: 'ZellzahlBIS',
            default: 10000
        },
        {
            name: 'MelktageVON',
            data: [{key:"0",value:"0"},{key:"100",value:"100"},{key:"305",value:"305"},{key:"90000",value:">400"}],
            default: "0"
        },
        {
            name: 'MelktageBIS',
            data: [{key:"0",value:"0"},{key:"100",value:"100"},{key:"305",value:"305"},{key:"90000",value:">400"}],
            default: "90000"
        },{
            name: 'Lactation',
            max: 15,
            multiple: true,
            allowClear: false
        }
    ]
};

export default report;
