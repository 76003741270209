import { Filter } from '../../../definitions/FilterEnum';
import { Renderer } from '../../../definitions/RendererEnum';

const farmer = {
    detailProp: 'landwirtId',
    formitems: [
        {
            type: 'collapse',
            label: 'farmer',
            defaultActiveKey: ['farmer_0'],
            groups: [
                {
                    label: 'farmer',
                    items: [
                        {
                            label: 'landwirtIdNumber',
                            name: 'landwirtId',
                            type: 'copytextplain',
                            disabled: true
                        },
                        {
                            label: 'contact',
                            type: 'divider'
                        },
                        {
                            label: 'nachname',
                            name: 'nachname',
                            type: 'text',
                            disabled: false,
                            required: true
                        },
                        {
                            label: 'vorname',
                            name: 'vorname',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'email',
                            name: 'email',
                            type: 'email',
                            disabled: false
                        },
                        {
                            label: 'emailbenachrichtigung',
                            name: 'emailbenachrichtigungJn',
                            type: 'boolean',
                            disabled: false
                        },
                        {
                            label: 'telefonNummer',
                            name: 'telefonNummer',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'land',
                            name: 'land',
                            type: 'geoland',
                            disabled: false,
                            data: [],
                            required: true
                        },
                        {
                            label: 'plz',
                            name: 'plz',
                            type: 'geoplz',
                            disabled: false,
                            data: [],
                            required: true
                        },
                        {
                            label: 'ort',
                            name: 'ort',
                            type: 'geoort',
                            disabled: false,
                            data: []
                        },
                        {
                            label: 'bundesland',
                            name: 'bundesland',
                            type: 'geobundesland',
                            disabled: false,
                            data: []
                        },
                        {
                            label: 'strasse',
                            name: 'strasse',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'ortsteil',
                            name: 'ortsteil',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'beschreibung',
                            name: 'beschreibung',
                            type: 'textarea',
                            disabled: false
                        },
                        {
                            label: 'externeSysteme',
                            type: 'divider'
                        },
                        {
                            label: 'externeSystemeDDW',
                            // "name": "['externeSysteme', 'DDW']",
                            name: 'externeSystemeDDW',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'externeSystemeCVAS',
                            name: 'externeSystemeCVAS',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'externeSystemeCRM',
                            name: 'externeSystemeCRM',
                            type: 'text',
                            disabled: false
                        },
                        {
                            label: 'externeSystemeERP',
                            name: 'externeSystemeERP',
                            type: 'text',
                            disabled: false
                        }    
    
                    ]
                },
                {
                    label: 'furtherInfos',
                    items: [
                        {
                            label: 'tsInsertFarmer',
                            name: 'tsInsert',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'uiInsert',
                            name: 'uiInsert',
                            type: 'plaintext',
                            disabled: false
                        },
                        {
                            label: 'tsChangeFarmer',
                            name: 'tsChange',
                            type: 'plaintimedisplay',
                            disabled: false
                        },
                        {
                            label: 'uiChange',
                            name: 'uiChange',
                            type: 'plaintext',
                            disabled: false
                        }

                    ]
                }
            ]}
        ]
        ,
    renderTitle: (rec) => {
        let title = rec.vorname !== null ? rec.vorname + ' ' : '';
        title += rec.nachname;
        return title;
    },

    columns: [
        {
            title: 'landwirtIdNumber',
            dataIndex: 'landwirtId',
            filter: Filter.NUMBER,
            sort: true,
			align:"right"
            // "responsive": ["lg"]
        },
        {
            title: 'nachname',
            dataIndex: 'nachname',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'vorname',
            dataIndex: 'vorname',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'plz',
            dataIndex: 'plz',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'ort',
            dataIndex: 'ort',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'ortsteil',
            dataIndex: 'ortsteil',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg']
        },
        {
            title: 'bundesland',
            dataIndex: 'bundesland',
            renderer: Renderer.BUNDESLAND,
            filter: Filter.BUNDESLAND
            //"sort": true,
            // "responsive": ["lg"]
        },
        {
            title: 'land',
            dataIndex: 'land',
            filter: Filter.TEXT,
            sort: true
            // "responsive": ["lg"]
        },
        {
            title: 'email',
            dataIndex: 'email',
            filter: Filter.TEXT,
            sort: true,
            responsive: ['lg'],
            // renderer: 'email'
        }
    ]
};

export default farmer;