import React from "react";
import { Select, Spin } from "antd";
import * as Api from "../../../../../api/rest";
import { getRestApiConfig } from "../../../../../_conf";
import { AuthContextProps, withAuth } from "oidc-react";
import helper from "./../../listdetail/listtable/_helper";
import userNotification from "../../../../misc/notification";

interface IState {
    selectdata: Array<any>;
    value: Array<number>;
    loading: boolean;
    countries: Array<any>;
}

interface IProps extends AuthContextProps {
    onChange: Function;
    selectedCountrys: Array<string>;
}

let selectLand = class selectLand extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            selectdata: [],
            value: [],
            loading: false,
            countries: []
        };
    }
    componentDidUpdate() {
        if (this.props.selectedCountrys.length !== this.state.countries.length) {
            if (this.props.selectedCountrys.length < this.state.countries.length) {
                this.setState({
                    selectdata: [],
                    value: []
                });
            }

            this.setState({
                countries: this.props.selectedCountrys
            });
            this._getSelectData();
        }
    }

    async _getSelectData() {
        if (this.props.selectedCountrys.length === 0) {
            return;
        }
        this.setState({
            loading: true
        });
        try {
            let api = new Api.RegionControllerApi(getRestApiConfig("region", this.props.userData));
            let params = helper.prepareListParams(
                {
                    filter: { land: this.props.selectedCountrys }
                },
                20
            );
            let requestBody = {
                sort: params.sort,
                search: params.search,
                page: params.page,
                size: params.size
            };
            let resp = await api.findFiltered(requestBody);
            if (resp.content !== undefined) {
                this.setState({
                    selectdata: resp.content !== undefined ? resp.content : [],
                    loading: false
                });
            }
        } catch (e) {
            userNotification("error", "notification.region.get.error");
            this.setState({
                loading: false
            });
        }
    }

    onChange = (value: Array<number>) => {
        this.setState(
            {
                value,
                loading: false
            },
            () => {
                this.props.onChange(value);
            }
        );
    };

    render() {
        const { loading, value } = this.state;
        return (
            <Select
                value={value}
                mode="multiple"
                notFoundContent={loading ? <Spin size="small" /> : null}
                onChange={this.onChange}
                filterOption={false}
                style={{ width: "100%" }}
            >
                {this.state.selectdata.map((itm, y) => {
                    return (
                        <Select.Option key={y} value={itm.regionId}>
                            {itm.regionName}
                        </Select.Option>
                    );
                })}
            </Select>
        );
    }
};

export default withAuth(selectLand);
