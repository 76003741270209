import React from 'react';
import { Spin } from 'antd';

interface IProps {
    src: string;
    title: string;
    frameBorder: number;
}

interface IState {
    loading: boolean;
}
export default class DeIframe extends React.Component<IProps, IState> {
    constructor(props: IProps) {
        super(props);
        this.state = {
            loading: false
        };
    }

    componentDidMount() {
        this.setState({
            loading: true
        });
    }

    render() {
        return (
            <React.Fragment>
                <div className="card">
                    {this.state.loading ? (
                        <div className="overlay">
                            <div className="spinner">
                                <Spin size="large" />
                            </div>
                        </div>
                    ) : null}
                    <iframe
                        onLoad={() => {
                            this.setState({
                                loading: false
                            });
                        }}
                        src={this.props.src ? this.props.src : 'about:blank'}
                        title={this.props.title ? this.props.title : this.props.src}
                        // className={(this.props.className) ? this.props.className : null}
                        className="card"
                        frameBorder={this.props.frameBorder ? this.props.frameBorder : 0}
                    ></iframe>
                </div>
            </React.Fragment>
        );
    }
}
