/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface CredentialRepresentation
 */
export interface CredentialRepresentation {
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    id?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    type?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    userLabel?: string;
    /**
     * 
     * @type {number}
     * @memberof CredentialRepresentation
     */
    createdDate?: number;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    secretData?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    credentialData?: string;
    /**
     * 
     * @type {number}
     * @memberof CredentialRepresentation
     */
    priority?: number;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    value?: string;
    /**
     * 
     * @type {boolean}
     * @memberof CredentialRepresentation
     */
    temporary?: boolean;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    device?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    hashedSaltedValue?: string;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    salt?: string;
    /**
     * 
     * @type {number}
     * @memberof CredentialRepresentation
     */
    hashIterations?: number;
    /**
     * 
     * @type {number}
     * @memberof CredentialRepresentation
     */
    counter?: number;
    /**
     * 
     * @type {string}
     * @memberof CredentialRepresentation
     */
    algorithm?: string;
    /**
     * 
     * @type {number}
     * @memberof CredentialRepresentation
     */
    digits?: number;
    /**
     * 
     * @type {number}
     * @memberof CredentialRepresentation
     */
    period?: number;
    /**
     * 
     * @type {{ [key: string]: Array<string>; }}
     * @memberof CredentialRepresentation
     */
    config?: { [key: string]: Array<string>; };
}

export function CredentialRepresentationFromJSON(json: any): CredentialRepresentation {
    return CredentialRepresentationFromJSONTyped(json, false);
}

export function CredentialRepresentationFromJSONTyped(json: any, ignoreDiscriminator: boolean): CredentialRepresentation {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'type': !exists(json, 'type') ? undefined : json['type'],
        'userLabel': !exists(json, 'userLabel') ? undefined : json['userLabel'],
        'createdDate': !exists(json, 'createdDate') ? undefined : json['createdDate'],
        'secretData': !exists(json, 'secretData') ? undefined : json['secretData'],
        'credentialData': !exists(json, 'credentialData') ? undefined : json['credentialData'],
        'priority': !exists(json, 'priority') ? undefined : json['priority'],
        'value': !exists(json, 'value') ? undefined : json['value'],
        'temporary': !exists(json, 'temporary') ? undefined : json['temporary'],
        'device': !exists(json, 'device') ? undefined : json['device'],
        'hashedSaltedValue': !exists(json, 'hashedSaltedValue') ? undefined : json['hashedSaltedValue'],
        'salt': !exists(json, 'salt') ? undefined : json['salt'],
        'hashIterations': !exists(json, 'hashIterations') ? undefined : json['hashIterations'],
        'counter': !exists(json, 'counter') ? undefined : json['counter'],
        'algorithm': !exists(json, 'algorithm') ? undefined : json['algorithm'],
        'digits': !exists(json, 'digits') ? undefined : json['digits'],
        'period': !exists(json, 'period') ? undefined : json['period'],
        'config': !exists(json, 'config') ? undefined : json['config'],
    };
}

export function CredentialRepresentationToJSON(value?: CredentialRepresentation | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'type': value.type,
        'userLabel': value.userLabel,
        'createdDate': value.createdDate,
        'secretData': value.secretData,
        'credentialData': value.credentialData,
        'priority': value.priority,
        'value': value.value,
        'temporary': value.temporary,
        'device': value.device,
        'hashedSaltedValue': value.hashedSaltedValue,
        'salt': value.salt,
        'hashIterations': value.hashIterations,
        'counter': value.counter,
        'algorithm': value.algorithm,
        'digits': value.digits,
        'period': value.period,
        'config': value.config,
    };
}

