/* tslint:disable */
/* eslint-disable */
/**
 * Sano Webservice
 * Provides webservice calls for Sano
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 * 
 * @export
 * @interface SdnNachrichtEmpfaenger
 */
export interface SdnNachrichtEmpfaenger {
    /**
     * 
     * @type {number}
     * @memberof SdnNachrichtEmpfaenger
     */
    id?: number;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtEmpfaenger
     */
    rolle?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtEmpfaenger
     */
    land?: string;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtEmpfaenger
     */
    emailadresse?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnNachrichtEmpfaenger
     */
    tsInsert?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtEmpfaenger
     */
    uiInsert?: string;
    /**
     * 
     * @type {Date}
     * @memberof SdnNachrichtEmpfaenger
     */
    tsChange?: Date;
    /**
     * 
     * @type {string}
     * @memberof SdnNachrichtEmpfaenger
     */
    uiChange?: string;
}

export function SdnNachrichtEmpfaengerFromJSON(json: any): SdnNachrichtEmpfaenger {
    return SdnNachrichtEmpfaengerFromJSONTyped(json, false);
}

export function SdnNachrichtEmpfaengerFromJSONTyped(json: any, ignoreDiscriminator: boolean): SdnNachrichtEmpfaenger {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        
        'id': !exists(json, 'id') ? undefined : json['id'],
        'rolle': !exists(json, 'rolle') ? undefined : json['rolle'],
        'land': !exists(json, 'land') ? undefined : json['land'],
        'emailadresse': !exists(json, 'emailadresse') ? undefined : json['emailadresse'],
        'tsInsert': !exists(json, 'tsInsert') ? undefined : (new Date(json['tsInsert'])),
        'uiInsert': !exists(json, 'uiInsert') ? undefined : json['uiInsert'],
        'tsChange': !exists(json, 'tsChange') ? undefined : (new Date(json['tsChange'])),
        'uiChange': !exists(json, 'uiChange') ? undefined : json['uiChange'],
    };
}

export function SdnNachrichtEmpfaengerToJSON(value?: SdnNachrichtEmpfaenger | null): any {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        
        'id': value.id,
        'rolle': value.rolle,
        'land': value.land,
        'emailadresse': value.emailadresse,
        'tsInsert': value.tsInsert === undefined ? undefined : (value.tsInsert.toISOString()),
        'uiInsert': value.uiInsert,
        'tsChange': value.tsChange === undefined ? undefined : (value.tsChange.toISOString()),
        'uiChange': value.uiChange,
    };
}

