import React from 'react';
import { Form, Button } from 'antd';
import moment from 'moment';
import i18n from './../../../../misc/i18n';

export default class SearchPanel extends React.Component {
    constructor(props) {
        super(props);
        this.filters = {};
        this.state = {
            btnSize: 'large'
        };
    }

    _handleReset() {
        if (this.props.onReset) {
            this.filters = {};
            this.props.onReset(this.filters);
        }
    }

    _handleSearch() {
        if (this.props.onSearch) {
            this.props.onSearch(this.filters);
        }
    }

    _handleAbort() {
        if (this.props.onAbort) {
            this.props.onAbort();
        }
    }

    render() {
        let fields = [],
            ffields = [];
        this.filters = this.props.filters;
        for (let i = 0; i < this.props.columns.length; i++) {
            let col = this.props.columns[i];
            if (typeof col.filter !== 'undefined') {
                let value = this.props.filters[col.dataIndex];
                if (typeof value !== 'undefined' && value !== null && col.filter === 'daterange') {
                    value = [moment(value[0][0]), moment(value[0][1])];
                }
                fields.push({
                    name: [col.dataIndex],
                    value: value
                });
                ffields.push(
                    <Form.Item key={i} name={[col.dataIndex]} type={col.filter}>
                        {col._doSimpleType(col.filter, col.title)}
                    </Form.Item>
                );
            }
        }
        return (
            <>
                <Form
                    fields={fields}
                    onFieldsChange={(_, all) => {
                        let filters = {};
                        for (let i = 0; i < all.length; i++) {
                            if (all[i].value !== '') {
                                for (let y = 0; y < this.props.columns.length; y++) {
                                    if (this.props.columns[y].dataIndex === all[i].name[0]) {
                                        if (this.props.columns[y].filter === 'daterange') {
                                            filters[all[i].name[0]] = all[i].value
                                                ? [[moment(all[i].value[0]).startOf('day').format(), moment(all[i].value[1]).endOf('day').format()]]
                                                : null;
                                        } else {
                                            filters[all[i].name[0]] = all[i].value ? [all[i].value] : null;
                                        }
                                    }
                                }
                            }
                        }
                        this.filters = filters;
                    }}
                >
                    {ffields}
                </Form>
                <div className="resp-table-menu-button-wrap">
                    <Button
                        size={this.state.btnSize}
                        onClick={() => {
                            this._handleReset();
                        }}
                    >
                        {i18n.t('btn.reset')}
                    </Button>
                    <Button
                        size={this.state.btnSize}
                        onClick={() => {
                            this._handleAbort();
                        }}
                    >
                        {i18n.t('btn.cancel')}
                    </Button>
                    <Button
                        size={this.state.btnSize}
                        type="primary"
                        onClick={() => {
                            this._handleSearch();
                        }}
                    >
                        {i18n.t('btn.ok')}
                    </Button>
                </div>
            </>
        );
    }
}
