import React from "react";

export default class Dashboard extends React.Component{

    componentDidUpdate(){
        if (typeof (Event) === 'function') {
            // modern browsers
            window.dispatchEvent(new Event('resize'));
        } else {
            //This will be executed on old browsers and especially IE
            var resizeEvent = window.document.createEvent('UIEvents');
            resizeEvent.initUIEvent('resize', true, false, window, 0);
            window.dispatchEvent(resizeEvent);
        }
    }

    render(){
        return(
            <div className="demes-dashboard-wrap"></div>
        )
    }
}