import React from 'react';
import { Tree,Spin } from 'antd';
import * as Api from '../../../../api/rest';
import { getRestApiConfig } from '../../../../_conf';
import { withAuth } from "oidc-react";
import i18n, { i18nApp } from './../../../misc/i18n';
let BundeslandRender = class BundeslandRenderer extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            data: [],
            loading: false,
            language: ''
        }
    }

    async loadData(){
        let api = new Api.GeoControllerApi(
            getRestApiConfig('geo', this.props.userData)
        );
        this.setState({
            loading: true
        })
        try {
            let body = await api.findAllBundeslandForUser() 
            let manuelList = [];
            body.forEach((obj) => {
                let newObj = {};
                newObj.key = 'country-' + obj.name;
                newObj.title = obj.name;
                newObj.children = [];
                obj.children.forEach((child) => {
                    newObj.children.push({
                        key: child,
                        title: i18nApp.getBundeslandString(child)
                    });
                });
                manuelList.push(newObj);
            });
            this.setState({
                data: manuelList,
                loading: false
            })
        } catch (e) {
            this.setState({
                loading: false
            })
        }
    }

    componentDidUpdate() {
        if (i18n.language.substr(0,2) !== this.state.language) {
            this.setState(
                {
                    language: i18n.language.substr(0,2)
                },
                () => {
                    this.loadData();
                }
            );
        }
    }

    async componentDidMount() {
        await this.loadData()
    }

    render() {
        if(this.state.loading){
            return(
                <Spin/>
            );
        }
        return (
            <Tree
                checkable
                height={350}
                defaultCheckedKeys={(this.props.defaultCheckedKeys) ? this.props.defaultCheckedKeys[0] : []}
                onCheck={(checkedKeys, e) => {
                    let newKeys = [];
                    //Knotenpunkte aus Tree Objekt entfernen die mit country- anfangen
                    for (let i in checkedKeys) {
                        if (!checkedKeys[i].includes('country-')) {
                            newKeys.push(checkedKeys[i]);
                        }
                    }
                    this.props.handlePreSelect(newKeys, this.props.setSelectedKeys);
                }}
                treeData={this.state.data}
            />
        );
    }
};

export default withAuth(BundeslandRender);