const companies = {
	"detailProp": "betriebsId",
	"columns": [{
		"title": "username",
		"dataIndex": "username",
	}, {
		"title": "land",
		"dataIndex": "sichtbarkeitCountry",
	}, {
		"title": "nachname",
		"dataIndex": "lastName",
	}, {
		"title": "vorname",
		"dataIndex": "firstName",
	}]

}

export default companies;