
import parseJwt from "./permission"

interface IOidcUser{
    access_token: string
}
function getUserRoles(oidcUser: IOidcUser) {
    if(oidcUser == null){
        return []
    }
    let token = parseJwt(oidcUser.access_token);
    return token.realm_access.roles;
}

function isFarmer(oidcUser: IOidcUser){
    let roles = getUserRoles(oidcUser)
    for(let x in roles){
        if(roles[x].toLowerCase().includes("farmer")){
            return true;
        }
    }
    return false;
}
function isAdmin(oidcUser: IOidcUser){
    let roles = getUserRoles(oidcUser)
    for(let x in roles){
        if(roles[x].toLowerCase().includes("administrator")){
            return true;
        }
    }
    return false;
}
function isConsultant(oidcUser: IOidcUser){
    let roles = getUserRoles(oidcUser)
    for(let x in roles){
        if(roles[x].toLowerCase().includes("consultant")){
            return true;
        }
    }
    return false;
}
function isBusinessadmin(oidcUser: IOidcUser){
    let roles = getUserRoles(oidcUser)
    for(let x in roles){
        if(roles[x].toLowerCase().includes("businessadministrator")){
            return true;
        }
    }
    return false;
}
function isExtern(oidcUser: IOidcUser){
    let roles = getUserRoles(oidcUser)
    for(let x in roles){
        if(roles[x].toLowerCase().includes("extern")){
            return true;
        }
    }
    return false;
}
function isLabor(oidcUser: IOidcUser){
    let roles = getUserRoles(oidcUser)
    for(let x in roles){
        if(roles[x].toLowerCase().includes("labor")){
            return true;
        }
    }
    return false;
}



export {
    getUserRoles,
    isAdmin,
    isBusinessadmin,
    isConsultant,
    isFarmer,
    isExtern,
    isLabor
};
